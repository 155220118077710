import AgentCompany from "@/pages/AgentManage/AgentCompany";
import AgentWarehouse from "@/pages/AgentManage/AgentWarehouse";
import { Navigate, Route, Routes } from "react-router-dom";
import ShippingCountry from "@/pages/Shipping/ShippingCountry";
import ShippingCategory from "@/pages/Shipping/ShippingCategory";
import BaseShippingCategory from "@/pages/Shipping/BaseShippingCategory";
import { Button, Result } from "antd";
import ShipmentOrders from "@/pages/Shipping/ShipmentOrders";
import ShipmentProducts, {
    ShipmentProductDetails,
} from "@/pages/Shipping/ShipmentProducts";
import Country from "@/pages/Region/Country";
import Currency from "@/pages/Region/Currency";
import Language from "@/pages/Region/Language";
import Region from "@/pages/Region/Region";
import Wishlists from "@/pages/CustomerManage/Wishlists";
import DestinationWarehouse from "@/pages/Destination/DestinationWarehouse";
import { PageNotFound } from "@/pages/Generic";
import CreateStoreExchange from "@/pages/StoreManage/StoreExchange/CreateStoreExchange";
import Role from "@/pages/RoleManage/Role";
import CustomerUser from "@/pages/CustomerManage/User/CustomerUser";
import ShippingCarts from "@/pages/CustomerManage/ShippingCarts";
import PaymentGateway from "@/pages/PaymentManage/PaymentGateway";
import BuyOrders from "@/pages/BuyAndShip/BuyOrders";
import BuyProducts from "@/pages/BuyAndShip/BuyProducts";
import Transaction from "@/pages/PaymentManage/Transactions";
import WalletOverview from "@/pages/WalletManage/Balance/walletOverview";
import {
    CustomerInvoiceDetails,
    CustomerInvoices,
    InvoicePaymentRule,
} from "@/pages/InvoiceManage/Invoice";
import PrintCustomerInvoice from "@/pages/InvoiceManage/Invoice/Cutomer/printCustomerInvoice";
import PrintAgentInvoice from "@/pages/InvoiceManage/Invoice/agent/printAgentInvoice";
import CampaignsAll from "@/pages/CampaignsManage/Campaign";
import ActionsNeeded from "@/pages/BuyAndShip/ActionsNeeded";
import { RefundDispute, RefundDisputeDetails } from "@/pages/RefundManage";
import AddonServices from "@/pages/AddonManage/AddonService";
import HarvestJobManage from "@/pages/HarvestJobManage/HarvestJobMange";
import TransactionDetailSingle from "@/pages/PaymentManage/Transactions/components/transactionsDetails";
import BuyProductDetail from "@/pages/BuyAndShip/BuyProducts/BuyProductDetail";
import BuyOrderCreate from "@/pages/BuyAndShip/BuyOrders/components/BuyOrderCreate";
import StoreExchange from "@/pages/StoreManage/StoreExchange";
import ExchangeGroup from "@/pages/StoreManage/ExchangeGroup";
import StoreList from "@/pages/StoreManage/StoreList";
import WalletOverviewForAgentCompany from "@/pages/WalletManage/AgentWallet/walletOverviewForAgentCopmany";
import WalletOverviewForUser from "@/pages/WalletManage/UserWallet/walletOverviewForUser";
import PayoutGateways from "@/pages/PaymentManage/PayoutGateways/PayoutGateways";
import { AdminUser } from "@/pages/AdminManage/User/AdminUser";
import {
    AgentInvoiceDetails,
    AgentInvoices,
} from "@/pages/InvoiceManage/Invoice/agent";
import AuthenticatedRoute from "@/components/Authorized/AuthenticatedRoute";
import { getEnvironmentMode } from "@/utils/helper";
import UpdateStoreExchange from "@/pages/StoreManage/StoreExchange/UpdateStoreExchange";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import permissionRoutes from "@/consts/route-base-permissions";
import { ImpersonateSessions } from "@/pages/AdminManage/User/ImpersonateSessions";
import ProductDetailPage from "@/pages/ProductManage/ProductDetail";
import Overview from "@/pages/RoleManage/Overview";
import InventoryProducts from "@/pages/ProductManage/Products/InventoryProducts";
import UserSettings from "@/pages/Profile";
import EditOrder from "@/pages/BuyAndShip/BuyOrders/EditOrder";
import AntDesignLayout from "@/layout/AntDesignLayout/AntDesignLayout";
import ErrorPage from "@/pages/Generic/404";
import { ADMIN_GENERIC_PERMISSION_ENUM } from "@/consts/permission-enum/generic-enum";
import OryAuthProvider from "@/lib/core-react/contexts/OryAuthProvider.tsx";
import Login from "@/pages/AuthAdmin/Login";
import Settings from "@/pages/AuthAdmin/Settings";
import Recovery from "@/pages/AuthAdmin/Recovery";
import AuthError from "@/pages/AuthAdmin/AuthError";
import Tools from "@/pages/Services/Tools";
import SupportInquiry from "@/pages/CustomerManage/SupportInquiry";
import PurchaseCart from "@/pages/CustomerManage/PurchaseCarts";
import BuyOrderCustomOrderCreate from "@/pages/BuyAndShip/BuyOrders/components/BuyOrderCustomOrderCreate/BuyOrderCustomOrderCreate";
import UnlistedPackages from "@/pages/Shipping/UnlistedProducts";
import { DeliveryRequest } from "@/pages/DeliveryManage/DeliveryRequests/deliveryRequest";
import { DeliveryRequestDetails } from "@/pages/DeliveryManage/DeliveryRequests/component/deliveryRequestDetails";
import AgentWithdrawalHistory from "@/pages/WalletManage/AgentWithdrawalHistory";
import CustomerWithdrawalHistory from "@/pages/WalletManage/CustomerWithdrawalHistory";
import InvoiceSummaryShipmentProducts from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/InvoiceSummaryShipmentProducts";
import InvoiceSummaryBuyProduct from "@/pages/BuyAndShip/BuyProducts/InvoiceSummary/InvoiceSummaryBuyProduct";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import InventoryCategory from "@/pages/Shipping/InventoryCategory";
import CostCalculationForm from "@/pages/Services/Tools/CostCalculator";
import Homepage from "@/pages/Home";
import { RfqQuoteList, RfqRequestList } from "@/pages/RfqManage";
import Promotion from "@/pages/Promotion/Promotion";
import { SUPER_ADMIN } from "@/consts/storage";
import PurchaseRule from "@/pages/StoreManage/PurchaseRule";
import PurchaseRuleGroup from "@/pages/StoreManage/PurchaseRuleGroup";
import BuyProductUpdate from "@/pages/BuyAndShip/BuyProductUpdate";

const AllRoutes = () => {
    return (
        <Routes>
            {getEnvironmentMode() !== "development" && (
                <>
                    <Route path="/auth/login" element={<Login />} />
                    <Route path="/auth/settings" element={<Settings />} />
                    <Route path="/auth/recovery" element={<Recovery />} />
                    <Route path="/auth/error" element={<AuthError />} />
                </>
            )}
            <Route
                path={"/"}
                element={
                    <OryAuthProvider>
                        <AntDesignLayout />
                    </OryAuthProvider>
                }
            >
                <Route index element={<Homepage />} />
                <Route
                    path="/403"
                    element={
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                            extra={<Button type="primary">Back Home</Button>}
                        />
                    }
                />

                {/*admin-user-manage start*/}
                <Route path={"/admin"}>
                    <Route
                        index
                        element={<Navigate to="/admin/users" replace />}
                    />
                    <Route
                        path="/admin/users"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminUserRoutePermissions
                                }
                            >
                                <AdminUser />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/admin/acl/roles"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminAclPermissions
                                }
                            >
                                <Role />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/admin/acl/overview"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminAclPermissions
                                }
                            >
                                <Overview />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/admin/impersonate"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminUserRoutePermissions
                                }
                            >
                                <ImpersonateSessions />
                            </AuthenticatedRoute>
                        }
                    />
                    {/*  /admin-manage/sessions*/}
                </Route>
                {/*admin-manage end*/}

                {/* Customer Routes */}
                <Route path={"/customer"}>
                    <Route
                        path="/customer/users"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ...permissionRoutes.manageAdminCustomerRoutePermission,
                                    ...permissionRoutes.customerWalletViewRoutePermissions,
                                ]}
                            >
                                <CustomerUser />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/customer/wishlists"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_WISHLIST,
                                ]}
                            >
                                <Wishlists />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/customer/shipping-carts"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_SHIPPING_CART,
                                ]}
                            >
                                <ShippingCarts />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/customer/support-inquiry"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_SUPPORT_INQUIRY_VIEW,
                                ]}
                            >
                                <SupportInquiry />
                            </AuthenticatedRoute>
                        }
                    />

                    <Route
                        path="/customer/purchase-cart"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_CUSTOMER_PURCHASE_CART,
                                ]}
                            >
                                <PurchaseCart />
                            </AuthenticatedRoute>
                        }
                    />

                    <Route
                        path="/customer/user/:id/wallet"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.customerWalletViewRoutePermissions
                                }
                            >
                                <WalletOverviewForUser />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                <Route path={"/agent"}>
                    <Route
                        path="/agent/companies"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentCompanyManageRoutePermissions
                                }
                            >
                                <AgentCompany />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/agent/warehouses"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentWarehouseManageRoutePermissions
                                }
                            >
                                <AgentWarehouse />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/agent/company/:id/wallet"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentWalletViewRoutePermissions
                                }
                            >
                                <WalletOverviewForAgentCompany />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Refund */}
                <Route path="/refund">
                    <Route
                        path="/refund/disputes"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageRefundRoutePermissions
                                }
                            >
                                <RefundDispute />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/refund/dispute/details/:id/:buyProductId"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageRefundRoutePermissions
                                }
                            >
                                <RefundDisputeDetails />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* RegionRoutes */}
                <Route path={"/region"}>
                    <Route
                        path="/region/countries"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.countryManageRoutePermissions
                                }
                            >
                                <Country />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/region/currencies"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.currencyManageRoutePermissions
                                }
                            >
                                <Currency />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/region/languages"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.languageManageRoutePermissions
                                }
                            >
                                <Language />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/region/regions"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.regionManageRoutePermissions
                                }
                            >
                                <Region />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/region/shipping-countries"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingCountryManageRoutePermissions
                                }
                            >
                                <ShippingCountry />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Store Manage */}
                <Route path={"/store"}>
                    <Route
                        path="/store/stores"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageStoreRoutePermissions
                                }
                            >
                                <StoreList />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/store-fx"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageStoreExchangeRoutePermissions
                                }
                            >
                                <StoreExchange />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/store-fx/create"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                                ]}
                            >
                                <CreateStoreExchange />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/store-fx/update/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                                ]}
                            >
                                <UpdateStoreExchange />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/fx-groups"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageStoreExchangeGroupRoutePermission
                                }
                            >
                                <ExchangeGroup />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/purchase-rule"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageStorPurchaseRuleRoutePermissions
                                }
                            >
                                <PurchaseRule />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/store/purchase-rule-group"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageStorePurchaseRuleGroupRoutePermissions
                                }
                            >
                                <PurchaseRuleGroup />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Shipping */}
                <Route path={"/shipping"}>
                    <Route
                        path="/shipping/regional-categories"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingCategoryManageRoutePermissions
                                }
                            >
                                <ShippingCategory />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/shipping/inventory-categories"
                        element={<InventoryCategory />}
                    />
                    <Route
                        path="/shipping/global-categories"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingBaseShippingCategoryManageRoutePermissions
                                }
                            >
                                <BaseShippingCategory />
                            </AuthenticatedRoute>
                        }
                    />
                    {/* <Route
           path="/shipping/commission"
           element={
           <AuthenticatedRoute permissions={permissionRoutes.shippingCommissionManageRoutePermissions}>
             <Commission />
            </AuthenticatedRoute>
           }

         />
          <Route
            path="/shipping/commission/create"
            element={
              <AuthenticatedRoute permissions={[ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.ADMIN_COMMISSION_ADD]}>
                <CreateCommission />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/shipping/commission/update-category/:regionId/:commissionId/:commissionAssociationId/:categoryId"
            element={
              <AuthenticatedRoute permissions={[]}>
                <UpdateCommissionCategory />
              </AuthenticatedRoute>
            }
          /> */}
                    <Route
                        path="/shipping/shipment-orders"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingShipmentProductManageRoutePermissions
                                }
                            >
                                <ShipmentOrders />
                            </AuthenticatedRoute>
                        }
                    />
                    {/* <Route
            path="/shipping/shipment-orders/create"
            element={
              <AuthenticatedRoute permissions={[]}>
                <CreateOrderForCustomer />
              </AuthenticatedRoute>
            }
          /> */}
                    <Route
                        path="/shipping/shipment-products"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingShipmentProductManageRoutePermissions
                                }
                            >
                                <ShipmentProducts />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/shipping/for-invoice"
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_INVOICE_SUMMERY_VIEW,
                                ]}
                            >
                                <InvoiceSummaryShipmentProducts />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/shipping/unlisted-packages"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.unlistedProductManageRoutePermissions
                                }
                            >
                                <UnlistedPackages />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/shipping/shipment-products/edit/:productId"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.shippingShipmentProductManageRoutePermissions
                                }
                            >
                                <ShipmentProductDetails />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/shipping/destination-warehouses"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.destinationManageRoutePermissions
                                }
                            >
                                <DestinationWarehouse />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/*BuyOrders Route*/}
                <Route path={"/purchase"}>
                    <Route
                        path={"/purchase/buy-orders"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductOrderManageRoutePermissions
                                }
                            >
                                <BuyOrders />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/buy-orders/edit/:id"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductOrderEditRoutePermissions
                                }
                            >
                                <EditOrder />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/buy-products"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductManageRoutePermissions
                                }
                            >
                                <BuyProducts />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/for-invoice"}
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_INVOICE_SUMMERY_VIEW,
                                ]}
                            >
                                <InvoiceSummaryBuyProduct />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/actions"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductTakeActionRoutePermissions
                                }
                            >
                                <ActionsNeeded />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/buy-products/detail/:id"}
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductManageRoutePermissions
                                }
                            >
                                <BuyProductDetail />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path={"/purchase/buy-products/update/:id"}
                        element={
                            <AuthenticatedRoute
                                permissions={[
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_UPDATE,
                                ]}
                            >
                                <BuyProductUpdate />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/purchase/buy-orders/create"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductOrderCreateRoutePermissions
                                }
                            >
                                <BuyOrderCreate />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/purchase/buy-orders/create-custom-order"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.buyProductOrderCreateRoutePermissions
                                }
                            >
                                <BuyOrderCustomOrderCreate />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                <Route path={"/rfq"}>
                    <Route
                        path="/rfq/requests"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageRfqRoutePermissions
                                }
                            >
                                <RfqRequestList />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/rfq/requests/:requestId/quotes"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageRfqRoutePermissions
                                }
                            >
                                <RfqQuoteList />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* invoice */}
                <Route path={"/invoice"}>
                    <Route
                        path="/invoice/customer-invoices"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.customerInvoiceViewRoutePermissions
                                }
                            >
                                <CustomerInvoices />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/invoice/customer-invoice/details/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.customerInvoiceViewRoutePermissions
                                }
                            >
                                <CustomerInvoiceDetails />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/invoice/customer-invoice/print/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.customerInvoiceViewRoutePermissions
                                }
                            >
                                <PrintCustomerInvoice />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/invoice/agent-invoices"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentInvoiceViewRoutePermissions
                                }
                            >
                                <AgentInvoices />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/invoice/agent-invoice/details/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentInvoiceViewRoutePermissions
                                }
                            >
                                <AgentInvoiceDetails />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/invoice/agent-invoice/print/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentInvoiceViewRoutePermissions
                                }
                            >
                                <PrintAgentInvoice />
                            </AuthenticatedRoute>
                        }
                    />

                    <Route
                        path="/invoice/rules"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.agentInvoiceRuleGroupViewRoutePermissions
                                }
                            >
                                <InvoicePaymentRule />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Payment Manage */}
                <Route path={"/payment"}>
                    <Route
                        path="/payment/payment-gateways"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.paymentGateWayManageRoutePermissions
                                }
                            >
                                <PaymentGateway />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/payment/transactions"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.gatewayTransactionsRoutePermissions
                                }
                            >
                                <Transaction />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/payment/cashout-gateways"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.payoutGatewayManageRoutePermissions
                                }
                            >
                                <PayoutGateways />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/payment/transaction/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.gatewayTransactionsRoutePermissions
                                }
                            >
                                <TransactionDetailSingle />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Wallet */}
                <Route path={"/wallet"}>
                    <Route
                        path="/wallet/overview"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.masterWalletViewRoutePermissions
                                }
                            >
                                <WalletOverview />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/wallet/cashouts/customer"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.payoutRequestManageRoutePermissions
                                }
                            >
                                <CustomerWithdrawalHistory />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/wallet/cashouts/agent"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.payoutRequestManageRoutePermissions
                                }
                            >
                                <AgentWithdrawalHistory />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/*Product Manage  */}
                <Route path={"/service"}>
                    <Route
                        path="/service/inventory"
                        element={
                            <AuthenticatedRoute
                                requiredPermission={false}
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_INVENTORY_PRODUCTS,
                                ]}
                            >
                                <InventoryProducts />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/service/inventory/product-detail"
                        element={
                            <AuthenticatedRoute
                                requiredPermission={false}
                                permissions={[
                                    ADMIN_GENERIC_PERMISSION_ENUM.ADMIN_VIEW_INVENTORY_PRODUCTS,
                                ]}
                            >
                                {" "}
                                <ProductDetailPage />,
                            </AuthenticatedRoute>
                        }
                    />

                    <Route path="/service/tools">
                        <Route index element={<Tools />} />
                        <Route
                            path="cost-calculator"
                            element={<CostCalculationForm />}
                        />
                    </Route>

                    <Route
                        path="/service/addons"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAddonsRoutePermissions
                                }
                            >
                                <AddonServices />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Wallet */}
                <Route path={"/delivery-request"}>
                    <Route
                        path="/delivery-request"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminDeliveryRoutePermissions
                                }
                            >
                                <DeliveryRequest />
                            </AuthenticatedRoute>
                        }
                    />
                    <Route
                        path="/delivery-request/details/:id"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.manageAdminDeliveryRoutePermissions
                                }
                            >
                                <DeliveryRequestDetails />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/* Campaigns */}
                {/* incomplete-> TODO*/}
                <Route path="/campaigns">
                    <Route
                        path="/campaigns"
                        element={
                            <AuthenticatedRoute permissions={[]}>
                                <CampaignsAll />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>

                {/*Courier  */}
                {/*<Route path={"/courier"}>*/}
                {/*  <Route*/}
                {/*    path="/courier/courier-gateway"*/}
                {/*    element={*/}
                {/*      <AuthenticatedRoute permissions={permissionRoutes.destinationManageRoutePermissions}>*/}
                {/*        <CourierGateway />*/}
                {/*      </AuthenticatedRoute>*/}
                {/*    }*/}
                {/*  />*/}
                {/*</Route>*/}
                <Route
                    path="/my-profile"
                    element={
                        <AuthenticatedRoute
                            requiredPermission={false}
                            permissions={[]}
                        >
                            <UserSettings />
                        </AuthenticatedRoute>
                    }
                />

                {/*Discount*/}
                {/* <Route path="/discount-manage"> */}
                {/* <Route
            path="/discount-manage/discounts"
            element={
              <AuthenticatedRoute requiredPermission={false} permissions={[]}>
                <Discount />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/discount-manage/discounts/create"
            element={
              <AuthenticatedRoute requiredPermission={false} permissions={[]}>
                <CreateDiscount/>
              </AuthenticatedRoute>
            }
          /> */}
                {/*<Route*/}
                {/*  path="/discount-manage/discounts/update/:id"*/}
                {/*  element={*/}
                {/*    <AuthenticatedRoute permissions={[]}>*/}
                {/*      <UpdateDiscount />*/}
                {/*    </AuthenticatedRoute>*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  path="/discount-manage/discount-usage-histories"*/}
                {/*  element={*/}
                {/*    <AuthenticatedRoute permissions={[]}>*/}
                {/*      <DiscountUsageHistory />*/}
                {/*    </AuthenticatedRoute>*/}
                {/*  }*/}
                {/*/>*/}
                {/* </Route> */}

                {/* Harvest Job */}
                <Route path="/system">
                    <Route
                        path="/system/queue-tasks"
                        element={
                            <AuthenticatedRoute
                                permissions={
                                    permissionRoutes.harvestViewRoutePermissions
                                }
                            >
                                <HarvestJobManage />
                            </AuthenticatedRoute>
                        }
                    />
                </Route>
                <Route
                    path="/promotion/publish-to-all"
                    element={
                        <AuthenticatedRoute permissions={[SUPER_ADMIN]}>
                            <Promotion />
                        </AuthenticatedRoute>
                    }
                />

                <Route path="/error/*" element={<ErrorPage />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
};

export default AllRoutes;
