import { ShipmentProductStatusEnum } from "@/enums/shipmentProductCollectionEnums";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { IUnlistedPackageIdsByTracking } from "@/types/unlistedPackageCollection";

export const getCustomerRateCalc = (
    rateType: "fixed" | "percentage",
    agentRate: number,
    commission: number,
) => {
    if (rateType === "fixed") {
        return agentRate + commission;
    }

    if (rateType === "percentage") {
        const calculatedRate = (agentRate * commission) / 100;
        return agentRate + calculatedRate;
    }

    return 0;
};

export const getCommissionRate = (
    customerRate: number,
    agentRate: number,
    rateType: "fixed" | "percentage",
) => {
    if (!customerRate || !agentRate) {
        return 0;
    }

    if (rateType === "fixed") {
        return customerRate - agentRate;
    }
    if (rateType === "percentage") {
        const calculatedRate = (100 * (customerRate - agentRate)) / agentRate;
        return calculatedRate;
    }
};

export const getSyncableTrackings = (data: ShipmentProductModel[]) => {
    return Array.from(
        new Set(
            data
                .flatMap((d) =>
                    [
                        ShipmentProductStatusEnum.PENDING,
                        ShipmentProductStatusEnum.APPROVED,
                        ShipmentProductStatusEnum.ASSIGNED_AGENT,
                        ShipmentProductStatusEnum.SHIPPING_STARTED,
                    ].includes(d.getStatus() as ShipmentProductStatusEnum)
                        ? d.getPackages().map((p) => p.getAbroadTrackingCode())
                        : [],
                )
                .filter((code): code is string => Boolean(code)),
        ),
    );
};

export const checkTrackingMatch = (
    shipmentProduct: ShipmentProductModel,
    unlistedPackageIdsByTracking: IUnlistedPackageIdsByTracking,
) => {
    const productTrackingCodes = shipmentProduct
        .getPackages()
        .map((p) => p.getAbroadTrackingCode())
        .filter((code): code is string => Boolean(code)); // Ensure valid codes

    const matchingCode = productTrackingCodes.find(
        (code) => unlistedPackageIdsByTracking[code],
    );

    return matchingCode ?? null;
};
