import { Button, Form, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, createRef } from "react";

import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { usePurchaseRuleGroup } from "@/lib/core-react/hooks/private/usePurchaseRuleGroup";

interface IProps {
    setIsShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
}

const AddPurchaseRuleGroupCustomer = ({
    setIsShowAddCustomerModal,
    selectedCustomerUser,
}: IProps) => {
    const {
        allOptionsData: { purchaseRuleGroupOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { createPurchaseRuleGroupUser, isLoading } = usePurchaseRuleGroup();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [selectedCustomerUser, form]);

    const onFinish = async (value: { purchase_rule_group_id: number }) => {
        const user_id = selectedCustomerUser.getId();
        const id = Number(value.purchase_rule_group_id);

        try {
            const res = await createPurchaseRuleGroupUser(id, {
                user_ids: [user_id],
            });
            if (res) {
                setIsShowAddCustomerModal(false);
                notification.success({
                    message: res.message,
                });
            }
        } catch (error: any) {
            showError(error, form);
        }
    };
    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add-Purchase-Rule-Group-Customer"
            ref={formRef}
        >
            <div style={{ margin: "36px 0px" }}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Purchase rule group is required",
                        },
                    ]}
                    name="purchase_rule_group_id"
                    label="Purchase Rule Group"
                >
                    <Select
                        allowClear
                        onFocus={() =>
                            onFetchFilterApi(
                                filterResourceEnum.PURCHASE_RULE_GROUP,
                            )
                        }
                        onClear={() =>
                            onFetchFilterApi(
                                filterResourceEnum.PURCHASE_RULE_GROUP,
                            )
                        }
                        loading={purchaseRuleGroupOptionsData.isLoading}
                        placeholder="Please select a purchase rule group"
                    >
                        {purchaseRuleGroupOptionsData.isLoading
                            ? LoaderSmall
                            : purchaseRuleGroupOptionsData.options.map(
                                  (data) => (
                                      <Select.Option
                                          key={`${data.label}-${data.value}`}
                                          value={data.value}
                                      >
                                          {data.label}
                                      </Select.Option>
                                  ),
                              )}
                    </Select>
                </Form.Item>
            </div>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddPurchaseRuleGroupCustomer;
