import { Flex, Table, Typography } from "antd";
import {
    ActionSequenceModel,
    BuyActionModel,
    VariantMismatchModel,
} from "src/models/buyActionCollectionModel";
import PropertyVariation from "./ExtraComponent/PropertyVariation";
import PriceDisplay from "./ExtraComponent/PriceDisplay";

interface IProps {
    data: ActionSequenceModel;
    buyActionData: BuyActionModel;
}

const StockOutMismatchedTable = ({ data, buyActionData }: IProps) => {
    const columns = [
        {
            title: "Meta",
            dataIndex: "variation_mismatch",
            key: "variation_mismatch",
            render: (_: any, mismatch: VariantMismatchModel) =>
                PropertyVariation(mismatch),
        },
        {
            title: "Old Information",
            dataIndex: "quantity",
            key: "quantity",
            render: (_: any, mismatch: VariantMismatchModel) => {
                return (
                    <Flex vertical>
                        <Typography.Text>
                            Quantity:{" "}
                            {mismatch.getBuyProductVariation()?.getQuantity() ||
                                0}
                        </Typography.Text>
                        <Flex vertical>
                            <div>Unit Price:</div>
                            <PriceDisplay
                                buyActionData={buyActionData}
                                originalUnitPrice={mismatch
                                    .getBuyProductVariation()
                                    ?.getOriginalUnitPrice()
                                    .toFixed(2)}
                                unitPrice={mismatch
                                    .getBuyProductVariation()
                                    ?.getUnitPrice()
                                    .toFixed(2)}
                            />
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: "New Information",
            dataIndex: "quantity",
            key: "new_quantity",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <Flex vertical>
                    <Typography.Text>
                        Quantity:{" "}
                        <span
                            style={{
                                color:
                                    mismatch.getNewQuantity() === 0
                                        ? "#DC2626"
                                        : "inherit",
                            }}
                        >
                            {(mismatch?.getNewQuantity() || 0) === 0
                                ? "Stock out"
                                : ""}
                        </span>
                    </Typography.Text>
                    <Flex vertical>
                        <div>Unit Price:</div>
                        <PriceDisplay
                            buyActionData={buyActionData}
                            originalUnitPrice={mismatch.getNewOriginalUnitPrice()}
                            unitPrice={mismatch.getNewUnitPrice()}
                        />
                    </Flex>
                </Flex>
            ),
        },
        {
            title: "Difference",
            dataIndex: "total_price",
            key: "total_price",
            render: (_: any, mismatch: VariantMismatchModel) => {
                const regionalIncrease = Math.abs(
                    (Number(mismatch.getNewUnitPrice()) || 0) -
                        (mismatch.getBuyProductVariation()?.getUnitPrice() ||
                            0),
                );
                const originalIncrease = Math.abs(
                    (Number(mismatch.getNewOriginalUnitPrice()) || 0) -
                        (mismatch
                            .getBuyProductVariation()
                            ?.getOriginalUnitPrice() || 0),
                );

                return (
                    <PriceDisplay
                        buyActionData={buyActionData}
                        originalUnitPrice={originalIncrease.toString()}
                        unitPrice={regionalIncrease.toString()}
                    />
                );
            },
        },
    ];

    const dataSource: VariantMismatchModel[] =
        data.getVariantMismatches() || [];

    return (
        <div style={{ marginTop: "1rem" }}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.getId()}
            />
        </div>
    );
};

export default StockOutMismatchedTable;
