import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import {
    purchaseRuleGroupCollectionAtom,
    purchaseRuleGroupUserCollectionAtom,
} from "@/lib/core-react/store/store";
import {
    IPurchaseRuleGroupModificationFormData,
    IPurchaseRuleGroupUserModificationFormData,
} from "@/types/purchaseRuleGroupCollection";

export const usePurchaseRuleGroup = () => {
    const [
        purchaseRuleGroupCollectionAtomData,
        setPurchaseRuleGroupCollectionAtomData,
    ] = useAtom(purchaseRuleGroupCollectionAtom);
    const [
        purchaseRuleGroupUserCollectionAtomData,
        setPurchaseRuleGroupUserCollectionAtomData,
    ] = useAtom(purchaseRuleGroupUserCollectionAtom);

    const [isLoading, setIsLoading] = useState(false);
    const { purchaseService } = useService();

    const getPurchaseRuleGroupCollection = async (
        params?: string,
    ): Promise<void> => {
        setPurchaseRuleGroupCollectionAtomData({
            ...purchaseRuleGroupCollectionAtomData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.getPurchaseRuleGroupCollection(
                    params,
                );
            setPurchaseRuleGroupCollectionAtomData({
                ...purchaseRuleGroupCollectionAtomData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseRuleGroupCollectionAtomData({
                ...purchaseRuleGroupCollectionAtomData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };

    const createPurchaseRuleGroup = async (
        payload: IPurchaseRuleGroupModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.createPurchaseRuleGroup(
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleGroupCollectionAtomData({
                ...purchaseRuleGroupCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const updatePurchaseRuleGroup = async (
        id: number,
        payload: IPurchaseRuleGroupModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.updatePurchaseRuleGroup(
                    id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleGroupCollectionAtomData({
                ...purchaseRuleGroupCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const deletePurchaseRuleGroup = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.deletePurchaseRuleGroup(
                    id,
                );
            setIsLoading(false);
            setPurchaseRuleGroupCollectionAtomData({
                ...purchaseRuleGroupCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const getPurchaseRuleGroupUserCollection = async (
        params: Record<string, any>,
    ): Promise<void> => {
        setPurchaseRuleGroupUserCollectionAtomData({
            ...purchaseRuleGroupUserCollectionAtomData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.getPurchaseRuleGroupUserCollection(
                    params,
                );
            setPurchaseRuleGroupUserCollectionAtomData({
                ...purchaseRuleGroupUserCollectionAtomData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseRuleGroupUserCollectionAtomData({
                ...purchaseRuleGroupUserCollectionAtomData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };

    const createPurchaseRuleGroupUser = async (
        id: number,
        payload: IPurchaseRuleGroupUserModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.createPurchaseRuleGroupUser(
                    id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleGroupUserCollectionAtomData({
                ...purchaseRuleGroupUserCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const deletePurchaseRuleGroupUser = async (
        id: number,
        payload: IPurchaseRuleGroupUserModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleGroupResource.deletePurchaseRuleGroupUser(
                    id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleGroupUserCollectionAtomData({
                ...purchaseRuleGroupUserCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return {
        getPurchaseRuleGroupCollection,
        createPurchaseRuleGroup,
        updatePurchaseRuleGroup,
        deletePurchaseRuleGroup,
        getPurchaseRuleGroupUserCollection,
        createPurchaseRuleGroupUser,
        deletePurchaseRuleGroupUser,
        isLoading,
    } as const;
};
