import { useEffect } from "react";
import { PageLoading } from "@ant-design/pro-layout";
import { Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAtomValue } from "jotai";

import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
    AgentCategoryPriceSlotModel,
} from "@/models/agentCategoryPriceReadCollectionModel";
import { IUnlistedPackageClaimFormDataRaw } from "@/types/unlistedPackageCollection";

import { agentCategoryPriceReadCollectionAtom } from "@/lib/core-react/store/store";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import { formatString } from "@/utils/helper";

interface IProps {
    data: UnlistedPackageModel;
    claimValues: IUnlistedPackageClaimFormDataRaw | undefined;
    setClaimValues: React.Dispatch<
        React.SetStateAction<IUnlistedPackageClaimFormDataRaw | undefined>
    >;
}
const CategoryPriceSelection = ({
    data,
    claimValues,
    setClaimValues,
}: IProps) => {
    const { getCategoryPrices } = useGetCategoryPrices();
    const { data: agentCategoryPriceReadCollectionData, isLoading } =
        useAtomValue(agentCategoryPriceReadCollectionAtom);

    const categoryPriceDetails = {
        region: claimValues?.shipment_product?.getRegion().getCode() || "",
        shipping_country_id: claimValues?.shipment_product
            ?.getShippingCountry()
            ?.getId(),
        shipping_category_id: claimValues?.shipment_product
            ?.getShippingCategory()
            ?.getId(),
        destination_warehouse_id: data.getDestinationWarehouse().getId(),
        shipping_type: claimValues?.shipment_product?.getShippingType(),
        shipping_mode: claimValues?.shipment_product?.getShippingMode(),
        agent_warehouse_id: data?.getAgentWarehouse().getId(),
    };

    const getData = async () => {
        await getCategoryPrices(categoryPriceDetails);
    };

    useEffect(() => {
        getData();
    }, [data]);

    const agentCategoryPriceRead =
        agentCategoryPriceReadCollectionData &&
        new AgentCategoryPriceReadCollectionModel(
            agentCategoryPriceReadCollectionData,
        );

    const slotsColumns = [
        {
            title: "Min",
            dataIndex: "min_amount",
            key: "min_amount",
        },
        {
            title: "Max",
            dataIndex: "max_amount",
            key: "max_amount",
        },
        {
            title: "Rate",
            dataIndex: "unitType",
            key: "unitType",
            render: (unitType: string, record: AgentCategoryPriceSlotModel) => (
                <span>
                    {record.getRate()}
                    <small>/-{unitType}</small>
                </span>
            ),
        },
    ];
    const columns: ColumnsType<AgentCategoryPriceReadModel> = [
        {
            title: "Warehouse",
            dataIndex: "warehouse",
            key: "warehouse",
            width: 350,
            render: (_text: string, record: AgentCategoryPriceReadModel) => (
                <>
                    <Typography.Paragraph
                        strong
                        ellipsis={{
                            tooltip: `${record.getAgentWarehouse().getCompanyName()}}`,
                        }}
                        style={{
                            maxWidth: 300,
                            fontSize: 16,
                        }}
                    >
                        {record.getAgentWarehouse().getCompanyName()}
                    </Typography.Paragraph>
                    <Typography.Text
                        ellipsis={{
                            tooltip: `${record.getAgentWarehouse().getName()}}`,
                        }}
                        style={{
                            maxWidth: 300,
                        }}
                    >
                        {record.getAgentWarehouse().getName()}
                    </Typography.Text>
                    <Typography>
                        <Typography.Text>Contains: </Typography.Text>
                        <Tag color="green">{formatString(record.contain)}</Tag>
                    </Typography>
                </>
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) =>
                record.getRate(),
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() === "percentage"
                        ? " (%)"
                        : " (Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Customer Cost",
            dataIndex: "customer_cost",
            key: "customer_cost",
            width: 150,
            align: "center" as const,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getTotalRate()} /- {record.getUnitType()}
                </Typography.Text>
            ),
        },
        {
            title: "Slots",
            dataIndex: ["slots", "data"],
            key: "slots",
            align: "center" as const,
            width: 300,
            render: (
                _: AgentCategoryPriceSlotModel,
                record: AgentCategoryPriceReadModel,
            ) => {
                const unitType = record.getUnitType();
                return (
                    <Table
                        columns={slotsColumns}
                        size="small"
                        dataSource={record
                            .getSlots()
                            ?.getData()
                            .map((slot) => ({
                                ...slot,
                                unitType,
                            }))}
                        rowKey={(r) => r.getId()}
                        pagination={false}
                        bordered={false}
                        className="meta_table"
                    />
                );
            },
        },
    ];

    if (isLoading) return <PageLoading />;

    return (
        <div>
            <Table
                loading={isLoading}
                dataSource={agentCategoryPriceRead?.getData()}
                columns={columns}
                rowHoverable={false}
                rowKey={(r) => r.id}
                style={{
                    backgroundColor: "white",
                    marginTop: 24,
                    width: "100%", // Ensure table takes full container width
                }}
                bordered
                pagination={false}
                rowSelection={{
                    type: "radio",
                    onChange: (_, selectedRows) => {
                        setClaimValues((prevValues) => ({
                            ...prevValues,
                            agent_category_price_read: selectedRows[0],
                        }));
                    },
                }}
            />
        </div>
    );
};

export default CategoryPriceSelection;
