import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { getError } from "../utils/errors";
import { useState } from "react";
import {
    rfqRequestQuotesAtom,
    rfqRequestListAtom,
} from "@/lib/core-react/store/store";

import { IHeaders } from "@/lib/core/request/types/request";
import {
    IRfqRequestsBulkApprove,
    IRfqRequestsApprovePayload,
    RFQRequestAddPayload,
    RFQRequestUpdatePayload,
} from "@/types/rfqRequestCreate";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { getErrorCode } from "@/helpers/getErrorCode";
import { RfqQouteCollectionModel } from "@/models/RfqQouteCollectionModel";

export const useGetRfqList = () => {
    const [requests, setRequests] = useAtom(rfqRequestListAtom);
    const { rfqService } = useService();

    const getRequestsList = async (params?: string) => {
        try {
            setRequests({
                ...requests,
                isLoading: true,
                error: null,
            });
            const response = await rfqService.rfqResource.list(params);
            setRequests({
                ...requests,
                data: response,
                isLoading: false,
                error: null,
                refetch: false,
            });
        } catch (error: any) {
            setRequests({
                ...requests,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                unAuthorized: getErrorCode(error) === 403,
            });

            throw error;
        }
    };

    return { getRequestsList } as const;
};

export const useGetRfqQuotes = () => {
    const [quotes, setQuotes] = useAtom(rfqRequestQuotesAtom);
    const { rfqService } = useService();

    const getRfqQuotes = async (requestId: number, params?: string) => {
        try {
            setQuotes({
                ...quotes,
                isLoading: true,
                error: null,
            });
            const response = await rfqService.rfqResource.quotes(
                requestId,
                params,
            );

            const processData =
                response && new RfqQouteCollectionModel(response);

            setQuotes({
                ...quotes,
                data: processData,
                isLoading: false,
                error: null,
                refetch: false,
            });
        } catch (error: any) {
            setQuotes({
                ...quotes,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                unAuthorized: getErrorCode(error) === 403,
            });

            throw error;
        }
    };

    return { getRfqQuotes } as const;
};
export const useGetRfqQuotesDetail = () => {
    const [quotes, setQuotes] = useAtom(rfqRequestQuotesAtom);
    const { rfqService } = useService();

    const getRfqQuotes = async (
        requestId: number,
        quoteId: number,
        params?: string,
    ) => {
        try {
            setQuotes({
                ...quotes,
                isLoading: true,
                error: null,
            });
            const response = await rfqService.rfqResource.quotesDetail(
                quoteId,
                requestId,
                params,
            );
            setQuotes({
                ...quotes,
                data: response,
                isLoading: false,
                error: null,
                refetch: false,
            });
        } catch (error: any) {
            setQuotes({
                ...quotes,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                unAuthorized: getErrorCode(error) === 403,
            });

            throw error;
        }
    };

    return { getRfqQuotes } as const;
};

export const useUpdateRfqApprove = () => {
    const { rfqService } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateRequestsApprove = async ({
        requestId,
        payload,
        customHeaders = {},
    }: {
        params?: string;
        requestId: string;
        payload: IRfqRequestsApprovePayload;
        customHeaders?: IHeaders;
    }): Promise<any> => {
        setIsLoading(true);
        try {
            const response = await rfqService.rfqResource.approve({
                requestId,
                payload,
                customHeaders,
            });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setIsLoading(false);
            setError(getError(error));
            throw error;
        }
    };
    return { updateRequestsApprove, isLoading, isError };
};

export const useRfqBulkApprove = () => {
    const { rfqService } = useService();
    const [requests, setRequests] = useAtom(rfqRequestListAtom);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const bulkRequestsApprove = async ({
        payload,
        customHeaders = {},
    }: {
        payload: IRfqRequestsBulkApprove;
        customHeaders?: IHeaders;
    }): Promise<any> => {
        setIsLoading(true);
        try {
            const response = await rfqService.rfqResource.bulkApprove({
                payload,
                customHeaders,
            });
            setRequests({
                ...requests,
                data: response,
                isLoading: false,
                error: null,
                refetch: true,
            });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setRequests({
                ...requests,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                unAuthorized: getErrorCode(error) === 403,
            });
            setIsLoading(false);
            setError(getError(error));
            throw error;
        }
    };
    return { bulkRequestsApprove, isLoading, isError };
};

export const useUpdateRfqReject = () => {
    const { rfqService } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateRequestsReject = async ({
        requestId,
        payload,
    }: {
        requestId: string;
        payload: {
            reject_reason: string;
        };
    }): Promise<any> => {
        setIsLoading(true);
        try {
            const response = await rfqService.rfqResource.reject({
                requestId,
                payload,
            });

            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateRequestsReject, isLoading, isError };
};

export const useRfqCreate = () => {
    const { rfqService } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const createRFQ = async ({
        payload,
    }: {
        payload: RFQRequestAddPayload;
    }): Promise<any> => {
        setIsLoading(true);
        try {
            const response = await rfqService.rfqResource.create(payload);

            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { createRFQ, isLoading, isError };
};
export const useRfqUpdate = () => {
    const { rfqService } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateRFQ = async ({
        requestId,
        payload,
    }: {
        requestId: number;
        payload: RFQRequestUpdatePayload;
    }): Promise<any> => {
        setIsLoading(true);
        try {
            const response = await rfqService.rfqResource.update(
                requestId,
                payload,
            );

            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateRFQ, isLoading, isError };
};
