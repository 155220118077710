import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import { PurchaseRuleStatusEnum } from "@/enums/purchaseRuleCollectionEnums";
import { PurchaseRuleGroupCollectionEnums } from "@/enums/purchaseRuleGroupCollectionEnums";
import {
    IPurchaseRuleGroup,
    IPurchaseRuleGroupCollection,
    IPurchaseRuleGroupUser,
    IPurchaseRuleGroupUserCollection,
} from "@/types/purchaseRuleGroupCollection";
import { PurchaseRuleModel } from "./purchaseRuleCollectionModel";
import { IPagination } from "@/types/pagination";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class PurchaseRuleGroupUserModel {
    object: PurchaseRuleGroupCollectionEnums.PurchaseRuleGroupUser;
    id: number;
    group_id: number;
    user: CustomerUserModel;
    created_at: string;

    constructor(data: IPurchaseRuleGroupUser) {
        this.object = data.object;
        this.id = data.id;
        this.group_id = data.group_id;
        this.user = new CustomerUserModel(data.user);
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getGroupId = () => this.group_id;
    getUser = () => this.user;
    getCreatedAt = () => this.created_at;
}

export class PurchaseRuleGroupUserCollectionModel {
    object: PurchaseRuleGroupCollectionEnums.PurchaseRuleGroupUserCollection;
    data: PurchaseRuleGroupUserModel[];
    pagination: IPagination;
    filters: IFilterType;

    constructor(data: IPurchaseRuleGroupUserCollection) {
        this.object = data.object;
        this.data = data.data.map((d) => new PurchaseRuleGroupUserModel(d));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getData = () => this.data;
    getModifiedData = () => this.data.map((d) => d.getUser());
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}

export class PurchaseRuleGroupModel {
    object: PurchaseRuleGroupCollectionEnums.PurchaseRuleGroup;
    id: number;
    name: string;
    status: PurchaseRuleStatusEnum;
    purchase_rule: PurchaseRuleModel;
    created_at: number;

    constructor(data: IPurchaseRuleGroup) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.purchase_rule = new PurchaseRuleModel(data.purchase_rule);
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getStatus = () => this.status;
    getPurchaseRule = () => this.purchase_rule;
    getCreatedAt = () => this.created_at;
}

export class PurchaseRuleGroupCollectionModel {
    object: PurchaseRuleGroupCollectionEnums.PurchaseRuleGroupCollection;
    data: PurchaseRuleGroupModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: IPurchaseRuleGroupCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PurchaseRuleGroupModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    // getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
