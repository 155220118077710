// Enum for admin permissions in the Store module
export enum ADMIN_STORE_PERMISSION_ENUM {
    // Store permissions
    ADMIN_STORE_VIEW = "admin-store-view",
    ADMIN_EXCHANGE_MANAGE = "admin-store-manage",

    // Exchange permissions
    ADMIN_EXCHANGE_VIEW = "admin-exchange-view",

    // Exchange Group permissions
    ADMIN_EXCHANGE_GROUP_VIEW = "admin-exchange-group-view",
    ADMIN_EXCHANGE_GROUP_MANAGE = "admin-exchange-group-manage",

    // Store Exchange Read permissions
    ADMIN_STORE_EXCHANGE_READ_VIEW = "admin-store-exchange-read-view",

    PURCHASE_RULE_VIEW = "purchase-rule-view",
    PURCHASE_RULE_MANAGE = "purchase-rule-manage",
    PURCHASE_RULE_GROUP_VIEW = "purchase-rule-group-view",
    PURCHASE_RULE_GROUP_MANAGE = "purchase-rule-group-manage",
}

export const manageStoreRoutePermissions = [
    ADMIN_STORE_PERMISSION_ENUM.ADMIN_STORE_VIEW,
];
export const manageStoreExchangeRoutePermissions = [
    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_VIEW,
];
export const manageStoreExchangeGroupRoutePermission = [
    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_VIEW,
];

export const manageStorPurchaseRuleRoutePermissions = [
    ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_VIEW,
];
export const manageStorePurchaseRuleGroupRoutePermissions = [
    ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_VIEW,
];
