// Enum for admin permissions in the Wallet module
export enum ADMIN_WALLET_PERMISSION_ENUM {
    // Payout Gateway permissions
    ADMIN_PAYOUT_GATEWAY_VIEW = "admin-payout-gateway-view",
    ADMIN_PAYOUT_GATEWAY_MANAGE = "admin-payout-gateway-manage",

    // Payout Request permissions
    ADMIN_PAYOUT_REQUEST_VIEW = "admin-payout-request-view",
    ADMIN_PAYOUT_REQUEST_MANAGE = "admin-payout-request-manage",

    // Wallet permissions customer
    ADMIN_WALLET_VIEW = "admin-wallet-view",

    // Wallet permissions agent
    ADMIN_VIEW_AGENT_WALLET = "admin-view-agent-wallet",

    ADMIN_CUSTOMER_WALLET_VIEW = "admin-customer-wallet-view",
    ADMIN_CUSTOMER_WALLET_RECHARGE = "admin-customer-wallet-recharge",
}

export const customerWalletViewRoutePermissions = [
    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_CUSTOMER_WALLET_VIEW,
];
export const agentWalletViewRoutePermissions = [
    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
];
export const payoutGatewayManageRoutePermissions = [
    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_VIEW,
];
export const payoutRequestManageRoutePermissions = [
    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_VIEW,
];
export const masterWalletViewRoutePermissions = [
    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
];
