import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import { IAgentCompany } from "@/types/agentCompanyCollection";

export const useGetShippingCompanyMinimal = () => {
    const { shippingAgentService } = useService();
    const [shippingCompanyOptionsData, setShippingCompanyOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getShippingCompanies = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setShippingCompanyOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingAgentService.agentCompanyResource.get(params);

            if (response && response.data) {
                const shippingCompanyList = response.data.filter(
                    (item) => item.shipping_warehouse_count > 0,
                );
                const options =
                    shippingCompanyList.length > 0
                        ? new SelectOptionModel<IAgentCompany>(
                              shippingCompanyList,
                          ).getOptionsByLabelKey("primary_name")
                        : [];

                setShippingCompanyOptionsData({
                    isLoading: false,
                    options: options,
                });
            }
        } catch (_) {
            setShippingCompanyOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getShippingCompanies, shippingCompanyOptionsData } as const;
};
