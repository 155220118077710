import { useEffect, useState } from "react";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import LoaderSmall from "./LoaderSmall";
import { getMatchedResource, getSelectedOption } from "@/filters/utils";
import { ICustomElementProps } from "./CustomElement";
import { Select } from "antd";
import { useDebounce } from "@/hooks/useDebounce";
import { UserSelect } from "./UserSelect";
import ShippingCategoryTreeSelect from "./ShippingCategoryTreeSelect";
import { FieldType } from "@/filters/enum/FieldType";
import { TreeSelect } from "@/types";

export const ResourceInput = (props: ICustomElementProps): JSX.Element => {
    const { name, placeholder, handleChange, fieldValue, resource, type } =
        props;
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const regionParam = !isNaN(Number(props.availableRegionId))
        ? { region_id: Number(props.availableRegionId) }
        : {};

    const { allOptionsData, onFetchFilterApi } = useFiltersApiData(regionParam);
    const {
        exchangeGroupOptionsData,
        exchangeListOptionsData,
        customerOptionsData,
        countryOptionsData,
        shippingCategoryOptionsData,
        shippingCountryOptionsData,
        adminOptionsData,
        agentCompanyOptionsData,
        agentWarehouseOptionsData,
        currencyOptionsData,
        destinationWarehouseOptionsData,
        payoutGatewayOptionsData,
        payoutRequestOptionsData,
        regionOptionsData,
        storeOptionsData,
        paymentGatewayOptionsData,
        roleOptionsData,
        baseShippingCategoryOptionsData,
        orderHandlerOptionsData,
        courierOptionsData,
        warehouseShipmentOptionsData,
        purchaseCompanyOptionsData,
        shippingCompanyOptionsData,
    } = allOptionsData;

    useEffect(() => {
        if (resource) {
            const matchedEnum = getMatchedResource(resource);
            if (matchedEnum) {
                switch (matchedEnum) {
                    case filterResourceEnum.SHIPPING_COUNTRY:
                        onFetchFilterApi(matchedEnum, {}); //FIXME : region_id is required
                        break;
                    case filterResourceEnum.ADMIN:
                    case filterResourceEnum.USER:
                    case filterResourceEnum.CUSTOMER:
                        onFetchFilterApi(matchedEnum, {
                            per_page: 25,
                            keyword: debouncedSearchTerm,
                        });

                        break;

                    default:
                        onFetchFilterApi(matchedEnum);
                        break;
                }
            }
        }
    }, [debouncedSearchTerm]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };

    let fieldInput;

    if (resource === filterResourceEnum.COUNTRY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    countryOptionsData.options,
                )}
                options={countryOptionsData.options}
                loading={countryOptionsData.isLoading}
                notFoundContent={
                    countryOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.REGION) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(fieldValue, regionOptionsData.options)}
                options={regionOptionsData.options}
                loading={regionOptionsData.isLoading}
                notFoundContent={
                    regionOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.COURIER_OPTIONS) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    courierOptionsData.options,
                )}
                options={courierOptionsData.options}
                loading={courierOptionsData.isLoading}
                notFoundContent={
                    courierOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.AGENT_COMPANY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    agentCompanyOptionsData.options,
                )}
                options={agentCompanyOptionsData.options}
                loading={agentCompanyOptionsData.isLoading}
                notFoundContent={
                    agentCompanyOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.SHIPPING_COMPANY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                mode="multiple"
                placeholder={placeholder}
                onChange={(value) => {
                    handleChange({ [name]: value });
                }}
                value={fieldValue}
                options={shippingCompanyOptionsData.options}
                loading={shippingCompanyOptionsData.isLoading}
                notFoundContent={
                    shippingCompanyOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.PURCHASE_COMPANY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                mode="multiple"
                placeholder={placeholder}
                onChange={(value) => {
                    handleChange({ [name]: value });
                }}
                value={fieldValue}
                options={purchaseCompanyOptionsData.options}
                loading={purchaseCompanyOptionsData.isLoading}
                notFoundContent={
                    purchaseCompanyOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.PAYOUT_GATEWAY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    payoutGatewayOptionsData.options,
                )}
                options={payoutGatewayOptionsData.options}
                loading={payoutGatewayOptionsData.isLoading}
                notFoundContent={
                    payoutGatewayOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.PAYOUT_ACCOUNT) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    payoutRequestOptionsData.options,
                )}
                options={payoutRequestOptionsData.options}
                loading={payoutRequestOptionsData.isLoading}
                notFoundContent={
                    payoutRequestOptionsData.isLoading ? LoaderSmall : null
                }
            />
        );
    } else if (resource === filterResourceEnum.STORE) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                mode={type === "multiselect" ? "multiple" : undefined}
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={fieldValue}
                options={storeOptionsData.options}
                loading={storeOptionsData.isLoading}
                notFoundContent={
                    storeOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.CURRENCY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    currencyOptionsData.options,
                )}
                options={currencyOptionsData.options}
                loading={currencyOptionsData.isLoading}
                notFoundContent={
                    currencyOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.AGENT_WAREHOUSE) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    agentWarehouseOptionsData.options,
                )}
                options={agentWarehouseOptionsData.options}
                loading={agentWarehouseOptionsData.isLoading}
                notFoundContent={
                    agentWarehouseOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (
        resource === filterResourceEnum.DESTINATION_WAREHOUSE ||
        resource === filterResourceEnum.DESTINATION_WAREHOUSE_2
    ) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    destinationWarehouseOptionsData.options,
                )}
                options={destinationWarehouseOptionsData.options}
                loading={destinationWarehouseOptionsData.isLoading}
                notFoundContent={
                    destinationWarehouseOptionsData.isLoading
                        ? LoaderSmall
                        : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.EXCHANGE_GROUP) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                onChange={(value) => handleChange({ [name]: value })}
                placeholder={placeholder}
                value={getSelectedOption(
                    fieldValue,
                    exchangeGroupOptionsData.options,
                )}
                options={exchangeGroupOptionsData.options}
                loading={exchangeGroupOptionsData.isLoading}
                notFoundContent={
                    exchangeGroupOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.EXCHANGE_LIST) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value })}
                value={getSelectedOption(
                    fieldValue,
                    exchangeListOptionsData.options,
                )}
                options={exchangeListOptionsData.options}
                loading={exchangeListOptionsData.isLoading}
                notFoundContent={
                    exchangeListOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.ADMIN) {
        fieldInput = (
            <UserSelect
                initialData={adminOptionsData.options as any[]}
                loading={adminOptionsData.isLoading}
                resource={resource}
                onChange={(value) => handleChange({ [name]: value })}
                onSearch={async (value) => {
                    handleSearch(value);
                }}
                value={getSelectedOption(fieldValue, adminOptionsData.options)}
                allowClear={true}
                showSearch={true}
                placeholder={"Search users by email or name"}
                style={{ width: "100%" }}
            />
        );
    } else if (resource === filterResourceEnum.ORDER_HANDLER) {
        fieldInput = (
            <UserSelect
                initialData={orderHandlerOptionsData.options as any[]}
                loading={orderHandlerOptionsData.isLoading}
                resource={resource}
                onChange={(value) => handleChange({ [name]: value })}
                onSearch={async (value) => {
                    handleSearch(value);
                }}
                value={getSelectedOption(
                    fieldValue,
                    orderHandlerOptionsData.options,
                )}
                allowClear={true}
                showSearch={true}
                placeholder={"Search users by email or name"}
                style={{ width: "100%" }}
            />
        );
    } else if (
        resource === filterResourceEnum.CUSTOMER ||
        resource === filterResourceEnum.USER
    ) {
        fieldInput = (
            <UserSelect
                initialData={customerOptionsData.options as any[]}
                loading={customerOptionsData.isLoading}
                resource={resource}
                onChange={(value) => handleChange({ [name]: value })}
                onSearch={(value) => {
                    handleSearch(value);
                }}
                value={getSelectedOption(
                    fieldValue,
                    customerOptionsData.options,
                )}
                allowClear={true}
                showSearch={true}
                placeholder={"Search users by email or name"}
                style={{ width: "100%" }}
            />
        );
    } else if (
        resource === filterResourceEnum.SHIPPING_CATEGORY ||
        resource === filterResourceEnum.SHIPPING_CATEGORY_2
    ) {
        fieldInput = (
            <ShippingCategoryTreeSelect
                initialOptions={
                    shippingCategoryOptionsData.options as unknown as TreeSelect[]
                }
                value={fieldValue}
                allowClear={true}
                showSearch={true}
                loading={shippingCountryOptionsData.isLoading}
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value?.value })}
                style={{ width: "100%" }}
            />
        );
    } else if (resource === filterResourceEnum.BASE_SHIPPING_CATEGORY) {
        fieldInput = (
            <ShippingCategoryTreeSelect
                initialOptions={
                    baseShippingCategoryOptionsData.options as unknown as TreeSelect[]
                }
                value={fieldValue}
                allowClear={true}
                showSearch={true}
                loading={baseShippingCategoryOptionsData.isLoading}
                placeholder={placeholder}
                onChange={(value) => handleChange({ [name]: value?.value })}
                style={{ width: "100%" }}
            />
        );
    } else if (resource === filterResourceEnum.SHIPPING_COUNTRY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                onChange={(value) => handleChange({ [name]: value })}
                placeholder={placeholder}
                value={getSelectedOption(
                    fieldValue,
                    shippingCountryOptionsData.options,
                )}
                options={shippingCountryOptionsData.options}
                loading={shippingCountryOptionsData.isLoading}
                notFoundContent={
                    shippingCountryOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.WAREHOUSE_SHIPMENT) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                onChange={(value) => handleChange({ [name]: value })}
                placeholder={placeholder}
                value={getSelectedOption(
                    fieldValue,
                    warehouseShipmentOptionsData.options,
                )}
                options={warehouseShipmentOptionsData.options}
                loading={warehouseShipmentOptionsData.isLoading}
                notFoundContent={
                    warehouseShipmentOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (resource === filterResourceEnum.GATEWAY) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                onChange={(value) => handleChange({ [name]: value })}
                placeholder={placeholder}
                value={getSelectedOption(
                    fieldValue,
                    paymentGatewayOptionsData.options,
                )}
                options={paymentGatewayOptionsData.options}
                loading={paymentGatewayOptionsData.isLoading}
                notFoundContent={
                    paymentGatewayOptionsData.isLoading ? LoaderSmall : null
                }
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else if (
        resource === filterResourceEnum.AGENT_ROLE &&
        type === FieldType.MULTI_SELECT
    ) {
        fieldInput = (
            <Select
                allowClear
                showSearch
                mode="multiple"
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                placeholder={placeholder}
                options={roleOptionsData.options}
                loading={roleOptionsData.isLoading}
                notFoundContent={roleOptionsData.isLoading ? LoaderSmall : null}
                filterOption={(input, option) => {
                    if (!option) return false;
                    return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                }}
            />
        );
    } else {
        return <></>;
    }

    return fieldInput;
};
