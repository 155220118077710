import {
    BuyProductsResource,
    PurchaseCommissionResource,
    PurchaseRuleGroupResource,
    PurchaseRuleResource,
} from "../../resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";

class PurchaseService extends CommerceApiService {
    public buyProductsResource: BuyProductsResource;
    public purchaseCommissionResource: PurchaseCommissionResource;
    purchaseRuleResource: PurchaseRuleResource;
    purchaseRuleGroupResource: PurchaseRuleGroupResource;
    constructor() {
        super();

        this.buyProductsResource = new BuyProductsResource(this.config);
        this.purchaseCommissionResource = new PurchaseCommissionResource(
            this.config,
        );
        this.purchaseRuleResource = new PurchaseRuleResource(this.config);
        this.purchaseRuleGroupResource = new PurchaseRuleGroupResource(
            this.config,
        );
    }
}

export default PurchaseService;
