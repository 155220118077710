import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Table,
    Dropdown,
    Modal,
    Tag,
    Flex,
    notification,
    Row,
    Col,
} from "antd";

import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { purchaseRuleCollectionAtom } from "@/lib/core-react/store/store";
import { IFilterType } from "@/types/filters";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import CommonError from "@/components/Error/CommonError";
import { usePurchaseRule } from "@/lib/core-react/hooks/private/usePurchaseRule";
import {
    PurchaseRuleCollectionModel,
    PurchaseRuleModel,
} from "@/models/purchaseRuleCollectionModel";
import {
    PurchaseRuleModalEnum,
    PurchaseRuleStatusEnum,
} from "@/enums/purchaseRuleCollectionEnums";
import PurchaseRuleModification from "./PurchaseRuleModification";
import { formatDateTime } from "@/utils/helpers";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import FiltersComponent from "@/components/FiltersComponent";
import { PaginationModel } from "@/models/pagination";

const PurchaseRule = () => {
    const { getPurchaseRuleCollection, deletePurchaseRule } = usePurchaseRule();
    const [
        {
            data: purchaseRuleCollectionAtomData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(purchaseRuleCollectionAtom);

    const PurchaseRuleCollectionData =
        purchaseRuleCollectionAtomData &&
        new PurchaseRuleCollectionModel(purchaseRuleCollectionAtomData);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();
    const { isMobile } = useWindowWidth();

    const [modalOpen, setModalOpen] = useState<PurchaseRuleModalEnum>();
    const [selectedPurchaseRule, setSelectedPurchaseRule] =
        useState<PurchaseRuleModel>();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getPurchaseRuleCollection);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Filter
    const filterData = PurchaseRuleCollectionData?.getFilters();

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getPurchaseRuleCollection);
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPurchaseRuleCollection,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PurchaseRuleCollectionData,
        handlePaginationChange,
    );

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && PurchaseRuleCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        PurchaseRuleCollectionData?.getFilters(),
    ]);

    const purchaseRuleColumns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 70,
            key: "purchase-rule-id",
            render: (_: string, record: PurchaseRuleModel) => {
                return <div>{record.getId()}</div>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "purchase-rule-name",
            render: (_: string, record: PurchaseRuleModel) => {
                return (
                    <Flex vertical>
                        {record.getName()}{" "}
                        <div>
                            {record.getIsDefault() ? (
                                <Tag color="processing">Default</Tag>
                            ) : (
                                ""
                            )}
                            <Tag
                                style={{ textTransform: "capitalize" }}
                                color={
                                    record.getStatus() ===
                                    PurchaseRuleStatusEnum.Active
                                        ? "#87d068"
                                        : "#f50"
                                }
                            >
                                {record.getStatus()}
                            </Tag>
                        </div>
                    </Flex>
                );
            },
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "purchase-rule-region",
            render: (_: string, record: PurchaseRuleModel) => {
                return <div>{record.getRegion()?.getName()}</div>;
            },
        },
        {
            title: "Rules",
            dataIndex: "rules",
            hidden: isMobile,
            key: "purchase-rule-rules",
            render: (_: string, record: PurchaseRuleModel) => {
                return (
                    <div>
                        {Object.entries(record.getRule()).map(
                            ([key, value]) => (
                                <div
                                    style={{ textTransform: "capitalize" }}
                                    key={key}
                                >
                                    <strong>{key}:</strong>{" "}
                                    {Array.isArray(value)
                                        ? value.join(", ")
                                        : typeof value === "string"
                                          ? value.replace(/_/g, " ")
                                          : value}
                                </div>
                            ),
                        )}
                    </div>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            align: "center" as const,
            render: (_: string, record: PurchaseRuleModel) => {
                return (
                    <div>
                        <Tag color="purple">
                            {formatDateTime(record.getCreatedAt())}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            hidden: !checkActionPermission(
                [ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_MANAGE],
                "checkPermission",
                null,
            ),
            fixed: isMobile ? ("right" as const) : undefined,
            align: "center" as const,
            width: 100,
            render: (_: string, record: PurchaseRuleModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_MANAGE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedPurchaseRule(record);
                            setModalOpen(
                                PurchaseRuleModalEnum["Update Purchase Rule"],
                            );
                        },
                    },
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_MANAGE,
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        onClick: async () => {
                            deletePurchaseRuleHandler(record.getId());
                        },
                        danger: true,
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const deletePurchaseRuleHandler = (id: number) => {
        Modal.confirm({
            title: "Do you want to delete this item ?",
            icon: <ExclamationCircleOutlined />,
            content: "Once deleted, the item cannot be recovered.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            async onOk() {
                try {
                    await deletePurchaseRule(id);
                    showSuccessAlert("Purchase rule deleted successfully!");
                    Promise.resolve();
                    await getPurchaseRuleCollection();
                } catch (error) {
                    showError(error);
                    Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Request aborted.",
                });
            },
        });
    };

    const onCancelModal = () => {
        setModalOpen(undefined);
    };

    // Error handle
    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Purchase Rule"
                style={{ marginTop: "10px" }}
                onBack={() => window.history.back()}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_MANAGE,
                        <Button
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            key="create-rule-btn"
                            icon={<PlusOutlined />}
                            onClick={() =>
                                setModalOpen(
                                    PurchaseRuleModalEnum[
                                        "Create Purchase Rule"
                                    ],
                                )
                            }
                        >
                            Add Rule
                        </Button>,
                        null,
                    ),
                ]}
            >
                <div>
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <Table
                        bordered
                        style={{ marginTop: "15px" }}
                        loading={isLoading}
                        rowKey="id"
                        pagination={{
                            ...paginationConfig,
                            showTotal: (total) => (
                                <div>
                                    Total{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            color: "green",
                                        }}
                                    >
                                        {total}
                                    </span>{" "}
                                    Items
                                </div>
                            ),
                        }}
                        dataSource={PurchaseRuleCollectionData?.getData()}
                        columns={purchaseRuleColumns}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </PageHeader>

            <Modal
                title={modalOpen}
                open={Boolean(modalOpen)}
                destroyOnClose={true}
                footer={null}
                onCancel={onCancelModal}
            >
                {modalOpen ===
                    PurchaseRuleModalEnum["Update Purchase Rule"] && (
                    <PurchaseRuleModification
                        data={selectedPurchaseRule}
                        onHide={onCancelModal}
                    />
                )}

                {modalOpen ===
                    PurchaseRuleModalEnum["Create Purchase Rule"] && (
                    <PurchaseRuleModification onHide={onCancelModal} />
                )}
            </Modal>
        </>
    );
};

export default PurchaseRule;
