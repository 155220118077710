import { useEffect } from "react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Spin,
} from "antd";

import { transformEnumToLabeledValue } from "@/utils/helpers";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { PurchaseRuleModel } from "@/models/purchaseRuleCollectionModel";
import { usePurchaseRule } from "@/lib/core-react/hooks/private/usePurchaseRule";
import { useAtom } from "jotai";
import { purchaseRuleSchemaAtom } from "@/lib/core-react/store/store";
import { AddressSchemaModel } from "@/models/addressSchemaModel";
import {
    PurchaseRuleStatusEnum,
    PurchaseRuleTypeEnum,
} from "@/enums/purchaseRuleCollectionEnums";
import { IPurchaseRuleModificationFormData } from "@/types/purchaseRuleCollection";

interface IProps {
    onHide: () => void;
    data?: PurchaseRuleModel;
}

const PurchaseRuleModification = ({ onHide, data }: IProps) => {
    const {
        getPurchaseRuleCollection,
        getPurchaseRuleSchema,
        createPurchaseRule,
        updatePurchaseRule,
        isLoading,
    } = usePurchaseRule();

    const [{ data: purchaseRuleSchemaAtomData, isLoading: isLoadingSchema }] =
        useAtom(purchaseRuleSchemaAtom);

    const PurchaseRuleSchemaData =
        purchaseRuleSchemaAtomData &&
        new AddressSchemaModel(purchaseRuleSchemaAtomData);

    const [form] = Form.useForm<IPurchaseRuleModificationFormData>();
    const ruleType = Form.useWatch("type", form);

    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (ruleType) {
            getPurchaseRuleSchema(ruleType);
        }
    }, [ruleType]);

    const onFinish = async (value: IPurchaseRuleModificationFormData) => {
        try {
            if (data) {
                await updatePurchaseRule(data.getId(), value);
            } else {
                await createPurchaseRule(value);
            }
            onHide();
            await getPurchaseRuleCollection();
        } catch (error) {
            showError(error, form);
        }
    };

    const webFull = PurchaseRuleSchemaData?.getData().getWebFull() || {};
    const fields = PurchaseRuleSchemaData?.getData().getFields() || {};

    const renderField = (fieldKey, fieldSchema) => {
        const {
            type,
            place_holder,
            label,
            is_required,
            errors,
            data_source,
            data,
            properties,
        } = fieldSchema;

        let fieldComponent;
        const rules = [
            {
                required: is_required,
                message: errors?.on_empty || `${label} is required`,
            },
        ];

        // Ensure all fields are stored inside the "rule" object
        const ruleObject = { name: ["rule", fieldKey] };

        if (type === "string") {
            fieldComponent = (
                <Form.Item label={label} {...ruleObject} rules={rules}>
                    <Input
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                    />
                </Form.Item>
            );
        } else if (type === "number") {
            fieldComponent = (
                <Form.Item label={label} {...ruleObject} rules={rules}>
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                    />
                </Form.Item>
            );
        } else if (type === "select" || type == "multiselect") {
            const options =
                data_source === "on-premise"
                    ? Object.keys(data?.data || {}).map((x) => ({
                          label: data?.data[x]?.label,
                          value: data?.data[x]?.value,
                      }))
                    : [];

            fieldComponent = (
                <Form.Item label={label} {...ruleObject} rules={rules}>
                    <Select
                        mode={type === "multiselect" ? "multiple" : undefined}
                        filterOption={(input, option) =>
                            (option?.label?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        showSearch={true}
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                        options={options}
                    />
                </Form.Item>
            );
        }

        return fieldComponent;
    };

    const renderLines = (lines) => {
        return lines.map((line) => (
            <Row gutter={16} key={line.join("-")}>
                {line.map((fieldKey) => (
                    <Col span={24} key={fieldKey}>
                        {fields && renderField(fieldKey, fields[fieldKey])}
                    </Col>
                ))}
            </Row>
        ));
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name={data ? "Update Purchase Rule" : "Create Purchase Rule"}
            initialValues={{
                name: data?.getName(),
                status: data?.getStatus(),
                type: data
                    ? data.getType()
                    : PurchaseRuleTypeEnum.CHECKOUT_TOTAL_AMOUNT,
                is_default: data ? data.getIsDefault() : false,
                region_id: data?.getRegionId(),
                rule: data?.getRule(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Name" />
            </Form.Item>

            {!data && (
                <>
                    <Form.Item
                        rules={[
                            { required: true, message: "region is required" },
                        ]}
                        name="region_id"
                        label="Region"
                    >
                        <Select
                            loading={regionOptionsData.isLoading}
                            placeholder="Please select a region"
                            options={regionOptionsData.options}
                        />
                    </Form.Item>
                </>
            )}

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select
                    placeholder="Please select a status"
                    options={transformEnumToLabeledValue(
                        PurchaseRuleStatusEnum,
                    )}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Rule type is required" }]}
                name="type"
                label="Choose a rule type"
            >
                <Radio.Group
                    options={[
                        {
                            value: PurchaseRuleTypeEnum.CHECKOUT_TOTAL_AMOUNT,
                            label: "Checkout Total Amount",
                        },
                        {
                            value: PurchaseRuleTypeEnum.STORE_TOTAL_AMOUNT,
                            label: "Store Total Amount",
                        },
                    ]}
                />
            </Form.Item>

            {isLoadingSchema ? (
                <Spin />
            ) : (
                webFull &&
                Object.keys(webFull).map((lineKey) =>
                    renderLines([webFull[lineKey]]),
                )
            )}

            <Form.Item valuePropName="checked" name="is_default">
                <Checkbox>Mark as default</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PurchaseRuleModification;
