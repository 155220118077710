import { PageHeader } from "@ant-design/pro-layout";
import { Flex, Table, TableColumnsType, Tag, Typography } from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";

import { rfqRequestQuotesAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";

import { useEffect } from "react";
import { useGetRfqQuotes } from "@/lib/core-react/hooks/private/useRfq";
import StatusTag from "@/components/StatusTag/StatusTag";
import { PaginationModel } from "@/models/pagination";
import CommonError from "@/components/Error/CommonError";

import { defaultQuery } from "@/consts/defaultQuery";
import { BREAK_POINTS } from "@/consts/appConstants";
import ShippingText from "@/components/ShippingText";
import { formatDateTime } from "@/utils/helpers";
import { RFQRequestQuoteModel } from "@/models/RfqQouteCollectionModel";

const { Text } = Typography;
import { Link, useParams } from "react-router-dom";
import RFQQuoteVariantTable from "./components/RfqQuoteVariantTable";
import ReadOnlyEditorReactQuill from "@/components/MvnRichTextEditor/ReadOnlyEditorReactQuill";

const RfqQuoteList = () => {
    const { requestId } = useParams();

    const { getRfqQuotes } = useGetRfqQuotes();

    const [
        {
            data: rfqRequestQuoteCollectionModel,
            isLoading: isLoadingRequests,
            error,
            unAuthorized,
            refetch,
        },
    ] = useAtom(rfqRequestQuotesAtom);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
        isFirstCall,
    } = useDataFilters();

    const filterData = rfqRequestQuoteCollectionModel?.filters;

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData);
        }
    }, [isFetched, initializeAvailableFilter]);

    const handleGetQuotes = async (params) => {
        await getRfqQuotes(Number(requestId), params);
    };

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, handleGetQuotes);
        }
    }, [
        isFirstCall,
        filters,
        isFetched,
        refetchFromFilter,
        refetchFromFilter,
        refetch,
    ]);

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,
            },
            handleGetQuotes,
        );
    };

    // ------------------------------ Modal -> --------------------------- //

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,

                ...pageInfo,
            },
            handleGetQuotes,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        rfqRequestQuoteCollectionModel,
        handlePaginationChange,
    );

    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    const columns: TableColumnsType<RFQRequestQuoteModel> = [
        {
            title: "Product Info",
            key: "product_info",
            width: 600,
            render: (_tags: any, record) => {
                return (
                    <>
                        <Flex gap={12} vertical>
                            <Text
                                strong
                                ellipsis={{
                                    tooltip: record.getProductTitle(),
                                }}
                                style={{
                                    maxWidth: "500px",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {record.getProductTitle()}
                            </Text>
                            <ShippingText label="PB">
                                <Text strong copyable>
                                    {record.getProductId()}
                                </Text>
                            </ShippingText>
                            {record?.getValidUntil() && (
                                <ShippingText label="Valid Until">
                                    <StatusTag
                                        color="purple"
                                        slug={""}
                                        text={`${formatDateTime(record.getValidUntil())}`}
                                    />
                                </ShippingText>
                            )}

                            {record?.getProductLink() && (
                                <ShippingText label="Product link">
                                    <Link
                                        style={{ fontWeight: "bold" }}
                                        to={record.getProductLink()}
                                        target="_blank"
                                    >
                                        {record.getProductLink()}
                                    </Link>
                                </ShippingText>
                            )}

                            {record?.getProductDescription() && (
                                <Text>
                                    <Text>Description: </Text>
                                    <ReadOnlyEditorReactQuill
                                        value={record.getProductDescription()}
                                    />
                                </Text>
                            )}
                            {record?.getAgentNote() && (
                                <Text>
                                    <Text>Agent Note: </Text>

                                    <ReadOnlyEditorReactQuill
                                        value={record.getAgentNote()}
                                    />
                                </Text>
                            )}
                        </Flex>
                    </>
                );
            },
        },
        {
            title: "Variation",
            key: "Variation",
            width: 600,
            render: (_tags: any, record) => {
                const variation =
                    record.getQuoteVariants()?.getVariants() || [];

                return (
                    <>
                        <RFQQuoteVariantTable
                            productId={record.getProductId()}
                            data={variation}
                        />
                    </>
                );
            },
        },
        {
            title: "Participant",
            dataIndex: "participant",
            key: "participant",
            render: (_tags: any, record) => {
                return (
                    <>
                        <Flex gap={12} vertical>
                            {record.getParticipant()?.getAgentCompany() &&
                                record
                                    .getParticipant()
                                    ?.getAgentCompany()
                                    ?.getName() && (
                                    <ShippingText label="Source Company">
                                        <Tag color="green">
                                            {record
                                                .getParticipant()
                                                ?.getAgentCompany()
                                                ?.getName()}
                                        </Tag>
                                    </ShippingText>
                                )}
                        </Flex>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader
                ghost={false}
                title={"RFQ Quotes"}
                onBack={() => window.history.back()}
            >
                <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                />

                <div style={{ marginTop: 20 }}>
                    <Table
                        rowHoverable={false}
                        columns={columns}
                        dataSource={rfqRequestQuoteCollectionModel?.getData()}
                        pagination={{
                            ...paginationConfig,
                            showTotal: (total) => (
                                <div>
                                    Total{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            color: "green",
                                        }}
                                    >
                                        {total}
                                    </span>{" "}
                                    Items
                                </div>
                            ),
                        }}
                        bordered
                        loading={isLoadingRequests || isLoadingRequests}
                        rowKey="id"
                        scroll={{ x: BREAK_POINTS.XL }}
                    />
                </div>
            </PageHeader>
        </>
    );
};

export default RfqQuoteList;
