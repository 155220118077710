import { Table } from "antd";
import {
    ActionSequenceModel,
    BuyActionModel,
    VariantMismatchModel,
} from "src/models/buyActionCollectionModel";
import PropertyVariation from "./ExtraComponent/PropertyVariation";
import PriceDisplay from "./ExtraComponent/PriceDisplay";

interface IProps {
    data: ActionSequenceModel;
    buyActionData: BuyActionModel;
}

const PriceChangeIssues = ({ data, buyActionData }: IProps) => {
    const columns = [
        {
            title: "Meta",
            dataIndex: "variation_mismatch",
            key: "variation_mismatch",
            render: (_: any, mismatch: VariantMismatchModel) => {
                return PropertyVariation(mismatch);
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (_: any, mismatch: VariantMismatchModel) =>
                mismatch.getBuyProductVariation()?.getQuantity() || 0,
        },
        {
            title: "Old Price",
            dataIndex: "unit_price",
            key: "unit_price",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <PriceDisplay
                    buyActionData={buyActionData}
                    originalUnitPrice={mismatch
                        .getBuyProductVariation()
                        ?.getOriginalUnitPrice()
                        .toFixed(2)}
                    unitPrice={mismatch
                        .getBuyProductVariation()
                        ?.getUnitPrice()
                        .toFixed(2)}
                />
            ),
        },
        {
            title: "New Price",
            dataIndex: "new_unit_price",
            key: "new_unit_price",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <PriceDisplay
                    buyActionData={buyActionData}
                    originalUnitPrice={mismatch.getNewOriginalUnitPrice()}
                    unitPrice={mismatch.getNewUnitPrice()}
                />
            ),
        },
        {
            title: "Difference",
            dataIndex: "total_price",
            key: "total_price",
            render: (_: any, mismatch: VariantMismatchModel) => {
                const regionalIncrease = Math.abs(
                    (Number(mismatch.getNewUnitPrice()) || 0) -
                        (mismatch.getBuyProductVariation()?.getUnitPrice() ||
                            0),
                );
                const originalIncrease = Math.abs(
                    (Number(mismatch.getNewOriginalUnitPrice()) || 0) -
                        (mismatch
                            .getBuyProductVariation()
                            ?.getOriginalUnitPrice() || 0),
                );

                return (
                    <PriceDisplay
                        buyActionData={buyActionData}
                        originalUnitPrice={originalIncrease.toString()}
                        unitPrice={regionalIncrease.toString()}
                    />
                );
            },
        },
    ];

    const dataSource: VariantMismatchModel[] =
        data.getVariantMismatches() || [];

    return (
        <div style={{ marginTop: "1rem" }}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.getId()}
            />
        </div>
    );
};

export default PriceChangeIssues;
