import React from "react";
import { Flex, Typography } from "antd";
import { IProperty, ISku } from "@/types/productDetail";
import Separator from "@/components/Separator/Separator";

const { Text } = Typography;

interface PropertyAssociationsProps {
    sku: ISku;
    properties: IProperty[];
}

const PropertyAssociations: React.FC<PropertyAssociationsProps> = ({
    sku,
    properties,
}) => {
    return (
        <Flex vertical align="start">
            {sku.property_associations.data.map((association) => {
                const matchProperty = properties.find(
                    (p) => p.id === association.property_id,
                );
                if (!matchProperty) return null;

                const propertyValue = matchProperty.values.data.find(
                    (v) => v.id === association.property_value_id,
                );
                if (!propertyValue) return null;

                return (
                    <React.Fragment key={association.id}>
                        <Text>
                            <Text>{matchProperty.name}:</Text>
                            <Text
                                strong
                                ellipsis={{ tooltip: propertyValue.name }}
                                style={{
                                    maxWidth: "250px",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontSize: 12,
                                }}
                            >
                                {propertyValue.name}
                            </Text>
                        </Text>
                    </React.Fragment>
                );
            })}
            <div style={{ width: 150 }}>
                <Separator />
            </div>

            {sku.property_associations.data.map((association) => {
                const matchProperty = properties.find(
                    (p) => p.id === association.property_id,
                );
                if (!matchProperty) return null;

                const propertyValue = matchProperty.values.data.find(
                    (v) => v.id === association.property_value_id,
                );
                if (!propertyValue) return null;

                return (
                    <React.Fragment key={association.id}>
                        <Text>
                            <Text>{matchProperty.name_original}:</Text>
                            <Text
                                strong
                                ellipsis={{
                                    tooltip: propertyValue.name_original,
                                }}
                                style={{
                                    maxWidth: "250px",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontSize: 12,
                                }}
                            >
                                {propertyValue.name_original}
                            </Text>
                        </Text>
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};

export default PropertyAssociations;
