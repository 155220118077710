import React, { memo, useEffect, useMemo, useState } from "react";
import {
    Button,
    Flex,
    Modal,
    notification,
    Table,
    TableColumnsType,
} from "antd";
import { Typography } from "antd";
import { RFQQuoteVariantModel } from "@/models/RfqQouteCollectionModel";

import { useGetProductById } from "@/lib/core-react/hooks/private/useProduct";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { defaultQuery } from "@/consts/defaultQuery";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { ISku } from "@/types/productDetail";
import PropertyAssociations from "./PropertyAssociations";
const { Text } = Typography;

interface RFQQuoteVariantTableProps {
    data: RFQQuoteVariantModel[];
    productId: string;
}

interface columnsWithMetaProps {
    quoteVariant: RFQQuoteVariantModel;
    sku: ISku;
}
type modalActionType = "view-quote-variaiton" | boolean;

const RFQQuoteVariantWithMeta = ({
    productId,
    data,
}: RFQQuoteVariantTableProps) => {
    const {
        getProductDetailById,
        isLoading,
        data: product,
        rawData,
    } = useGetProductById();

    const variation = useMemo(() => {
        if (!rawData) return [];

        return data
            .map((item) => {
                const match =
                    rawData.variation.skus.data.find(
                        (sku) => sku.id === item.getSkuId(),
                    ) || null;

                return match ? { quoteVariant: item, sku: match } : null;
            })
            .filter(Boolean) as columnsWithMetaProps[];
    }, [product, data]);

    const properties = rawData ? rawData.variation.properties.data : [];

    useEffect(() => {
        const handleGetProduct = async () => {
            if (!productId) return;
            try {
                await getProductDetailById(productId, {
                    region: defaultQuery.region,
                    locale: defaultQuery.locale,
                    apply_exchange: 1,
                    refresh: 1,
                });
            } catch (error) {
                notification.error({ message: getErrorMessage(error) });
            }
        };
        handleGetProduct();
    }, [productId]);

    const columns: TableColumnsType<columnsWithMetaProps> = useMemo(() => {
        return [
            {
                title: "Meta",
                key: "meta",
                align: "center",
                render: (_value: any, record) => {
                    const { sku } = record;
                    return (
                        <div
                            style={{
                                position: "relative",
                                paddingRight: "50px",
                            }}
                        >
                            <Flex gap={8} align="flex-start">
                                <ImageWithPlaceholder
                                    style={{ width: 42, height: 42 }}
                                    src={sku.image}
                                />
                                <PropertyAssociations
                                    properties={properties}
                                    sku={sku}
                                />
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: "MOQ",
                key: "MOQ",
                dataIndex: "minimum_order_quantity",
                align: "center",
                render: (
                    _value: any,
                    record: { quoteVariant: RFQQuoteVariantModel },
                ) => (
                    <Text>{record.quoteVariant.getMinimumOrderQuantity()}</Text>
                ),
            },
            {
                title: "Unit Price",
                key: "unit_price",
                dataIndex: "unit_price",
                align: "center",
                render: (
                    _value: any,
                    record: { quoteVariant: RFQQuoteVariantModel },
                ) => <Text>{record.quoteVariant.getUnitPrice()}</Text>,
            },
        ];
    }, [variation]);

    return (
        <Table
            style={{ margin: "4px 0 0" }}
            bordered
            rowHoverable={false}
            columns={columns}
            dataSource={variation}
            loading={isLoading}
            pagination={false}
            rowKey={(record) => record.quoteVariant.getId()}
        />
    );
};

const SLICE_COUNT = 3;

const RFQQuoteVariantTable: React.FC<RFQQuoteVariantTableProps> = ({
    data,
    productId,
}) => {
    const [modalOpenType, setModalOpenType] = useState<modalActionType>(false);
    const [isMore, setIsMore] = useState(false);

    const variation =
        data.length > SLICE_COUNT ? data.slice(0, SLICE_COUNT) : data;

    const columns: TableColumnsType<RFQQuoteVariantModel> = [
        {
            title: "MOQ",
            key: "MOQ",
            dataIndex: "minimum_order_quantity",
            align: "center",
            render: (_value, record) => (
                <Text>{record.getMinimumOrderQuantity()}</Text>
            ),
        },
        {
            title: "Unit Price",
            key: "unit_price",
            dataIndex: "unit_price",
            align: "center",
            render: (_value, record) => <Text>{record.getUnitPrice()}</Text>,
        },
    ];

    return (
        <Flex vertical gap={8}>
            <Flex vertical align="center">
                <Button
                    style={{
                        width: 150,
                    }}
                    type="primary"
                    onClick={() => setModalOpenType("view-quote-variaiton")}
                >
                    View Meta
                </Button>
            </Flex>
            <Table
                style={{
                    margin: "4px 0px 0px 0px",
                }}
                size="small"
                bordered
                rowHoverable={false}
                columns={columns}
                dataSource={isMore ? data : variation}
                pagination={false}
                rowKey={(record) => record.getId()}
            />

            <Flex>
                {data.length > 3 ? (
                    <Button type="text" onClick={() => setIsMore(!isMore)}>
                        {isMore ? "show less" : "show more"}
                    </Button>
                ) : (
                    <></>
                )}
            </Flex>
            <Modal
                title={`Quote  Variation`}
                open={modalOpenType === "view-quote-variaiton"}
                onCancel={() => {
                    setModalOpenType(false);
                }}
                footer={false}
                destroyOnClose={true}
                width={800}
            >
                <RFQQuoteVariantWithMeta data={data} productId={productId} />
            </Modal>
        </Flex>
    );
};

export default memo(RFQQuoteVariantTable);
