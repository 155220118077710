import { Dispatch, SetStateAction, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import {
    Alert,
    Avatar,
    Button,
    Card,
    Col,
    Flex,
    Form,
    Row,
    Select,
    Skeleton,
    Table,
    Tag,
    TreeSelect,
    Typography,
} from "antd";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import {
    agentCategoryPriceReadCollectionAtom,
    inventoryProductDetailAtom,
    shippingCategoryAtom,
} from "@/lib/core-react/store/store";
import { ShippingCategoryCollectionModel } from "@/models/shippingCategory";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
} from "@/models/agentCategoryPriceReadCollectionModel";
import { IBuyPlaceOrderCartShippingFormData } from "@/types/buyOrderPlace";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { useForm } from "antd/es/form/Form";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import { ShippingModeEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
import { UnitTypeEnums } from "@/enums/shippingCoreEnums";
import { ContainsEnum } from "@/enums/shipForMeContextEnums";
import { showError } from "@/helpers/showError";
import { getFilteredQuery } from "@/helpers/getFilteredQuery";

interface IProps {
    placeBuyOrderProductStep2FormData:
        | IBuyPlaceOrderCartShippingFormData
        | undefined;
    setPlaceBuyOrderProductStep2FormData: Dispatch<
        SetStateAction<IBuyPlaceOrderCartShippingFormData | undefined>
    >;
}
export type CategoryPriceAction =
    | { type: "SET_REGION"; payload: string }
    | { type: "SET_REGION_ID"; payload: number | undefined }
    | { type: "SET_USER_ID"; payload: number | null }
    | { type: "SET_AGENT_COMPANY_ID"; payload: number | null }
    | { type: "SET_AGENT_WAREHOUSE_ID"; payload: number | null }
    | { type: "SET_SHIPPING_COUNTRY_ID"; payload: number | null }
    | { type: "SET_DESTINATION_COUNTRY_ID"; payload: number | null }
    | { type: "SET_DESTINATION_WAREHOUSE_ID"; payload: number | null }
    | { type: "SET_SHIPPING_CATEGORY_ID"; payload: string | null }
    | { type: "SET_SHIPPING_TYPE"; payload: ShippingTypeEnums | null }
    | { type: "SET_SHIPPING_MODE"; payload: ShippingModeEnums | null }
    | { type: "SET_UNIT_TYPE"; payload: UnitTypeEnums | null }
    | { type: "SET_CONTAINS"; payload: ContainsEnum[] | null }
    | { type: "SET_PAGE"; payload: number | null }
    | { type: "SET_PER_PAGE"; payload: number | null }
    | { type: "RESET_QUERY" }; // To reset to initial state

// Reducer Function
export const categoryPriceReducer = (
    state: ICategoryPriceRequestParams,
    action: CategoryPriceAction,
): ICategoryPriceRequestParams => {
    switch (action.type) {
        case "SET_REGION":
            return { ...state, region: action.payload };
        case "SET_REGION_ID":
            return { ...state, region_id: action.payload };
        case "SET_USER_ID":
            return { ...state, user_id: action.payload };
        case "SET_AGENT_COMPANY_ID":
            return { ...state, agent_company_id: action.payload };
        case "SET_AGENT_WAREHOUSE_ID":
            return { ...state, agent_warehouse_id: action.payload };
        case "SET_SHIPPING_COUNTRY_ID":
            return { ...state, shipping_country_id: action.payload };
        case "SET_DESTINATION_COUNTRY_ID":
            return { ...state, destination_country_id: action.payload };
        case "SET_DESTINATION_WAREHOUSE_ID":
            return { ...state, destination_warehouse_id: action.payload };
        case "SET_SHIPPING_CATEGORY_ID":
            return { ...state, shipping_category_id: action.payload };
        case "SET_SHIPPING_TYPE":
            return { ...state, shipping_type: action.payload };
        case "SET_SHIPPING_MODE":
            return { ...state, shipping_mode: action.payload };
        case "SET_UNIT_TYPE":
            return { ...state, unit_type: action.payload };
        case "SET_CONTAINS":
            return { ...state, contains: action.payload };
        case "SET_PAGE":
            return { ...state, page: action.payload };
        case "SET_PER_PAGE":
            return { ...state, per_page: action.payload };
        case "RESET_QUERY": {
            const { user_id, per_page, region } = state;
            return {
                user_id,
                per_page,
                region,
            };
        }
        default:
            return state;
    }
};

export const ShippingMethod = ({
    placeBuyOrderProductStep2FormData,
    setPlaceBuyOrderProductStep2FormData,
}: IProps) => {
    const location = useLocation();
    const {
        allOptionsData: {
            shippingCountryOptionsData,
            destinationWarehouseOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();
    const [form] = useForm();

    const userId = new URLSearchParams(location.search).get("userId");
    const region_id = new URLSearchParams(location.search).get("region_id");
    const region = new URLSearchParams(location.search).get("region");

    const [categoryPriceQuery, dispatch] = useReducer(categoryPriceReducer, {
        region: region as string,
        user_id: Number(userId),
        per_page: 500,
    });
    const productDetail = useAtomValue(inventoryProductDetailAtom);

    const { getShippingCategory } = useGetShippingCategory();
    const { getCategoryPrices } = useGetCategoryPrices();

    useEffect(() => {
        if (region_id) {
            onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
                region_id: region_id,
            });
            onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE, {
                region_id: region_id,
            });
            getShippingCategory(`region_id=${region_id}&per_page=500`);
        }
    }, [region_id]);

    useEffect(() => {
        if (categoryPriceQuery.shipping_category_id) {
            getCategoryPrices(
                getFilteredQuery(
                    categoryPriceQuery,
                ) as ICategoryPriceRequestParams,
            );
        }
    }, [categoryPriceQuery]);

    const {
        data: shippingCategoryCollection,
        isLoading: shippingCategoryLoading,
    } = useAtomValue(shippingCategoryAtom);

    const [
        {
            data: categoryPriceReadCollection,
            isLoading: categoryPriceReadLoading,
        },
    ] = useAtom(agentCategoryPriceReadCollectionAtom);

    const shippingCategoryCollectionData =
        shippingCategoryCollection &&
        new ShippingCategoryCollectionModel(shippingCategoryCollection);

    const categoryPriceReadCollectionData =
        categoryPriceReadCollection &&
        new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection);

    // useEffect(() => {
    //     if (shippingCountryOptionsData.options.length) {
    //         const countryId = shippingCountryOptionsData?.options.find(
    //             (x) => x.label === productDetail?.getCountry().name,
    //         )?.value;
    //         if (countryId) {
    //             dispatch({
    //                 type: "SET_SHIPPING_COUNTRY_ID",
    //                 payload: Number(countryId),
    //             });
    //         }
    //     }
    // }, [shippingCountryOptionsData.options.length]);

    const handleReset = () => {
        dispatch({ type: "RESET_QUERY" });
        if (categoryPriceQuery.shipping_category_id) {
            getCategoryPrices(
                getFilteredQuery(
                    categoryPriceQuery,
                ) as ICategoryPriceRequestParams,
            );
        }
        form.resetFields([
            "shipping_category_id",
            "shipping_country_id",
            "shipping_country_id",
            "destination_country_id",
            "destination_warehouse_id",
            "shipping_type",
            "shipping_mode",
            "unit_type",
            "contains",
        ]);
    };

    const handleCartItemAddPayload = (data: AgentCategoryPriceReadModel) => {
        try {
            if (!categoryPriceQuery.shipping_category_id) {
                throw new Error("Product category is required");
            }
            if (!categoryPriceQuery.shipping_country_id) {
                throw new Error("Product country is required");
            }
            if (!data.getId()) {
                throw new Error("agent category price read id not found");
            }
            if (!data.getAgentWarehouse().getName()) {
                throw new Error("Shipping Provider not found");
            }

            setPlaceBuyOrderProductStep2FormData({
                agent_category_price_read_id: data.getId(),
                provider: data.getAgentWarehouse().getName(),
                shipping_category_id: categoryPriceQuery.shipping_category_id,
                shipping_country: categoryPriceQuery.shipping_country_id,
                selectedCategoryPrice: data,
            });
        } catch (error) {
            showError(error);
        }
    };

    const isDisable =
        !categoryPriceQuery.shipping_category_id ||
        !categoryPriceQuery.shipping_country_id;

    // -----------------------------------ui-----------------------

    const slotsColumns = [
        {
            title: "Min",
            dataIndex: "min_amount",
            key: "min_amount",
        },
        {
            title: "Max",
            dataIndex: "max_amount",
            key: "max_amount",
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
        },
    ];

    const { Text } = Typography;
    return (
        <>
            <Flex justify="end">
                <Button
                    disabled={isDisable}
                    type="dashed"
                    htmlType="button"
                    onClick={handleReset}
                >
                    Reset
                </Button>
            </Flex>

            <Form form={form} layout="vertical">
                <Form.Item
                    name={"shipping_category_id"}
                    label="Product Category"
                    required
                >
                    <TreeSelect
                        onChange={(value) => {
                            dispatch({
                                type: "SET_SHIPPING_CATEGORY_ID",
                                payload: value,
                            });
                        }}
                        showSearch
                        dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
                        placeholder="Please select a category"
                        allowClear
                        loading={shippingCategoryLoading}
                        treeLine={{ showLeafIcon: true }}
                        treeData={
                            shippingCategoryCollectionData
                                ? shippingCategoryCollectionData.getShippingCategoryTreeIds()
                                : []
                        }
                        filterTreeNode={(searchValue, treeNode) => {
                            const title = treeNode.title;
                            return (
                                typeof title === "string" &&
                                title
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        }}
                    />
                </Form.Item>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            name={"shipping_country_id"}
                            label="Shipping Country"
                            required
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                onClear={() => {
                                    dispatch({
                                        type: "SET_SHIPPING_COUNTRY_ID",
                                        payload: null,
                                    });
                                }}
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (
                                                optionB?.label ?? ""
                                            ).toLowerCase(),
                                        )
                                }
                                loading={shippingCountryOptionsData.isLoading}
                                disabled={
                                    !categoryPriceQuery.shipping_category_id
                                }
                                defaultValue={
                                    shippingCountryOptionsData.options.find(
                                        (x) =>
                                            x.code ===
                                            productDetail?.getCountry().code,
                                    )?.value
                                }
                                placeholder="Select Shipping Country"
                                onChange={(value) =>
                                    dispatch({
                                        type: "SET_SHIPPING_COUNTRY_ID",
                                        payload: Number(value),
                                    })
                                }
                                options={shippingCountryOptionsData.options}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"destination_warehouse_id"}
                            label="Destination Warehouse"
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (
                                                optionB?.label ?? ""
                                            ).toLowerCase(),
                                        )
                                }
                                onClear={() => {
                                    dispatch({
                                        type: "SET_DESTINATION_WAREHOUSE_ID",
                                        payload: null,
                                    });
                                }}
                                loading={
                                    destinationWarehouseOptionsData.isLoading
                                }
                                disabled={isDisable}
                                placeholder="select destination Warehouse"
                                options={
                                    destinationWarehouseOptionsData.options
                                }
                                onChange={(value) =>
                                    dispatch({
                                        type: "SET_DESTINATION_WAREHOUSE_ID",
                                        payload: Number(value),
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row gutter={8}>
                            <Col span={6}>
                                <Form.Item
                                    name={"shipping_type"}
                                    label="Shipping Type       "
                                >
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_SHIPPING_TYPE",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        disabled={isDisable}
                                        placeholder="Shipping Type"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_SHIPPING_TYPE",
                                                payload: value,
                                            })
                                        }
                                        options={categoryPriceReadCollectionData?.getShippingTypes()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={"shipping_mode"}
                                    label="Shipping Mode       "
                                >
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_SHIPPING_MODE",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        placeholder="Shipping Mode"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_SHIPPING_MODE",
                                                payload: value,
                                            })
                                        }
                                        disabled={isDisable}
                                        options={categoryPriceReadCollectionData?.getShippingModes()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={"unit_type"} label="Unit Type">
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        allowClear
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_UNIT_TYPE",
                                                payload: null,
                                            });
                                        }}
                                        placeholder="select unit type"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_UNIT_TYPE",
                                                payload: value,
                                            })
                                        }
                                        disabled={isDisable}
                                        options={categoryPriceReadCollectionData?.getShippingUnits()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={"contains"} label="Contains">
                                    <Select
                                        mode="multiple"
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        placeholder="select contains"
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_CONTAINS",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_CONTAINS",
                                                payload: value,
                                            })
                                        }
                                        disabled={isDisable}
                                        options={categoryPriceReadCollectionData?.getShippingContains()}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <Flex vertical gap={24}>
                <Card
                    title={<Text strong>Select Company</Text>}
                    style={{
                        backgroundColor: "#F7F8FA",
                    }}
                >
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {categoryPriceReadLoading ? (
                            <Skeleton active />
                        ) : isDisable ? (
                            <Alert
                                message="Please select Product Category and Shipping Country"
                                type="info"
                            />
                        ) : categoryPriceReadCollectionData?.getData()
                              .length === 0 ? (
                            <Alert
                                message="Shipping Partner Not Available. please change product category, shipping country or others filters"
                                type="info"
                            />
                        ) : (
                            categoryPriceReadCollectionData
                                ?.getData()
                                .map((data) => (
                                    <div
                                        onClick={() =>
                                            handleCartItemAddPayload(data)
                                        }
                                        key={data.getId()}
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            padding: "16px",
                                            marginBottom: "16px",
                                            cursor: "pointer",
                                            border: `${
                                                placeBuyOrderProductStep2FormData?.agent_category_price_read_id ===
                                                data.getId()
                                                    ? "1px solid green"
                                                    : "1px solid white"
                                            }`,
                                        }}
                                    >
                                        <Flex vertical gap={12}>
                                            <Flex
                                                gap={12}
                                                flex={"1"}
                                                justify="space-between"
                                            >
                                                <Flex gap={8}>
                                                    <Avatar
                                                        size={50}
                                                        shape="square"
                                                        style={{
                                                            backgroundColor:
                                                                "#00897B",
                                                            color: "white",
                                                            fontWeight: 700,
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        {data
                                                            .getAgentWarehouse()
                                                            .getCompanyName()}
                                                    </Avatar>
                                                    <Flex vertical gap={4}>
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        >
                                                            {data
                                                                .getAgentWarehouse()
                                                                .getCompanyName()}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            Shipping Time :{" "}
                                                            <Text strong>
                                                                {data.getMinShippingTime()}
                                                                -
                                                                {data.getMaxShippingTime()}{" "}
                                                                {data.getShippingTimeUnit()}
                                                            </Text>
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            {data.getShippingMode()}{" "}
                                                            by{" "}
                                                            {data.getShippingType()}{" "}
                                                            | Total Slots :
                                                            {"  "}
                                                            <Tag>
                                                                {data.getSlots() &&
                                                                    data
                                                                        .getSlots()
                                                                        ?.getData()
                                                                        ?.length}
                                                            </Tag>
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                textTransform:
                                                                    "capitalize",
                                                            }}
                                                        >
                                                            {data
                                                                .getAgentWarehouse()
                                                                .getName()}{" "}
                                                            <Text
                                                                strong
                                                                type="danger"
                                                            >
                                                                To{" "}
                                                            </Text>
                                                            {data
                                                                .getDestinationWarehouse()
                                                                .getName()}
                                                        </Text>
                                                    </Flex>
                                                </Flex>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "7px",
                                                    }}
                                                >
                                                    <Text strong>
                                                        {data.getRate()}/
                                                        {data.getUnitType()}
                                                    </Text>
                                                    <Text
                                                        strong
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {data.contain}
                                                    </Text>
                                                </div>
                                            </Flex>

                                            {placeBuyOrderProductStep2FormData?.agent_category_price_read_id ===
                                                data.getId() &&
                                                data.getSlots() &&
                                                // @ts-ignore
                                                data.getSlots()?.getData()
                                                    ?.length > 0 && (
                                                    <Table
                                                        title={() => "Slots"}
                                                        columns={slotsColumns}
                                                        size="small"
                                                        dataSource={data
                                                            .getSlots()
                                                            ?.getData()}
                                                        rowKey={(r) =>
                                                            r.getId()
                                                        }
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        pagination={false}
                                                        bordered={true}
                                                        rowHoverable={false}
                                                    />
                                                )}
                                        </Flex>
                                    </div>
                                ))
                        )}
                    </div>
                </Card>
            </Flex>
        </>
    );
};
