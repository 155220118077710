import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Flex,
    Form,
    Modal,
    Popover,
    Row,
    Table,
    TableColumnsType,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom, useSetAtom } from "jotai";

import { rfqRequestListAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";

import { useEffect, useState } from "react";
import {
    CheckSquareOutlined,
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    RfqRequestCollectionModel,
    RfqRequestModel,
} from "@/models/rfqRequestCollectionModel";
import { useGetRfqList } from "@/lib/core-react/hooks/private/useRfq";
import StatusTag from "@/components/StatusTag/StatusTag";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_RFQ_PERMISSION_ENUM } from "@/consts/permission-enum/RFQ-enum";
import { PaginationModel } from "@/models/pagination";
import CommonError from "@/components/Error/CommonError";

import { defaultQuery } from "@/consts/defaultQuery";
import ImagesModal from "./components/ImagesModal";
import CustomerInfo from "../../components/shared/Order/CustomerInfo";
import { showError } from "@/helpers/showError";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import useActionsProps from "@/helpers/useActionsProps";
import { BREAK_POINTS } from "@/consts/appConstants";
import ShippingText from "@/components/ShippingText";
import { formatDateTime } from "@/utils/helpers";
import CreateRFQForm from "./components/CreateRFQForm";
import UpdateRFQForm from "./components/UpdateRFQForm";
import useActions from "./useActions";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import UpdateRFQSingleFieldForm from "./components/UpdateRFQSingleFieldForm";
import { RFQRequestUpdateSingleFieldKeys } from "@/types/rfqRequestCreate";
import ApproveRFQForm from "./components/ApproveRFQForm";
import RejectRFQForm from "./components/RejectRFQForm";
import { ExtendedMenuItemType } from "@/types";
import BulkApproveRFQForm from "./components/BulkApproveRFQForm";

const RfqRequestList = () => {
    const [selectedItem, setSelectedItem] = useState<RfqRequestModel>();
    const [form] = Form.useForm();
    const { Text } = Typography;

    const [isOpenImageModal, setIsOpenImageModal] = useState(false);
    const [fieldName, setFieldName] = useState<
        RFQRequestUpdateSingleFieldKeys | false
    >();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<RfqRequestModel[]>([]);

    const actionsProps = useActionsProps();
    const onClose = () => {
        setIsOpenImageModal(false);
    };

    const imagesDetailsHandler = (product) => {
        setSelectedItem(product);
        setIsOpenImageModal(true);
    };
    const { getRequestsList } = useGetRfqList();

    const [
        {
            data: requestsData,
            isLoading: isLoadingRequests,
            error,
            unAuthorized,
            refetch,
        },
    ] = useAtom(rfqRequestListAtom);
    const setRfqRequestList = useSetAtom(rfqRequestListAtom);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
        isFirstCall,
    } = useDataFilters();

    const rfqRequestCollectionModel =
        requestsData &&
        requestsData.data &&
        new RfqRequestCollectionModel(requestsData);
    const filterData = rfqRequestCollectionModel?.getFilters();

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getRequestsList);
        }
    }, [
        isFirstCall,
        filters,
        isFetched,
        refetchFromFilter,
        refetchFromFilter,
        refetch,
    ]);

    useEffect(() => {
        if (!isFetched && rfqRequestCollectionModel) {
            initializeAvailableFilter(rfqRequestCollectionModel?.getFilters());
        }
    }, [isFetched, initializeAvailableFilter]);

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,
            },
            getRequestsList,
        );
    };

    // ------------------------------ Modal -> --------------------------- //

    const handleModal = async (payload: IModalData) => {
        try {
            setModalOpenType(payload.action);
            setSelectedItem(() => payload.data);
        } catch (error) {
            showError(error);
        }
    };

    const handleEditRFQField = async (payload: {
        action: RFQRequestUpdateSingleFieldKeys | false;
        data?: RfqRequestModel;
    }) => {
        try {
            setFieldName(payload.action);
            setSelectedItem(() => payload.data);
        } catch (error) {
            showError(error);
        }
    };

    const { getActions } = useActions({
        handleModal,
    });

    const onCancel = () => {
        handleModal({
            action: false,
            data: null,
        });
        form.resetFields();
    };

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...defaultQuery,
                ...filters,

                ...pageInfo,
            },
            getRequestsList,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        requestsData,
        handlePaginationChange,
    );

    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    const columns: TableColumnsType<RfqRequestModel> = [
        {
            title: "Product Info",
            dataIndex: "product_info",
            key: "product_info",
            render: (_tags: any, record) => {
                return (
                    <Flex gap={12}>
                        <div>
                            <ImageWithPlaceholder
                                style={{
                                    width: 100,
                                    height: 100,
                                }}
                                src={record.getFirstImageUrl()}
                            />

                            <Flex style={{ marginTop: 16 }} vertical gap={8}>
                                {record.isEditable() ? (
                                    <>
                                        {checkActionPermission(
                                            ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                                            <Button
                                                type="link"
                                                onClick={() =>
                                                    handleEditRFQField({
                                                        action: "images",
                                                        data: record,
                                                    })
                                                }
                                            >
                                                Images <EditOutlined />
                                            </Button>,
                                            null,
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={() =>
                                            imagesDetailsHandler(record)
                                        }
                                    >
                                        view Images
                                    </Button>
                                )}
                            </Flex>
                        </div>

                        <div>
                            <Flex vertical gap={8}>
                                <Typography.Text
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Text>Request No : </Text>
                                    <Text copyable strong>
                                        {record?.getRequestedNumber()}
                                    </Text>
                                </Typography.Text>
                                <ShippingText
                                    label="Product Name"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "product_name",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Text strong>
                                        {record.getProductName()}{" "}
                                    </Text>
                                </ShippingText>

                                {record?.getProductLink() && (
                                    <ShippingText
                                        label="Product link"
                                        isEditable={record.isEditable()}
                                        permissionCheck={
                                            ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                        }
                                        onActionClick={() => {
                                            handleEditRFQField({
                                                action: "product_link",
                                                data: record,
                                            });
                                        }}
                                    >
                                        <Link
                                            style={{ fontWeight: "bold" }}
                                            to={record.getProductLink()}
                                            target="_blank"
                                        >
                                            view
                                        </Link>
                                    </ShippingText>
                                )}
                                <ShippingText
                                    label="Purchase Quantity"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "purchase_quantity",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Text strong>
                                        {record.getPurchaseQuantity()}{" "}
                                    </Text>
                                </ShippingText>
                                <ShippingText label="Region">
                                    <Popover
                                        content={`${record?.getRegionName()}`}
                                    >
                                        <StatusTag
                                            slug={record?.getRegionCode()}
                                            text={record?.getRegionCode()}
                                        />
                                    </Popover>
                                </ShippingText>
                                <Text>
                                    <span>Status: </span>
                                    <StatusTag
                                        text={record?.getStatus()}
                                        slug={record?.getStatus()}
                                    />
                                </Text>
                                {record?.getRejectReason() ? (
                                    <Flex align="center" gap={2}>
                                        <span>Reject Reason: </span>

                                        <Tooltip
                                            title={record.getRejectReason()}
                                            placement="top"
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <Tag
                                                color="error"
                                                style={{
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "400px",
                                                    cursor: "pointer",
                                                    fontSize: 14,
                                                }}
                                            >
                                                {record?.getRejectReason()}
                                            </Tag>
                                        </Tooltip>
                                    </Flex>
                                ) : (
                                    <></>
                                )}

                                <ShippingText
                                    label="Descriptions"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "product_description",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Tooltip
                                        title={record.getProductDescription()}
                                        placement="top"
                                        overlayStyle={{ maxWidth: 400 }}
                                    >
                                        <Text
                                            strong
                                            style={{
                                                display: "block",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "400px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {record.getProductDescription()}
                                        </Text>
                                    </Tooltip>
                                </ShippingText>
                                {record.getQuotesCount() > 0 ? (
                                    <Link
                                        target="_blank"
                                        to={`/rfq/requests/${record.getId()}/quotes`}
                                    >
                                        View Quotes{" "}
                                        <Tag>{record.getQuotesCount()}</Tag>
                                    </Link>
                                ) : (
                                    <Text
                                        style={{
                                            color: "blue",
                                        }}
                                    >
                                        View Quotes{" "}
                                        <Tag>{record.getQuotesCount()}</Tag>
                                    </Text>
                                )}
                            </Flex>
                        </div>
                    </Flex>
                );
            },
        },

        {
            title: "Shipping",
            dataIndex: "shipping",
            colSpan: 1,
            key: "shipping",
            render: (_tags: any, record) => {
                return (
                    <Row>
                        <Col>
                            <Flex vertical gap={8}>
                                {record?.getValidUntil() && (
                                    <ShippingText label="Valid Until">
                                        <StatusTag
                                            color="purple"
                                            slug={""}
                                            text={`${formatDateTime(record.getValidUntil())}`}
                                        />
                                    </ShippingText>
                                )}

                                <ShippingText
                                    label="Shipping Country"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "shipping_country_id",
                                            data: record,
                                        });
                                    }}
                                >
                                    <StatusTag
                                        slug={record.getShippingCountryCode()}
                                        text={record.getShippingCountryName()}
                                    />
                                </ShippingText>
                                <ShippingText
                                    label="Shipping Category"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "shipping_category_id",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Tag color="blue">
                                        {record.getShippingCategory().name}
                                    </Tag>
                                </ShippingText>
                                <ShippingText
                                    label="Shipping Type"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "shipping_type",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Text strong>
                                        {record.getShippingType()}
                                    </Text>
                                </ShippingText>
                                <ShippingText
                                    label="Shipping Mode"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "shipping_mode",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Text strong>
                                        {record.getShippingMode()}
                                    </Text>
                                </ShippingText>
                                <ShippingText
                                    label="Destination Warehouse"
                                    isEditable={record.isEditable()}
                                    permissionCheck={
                                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE
                                    }
                                    onActionClick={() => {
                                        handleEditRFQField({
                                            action: "destination_warehouse_id",
                                            data: record,
                                        });
                                    }}
                                >
                                    <Text strong>
                                        {record.getWarehouseName()}
                                    </Text>
                                </ShippingText>
                            </Flex>
                        </Col>
                    </Row>
                );
            },
        },

        {
            title: "User",
            dataIndex: "user",
            key: "user",
            width: 300,
            render: (_, record) => {
                return <CustomerInfo user={record.user} />;
            },
        },

        {
            ...actionsProps,
            hidden: !checkActionPermission(
                ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                "checkPermission",
                null,
            ),
            render: (_key: string, record) => {
                return (
                    <Dropdown
                        menu={{
                            items: getActions(record),
                        }}
                    >
                        <Button icon={<HddFilled />}></Button>
                    </Dropdown>
                );
            },
        },
    ];

    const bulkItems: ExtendedMenuItemType[] = [
        {
            permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
            key: "bulk-approve",
            icon: <CheckSquareOutlined />,
            label: "Bulk Approve",
            onClick: () => setModalOpenType("rfq-bulk-approve"),
        },
    ];

    const onSelectChange = (
        newSelectedRowKeys: any,
        newSelectedRows: RfqRequestModel[],
    ) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <PageHeader
                ghost={false}
                title={"Manage RFQ"}
                extra={[
                    checkActionPermission(
                        ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                        <Button
                            onClick={() =>
                                handleModal({
                                    action: "rfq-create",
                                })
                            }
                            type="primary"
                            htmlType="button"
                            icon={<PlusOutlined />}
                        >
                            Create RFQ
                        </Button>,
                        null,
                    ),
                ]}
                onBack={() => window.history.back()}
            >
                <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                />

                {checkActionPermission(
                    [ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE],
                    <div style={{ margin: "20px 0", textAlign: "right" }}>
                        <Dropdown
                            menu={{
                                items: bulkItems.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <Button icon={<HddFilled />}>
                                Bulk Actions
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>,
                    null,
                )}

                <div style={{ marginTop: 20 }}>
                    <Table
                        rowHoverable={false}
                        columns={columns}
                        dataSource={rfqRequestCollectionModel?.getData()}
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }}
                        pagination={{
                            ...paginationConfig,
                            showTotal: (total) => (
                                <div>
                                    Total{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            color: "green",
                                        }}
                                    >
                                        {total}
                                    </span>{" "}
                                    Items
                                </div>
                            ),
                        }}
                        bordered
                        loading={isLoadingRequests || isLoadingRequests}
                        rowKey="id"
                        scroll={{ x: BREAK_POINTS.XL }}
                    />
                </div>
                {selectedItem && (
                    <ImagesModal
                        open={isOpenImageModal}
                        cancelHandler={onClose}
                        rfqRequestData={selectedItem}
                    />
                )}
            </PageHeader>

            <Modal
                title={`RFQ Approved`}
                open={modalOpenType === "rfq-approve"}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={false}
            >
                {selectedItem && (
                    <ApproveRFQForm
                        onSuccess={() => {
                            onCancel();
                            setRfqRequestList((prev) => ({
                                ...prev,
                                refetch: true,
                            }));
                        }}
                        rfqRequestData={selectedItem}
                    />
                )}
            </Modal>

            <Modal
                title={`Bulk RFQ Approved`}
                open={modalOpenType === "rfq-bulk-approve"}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={false}
            >
                {selectedRows.length && (
                    <BulkApproveRFQForm
                        onSuccess={() => {
                            onCancel();
                            setRfqRequestList((prev) => ({
                                ...prev,
                                refetch: true,
                            }));
                        }}
                        rfqRequestData={selectedRows}
                    />
                )}
            </Modal>

            <Modal
                title={`RFQ update`}
                open={!!fieldName}
                onCancel={() => {
                    handleEditRFQField({
                        action: false,
                    });
                }}
                footer={false}
                destroyOnClose={true}
            >
                {selectedItem && fieldName && (
                    <UpdateRFQSingleFieldForm
                        editFieldName={fieldName}
                        requestData={selectedItem}
                        handleSuccess={() => {
                            setRfqRequestList((prev) => ({
                                ...prev,
                                refetch: true,
                            }));
                            onCancel();
                            handleEditRFQField({
                                action: false,
                            });
                        }}
                    />
                )}
            </Modal>

            <Modal
                title={`RFQ Reject`}
                open={modalOpenType === "rfq-reject"}
                onCancel={onCancel}
                footer={false}
                destroyOnClose={true}
            >
                {selectedItem && (
                    <RejectRFQForm
                        onSuccess={() => {
                            setRfqRequestList((prev) => ({
                                ...prev,
                                refetch: true,
                            }));
                            onCancel();
                        }}
                        rfqRequestData={selectedItem}
                    />
                )}
            </Modal>
            <Modal
                title={`RFQ Create`}
                open={modalOpenType === "rfq-create"}
                onCancel={onCancel}
                footer={false}
                destroyOnClose={true}
                width={1000}
            >
                <CreateRFQForm
                    handleSuccess={() => {
                        setRfqRequestList((prev) => ({
                            ...prev,
                            refetch: true,
                        }));

                        onCancel();
                    }}
                />
            </Modal>

            {selectedItem && (
                <Modal
                    title={`RFQ Edit`}
                    open={modalOpenType === "rfq-update"}
                    onCancel={onCancel}
                    footer={false}
                    destroyOnClose={true}
                    width={1000}
                >
                    <UpdateRFQForm
                        requestData={selectedItem}
                        handleSuccess={() => {
                            setRfqRequestList((prev) => ({
                                ...prev,
                                refetch: true,
                            }));
                            onCancel();
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

export default RfqRequestList;
