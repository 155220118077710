import { useParams } from "react-router-dom";
import { useGetBuyProduct } from "@/lib/core-react/hooks/private";
import { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Flex,
    Form,
    Input,
    Row,
    Spin,
    Typography,
} from "antd";
import { BuyProductVariationsTable } from "../components";
import { useAtomValue } from "jotai";
import CommonError from "@/components/Error/CommonError";
import { buyProductAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { PageHeader } from "@ant-design/pro-layout";
import BuyProductDetailTitle from "../components/BuyProductDetailTitle";
import {
    useGetProductById,
    useGetProductByUrl,
} from "@/lib/core-react/hooks/private/useProduct";
import { defaultQuery } from "@/consts/defaultQuery";
import { inventoryProductDetailAtom } from "@/lib/core-react/store/store";
import ProductVariations from "@/pages/ProductManage/ProductDetail/ProductVariations";
import isProductUrl from "@/helpers/isProductUrl";
import { useUpdateBuyProduct } from "@/lib/core-react/hooks/private/usePurchase";
import { IBuyProductUpdatePayload } from "@/types/buyProductCollection";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { Link } from "react-router-dom";
import { hasDuplicates } from "@/helpers/hasDuplicates";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

interface IInventoryProductVariaiton {
    index: number | null;
    sku_id: string;
    original_unit_price: number;
    quantity: number;
}

const BuyProductUpdate = () => {
    const { id } = useParams();
    const { getBuyProduct } = useGetBuyProduct();
    const { isMobile } = useWindowWidth();
    const { getProductDetailById, isLoading: isLoadingProductId } =
        useGetProductById();

    const { getProductDetailByUrl, isLoading: isLoadingProductUrl } =
        useGetProductByUrl();
    const { updateBuyProduct, isLoading } = useUpdateBuyProduct();

    const {
        data: buyProduct,
        error,
        code,
        isLoading: isLoadingBuyProduct,
        unAuthorized,
    } = useAtomValue(buyProductAtom);

    const productDetail = useAtomValue(inventoryProductDetailAtom);

    const [payloadData, setPayloadData] = useState<{
        inventoryProductVariation: IInventoryProductVariaiton[];
    }>({
        inventoryProductVariation: [],
    });

    const [productSearchForm] = Form.useForm();
    useEffect(() => {
        if (id) {
            getBuyProduct(Number(id));
        }
    }, [id]);

    const buyProductModel = buyProduct && new BuyProductModel(buyProduct);

    const handleInventoryProductVariation = (
        data: IInventoryProductVariaiton[],
    ) => {
        setPayloadData((prev) => ({
            ...prev,
            inventoryProductVariation: data,
        }));
    };

    const hasMatchingVariationCount = () => {
        return (
            payloadData.inventoryProductVariation.length ===
            buyProduct?.product_variations.data.length
        );
    };

    const validate = async () => {
        if (!productDetail?.id) {
            throw new Error("New Product Id Not Found");
        }
        if (!buyProduct?.id) {
            throw new Error("Buy Product Id Not Found");
        }
        if (payloadData.inventoryProductVariation.length === 0) {
            throw new Error("Please Select New Product Variation");
        }

        if (hasDuplicates(payloadData.inventoryProductVariation, "index")) {
            throw new Error("You selected same SL No. twice or more.");
        }
    };
    const handleBuyProductUpdate = async () => {
        try {
            await validate();
            if (!buyProduct) {
                throw new Error("Validation Fail");
            }

            const payloadVariation = payloadData.inventoryProductVariation.map(
                (variant) => {
                    const index = Number(variant?.index) - 1; // buy product variation showing SL No from 1 that's why minus one
                    if (index >= buyProduct?.product_variations.data.length) {
                        throw new Error(
                            `Select sl no ${index} does not exist in buy product variations`,
                        );
                    }

                    const product_variations =
                        buyProduct?.product_variations.data[index];

                    return {
                        new_sku_id: variant.sku_id,
                        quantity: variant.quantity,

                        sku_id: product_variations.sku_id,
                        variation_id: product_variations.id,
                    };
                },
            );

            const payload: IBuyProductUpdatePayload = {
                update_source: {
                    new_product_id: productDetail?.id as string,
                    variations: payloadVariation,
                },
            };

            await updateBuyProduct(Number(id), payload);

            showSuccessAlert("Successfully Product Updated");
            await getBuyProduct(Number(id));
        } catch (error) {
            showError(error);
        }
    };

    const handleProductSearch = (values) => {
        if (values && isProductUrl(values.product_url)) {
            getProductDetailByUrl({
                region: defaultQuery.region,
                locale: defaultQuery.locale,
                apply_exchange: 1,
                url: values.product_url,
            });
        } else {
            getProductDetailById(values.product_url, {
                region: defaultQuery.region,
                locale: defaultQuery.locale,
                apply_exchange: 1,
            });
        }
    };

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <PageHeader
            ghost={false}
            style={{ width: "100%", paddingBottom: 32 }}
            title={buyProduct ? `#${buyProduct.product_number}` : ""}
            subTitle={<BuyProductDetailTitle buyProduct={buyProductModel} />}
        >
            <Row gutter={[12, 32]}>
                <Col sm={24} lg={12}>
                    <Card
                        style={{
                            overflow: "auto",
                        }}
                        styles={{
                            body: {
                                minWidth: 800,
                            },
                        }}
                        title={
                            <Flex justify="space-between">
                                <Typography.Text>
                                    Buy Product Variaion (
                                    {buyProduct?.product_variations.data.length}
                                    )
                                </Typography.Text>
                                <Link
                                    target="_blank"
                                    to={`/purchase/buy-products/detail/${id}`}
                                    style={{ padding: 0 }}
                                >
                                    Buy Product Detail
                                </Link>
                            </Flex>
                        }
                    >
                        {isLoadingBuyProduct ? (
                            <Flex justify="center">
                                <Spin />
                            </Flex>
                        ) : (
                            buyProductModel && (
                                <BuyProductVariationsTable
                                    isListPage={false}
                                    isBuyOrderListPage={false}
                                    buyProduct={buyProductModel}
                                />
                            )
                        )}
                    </Card>
                </Col>
                <Col sm={24} lg={12}>
                    <Card
                        style={{
                            overflow: "auto",
                        }}
                        styles={{
                            body: {
                                minWidth: 800,
                            },
                        }}
                        title={
                            <>
                                <Form
                                    form={productSearchForm}
                                    onFinish={handleProductSearch}
                                >
                                    <Flex gap={12} flex={1}>
                                        <Form.Item
                                            noStyle
                                            name={"product_url"}
                                            style={{
                                                width: "100%",
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "product url or id is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="paste original product url or id" />
                                        </Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={
                                                isLoadingProductId ||
                                                isLoadingProductUrl
                                            }
                                        >
                                            {" "}
                                            Search
                                        </Button>
                                    </Flex>
                                </Form>
                            </>
                        }
                    >
                        {isLoadingProductId || isLoadingProductUrl ? (
                            <Flex justify="center">
                                <Spin />
                            </Flex>
                        ) : (
                            productDetail && (
                                <ProductVariations
                                    handleVariation={
                                        handleInventoryProductVariation
                                    }
                                    product={productDetail}
                                />
                            )
                        )}

                        <div
                            style={{
                                position: "fixed",
                                bottom: 65,
                                right: isMobile ? 120 : 18,
                                display: "flex",
                                gap: 8,
                            }}
                        >
                            <Button
                                loading={isLoading}
                                disabled={!hasMatchingVariationCount()}
                                style={{
                                    opacity: hasMatchingVariationCount()
                                        ? undefined
                                        : "0.7",
                                }}
                                type="primary"
                                htmlType="button"
                                onClick={handleBuyProductUpdate}
                            >
                                Update ({" "}
                                {payloadData.inventoryProductVariation.length} /{" "}
                                {buyProduct?.product_variations.data.length} )
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </PageHeader>
    );
};

export default BuyProductUpdate;
