import { useState, useEffect } from "react";
import { Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isTopToBottomVisible, setIsTopToBottomVisible] = useState(false);
    const { isMobile } = useWindowWidth();
    useEffect(() => {
        const toggleVisibility = () => {
            const scrolled = window.scrollY;
            const viewportHeight = window.innerHeight;
            const totalHeight = document.documentElement.scrollHeight;

            setIsVisible(scrolled > 100);
            setIsTopToBottomVisible(
                scrolled + viewportHeight < totalHeight - 100,
            );
        };

        window.addEventListener("scroll", toggleVisibility);
        toggleVisibility(); // Run once to check initial state

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    };

    return (
        <div
            style={{
                position: "fixed",
                bottom: 16,
                right: isMobile ? 120 : 18,
                display: "flex",
                gap: 8,
            }}
        >
            {isVisible && (
                <Button
                    style={{ opacity: "0.8" }}
                    type="primary"
                    shape="circle"
                    icon={<UpOutlined />}
                    onClick={scrollToTop}
                    aria-label="Scroll to top"
                />
            )}
            {isTopToBottomVisible && (
                <Button
                    style={{ opacity: "0.8" }}
                    type="primary"
                    shape="circle"
                    icon={<DownOutlined />}
                    onClick={scrollToBottom}
                    aria-label="Scroll to bottom"
                />
            )}
        </div>
    );
};

export default ScrollToTopButton;
