import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { usePublishToAll } from "@/lib/core-react/hooks/private/useNotification";
import { Button, Card, Flex, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const Promotion = () => {
    const { isLoading, publishToAll } = usePublishToAll();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            await publishToAll(values);
            showSuccessAlert("Successfully Published");
            form.resetFields();
        } catch (error) {
            showError(error);
        }
    };
    return (
        <div>
            <Flex
                justify="center"
                align="center"
                style={{
                    marginTop: 32,
                }}
            >
                <Card
                    title="Publish To All"
                    style={{
                        maxWidth: 700,
                        width: "100%",
                    }}
                >
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <Form.Item
                            required
                            name={"title"}
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    max: 50,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            required
                            name={"message"}
                            label="Message"
                            rules={[
                                {
                                    required: true,
                                    max: 250,
                                },
                            ]}
                        >
                            <TextArea rows={6} />
                        </Form.Item>

                        <Flex justify="end">
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Send
                            </Button>
                        </Flex>
                    </Form>
                </Card>
            </Flex>
        </div>
    );
};

export default Promotion;
