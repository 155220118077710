import { PageHeader } from "@ant-design/pro-layout";
import { Button, Dropdown, Flex, Form, Modal, notification, Spin } from "antd";
import { HddFilled, SyncOutlined } from "@ant-design/icons";

import { PaginationModel } from "@/models/pagination";
import { BulkRejectPayload } from "@/types/buyOrderCollection";
import CommonError from "../../../components/Error/CommonError";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { tw } from "@/consts/theme/tailwindTheme";
import { buyProductCollectionAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import {
    memo,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    useBulkRejectBuyProduct,
    useGetBuyProducts,
    useSyncBuyProducts,
} from "@/lib/core-react/hooks/private/usePurchase";
import { useAtom, useAtomValue } from "jotai";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import FiltersComponent from "@/components/FiltersComponent";
import { IModalActionsType, IModalData } from "../../../helpers/getModalTital";
import { useBuyProductBulkActions } from "./useActions";
import BuyProductsTable from "./BuyProductsTable";
import { showError } from "@/helpers/showError";
import { BuyProductAgentAssignModal } from "./components";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import RejectProductModal from "@/pages/Shipping/components/handleRejectModal";
import { RegionModel } from "@/models/regionCollectionModel";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import useRefetch from "@/hooks/useRefetch";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { getVisibleFilters } from "@/helpers/getfiltersBySettings";
import { FilterStorageKeys } from "@/consts/AppEnums";
import UpdateShippingCompanyBulk from "../components/UpdateShippingComapnyBulk";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import UpdateBulkFxForm from "./components/UpdateBulkFxForm";
import debounce from "lodash.debounce";
import BuyProductSelectedItemsSummary from "../components/BuyProductSelectedItemsSummary";

const BuyProducts = () => {
    const [form] = Form.useForm(); // pay now form
    const {
        paySession,
        calculateInvoices,
        isLoading: isLoadingPaySession,
    } = useInvoice();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<BuyProductModel[]>([]);
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
        buyProduct: BuyProductModel[];
    }>();
    const [bulkApproveModalOpen, setBulkApproveModalOpen] = useState<
        null | string
    >(null);
    const [bulkActionModalOpen, setBulkActionModalOpen] = useState<{
        action: IModalActionsType;
        data?: any;
    }>({
        action: false,
    });
    const [bulkRejectModalOpen, setBulkRejectModalOpen] = useState<
        false | string
    >(false);
    const { refetchListApi } = useRefetch();
    const [, setBuyProductCollection] = useAtom(buyProductCollectionAtom);
    const { isMobile } = useWindowWidth();

    const debouncedCalculateInvoices = useCallback(
        debounce((invoiceIds) => {
            calculateInvoices({ invoice_ids: invoiceIds });
        }, 2000),
        [],
    );

    const onChangeRow = (
        selectedRowKeys: React.Key[],
        rows: BuyProductModel[],
    ) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(rows);
        const invoiceIds = rows
            .map((d) => d.getCustomerInvoiceId())
            .filter((id) => typeof id === "number");
        if (invoiceIds.length) {
            debouncedCalculateInvoices(invoiceIds);
        }
    };

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
        handelSubFilterClear,
    } = useDataFilters();

    const {
        data: buyProductCollectionModel,
        refetch,
        isLoading: isBuyProductLoading,
        unAuthorized,
        error,
        code,
    } = useAtomValue(buyProductCollectionAtom);

    const { getBuyProducts } = useGetBuyProducts();
    const { bulkRejectBuyProduct } = useBulkRejectBuyProduct();
    const { syncProducts, isLoading: isLoadingSync } = useSyncBuyProducts();
    const selectedProductStoreIds = useMemo(() => {
        return Array.from(
            new Set(
                buyProductCollectionModel
                    ?.getData()
                    .filter((x) => selectedRowKeys?.includes(x.getId()))
                    .map((y) => y.getStore()?.getId())
                    .filter((id): id is number => id !== undefined),
            ),
        );
    }, [buyProductCollectionModel, selectedRowKeys]);

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setBulkApproveModalOpen(null);
        setBulkRejectModalOpen(false);
        setSelectedPayInvoiceData(undefined);
        onCancelModal();
    };

    const onCancelModal = () => {
        handleModal({
            action: false,
            data: undefined,
        });
    };

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getBuyProducts);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // ---------------------- Getting all available filters -> -------------------- //

    useEffect(() => {
        if (!isFetched && buyProductCollectionModel) {
            initializeAvailableFilter(buyProductCollectionModel?.getFilters());
        }
    }, [isFetched, initializeAvailableFilter]);

    // Filter Handler
    const handleFilter = () => {
        onClearBulkSelectedState();
        ApiHelperModel.makeGetRequest(filters, getBuyProducts);
    };
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
        if (!payload.data) setBulkApproveModalOpen(null);
        try {
            switch (payload.action) {
                case "reject_bulk_buy_product":
                    setBulkRejectModalOpen("reject_bulk_buy_product_open");
                    break;
                case "approved_buy_product_bulk":
                    setBulkApproveModalOpen("bulk_approve_modal_open");
                    break;
                case "pay-invoice":
                    setSelectedPayInvoiceData(payload.data);
                    break;
                case "update_agent_category_price_read":
                    setBulkActionModalOpen(payload);
                    break;
                default:
                    break;
            }
        } catch (error) {
            showError(error);
        }
    };

    const handleRejectBulk = async (value: { reject_reason: string }) => {
        try {
            const payload: BulkRejectPayload = {
                product_ids: selectedRowKeys.map((key) => Number(key)),
                reject_reason: value.reject_reason,
            };
            await bulkRejectBuyProduct(payload);

            notification["success"]({
                message: "Bulk products reject successfully",
            });

            onClearBulkSelectedState();
            setBuyProductCollection((pre) => ({
                ...pre,
                refetch: true,
            }));
        } catch (_error) {
            showError(_error);
        }
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                await refetchListApi();
                onClearBulkSelectedState();
                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const handleSync = async () => {
        try {
            if (selectedRows.length > 0) {
                const ids = selectedRows.map((row) => row.getId());
                await syncProducts({
                    ids,
                });
                onClearBulkSelectedState();
                showSuccessAlert(`Successfully Sync Products`);
                await refetchListApi();
            } else {
                throw new Error("Please Select Rows");
            }
        } catch (error) {
            showError(error);
        }
    };

    const { getActions: getBulkActions } = useBuyProductBulkActions({
        selectedRows,
        handleModal,
    });

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getBuyProducts,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        buyProductCollectionModel,
        handlePaginationChange,
    );

    // ------------------------------ ui --------------------------- //

    if ((!isBuyProductLoading && error) || unAuthorized) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Purchase Products"}
                style={{ marginTop: "10px" }}
            >
                <FiltersComponent
                    handleProductFilter={handleFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={
                        buyProductCollectionModel?.getFilters() &&
                        getVisibleFilters(
                            FilterStorageKeys.BuyProducts,
                            buyProductCollectionModel?.getFilters(),
                        )
                    }
                    isFromProductReceived={true}
                    isLoading={isBuyProductLoading}
                    harvestKey={
                        HarvestActionEnums.ADMIN_BUY_PRODUCT_EXPORT_HARVEST
                    }
                />

                <Flex
                    vertical
                    gap="middle"
                    style={{
                        marginTop: 32,
                    }}
                >
                    {selectedRows.length > 0 && (
                        <BuyProductSelectedItemsSummary
                            selectedRows={selectedRows}
                            onClear={onClearBulkSelectedState}
                        />
                    )}

                    <Flex gap={24} align="center">
                        <Dropdown
                            menu={{
                                items: getBulkActions(selectedRows),
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <Button
                                disabled={selectedRowKeys.length === 0}
                                style={{
                                    border: tw.border.none,
                                    boxShadow: tw.boxShadow.DEFAULT,
                                }}
                            >
                                Bulk Actions <HddFilled />
                            </Button>
                        </Dropdown>
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            htmlType="button"
                            disabled={selectedRowKeys.length === 0}
                            icon={<SyncOutlined spin={isLoadingSync} />}
                            onClick={() => handleSync()}
                        >
                            Sync
                        </Button>
                    </Flex>
                </Flex>

                <BuyProductsTable
                    onChangeRow={onChangeRow as any}
                    selectedRowKeys={selectedRowKeys}
                    isLoading={isBuyProductLoading}
                    paginationConfig={paginationConfig}
                    buyProductList={buyProductCollectionModel?.getData()}
                />
            </PageHeader>
            <Modal
                open={
                    selectedRowKeys.length > 0 &&
                    bulkApproveModalOpen === "bulk_approve_modal_open"
                }
                destroyOnClose={true}
                width={"100%"}
                onCancel={() => {
                    setBulkApproveModalOpen(null);
                    handelSubFilterClear();
                }}
                footer={false}
            >
                <Suspense fallback={<Spin />}>
                    {selectedRowKeys.length > 0 && (
                        <BuyProductAgentAssignModal
                            handleModal={handleModal}
                            selectedRowKeys={selectedRowKeys}
                            storeId={selectedProductStoreIds}
                            isBulk={true}
                        />
                    )}
                </Suspense>
            </Modal>
            <Modal
                width={"40%"}
                open={
                    selectedRowKeys.length > 0 &&
                    bulkRejectModalOpen === "reject_bulk_buy_product_open"
                }
                onCancel={onCancelModal}
                destroyOnClose={true}
                footer={null}
                title={"Confirm Reject"}
            >
                <RejectProductModal
                    onClose={onCancelModal}
                    handleOk={handleRejectBulk}
                />
            </Modal>

            <Modal
                width={"60%"}
                title="Pay Customer Invoice"
                open={Boolean(selectedPayInvoiceData)}
                onCancel={() => {
                    handelSubFilterClear();
                    handleModal({
                        action: false,
                        data: null,
                    });
                    setSelectedPayInvoiceData(undefined);
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingPaySession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>

            <Modal
                title={"Update Shipping Company"}
                open={
                    bulkActionModalOpen.action ===
                    "update_agent_category_price_read"
                }
                destroyOnClose={true}
                onCancel={() => {
                    setBulkActionModalOpen({
                        action: false,
                    });
                }}
                width={isMobile ? undefined : 800}
                footer={false}
            >
                {selectedRows.length && (
                    <Suspense fallback={<Spin />}>
                        <UpdateShippingCompanyBulk
                            product_ids={selectedRows.map((row) => row.getId())}
                            selectedFirstRow={selectedRows[0]}
                            handleModal={() => {
                                setBulkActionModalOpen({
                                    action: false,
                                });
                                onClearBulkSelectedState();
                            }}
                        />
                    </Suspense>
                )}
            </Modal>
            <Modal
                title={"Update Bulk FX"}
                open={modalOpenType === "admin-buy-product-fx-update"}
                destroyOnClose={true}
                onCancel={() => {
                    handelSubFilterClear();
                    onCancelModal();
                }}
                footer={false}
            >
                <UpdateBulkFxForm
                    selectedRows={selectedRows}
                    handleModal={handleModal}
                />
            </Modal>
        </>
    );
};

export default memo(BuyProducts);
