import { IPublishToAllPayload } from "@/types/notification.";
import { useService } from "../../contexts";
import { useState } from "react";

export const usePublishToAll = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { notificationService } = useService();

    const publishToAll = async (
        payload: IPublishToAllPayload,
    ): Promise<void> => {
        try {
            setIsLoading(true);
            const response =
                await notificationService.notificationResource.publishToAll(
                    payload,
                );

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { publishToAll, isLoading } as const;
};
