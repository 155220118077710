import { Button, Form, Input, Select } from "antd";

import { transformEnumToLabeledValue } from "@/utils/helpers";
import { showError } from "@/helpers/showError";
import { PurchaseRuleGroupModel } from "@/models/purchaseRuleGroupCollectionModel";
import { usePurchaseRuleGroup } from "@/lib/core-react/hooks/private/usePurchaseRuleGroup";
import { IPurchaseRuleGroupModificationFormData } from "@/types/purchaseRuleGroupCollection";
import { usePurchaseRule } from "@/lib/core-react/hooks/private/usePurchaseRule";
import { useAtom } from "jotai";
import { purchaseRuleCollectionAtom } from "@/lib/core-react/store/store";
import { PurchaseRuleCollectionModel } from "@/models/purchaseRuleCollectionModel";
import { PurchaseRuleStatusEnum } from "@/enums/purchaseRuleCollectionEnums";
import { useEffect } from "react";

interface IProps {
    onHide: () => void;
    data?: PurchaseRuleGroupModel;
}

const PurchaseRuleGroupModification = ({ onHide, data }: IProps) => {
    const {
        getPurchaseRuleGroupCollection,
        createPurchaseRuleGroup,
        updatePurchaseRuleGroup,
        isLoading,
    } = usePurchaseRuleGroup();

    const { getPurchaseRuleCollection } = usePurchaseRule();

    useEffect(() => {
        if (!data) {
            getPurchaseRuleCollection();
        }
    }, [data]);

    const [
        {
            data: purchaseRuleCollectionAtomData,
            isLoading: isLoadingPurchaseRule,
        },
    ] = useAtom(purchaseRuleCollectionAtom);

    const PurchaseRuleCollectionData =
        purchaseRuleCollectionAtomData &&
        new PurchaseRuleCollectionModel(purchaseRuleCollectionAtomData);

    const [form] = Form.useForm<IPurchaseRuleGroupModificationFormData>();

    const onFinish = async (value: IPurchaseRuleGroupModificationFormData) => {
        try {
            if (data) {
                await updatePurchaseRuleGroup(data.getId(), value);
            } else {
                await createPurchaseRuleGroup(value);
            }
            onHide();
            await getPurchaseRuleGroupCollection();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name={
                data
                    ? "Update Purchase Rule Group"
                    : "Create Purchase Rule Group"
            }
            initialValues={{
                name: data?.getName(),
                status: data?.getStatus(),
                purchase_rule_id: data?.getPurchaseRule().getId(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Name" />
            </Form.Item>

            {!data && (
                <>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Purchase rule is required",
                            },
                        ]}
                        name="purchase_rule_id"
                        label="Purchase Rule"
                    >
                        <Select
                            loading={isLoadingPurchaseRule}
                            placeholder="Please select a purchase rule"
                            options={PurchaseRuleCollectionData?.getData().map(
                                (p) => {
                                    return {
                                        label: p.getName(),
                                        value: p.getId(),
                                    };
                                },
                            )}
                        />
                    </Form.Item>
                </>
            )}

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select
                    placeholder="Please select a status"
                    options={transformEnumToLabeledValue(
                        PurchaseRuleStatusEnum,
                    )}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PurchaseRuleGroupModification;
