import { ResponsePromise } from "@/lib/core/request/types/typings";
import { BaseResource } from "../public";
import { IHeaders } from "@/lib/core/request/types/request";
import {
    IRfqRequestsBulkApprove,
    IRfqRequestsApprovePayload,
    RFQRequestAddPayload,
    RFQRequestUpdatePayload,
} from "@/types/rfqRequestCreate";

export class RfqResource extends BaseResource {
    private rfqPath = "/api/rfq/admin/request/v1/requests";
    public list(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = this.rfqPath;

        if (params) {
            path = `${path}?${params}`;
        }

        return this.client.request(
            "GET",
            path,
            undefined,
            undefined,
            customHeaders,
        );
    }
    public quotes(
        requestId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.rfqPath}/${requestId}/quotes`;
        if (params) {
            path = `${path}?${params}`;
        }

        return this.client.request(
            "GET",
            path,
            undefined,
            undefined,
            customHeaders,
        );
    }
    public quotesDetail(
        requestId: number,
        quoteId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.rfqPath}/${requestId}/quotes/${quoteId}`;
        if (params) {
            path = `${path}?${params}`;
        }

        return this.client.request(
            "GET",
            path,
            undefined,
            undefined,
            customHeaders,
        );
    }

    public approve({
        requestId,
        payload,
        customHeaders,
    }: {
        requestId: string;
        payload: IRfqRequestsApprovePayload;
        customHeaders: IHeaders;
    }) {
        const path = `${this.rfqPath}/${requestId}/approve`;
        return this.client.request(
            "POST",
            path,
            payload,
            undefined,
            customHeaders,
        );
    }

    public bulkApprove({
        payload,
        customHeaders,
    }: {
        payload: IRfqRequestsBulkApprove;
        customHeaders: IHeaders;
    }) {
        const path = `${this.rfqPath}/bulk-approve`;
        return this.client.request(
            "POST",
            path,
            payload,
            undefined,
            customHeaders,
        );
    }

    public reject({
        requestId,
        payload,
    }: {
        requestId: string;
        payload: {
            reject_reason: string;
        };
    }) {
        const path = `${this.rfqPath}/${requestId}/reject`;
        return this.client.request("POST", path, payload, undefined, {});
    }

    public create(payload: RFQRequestAddPayload) {
        const path = `${this.rfqPath}`;
        return this.client.request("POST", path, payload, undefined, {});
    }
    public update(requestId: number, payload: RFQRequestUpdatePayload) {
        const path = `${this.rfqPath}/${requestId}/updated`;
        return this.client.request("PUT", path, payload, undefined, {});
    }
}
