import React from "react";
import { Typography, Flex } from "antd";
import { BuyActionModel } from "@/models/buyActionCollectionModel";

interface IProps {
    buyActionData: BuyActionModel;
    unitPrice?: string;
    originalUnitPrice?: string;
}

const PriceDisplay: React.FC<IProps> = ({
    buyActionData,
    unitPrice = "0",
    originalUnitPrice = "0",
}) => {
    const originalCurrency = buyActionData
        .getBuyProduct()
        .getStore()
        .getDefaultCurrency()
        ?.getSymbolNative();
    const regionalCurrency = buyActionData
        .getBuyProduct()
        .getRegion()
        .getCurrency()
        ?.getSymbolNative();

    return (
        <Flex vertical>
            <Typography.Text
                strong
                style={{ fontSize: "18px", color: "#1890ff", fontWeight: 600 }}
            >
                {regionalCurrency}
                {Number(unitPrice).toFixed(2)}
            </Typography.Text>

            <Typography.Text
                style={{
                    fontSize: "13px",
                    color: "gray",
                    opacity: 0.8,
                }}
            >
                {originalCurrency}
                {Number(originalUnitPrice).toFixed(2)}
            </Typography.Text>
        </Flex>
    );
};

export default PriceDisplay;
