import { useAtom } from "jotai";
import { useState } from "react";
import { notification } from "antd";

import { useService } from "@/lib/core-react/contexts";
import {
    TransactionCollectionAtom,
    payoutGateWayCollectionAtom,
    walletOverviewCollectionAtom,
    masterWalletOverviewCollectionAtom,
    agentWalletOverviewCollectionAtom,
    customerPayoutRequestCollectionAtom,
    agentPayoutRequestCollectionAtom,
} from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { IPayoutGateWayFormRequest } from "@/types/payoutGatewayCollection";
import { ICustomerWalletRechargeFormData } from "@/types/walletOverviewCollection";

export const useWallet = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { walletService } = useService();

    const rechargeCustomerWallet = async (
        user_id: number,
        wallet_id: number,
        payload: ICustomerWalletRechargeFormData,
    ) => {
        try {
            setIsLoading(true);
            const res =
                await walletService.walletResource.rechargeCustomerWallet(
                    user_id,
                    wallet_id,
                    payload,
                );

            setIsLoading(false);
            return res;
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    return {
        rechargeCustomerWallet,
        isLoading,
    } as const;
};

export const useWalletTransactions = () => {
    const [walletTransaction, setWalletTransaction] = useAtom(
        TransactionCollectionAtom,
    );

    const { walletService } = useService();

    const getWalletTransctions = async (
        walletId: number,
        filters?: { [key: string]: any },
    ): Promise<void> => {
        setWalletTransaction({
            ...walletTransaction,
            isLoading: true,
            error: null,
        });
        try {
            const response = await walletService.walletResource.getTransactions(
                walletId,
                filters,
            );
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };

    return { getWalletTransctions } as const;
};

export const useWalletOverviewBalance = () => {
    const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
        masterWalletOverviewCollectionAtom,
    );

    const { walletService } = useService();

    const getWalletOverviewBalance = async () => {
        try {
            const response =
                await walletService.walletResource.getOverviewBalance();
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return {
        getWalletOverviewBalance,
    } as const;
};

// Agent Wallet
export const useAgentWalletOverviewBalance = () => {
    const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
        agentWalletOverviewCollectionAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const { walletService } = useService();

    const getAgentWalletOverviewBalance = async (agentCompanyId: number) => {
        setWalletOverviewBalance({
            ...walletOverviewBalance,
            isLoading: true,
            data: undefined,
            refetch: false,
            error: null,
        });

        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.getOverviewAgentCompanyBalance(
                    agentCompanyId,
                );
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                data: response.data,
                refetch: false,
                error: null,
            });
            setIsLoading(false);
        } catch (error: any) {
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            setIsLoading(false);
            setError(getError(error));
            //throw error;
        }
    };

    return {
        getAgentWalletOverviewBalance,
        isError,
        isLoading,
    } as const;
};

export const useAgentWalletTransactions = () => {
    const [walletTransaction, setWalletTransaction] = useAtom(
        TransactionCollectionAtom,
    );
    const [isError, setError] = useState<string>("");

    const { walletService } = useService();

    const getAgentWalletTransctions = async (
        agentCompanyId: number,
        walletId: number,
        params?: string,
    ): Promise<void> => {
        try {
            setWalletTransaction({
                ...walletTransaction,
                isLoading: true,
            });
            const response =
                await walletService.walletResource.getTransactionsAgentCompany(
                    agentCompanyId,
                    walletId,
                    params,
                );
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                data: response,
                refetch: false,
                error: null,
            });
        } catch (error: any) {
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            setError(getError(error));
            throw error;
        }
    };

    return { getAgentWalletTransctions, isError } as const;
};

// User Wallet
export const useUserWalletOverviewBalance = () => {
    const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
        walletOverviewCollectionAtom,
    );

    const { walletService } = useService();

    const getUserWalletOverviewBalance = async (userId: number) => {
        setWalletOverviewBalance({
            ...walletOverviewBalance,
            isLoading: true,
            data: undefined,
            refetch: false,
            error: null,
        });
        try {
            const response =
                await walletService.walletResource.getOverviewUserBalance(
                    userId,
                );
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                data: response.data,
                refetch: false,
                error: null,
            });
        } catch (error: any) {
            setWalletOverviewBalance({
                ...walletOverviewBalance,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };

    return {
        getUserWalletOverviewBalance,
    } as const;
};

export const useUserWalletTransactions = () => {
    const [walletTransaction, setWalletTransaction] = useAtom(
        TransactionCollectionAtom,
    );

    const { walletService } = useService();

    const getUserWalletTransctions = async (
        userId: number,
        walletId: number,
        params?: string,
    ): Promise<void> => {
        try {
            const response =
                await walletService.walletResource.getTransactionsUser(
                    userId,
                    walletId,
                    params,
                );
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setWalletTransaction({
                ...walletTransaction,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getUserWalletTransctions } as const;
};

// Payout GateWays
export const useGetPayoutGateways = () => {
    const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
        useAtom(payoutGateWayCollectionAtom);
    const { walletService } = useService();
    const getPayoutGateWay = async (params?: string): Promise<void> => {
        try {
            const response =
                await walletService.walletResource.getPayoutGateWays(params);
            setPayoutGateWaysCollectionData({
                ...payoutGateWayCollectionData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPayoutGateWaysCollectionData({
                ...payoutGateWayCollectionData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };
    return { getPayoutGateWay } as const;
};

export const useCreatedPayoutGateway = () => {
    const { walletService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
        useAtom(payoutGateWayCollectionAtom);

    const createdPayoutGateway = async (payload: IPayoutGateWayFormRequest) => {
        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.createPayoutGateWays(
                    payload,
                );
            setIsLoading(false);
            setPayoutGateWaysCollectionData({
                ...payoutGateWayCollectionData,
                refetch: true,
            });
            notification["success"]({
                message: "Create successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { createdPayoutGateway, isLoading, isError };
};

export const useUpdatePayoutGateway = () => {
    const { walletService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
        useAtom(payoutGateWayCollectionAtom);

    const updatePayoutGateway = async (
        id: number,
        payload: IPayoutGateWayFormRequest,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.updatePayoutGateWays(
                    id,
                    payload,
                );
            setIsLoading(false);
            setPayoutGateWaysCollectionData({
                ...payoutGateWayCollectionData,
                refetch: true,
            });
            notification["success"]({
                message: "Update successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updatePayoutGateway, isLoading, isError };
};

// Payout Request
export const useGetPayoutRequests = () => {
    const [
        customerPayoutRequestCollectionData,
        setCustomerPayoutRequestCollectionData,
    ] = useAtom(customerPayoutRequestCollectionAtom);
    const [
        agentPayoutRequestCollectionData,
        setAgentPayoutRequestCollectionData,
    ] = useAtom(agentPayoutRequestCollectionAtom);
    const { walletService } = useService();

    const getAgentPayoutRequests = async (params?: string): Promise<void> => {
        setAgentPayoutRequestCollectionData({
            ...agentPayoutRequestCollectionData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await walletService.walletResource.getPayoutRequests(
                    params
                        ? `${params}&ownable_type=agent-company`
                        : `ownable_type=agent-company`,
                );
            setAgentPayoutRequestCollectionData({
                ...agentPayoutRequestCollectionData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAgentPayoutRequestCollectionData({
                ...agentPayoutRequestCollectionData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };

    const getCustomerPayoutRequests = async (
        params?: string,
    ): Promise<void> => {
        setCustomerPayoutRequestCollectionData({
            ...customerPayoutRequestCollectionData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await walletService.walletResource.getPayoutRequests(
                    params
                        ? `${params}&ownable_type=personal`
                        : `ownable_type=personal`,
                );
            setCustomerPayoutRequestCollectionData({
                ...customerPayoutRequestCollectionData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setCustomerPayoutRequestCollectionData({
                ...customerPayoutRequestCollectionData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };
    return { getCustomerPayoutRequests, getAgentPayoutRequests } as const;
};

export const useUpdatePayoutRequest = () => {
    const { walletService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [
        customerPayoutRequestCollectionData,
        setCustomerPayoutRequestCollectionData,
    ] = useAtom(customerPayoutRequestCollectionAtom);
    const [
        agentPayoutRequestCollectionData,
        setAgentPayoutRequestCollectionData,
    ] = useAtom(agentPayoutRequestCollectionAtom);

    const updatePayoutRequest = async (
        id: number,
        action: "processing" | "reject",
    ) => {
        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.updatePayoutRequest(
                    id,
                    action,
                );
            setIsLoading(false);
            setCustomerPayoutRequestCollectionData({
                ...customerPayoutRequestCollectionData,
                refetch: true,
            });
            setAgentPayoutRequestCollectionData({
                ...agentPayoutRequestCollectionData,
                refetch: true,
            });
            notification["success"]({
                message: "Update successfully",
            });
            return response.data;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updatePayoutRequest, isLoading, isError };
};

export const useApprovePayoutRequest = () => {
    const { walletService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [
        customerPayoutRequestCollectionData,
        setCustomerPayoutRequestCollectionData,
    ] = useAtom(customerPayoutRequestCollectionAtom);
    const [
        agentPayoutRequestCollectionData,
        setAgentPayoutRequestCollectionData,
    ] = useAtom(agentPayoutRequestCollectionAtom);

    const approvePayoutRequest = async (
        id: number,
        payload: {
            approved_amount: number;
            admin_note: null | string;
        },
    ) => {
        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.approvePayoutRequest(
                    id,
                    payload,
                );
            setIsLoading(false);
            setCustomerPayoutRequestCollectionData({
                ...customerPayoutRequestCollectionData,
                refetch: true,
            });
            setAgentPayoutRequestCollectionData({
                ...agentPayoutRequestCollectionData,
                refetch: true,
            });
            notification["success"]({
                message: "Approved successfully",
            });
            return response.data;
        } catch (error: any) {
            notification["error"]({
                message: getError(error),
            });
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { approvePayoutRequest, isLoading, isError };
};

export const useBulkApprovePayoutRequests = () => {
    const { walletService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [
        customerPayoutRequestCollectionData,
        setCustomerPayoutRequestCollectionData,
    ] = useAtom(customerPayoutRequestCollectionAtom);
    const [
        agentPayoutRequestCollectionData,
        setAgentPayoutRequestCollectionData,
    ] = useAtom(agentPayoutRequestCollectionAtom);

    const bulkApprovePayoutRequests = async (payload: {
        ids: number[];
        admin_note?: string;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await walletService.walletResource.BulkApprovePayoutRequests(
                    payload,
                );
            setIsLoading(false);
            setCustomerPayoutRequestCollectionData({
                ...customerPayoutRequestCollectionData,
                refetch: true,
            });
            setAgentPayoutRequestCollectionData({
                ...agentPayoutRequestCollectionData,
                refetch: true,
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { bulkApprovePayoutRequests, isLoading, isError };
};
