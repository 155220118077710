import { useAtom } from "jotai";
import { useEffect } from "react";
import { baseShippingCategoryAtom } from "@/lib/core-react/store/store";
import React from "react";
import { Button, Form, Input, notification, Select, TreeSelect } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { FormInstance } from "antd/es/form/Form";
import {
    ShippingCategoryModel,
    ShippingCategoryRequestModel,
} from "@/models/shippingCategory";
import {
    useGetBaseShippingCategory,
    useUpdateShippingCategory,
} from "@/lib/core-react/hooks/private";
import { ShippingCategoryStatusEnum } from "@/enums/shippingCategoryCollectionEnums";
import { BaseShippingCategoryModel } from "@/models/baseShippingCategory";
import { showError } from "@/helpers/showError";

interface IProps {
    data: ShippingCategoryModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateShippingCategory = ({ data, setIsShowUpdateModal }: IProps) => {
    const { updateShippingCategory, isLoading } = useUpdateShippingCategory();
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [data, form]);

    useEffect(() => {
        getBaseShippingCategory("per_page=500");
    }, []);

    // region, base Shipping category
    const { getBaseShippingCategory } = useGetBaseShippingCategory();

    const [
        {
            data: baseShippingCategoryDataAtom,
            isLoading: baseShippingCategoryLoading,
        },
    ] = useAtom(baseShippingCategoryAtom);

    const baseShippingCategoryTreeId =
        baseShippingCategoryDataAtom &&
        new BaseShippingCategoryModel(
            baseShippingCategoryDataAtom,
        ).getBaseShippingCategoryTreeIds();

    const onFinish = async (value: ShippingCategoryRequestModel) => {
        const payload = {
            name: value.name,
            status: value.status,
            slug: value.slug,
            base_shipping_category_id: value.base_shipping_category_id,
        };
        try {
            const res = await updateShippingCategory(
                data.id as string,
                payload as ShippingCategoryRequestModel,
            );

            if (res) {
                notification["success"]({
                    message: res?.message,
                });

                setIsShowUpdateModal(false);
            }
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        if (changedValues.name) {
            const slug = changedValues.name.toLowerCase().replace(/\s+/g, "-");
            form.setFieldsValue({ slug });
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update_Shipping_Category"
            ref={formRef}
            initialValues={{
                slug: data.slug,
                name: data.name,
                parent_id: data.parent_id,
                status: data.status,
                base_shipping_category_id: data.base_shipping_category_id,
                region_id: data.region?.getId(),
            }}
            onValuesChange={onValuesChange}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Shipping category name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Shipping category name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Slug is required" }]}
                label="Slug"
                name="slug"
            >
                <Input placeholder="Slug name" />
            </Form.Item>
            <Form.Item
                label="Select Base Shipping Category"
                name="base_shipping_category_id"
                rules={[
                    {
                        required: true,
                        message: "Base Shipping Category is required",
                    },
                ]}
            >
                <TreeSelect
                    showSearch
                    dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
                    placeholder="Please select a base shipping category"
                    allowClear
                    loading={baseShippingCategoryLoading}
                    treeLine={{ showLeafIcon: true }}
                    treeData={baseShippingCategoryTreeId}
                    filterTreeNode={(searchValue, treeNode) => {
                        const title = treeNode.title;
                        return (
                            typeof title === "string" &&
                            title
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        );
                    }}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select placeholder="Please select a status">
                    {renderOptionsFromEnum(ShippingCategoryStatusEnum)}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateShippingCategory;
