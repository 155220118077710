export enum PurchaseRuleCollectionEnums {
    PurchaseRuleCollection = "PurchaseRuleCollection",
    PurchaseRule = "PurchaseRule",
}

export enum PurchaseRuleTypeEnum {
    CHECKOUT_TOTAL_AMOUNT = "regional_total_amount",
    STORE_TOTAL_AMOUNT = "store_total_amount",
}

export enum PurchaseRuleStatusEnum {
    Active = "active",
    Inactive = "inactive",
}

export enum PurchaseRuleModalEnum {
    "Create Purchase Rule" = "Create Purchase Rule",
    "Update Purchase Rule" = "Update Purchase Rule",
    "Delete Purchase Rule" = "Delete Purchase Rule",
}
