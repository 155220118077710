import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import {
    PurchaseRuleCollectionEnums,
    PurchaseRuleStatusEnum,
    PurchaseRuleTypeEnum,
} from "@/enums/purchaseRuleCollectionEnums";
import {
    IPurchaseRule,
    IPurchaseRuleCollection,
} from "@/types/purchaseRuleCollection";
import { CommonRegionModel } from "./shared";

export class PurchaseRuleModel {
    object: PurchaseRuleCollectionEnums.PurchaseRule;
    id: number;
    name: string;
    is_default: boolean;
    type: PurchaseRuleTypeEnum;
    rule: {
        value: number;
        stores?: string[];
        operand: string;
        operator: string;
    };
    status: PurchaseRuleStatusEnum;
    region_id: number;
    created_at: number;
    region?: CommonRegionModel;

    constructor(data: IPurchaseRule) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.is_default = data.is_default;
        this.type = data.type;
        this.rule = data.rule;
        this.status = data.status;
        this.region_id = data.region_id;
        this.created_at = data.created_at;
        this.region = data.region && new CommonRegionModel(data.region);
    }

    getId = () => this.id;
    getName = () => this.name;
    getIsDefault = () => this.is_default;
    getType = () => this.type;
    getRule = () => this.rule;
    getStatus = () => this.status;
    getRegionId = () => this.region_id;
    getRegion = () => this.region;
    getCreatedAt = () => this.created_at;
}

export class PurchaseRuleCollectionModel {
    object: PurchaseRuleCollectionEnums.PurchaseRuleCollection;
    data: PurchaseRuleModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: IPurchaseRuleCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PurchaseRuleModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
