export enum PurchaseRuleGroupCollectionEnums {
    PurchaseRuleGroupCollection = "PurchaseRuleGroupCollection",
    PurchaseRuleGroup = "PurchaseRuleGroup",
    PurchaseRuleGroupUserCollection = "PurchaseRuleGroupUserCollection",
    PurchaseRuleGroupUser = "PurchaseRuleGroupUser",
}

export enum PurchaseRuleGroupModalEnum {
    "Create Purchase Rule Group" = "Create Purchase Rule Group",
    "Update Purchase Rule Group" = "Update Purchase Rule Group",
    "Delete Purchase Rule Group" = "Delete Purchase Rule Group",
    "Purchase Rule Group Customers" = "Purchase Rule Group Customers",
    "Add Purchase Rule Group Customers" = "Add Purchase Rule Group Customers",
}
