import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ReadOnlyEditorProps {
    value: string;
}

const ReadOnlyEditorReactQuill: React.FC<ReadOnlyEditorProps> = ({ value }) => {
    return (
        <ReactQuill
            value={value}
            readOnly
            theme="bubble" // Using "bubble" gives a clean read-only display; use "snow" if you prefer your current style
            modules={{ toolbar: false }} // No toolbar
        />
    );
};

export default ReadOnlyEditorReactQuill;
