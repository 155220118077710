import { useEffect, useState } from "react";
import { PageLoading } from "@ant-design/pro-layout";
import { Input, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAtomValue } from "jotai";

import { useSyncableShipmentProductsGet } from "@/lib/core-react/hooks/private/useShipping";
import { shipmentProductsSyncableAtom } from "@/lib/core-react/store/store";
import {
    ShipmentProductCollectionModel,
    ShipmentProductModel,
} from "@/models/shipmentProductCollectionModel";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import { IUnlistedPackageClaimFormDataRaw } from "@/types/unlistedPackageCollection";

import ShipmentProductInfo from "@/pages/Shipping/newComponents/ShipmentProductInfo";

interface IProps {
    data: UnlistedPackageModel;
    setClaimValues: React.Dispatch<
        React.SetStateAction<IUnlistedPackageClaimFormDataRaw | undefined>
    >;
}
const ProductSelection = ({ data, setClaimValues }: IProps) => {
    const { getSyncable } = useSyncableShipmentProductsGet();
    const { data: syncableProductsCollectionData, isLoading } = useAtomValue(
        shipmentProductsSyncableAtom,
    );
    const trackingNumber = data.getTracking().getAbroadTracking();
    const dw_id = data.getDestinationWarehouse().getId();

    const [searchTerm, setSearchTerm] = useState<string>();

    let queryObj = {};
    if (trackingNumber && !searchTerm) {
        queryObj = {
            tracking_number: trackingNumber,
        };
    } else if (searchTerm) {
        queryObj = {
            product_number: searchTerm,
        };
    }

    useEffect(() => {
        getSyncable(dw_id, queryObj);
    }, [data, searchTerm]);

    const syncableProductsCollection =
        syncableProductsCollectionData &&
        new ShipmentProductCollectionModel(syncableProductsCollectionData);

    const columns: ColumnsType<ShipmentProductModel> = [
        {
            title: "Product Info",
            dataIndex: "product-details",
            key: "product-details",
            width: 400,
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, shipmentProduct) => (
                <>
                    <ShipmentProductInfo
                        handleModal={() => {}}
                        isDetailPage={false}
                        shipmentProductModel={shipmentProduct}
                    />
                </>
            ),
        },
    ];

    if (isLoading && !searchTerm) return <PageLoading />;

    return (
        <div>
            <Space
                direction="vertical"
                align="center"
                size="large"
                style={{ marginTop: "24px" }}
            >
                <Typography.Text strong>
                    Showing search result for{" "}
                    {searchTerm
                        ? `product number: ${searchTerm}`
                        : `tracking code: ${trackingNumber}`}
                </Typography.Text>

                <Input.Search
                    placeholder="Enter product number"
                    enterButton
                    size="middle"
                    onSearch={(value) => setSearchTerm(value)}
                    loading={isLoading}
                />
            </Space>
            <Table
                loading={isLoading}
                dataSource={syncableProductsCollection?.getAllProducts()}
                columns={columns}
                rowHoverable={false}
                rowKey={(r) => r.id}
                style={{
                    backgroundColor: "white",
                    marginTop: 24,
                    width: "100%", // Ensure table takes full container width
                }}
                bordered
                pagination={false}
                rowSelection={{
                    type: "radio",
                    onChange: (_, selectedRows) => {
                        setClaimValues({
                            shipment_product: selectedRows[0],
                        });
                    },
                }}
            />
        </div>
    );
};

export default ProductSelection;
