import {
    Form,
    Select,
    Button,
    InputNumber,
    Card,
    Typography,
    Row,
    Col,
    Divider,
    Input,
    TreeSelect,
    message,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import {
    agentCategoryPriceReadCollectionAtom,
    hsCodeCollection,
    shippingCategoryAtom,
} from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import { useEffect, useState, useCallback } from "react";
import { ApiHelperModel } from "@/models/apiHelper";
import debounce from "lodash.debounce";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { useHsCodeList } from "@/lib/core-react/hooks/private/useHsCode";
import { ContainsEnumOptions } from "@/enums/shipForMeContextEnums";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import { ISelectOption } from "@/filters/Models/SelectOptionModel";
import { ShippingCategoryCollectionModel } from "@/models/shippingCategory";
import CategoryPriceReadTable from "./CategoryPriceReadTable";
import { AgentCategoryPriceReadCollectionModel } from "@/models/agentCategoryPriceReadCollectionModel";

const { Title } = Typography;

const CostCalculationForm = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [selectedRegion, setSelectedRegion] = useState<
        ISelectOption | undefined
    >();

    const [shippingCategoryName, setShippingCategoryName] = useState("");

    const [categoryPriceReadParams, setCategoryPriceReadParams] = useState<
        ICategoryPriceRequestParams | undefined
    >();

    const [
        { data: shippingCategoryAtomData, isLoading: shippingCategoryLoading },
    ] = useAtom(shippingCategoryAtom);
    const [
        {
            data: agentCategoryPriceReadCollectionData,
            isLoading: agentCategoryPriceReadCollectionLoading,
        },
    ] = useAtom(agentCategoryPriceReadCollectionAtom);

    const { getShippingCategory } = useGetShippingCategory();

    const shippingCategoryOptions =
        shippingCategoryAtomData &&
        new ShippingCategoryCollectionModel(
            shippingCategoryAtomData,
        ).getShippingCategoryTreeIds();

    const {
        allOptionsData: {
            shippingCountryOptionsData,
            regionOptionsData,
            destinationWarehouseOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { getHsCodeList } = useHsCodeList();
    const [{ data: hsCodeData, isLoading: hsCodeLoading }] =
        useAtom(hsCodeCollection);

    // Create debounced search function
    const debouncedHsCodeSearch = useCallback(
        debounce((searchText: string) => {
            getHsCodeList(`hs_code=${searchText}`);
        }, 500),
        [],
    );

    // Initial load of HS codes
    useEffect(() => {
        getHsCodeList();
    }, []);

    const hsCodeOptions =
        hsCodeData?.data?.map((code) => ({
            value: code.id,
            label: `${code.hs_code} - ${code.description}`,
        })) || [];

    const debouncedShippingCategorySearch = debounce((value) => {
        setShippingCategoryName(value);
    }, 500);

    // Api Call
    useEffect(() => {
        ApiHelperModel.makeGetRequest(
            {
                name: shippingCategoryName,
                per_page: 100,
            },
            getShippingCategory,
        );
    }, [shippingCategoryName]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
        if (selectedRegion) {
            onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
                region_id: selectedRegion.value,
            });
            onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE, {
                region_id: selectedRegion.value,
            });
        }
    }, [selectedRegion]);

    const { getCategoryPrices } = useGetCategoryPrices();

    const handleSubmit = async (values: any) => {
        if (!selectedRegion) {
            message.error("Please select a region first");
            return;
        }

        /*
        Payload of Customer app:
        destination_country_id: 3
        shipping_country_id: 9
        destination_warehouse_id: 5
        shipping_category_id: 9da044c8-47bb-4057-828b-2ce2e349f3f5
        region: BD
        locale: en
        page: 1
        per_page: 25
        */

        const params: ICategoryPriceRequestParams = {
            region: selectedRegion?.code as string,
            destination_country_id: values.shipping_country,
            shipping_country_id: values.shipping_country,
            destination_warehouse_id: values.destination_warehouse,
            shipping_category_id: values.product_category,
            contains: values.contains,
        };

        setCategoryPriceReadParams(params);
        await getCategoryPrices(params);
    };

    const agentCategoryPriceRead =
        agentCategoryPriceReadCollectionData &&
        new AgentCategoryPriceReadCollectionModel(
            agentCategoryPriceReadCollectionData,
        );

    return (
        <>
            <PageHeader
                title={
                    <Button
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                        type="dashed"
                    >
                        Go Back
                    </Button>
                }
                style={{ padding: "16px" }}
                backIcon={false}
            />
            <Row gutter={16} style={{ marginTop: "16px", padding: "16px" }}>
                <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                    <Card>
                        <Title
                            level={4}
                            style={{
                                textAlign: "center",
                                marginBottom: "16px !important",
                            }}
                        >
                            Shipping Cost Calculator
                        </Title>

                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark="optional"
                            style={{ gap: "16px", marginTop: "16px" }}
                            onFinish={handleSubmit}
                        >
                            {/* Basic Information */}
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Product Category"
                                        required
                                        tooltip="Select the category that best describes your product"
                                        name="product_category"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select a product category",
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            showSearch
                                            dropdownStyle={{
                                                maxHeight: 600,
                                                overflow: "auto",
                                            }}
                                            placeholder="Please select a shipping category"
                                            allowClear
                                            loading={shippingCategoryLoading}
                                            treeLine={{ showLeafIcon: true }}
                                            treeData={shippingCategoryOptions}
                                            onSearch={(value) => {
                                                debouncedShippingCategorySearch(
                                                    value,
                                                );
                                            }}
                                            filterTreeNode={(
                                                inputValue,
                                                treeNode: any,
                                            ) => {
                                                return treeNode.title
                                                    .toLowerCase()
                                                    .includes(
                                                        inputValue.toLowerCase(),
                                                    );
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={16}>
                                    <Form.Item
                                        label="Price of Product"
                                        required
                                    >
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            min={1}
                                            placeholder="Enter price"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Currency" required>
                                        <Select
                                            defaultValue="BDT"
                                            options={[
                                                { value: "BDT", label: "BDT" },
                                                { value: "USD", label: "USD" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider style={{ margin: "8px 0" }} />

                            {/* Shipping Details */}
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Region"
                                        required
                                        name="region"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select a region",
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Region"
                                            options={regionOptionsData.options}
                                            loading={
                                                regionOptionsData.isLoading
                                            }
                                            onChange={(_, option) => {
                                                setSelectedRegion(
                                                    option as ISelectOption,
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Shipping Country"
                                        required
                                        name="shipping_country"
                                    >
                                        <Select
                                            placeholder={
                                                selectedRegion
                                                    ? "Select Shipping country"
                                                    : "Select Region First"
                                            }
                                            disabled={!selectedRegion}
                                            options={
                                                shippingCountryOptionsData.options
                                            }
                                            loading={
                                                shippingCountryOptionsData.isLoading
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        label="Destination Warehouse"
                                        required
                                        name="destination_warehouse"
                                    >
                                        <Select
                                            placeholder={
                                                selectedRegion
                                                    ? "Select Destination Warehouse"
                                                    : "Select Region First"
                                            }
                                            disabled={!selectedRegion}
                                            options={
                                                destinationWarehouseOptionsData.options
                                            }
                                            loading={
                                                destinationWarehouseOptionsData.isLoading
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider style={{ margin: "8px 0" }} />

                            {/* Package Details */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Quantity"
                                        required
                                        name="quantity"
                                    >
                                        <InputNumber
                                            style={{ width: "100%" }}
                                            min={1}
                                            defaultValue={1}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Weight"
                                        required
                                        name="weight"
                                    >
                                        <Input.Group compact>
                                            <InputNumber
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                }}
                                                placeholder="Enter weight"
                                            />
                                            <Select
                                                defaultValue="kg"
                                                style={{ width: 80 }}
                                                options={[
                                                    {
                                                        value: "kg",
                                                        label: "KG",
                                                    },
                                                    {
                                                        value: "lb",
                                                        label: "LB",
                                                    },
                                                ]}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        label="Length"
                                        required
                                        name="length"
                                    >
                                        <Input.Group compact>
                                            <InputNumber
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                }}
                                                placeholder="Length"
                                            />
                                            <Select
                                                defaultValue="cm"
                                                style={{ width: 80 }}
                                                options={[
                                                    {
                                                        value: "cm",
                                                        label: "CM",
                                                    },
                                                    {
                                                        value: "in",
                                                        label: "IN",
                                                    },
                                                ]}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        label="Width"
                                        required
                                        name="width"
                                    >
                                        <Input.Group compact>
                                            <InputNumber
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                }}
                                                placeholder="Width"
                                            />
                                            <Select
                                                defaultValue="cm"
                                                style={{ width: 80 }}
                                                options={[
                                                    {
                                                        value: "cm",
                                                        label: "CM",
                                                    },
                                                    {
                                                        value: "in",
                                                        label: "IN",
                                                    },
                                                ]}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        label="Height"
                                        required
                                        name="height"
                                    >
                                        <Input.Group compact>
                                            <InputNumber
                                                style={{
                                                    width: "calc(100% - 80px)",
                                                }}
                                                placeholder="Height"
                                            />
                                            <Select
                                                defaultValue="cm"
                                                style={{ width: 80 }}
                                                options={[
                                                    {
                                                        value: "cm",
                                                        label: "CM",
                                                    },
                                                    {
                                                        value: "in",
                                                        label: "IN",
                                                    },
                                                ]}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider style={{ margin: "8px 0" }} />

                            {/* Additional Information */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="HS Code"
                                        name="hs_code"
                                        tooltip="Harmonized System Code for your product"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Search and select HS Code"
                                            loading={hsCodeLoading}
                                            options={hsCodeOptions}
                                            style={{ width: "100%" }}
                                            filterOption={false}
                                            onSearch={debouncedHsCodeSearch}
                                            optionFilterProp="label"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Contains" name="contains">
                                        <Select
                                            mode="multiple"
                                            placeholder="Select contents"
                                            style={{ width: "100%" }}
                                            options={ContainsEnumOptions}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Submit and Clear Buttons */}
                            <Form.Item style={{ marginTop: 24 }}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Button
                                            type="text"
                                            block
                                            size="large"
                                            onClick={() => {
                                                form.resetFields();
                                                setSelectedRegion(undefined);
                                            }}
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Clear Form
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            block
                                            size="large"
                                        >
                                            Calculate Shipping Cost
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col xl={14} lg={24} md={24} sm={24} xs={24}>
                    <Card title="Category Price Read">
                        <CategoryPriceReadTable
                            data={agentCategoryPriceRead}
                            isLoading={agentCategoryPriceReadCollectionLoading}
                            getCategoryPrices={getCategoryPrices}
                            categoryPriceReadParams={categoryPriceReadParams}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CostCalculationForm;
