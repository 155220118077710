import { useRef, MutableRefObject, FC, useState } from "react";
import {
    Col,
    Row,
    Tag,
    Typography,
    Button,
    Card,
    Modal,
    Form,
    notification,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { UserWalletRechargeModal } from "./UserWallet/components/UserWalletRecharge";
import {
    ICustomerWalletRechargeFormData,
    IWalletItem,
} from "@/types/walletOverviewCollection";
import { useWallet } from "@/lib/core-react/hooks/private/useWallet";
import { showError } from "@/helpers/showError";
import { useParams } from "react-router-dom";
import {
    getIsCurrentUserSuperAdmin,
    getUserPermissionFromLocalStorage,
} from "@/utils/helper";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";

const { Title } = Typography;

interface IWalletCardProps {
    type?: "user";
    walletId: number | undefined;
    handleWalletBalanceClick: (id: number, code: string) => void;
    item: IWalletItem[];
}
const WalletCard: FC<IWalletCardProps> = ({
    type,
    item,
    handleWalletBalanceClick,
    walletId,
}) => {
    const [form] = Form.useForm(); // customer wallet recharge form
    const { id } = useParams();
    const userPermissions = getUserPermissionFromLocalStorage();

    const { rechargeCustomerWallet, isLoading } = useWallet();
    const sliderRef = useRef<HTMLDivElement | null>(
        null,
    ) as MutableRefObject<HTMLDivElement | null>;

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                top: 0,
                left: -250,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                top: 0,
                left: 250,
                behavior: "smooth",
            });
        }
    };

    const [selectedWallet, setSelectedWallet] = useState<IWalletItem>();

    const onFinishCustomerRecharge = async (
        values: ICustomerWalletRechargeFormData,
    ) => {
        if (
            selectedWallet &&
            typeof id === "string" &&
            typeof walletId === "number"
        ) {
            try {
                const { amount, gateway_id, ...restValues } = values;
                if (typeof gateway_id === "undefined") {
                    return notification.error({
                        message: "Please select a gateway",
                    });
                }

                const payload = {
                    amount,
                    gateway_id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await rechargeCustomerWallet(Number(id), walletId, payload);
                setSelectedWallet(undefined);
                notification.success({
                    message: "Recharge request initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    style={{ position: "relative" }}
                >
                    <div
                        style={{
                            position: "absolute",
                            right: "25px",
                            top: "0%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Button
                            shape="circle"
                            icon={<LeftOutlined />}
                            onClick={scrollLeft}
                        />
                        <Button
                            shape="circle"
                            icon={<RightOutlined />}
                            onClick={scrollRight}
                        />
                    </div>
                    <div
                        ref={sliderRef}
                        style={{
                            display: "flex",
                            overflowX: "auto",
                            scrollBehavior: "smooth",
                            gap: "14px",
                            padding: "10px 4px",
                        }}
                    >
                        {item.map((wallet, index) => (
                            <Card
                                key={index}
                                onClick={() => {
                                    handleWalletBalanceClick(
                                        wallet.walletId,
                                        wallet.code,
                                    );
                                }}
                                title={`${wallet.code} balance`}
                                extra={
                                    type === "user" &&
                                    (getIsCurrentUserSuperAdmin() ||
                                        userPermissions.includes(
                                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_CUSTOMER_WALLET_RECHARGE,
                                        )) ? (
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                setSelectedWallet(wallet);
                                            }}
                                        >
                                            Recharge
                                        </Button>
                                    ) : undefined
                                }
                                style={{
                                    marginTop: "10px",
                                    minWidth: "300px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    background:
                                        wallet.walletId === walletId
                                            ? "#DFFFED"
                                            : "#ffffff",
                                }}
                            >
                                <div>
                                    <Title
                                        style={{
                                            margin: 0,
                                            color: "#00893C",
                                            fontSize: 30,
                                        }}
                                    >
                                        {wallet.currency.getSymbolNative()}
                                        {wallet.totalAvailableBalance.toFixed(
                                            2,
                                        )}
                                    </Title>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <Tag bordered={false} color="red">
                                            Pending:{" "}
                                            {wallet.currency.getSymbolNative()}
                                            {wallet.totalPendingBalance}
                                        </Tag>
                                        <Tag
                                            bordered={false}
                                            color="volcano"
                                            style={{
                                                marginLeft: "10px",
                                            }}
                                        >
                                            Reserved:{" "}
                                            {wallet.currency.getSymbolNative()}
                                            {wallet.totalReservedBalance}
                                        </Tag>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                </Col>
            </Row>

            <Modal
                width={"50%"}
                title="Recharge Wallet"
                open={Boolean(selectedWallet)}
                onCancel={() => {
                    setSelectedWallet(undefined);
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoading }}
                centered
                destroyOnClose={true}
            >
                {selectedWallet && (
                    <UserWalletRechargeModal
                        form={form}
                        currency={selectedWallet.currency}
                        onFinish={onFinishCustomerRecharge}
                    />
                )}
            </Modal>
        </>
    );
};

export default WalletCard;
