import { CustomerUserEnum } from "@/enums/customerUserCollectionEnums";
import {
    ICustomerUser,
    ICustomerUserCollection,
    ICustomerUserPostRequest,
    ICustomerUserUpdateRequest,
} from "@/types/customerUserCollection";
import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";

export class CustomerUserModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly email: string;
    public readonly phone?: string | null;
    public readonly type?: string;
    public readonly shipping_mark?: string;
    public readonly created_at?: string;
    public readonly is_active?: boolean;
    public readonly is_drop_user?: boolean;
    public readonly status?: string;
    public readonly is_global_user: boolean;
    public order_handler?: null | {
        id: number;
        name: string;
        email: string;
        phone: string | null;
        created_at: string;
        is_active: number;
    };

    constructor(dataItem: ICustomerUser) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.email = dataItem.email;
        this.phone = dataItem.phone;
        this.type = dataItem.type;
        this.shipping_mark = dataItem.shipping_mark;
        this.created_at = dataItem.created_at;
        this.is_active = dataItem.is_active;
        this.order_handler = dataItem.order_handler;
        this.status = dataItem.status;
        this.is_drop_user = dataItem.is_drop_user;
        this.is_global_user = dataItem.is_global_user;
    }
    getId = () => this.id;
    getName = () => this.name;
    getEmail = () => this.email;
    getPhone = () => this.phone;
    getType = () => this.type;
    getCreatedAt = () => this.created_at;
    getShippingMark = () => this.shipping_mark;
    getIsActive = () => this.is_active;
    getOrderHandler = () => this.order_handler;
    getStatus = () => this.status;
    getIsGlobalUser = (): "Yes" | "No" => (this.is_global_user ? "Yes" : "No");
}

export class CustomerUserCollectionModel {
    public readonly object: CustomerUserEnum.CustomerUserCollection;
    public readonly data: CustomerUserModel[];
    public readonly pagination: PaginationModel;
    public readonly filters: IFilterType;

    constructor(data: ICustomerUserCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CustomerUserModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }
    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}

//Post Request
export class CustomerUserPostRequestModel {
    public readonly name: string;
    public readonly email: string;
    public readonly phone: string;
    public readonly password: string;
    public readonly password_confirmation: string;
    public readonly is_active: boolean;

    constructor(reqData: ICustomerUserPostRequest) {
        this.name = reqData.name;
        this.email = reqData.email;
        this.phone = reqData.phone;
        this.password = reqData.password;
        this.password_confirmation = reqData.password;
        this.is_active = reqData.is_active;
    }
}

//update Request
export class CustomerUserUpdateRequestModel {
    public readonly name: string;
    public readonly is_active: boolean;

    constructor(reqData: ICustomerUserUpdateRequest) {
        this.name = reqData.name;
        this.is_active = reqData.is_active;
    }
}
