import { useAtom } from "jotai";
import { useEffect } from "react";
import {
    useGetBaseShippingCategory,
    useUpdateBaseShippingCategory,
} from "@/lib/core-react/hooks/private";
import { baseShippingCategoryAtom } from "@/lib/core-react/store/store";
import {
    BaseShippingCategoryDataModel,
    BaseShippingCategoryModel,
    BaseShippingCategoryRequestModel,
} from "@/models/baseShippingCategory";
import React from "react";
import { Button, Form, Input, notification, Select, TreeSelect } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { BaseShippingCategoryDataStatus } from "@/enums/baseShippingCategoryCollectionEnums";
import { FormInstance } from "antd/es/form/Form";
import { showError } from "@/helpers/showError";
interface IProps {
    data: BaseShippingCategoryDataModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateBaseShippingCategory = ({ data, setIsShowUpdateModal }: IProps) => {
    const { updateBaseShippingCategory, isLoading } =
        useUpdateBaseShippingCategory();

    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [data, form]);

    // Base Shipping category
    const { getBaseShippingCategory } = useGetBaseShippingCategory();

    useEffect(() => {
        getBaseShippingCategory("per_page=500");
    }, []);

    const [
        {
            data: baseShippingCategoryDataAtom,
            isLoading: baseShippingCategoryLoading,
        },
    ] = useAtom(baseShippingCategoryAtom);

    const baseShippingCategoryTreeIds =
        baseShippingCategoryDataAtom &&
        new BaseShippingCategoryModel(
            baseShippingCategoryDataAtom,
        ).getBaseShippingCategoryTreeIds();

    const onFinish = async (value: BaseShippingCategoryRequestModel) => {
        const payload = {
            name: value.name,
            status: value.status,
            slug: value.slug
                .trim()
                .replace(/\s+/g, " ")
                .split(" ")
                .join("-")
                .toLowerCase(),
            parent_id: value.parent_id,
        };

        try {
            const res = await updateBaseShippingCategory(
                data.id as string,
                payload as BaseShippingCategoryRequestModel,
            );

            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowUpdateModal(false);
            }
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Edit_Base_Shipping_Category"
                    ref={formRef}
                    initialValues={{
                        slug: data.slug,
                        name: data.name,
                        parent_id: data.parent_id,
                        status: data.status,
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message:
                                    "Base shipping category name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Base shipping category name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Slug is required" },
                        ]}
                        label="Slug"
                        name="slug"
                    >
                        <Input placeholder="Slug" />
                    </Form.Item>

                    <Form.Item label="Select Parent" name="parent_id">
                        <TreeSelect
                            showSearch
                            dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
                            placeholder="Please select a parent"
                            allowClear
                            loading={baseShippingCategoryLoading}
                            treeLine={{ showLeafIcon: true }}
                            treeData={baseShippingCategoryTreeIds}
                            filterTreeNode={(searchValue, treeNode) => {
                                const title = treeNode.title;
                                return (
                                    typeof title === "string" &&
                                    title
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                );
                            }}
                        />
                    </Form.Item>

                    <Form.Item required name="status" label="Status">
                        <Select placeholder="Please select a status">
                            {renderOptionsFromEnum(
                                BaseShippingCategoryDataStatus,
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateBaseShippingCategory;
