import React, { useRef, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Modal, Input, Space, Tooltip } from "antd";
import {
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    StrikethroughOutlined,
    LinkOutlined,
    DisconnectOutlined,
    PictureOutlined,
    OrderedListOutlined,
    UnorderedListOutlined,
    MinusOutlined,
    EnterOutlined,
    DownOutlined,
    HighlightOutlined,
} from "@ant-design/icons";
import { useEditor, EditorContent } from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import Mention from "@tiptap/extension-mention";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import Blockquote from "@tiptap/extension-blockquote";
import Image from "@tiptap/extension-image";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import HardBreak from "@tiptap/extension-hard-break";
import { IframePortal } from "./IframePortal";
interface Props {
    content: string;
    editable?: boolean;
    onUpdate?: (content: string) => void;
}

const MenuBar = ({ editor }: { editor: any }) => {
    const [linkModalVisible, setLinkModalVisible] = useState(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [color, setColor] = useState("#000000");

    if (!editor) return null;

    const addLink = () => {
        if (linkUrl) {
            editor.chain().focus().setLink({ href: linkUrl }).run();
        }
        setLinkModalVisible(false);
        setLinkUrl("");
    };

    const addImage = () => {
        if (imageUrl) {
            editor.chain().focus().setImage({ src: imageUrl }).run();
        }
        setImageModalVisible(false);
        setImageUrl("");
    };

    const headingMenu = (
        <Menu
            onClick={(e) => {
                const level = parseInt(e.key);
                if (level === 0) {
                    editor.chain().focus().setParagraph().run();
                } else {
                    editor.chain().focus().setHeading({ level }).run();
                }
            }}
        >
            <Menu.Item key="0">Paragraph</Menu.Item>
            <Menu.Item key="1">Heading 1</Menu.Item>
            <Menu.Item key="2">Heading 2</Menu.Item>
            <Menu.Item key="3">Heading 3</Menu.Item>
        </Menu>
    );

    const colorMenu = (
        <Menu
            onClick={(e) => {
                const selectedColor = e.key;
                editor.chain().focus().setColor(selectedColor).run();
                setColor(selectedColor);
            }}
        >
            <Menu.Item key="#000000">Black</Menu.Item>
            <Menu.Item key="#ff0000">Red</Menu.Item>
            <Menu.Item key="#00ff00">Green</Menu.Item>
            <Menu.Item key="#0000ff">Blue</Menu.Item>
            <Menu.Item key="#ffa500">Orange</Menu.Item>
        </Menu>
    );

    return (
        <div
            style={{
                marginBottom: 16,
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: 8,
            }}
        >
            <Space wrap>
                <Tooltip title="Bold">
                    <Button
                        icon={<BoldOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleBold().run()
                        }
                        type={editor.isActive("bold") ? "primary" : "default"}
                    />
                </Tooltip>
                <Tooltip title="Italic">
                    <Button
                        icon={<ItalicOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleItalic().run()
                        }
                        type={editor.isActive("italic") ? "primary" : "default"}
                    />
                </Tooltip>
                <Tooltip title="Underline">
                    <Button
                        icon={<UnderlineOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleUnderline().run()
                        }
                        type={
                            editor.isActive("underline") ? "primary" : "default"
                        }
                    />
                </Tooltip>
                <Tooltip title="Strike">
                    <Button
                        icon={<StrikethroughOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleStrike().run()
                        }
                        type={editor.isActive("strike") ? "primary" : "default"}
                    />
                </Tooltip>
                <Dropdown overlay={headingMenu}>
                    <Button>
                        {editor.isActive("heading")
                            ? `H${editor.getAttributes("heading").level}`
                            : "P"}{" "}
                        <DownOutlined />
                    </Button>
                </Dropdown>

                <Tooltip title="Ordered List">
                    <Button
                        icon={<OrderedListOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleOrderedList().run()
                        }
                        type={
                            editor.isActive("orderedList")
                                ? "primary"
                                : "default"
                        }
                    />
                </Tooltip>
                <Tooltip title="Bullet List">
                    <Button
                        icon={<UnorderedListOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleBulletList().run()
                        }
                        type={
                            editor.isActive("bulletList")
                                ? "primary"
                                : "default"
                        }
                    />
                </Tooltip>
                <Tooltip title="Horizontal Rule">
                    <Button
                        icon={<MinusOutlined />}
                        onClick={() =>
                            editor.chain().focus().setHorizontalRule().run()
                        }
                    />
                </Tooltip>
                <Tooltip title="Hard Break">
                    <Button
                        icon={<EnterOutlined />}
                        onClick={() =>
                            editor.chain().focus().setHardBreak().run()
                        }
                    />
                </Tooltip>
                <Dropdown overlay={colorMenu}>
                    <Button>
                        <span style={{ color }}>A</span> <DownOutlined />
                    </Button>
                </Dropdown>
                <Tooltip title="Highlight">
                    <Button
                        icon={<HighlightOutlined />}
                        onClick={() =>
                            editor.chain().focus().toggleHighlight().run()
                        }
                        type={
                            editor.isActive("highlight") ? "primary" : "default"
                        }
                    />
                </Tooltip>
                <Tooltip title="Insert Link">
                    <Button
                        icon={<LinkOutlined />}
                        onClick={() => setLinkModalVisible(true)}
                    />
                </Tooltip>
                <Tooltip title="Remove Link">
                    <Button
                        icon={<DisconnectOutlined />}
                        onClick={() => editor.chain().focus().unsetLink().run()}
                    />
                </Tooltip>
                <Tooltip title="Insert Image">
                    <Button
                        icon={<PictureOutlined />}
                        onClick={() => setImageModalVisible(true)}
                    />
                </Tooltip>
            </Space>

            {/* Modal for adding a link */}
            <Modal
                title="Insert Link"
                open={linkModalVisible}
                onOk={addLink}
                onCancel={() => setLinkModalVisible(false)}
                okText="Insert"
            >
                <Input
                    placeholder="Enter URL"
                    value={linkUrl}
                    onChange={(e) => setLinkUrl(e.target.value)}
                />
            </Modal>

            {/* Modal for adding an image */}
            <Modal
                title="Insert Image"
                open={imageModalVisible}
                onOk={addImage}
                onCancel={() => setImageModalVisible(false)}
                okText="Insert"
            >
                <Input
                    placeholder="Enter Image URL"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default function MvnRichTextEditor({
    content,
    onUpdate,
    editable = false,
}: Props) {
    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Italic,
            Strike,
            Underline,
            Link,
            TextStyle,
            Color,
            Highlight,
            Mention,
            Heading,
            Blockquote,
            Image,
            OrderedList,
            ListItem,
            BulletList,
            HorizontalRule,
            HardBreak,
        ],
        content,
        editable,
        onUpdate({ editor }) {
            if (onUpdate) {
                onUpdate(editor.getHTML());
            }
        },
    });

    const [height, setHeight] = useState(0);

    const handleHeight = (value) => {
        setHeight(value + 30);
    };
    if (!editor) return null;

    return (
        <div
            style={{
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                padding: editable ? "16px 16px 0px 16px" : 0,
            }}
        >
            {editable && <MenuBar editor={editor} />}
            {/* Render the editor content inside an iframe to isolate styles and should not apply and global or others style */}
            <IframePortal
                style={{ width: "100%", border: "none", height }}
                title="Editor Content"
            >
                <EditorContentCustom
                    handleHeight={handleHeight}
                    editor={editor}
                />
            </IframePortal>
        </div>
    );
}

function EditorContentCustom({ editor, handleHeight }) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!containerRef.current) return;

        const updateHeight = () => {
            if (containerRef.current) {
                const currentHeight =
                    containerRef.current.getBoundingClientRect().height;
                setHeight(currentHeight);
            }
        };

        // Initialize with current height
        updateHeight();

        // Create a ResizeObserver to listen for changes in the container size
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === containerRef.current) {
                    const currentHeight = entry.contentRect.height;
                    setHeight(currentHeight);
                }
            }
        });

        observer.observe(containerRef.current);

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [editor]);

    useEffect(() => {
        handleHeight(height);
    }, [height]);
    return (
        <div ref={containerRef}>
            <EditorContent editor={editor} />
        </div>
    );
}
