import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Dropdown,
    Modal,
    notification,
    Space,
    Table,
    Typography,
} from "antd";
import { purchaseRuleGroupUserCollectionAtom } from "@/lib/core-react/store/store";
import { GetApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import {
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import { PageHeader } from "@ant-design/pro-layout";
import { showError } from "@/helpers/showError";
import { FormInstance } from "antd/lib";
import {
    PurchaseRuleGroupModel,
    PurchaseRuleGroupUserCollectionModel,
} from "@/models/purchaseRuleGroupCollectionModel";
import { usePurchaseRuleGroup } from "@/lib/core-react/hooks/private/usePurchaseRuleGroup";
import AddUserToExchangeGroup from "../../ExchangeGroup/components/AddUserToExchangeGroup";
import { PurchaseRuleGroupModalEnum } from "@/enums/purchaseRuleGroupCollectionEnums";
import { showSuccessAlert } from "@/helpers/showSuccess";

interface IProps {
    data: PurchaseRuleGroupModel;
}
const PurchaseRuleGroupUsers = ({ data }: IProps) => {
    const [filterData, setFilterData] = useState<Record<string, any>>({
        id: data.getId(),
    });
    const {
        getPurchaseRuleGroupUserCollection,
        createPurchaseRuleGroupUser,
        deletePurchaseRuleGroupUser,
        isLoading: isLoadingCreatePurchaseRuleGroupUser,
    } = usePurchaseRuleGroup();
    const [{ data: purchaseRuleGroupUserCollectionData, isLoading }] = useAtom(
        purchaseRuleGroupUserCollectionAtom,
    );

    const [modalOpen, setModalOpen] = useState<PurchaseRuleGroupModalEnum>();
    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if (Object.keys(filterData).length) {
            GetApiHelperModel.makeGetRequest(
                filterData,
                getPurchaseRuleGroupUserCollection,
            );
        }
    }, [filterData]);

    const CustomerCollectionData =
        purchaseRuleGroupUserCollectionData &&
        new PurchaseRuleGroupUserCollectionModel(
            purchaseRuleGroupUserCollectionData,
        );

    // Pagination
    const paginationData = CustomerCollectionData?.getPagination();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        setFilterData((pre) => {
            return {
                ...pre,
                ...pageInfo,
            };
        });
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        CustomerCollectionData,
        handlePaginationChange,
    );

    const columns = [
        {
            title: "#SL",
            width: 60,
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            render: (_: string, __: CustomerUserModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "User info",
            dataIndex: "user",
            key: "user",
            render: (_: string, record: CustomerUserModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>Name: </Typography.Text>
                            <Typography.Text>
                                {record.getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Phone: </Typography.Text>
                            <Typography.Text>
                                {record.getPhone()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Email: </Typography.Text>
                            <Typography.Text>
                                {record.getEmail()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:{" "}
                            </Typography.Text>{" "}
                            <Typography.Text>
                                {record.getShippingMark()}
                            </Typography.Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? ("right" as const) : undefined,
            hidden: !checkActionPermission(
                ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                "checkPermission",
                null,
            ),
            width: isMobile ? 90 : 150,
            render: (_: string, record: CustomerUserModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        onClick: async () => {
                            deleteUserHandler(record.getId());
                        },
                        danger: true,
                    },
                ];

                const menuProps = {
                    items: items.filter((x) =>
                        checkActionPermission(x.permission, x, null),
                    ),
                };

                return (
                    <Dropdown menu={menuProps}>
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const addUserHandler = async (
        value: { user: number },
        form: FormInstance<any>,
    ) => {
        const user_id = value.user;
        const id = Number(data.getId());
        try {
            const res = await createPurchaseRuleGroupUser(id, {
                user_ids: [user_id],
            });
            if (res) {
                setModalOpen(undefined);
                if (Object.keys(filterData).length) {
                    GetApiHelperModel.makeGetRequest(
                        filterData,
                        getPurchaseRuleGroupUserCollection,
                    );
                }
                notification.success({
                    message: res.message,
                });
            }
        } catch (error: any) {
            showError(error, form);
        }
    };

    const deleteUserHandler = (user_id: number) => {
        Modal.confirm({
            title: "Do you want to delete this customer from the group ?",
            icon: <ExclamationCircleOutlined />,
            content: "Once deleted, the item cannot be recovered.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            async onOk() {
                try {
                    await deletePurchaseRuleGroupUser(data.getId(), {
                        user_ids: [user_id],
                    });

                    showSuccessAlert(
                        "Customer deleted successfully from the group!",
                    );
                    Promise.resolve();
                    await getPurchaseRuleGroupUserCollection({
                        id: data.getId(),
                    });
                } catch (error) {
                    showError(error);
                    Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Request aborted.",
                });
            },
        });
    };

    return (
        <>
            <PageHeader
                ghost={false}
                title="Customers"
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                        <Button
                            key="1"
                            type="primary"
                            onClick={() =>
                                setModalOpen(
                                    PurchaseRuleGroupModalEnum[
                                        "Add Purchase Rule Group Customers"
                                    ],
                                )
                            }
                            icon={<PlusOutlined />}
                        >
                            Add customer
                        </Button>,
                        null,
                    ),
                ]}
            >
                <Table
                    columns={columns}
                    size="small"
                    bordered
                    loading={isLoading}
                    dataSource={CustomerCollectionData?.getModifiedData()}
                    pagination={paginationConfig}
                    rowKey="id"
                    scroll={{ x: 350 }}
                    style={{
                        maxHeight: "60vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                />
            </PageHeader>

            {/* Add user to purchase rule group */}
            {modalOpen ===
                PurchaseRuleGroupModalEnum[
                    "Add Purchase Rule Group Customers"
                ] && (
                <Modal
                    title={`Add user to ${data.getName()}`}
                    open={
                        modalOpen ===
                        PurchaseRuleGroupModalEnum[
                            "Add Purchase Rule Group Customers"
                        ]
                    }
                    onCancel={() => setModalOpen(undefined)}
                    footer={null}
                >
                    <AddUserToExchangeGroup
                        addCustomerHandler={addUserHandler}
                        loading={isLoadingCreatePurchaseRuleGroupUser}
                    />
                </Modal>
            )}
        </>
    );
};

export default PurchaseRuleGroupUsers;
