import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Table,
    Dropdown,
    Modal,
    Tag,
    Flex,
    notification,
    Row,
    Col,
} from "antd";

import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { purchaseRuleGroupCollectionAtom } from "@/lib/core-react/store/store";
import { IFilterType } from "@/types/filters";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import CommonError from "@/components/Error/CommonError";
import { PaginationModel } from "@/models/pagination";
import { PurchaseRuleStatusEnum } from "@/enums/purchaseRuleCollectionEnums";
import { formatDateTime } from "@/utils/helpers";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { usePurchaseRuleGroup } from "@/lib/core-react/hooks/private/usePurchaseRuleGroup";
import {
    PurchaseRuleGroupCollectionModel,
    PurchaseRuleGroupModel,
} from "@/models/purchaseRuleGroupCollectionModel";
import { PurchaseRuleGroupModalEnum } from "@/enums/purchaseRuleGroupCollectionEnums";
import PurchaseRuleGroupModification from "./PurchaseRuleGroupModification";
import FiltersComponent from "@/components/FiltersComponent";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import PurchaseRuleGroupCustomers from "./components/PurchaseRuleGroupUsers";

const PurchaseRuleGroup = () => {
    const { getPurchaseRuleGroupCollection, deletePurchaseRuleGroup } =
        usePurchaseRuleGroup();
    const [
        {
            data: purchaseRuleGroupCollectionAtomData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(purchaseRuleGroupCollectionAtom);

    const PurchaseRuleGroupCollectionData =
        purchaseRuleGroupCollectionAtomData &&
        new PurchaseRuleGroupCollectionModel(
            purchaseRuleGroupCollectionAtomData,
        );

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();
    const { isMobile } = useWindowWidth();

    const [modalOpen, setModalOpen] = useState<PurchaseRuleGroupModalEnum>();
    const [selectedPurchaseRuleGroup, setSelectedPurchaseRuleGroup] =
        useState<PurchaseRuleGroupModel>();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(
                filters,
                getPurchaseRuleGroupCollection,
            );
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Filter
    const filterData = PurchaseRuleGroupCollectionData?.getFilters();

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getPurchaseRuleGroupCollection);
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && PurchaseRuleGroupCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        PurchaseRuleGroupCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPurchaseRuleGroupCollection,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PurchaseRuleGroupCollectionData,
        handlePaginationChange,
    );

    const purchaseRuleGroupColumns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 70,
            key: "purchase-rule-group-id",
            render: (_: string, record: PurchaseRuleGroupModel) => {
                return <div>{record.getId()}</div>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "purchase-rule-group-name",
            render: (_: string, record: PurchaseRuleGroupModel) => {
                return (
                    <Flex vertical>
                        {record.getName()}{" "}
                        <div>
                            <Tag
                                style={{ textTransform: "capitalize" }}
                                color={
                                    record.getStatus() ===
                                    PurchaseRuleStatusEnum.Active
                                        ? "#87d068"
                                        : "#f50"
                                }
                            >
                                {record.getStatus()}
                            </Tag>
                        </div>
                    </Flex>
                );
            },
        },
        {
            title: "Rule",
            dataIndex: "rule",
            hidden: isMobile,
            key: "purchase-rule-group-rule",
            render: (_: string, record: PurchaseRuleGroupModel) => {
                return (
                    <div>
                        <Flex vertical>
                            {record.getPurchaseRule().getName()}{" "}
                            <div>
                                {record.getPurchaseRule().getIsDefault() ? (
                                    <Tag color="processing">Default</Tag>
                                ) : (
                                    ""
                                )}
                                <Tag
                                    style={{ textTransform: "capitalize" }}
                                    color={
                                        record.getPurchaseRule().getStatus() ===
                                        PurchaseRuleStatusEnum.Active
                                            ? "#87d068"
                                            : "#f50"
                                    }
                                >
                                    {record.getPurchaseRule().getStatus()}
                                </Tag>
                            </div>
                            <div>
                                <strong>Region:</strong>{" "}
                                {record
                                    .getPurchaseRule()
                                    .getRegion()
                                    ?.getName()}
                            </div>
                        </Flex>
                        {Object.entries(record.getPurchaseRule().getRule()).map(
                            ([key, value]) => (
                                <div
                                    style={{ textTransform: "capitalize" }}
                                    key={key}
                                >
                                    <strong>{key}:</strong>{" "}
                                    {Array.isArray(value)
                                        ? value.join(", ")
                                        : typeof value === "string"
                                          ? value.replace(/_/g, " ")
                                          : value}
                                </div>
                            ),
                        )}
                    </div>
                );
            },
        },
        {
            title: "Customers",
            dataIndex: "customers",
            key: "customers",
            hidden: isMobile
                ? true
                : !checkActionPermission(
                      ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW,
                      "checkPermission",
                      null,
                  ),
            align: "center" as const,
            render: (_: string, record: PurchaseRuleGroupModel) => {
                return checkActionPermission(
                    ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_VIEW,
                    <Button
                        onClick={() => {
                            setSelectedPurchaseRuleGroup(record);
                            setModalOpen(
                                PurchaseRuleGroupModalEnum[
                                    "Purchase Rule Group Customers"
                                ],
                            );
                        }}
                        icon={<TeamOutlined />}
                    >
                        Customers
                    </Button>,
                    null,
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            align: "center" as const,
            render: (_: string, record: PurchaseRuleGroupModel) => {
                return (
                    <div>
                        <Tag color="purple">
                            {formatDateTime(record.getCreatedAt())}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            hidden: !checkActionPermission(
                [ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE],
                "checkPermission",
                null,
            ),
            fixed: isMobile ? ("right" as const) : undefined,
            align: "center" as const,
            width: 100,
            render: (_: string, record: PurchaseRuleGroupModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedPurchaseRuleGroup(record);
                            setModalOpen(
                                PurchaseRuleGroupModalEnum[
                                    "Update Purchase Rule Group"
                                ],
                            );
                        },
                    },
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        onClick: async () => {
                            deletePurchaseRuleGroupHandler(record.getId());
                        },
                        danger: true,
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    // Delete
    const deletePurchaseRuleGroupHandler = (id: number) => {
        Modal.confirm({
            title: "Do you want to delete this item ?",
            icon: <ExclamationCircleOutlined />,
            content: "Once deleted, the item cannot be recovered.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            async onOk() {
                try {
                    await deletePurchaseRuleGroup(id);

                    showSuccessAlert(
                        "Purchase rule group deleted successfully!",
                    );
                    Promise.resolve();
                    await getPurchaseRuleGroupCollection();
                } catch (error) {
                    showError(error);
                    Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Request aborted.",
                });
            },
        });
    };

    const onCancelModal = () => {
        setModalOpen(undefined);
    };

    // Error handle
    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Purchase Rule"
                style={{ marginTop: "10px" }}
                onBack={() => window.history.back()}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.PURCHASE_RULE_GROUP_MANAGE,
                        <Button
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            key="create-rule-btn"
                            icon={<PlusOutlined />}
                            onClick={() =>
                                setModalOpen(
                                    PurchaseRuleGroupModalEnum[
                                        "Create Purchase Rule Group"
                                    ],
                                )
                            }
                        >
                            Add Rule Group
                        </Button>,
                        null,
                    ),
                ]}
            >
                <div>
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <Table
                        bordered
                        style={{ marginTop: "15px" }}
                        loading={isLoading}
                        rowKey="id"
                        pagination={{
                            ...paginationConfig,
                            showTotal: (total) => (
                                <div>
                                    Total{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            color: "green",
                                        }}
                                    >
                                        {total}
                                    </span>{" "}
                                    Items
                                </div>
                            ),
                        }}
                        dataSource={PurchaseRuleGroupCollectionData?.getData()}
                        columns={purchaseRuleGroupColumns}
                        scroll={{ x: 1000 }}
                    />
                </div>
            </PageHeader>

            <Modal
                title={modalOpen}
                open={Boolean(modalOpen)}
                destroyOnClose={true}
                footer={null}
                onCancel={onCancelModal}
            >
                {modalOpen ===
                    PurchaseRuleGroupModalEnum[
                        "Update Purchase Rule Group"
                    ] && (
                    <PurchaseRuleGroupModification
                        data={selectedPurchaseRuleGroup}
                        onHide={onCancelModal}
                    />
                )}

                {modalOpen ===
                    PurchaseRuleGroupModalEnum[
                        "Create Purchase Rule Group"
                    ] && (
                    <PurchaseRuleGroupModification onHide={onCancelModal} />
                )}

                {modalOpen ===
                    PurchaseRuleGroupModalEnum[
                        "Purchase Rule Group Customers"
                    ] &&
                    selectedPurchaseRuleGroup && (
                        <PurchaseRuleGroupCustomers
                            data={selectedPurchaseRuleGroup}
                        />
                    )}
            </Modal>
        </>
    );
};

export default PurchaseRuleGroup;
