export const hasDuplicates = (arr: any[], key: string) => {
    const seen = new Set();
    return arr.some((obj) => {
        if (seen.has(obj[key])) {
            return true;
        }
        seen.add(obj[key]);
        return false;
    });
};
