import { ShipmentProductStatusEnum } from "@/enums/shipmentProductCollectionEnums";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import { IUnlistedPackageIdsByTracking } from "@/types/unlistedPackageCollection";

export const getSyncableTrackings = (data: UnlistedPackageModel[]) => {
    return Array.from(
        new Set(
            data
                .filter(
                    (d) =>
                        d.getStatus() ===
                            ShipmentProductStatusEnum.RECEIVED_BY_MOVEON &&
                        d.getShipmentProduct() === null,
                )
                .map((d) => d.getTrackings().getAbroadTracking()),
        ),
    );
};

export const checkTrackingMatch = (
    unlistedPackage: UnlistedPackageModel,
    shipmentProductIdsByTracking: IUnlistedPackageIdsByTracking,
) => {
    const productTrackingCode = unlistedPackage
        .getTrackings()
        .getAbroadTracking();

    const hasMatch =
        unlistedPackage.getShipmentProduct() === null
            ? shipmentProductIdsByTracking[productTrackingCode]
            : false;

    return hasMatch;
};
