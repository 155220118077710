import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import {
    currentCurrencyAtom,
    regionRegion,
} from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import {
    IRegionCollection,
    IRegionPostRequest,
} from "@/types/regionCollection";
import { CurrentCurrencyModel } from "@/models/currentCurrencyModel";

export const useCreateRegion = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [regionData, setRegionData] = useAtom(regionRegion);

    const createRegion = async (payload: IRegionPostRequest) => {
        setIsLoading(true);
        try {
            const response = await coreService.RegionResource.create(payload);
            setIsLoading(false);
            setRegionData({ ...regionData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createRegion, isLoading, isError };
};

export const useUpdateRegion = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionData, setRegionData] = useAtom(regionRegion);

    const updateRegion = async (id: number, payload: IRegionPostRequest) => {
        setIsLoading(true);
        try {
            const response = await coreService.RegionResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setRegionData({ ...regionData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateRegion, isLoading, isError };
};

export const useGetRegion = () => {
    const [region, setRegion] = useAtom(regionRegion);
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getRegion = async (params?: string): Promise<IRegionCollection> => {
        setIsLoading(true);
        setRegion({ ...region, isLoading: true, error: null });
        try {
            const response = await coreService.RegionResource.get(params);
            setRegion({
                ...region,
                isLoading: false,
                data: response,
                refetch: false,
            });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setRegion({
                ...region,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            setIsLoading(false);
            throw error;
        }
    };
    return { getRegion, isLoading } as const;
};

export const useGetCurrencyByRegionCode = () => {
    const [currentCurrency, setCurrentCurrency] = useAtom(currentCurrencyAtom);
    const { coreService } = useService();
    const getCurrencies = async (params: {
        region: string;
        [key: string]: any;
    }): Promise<void> => {
        setCurrentCurrency({
            ...currentCurrency,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await coreService.RegionResource.getCurrency(params);
            const processData =
                response && new CurrentCurrencyModel(response.data);
            setCurrentCurrency({
                ...currentCurrency,
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error: any) {
            setCurrentCurrency({
                ...currentCurrency,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getCurrencies } as const;
};
