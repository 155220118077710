import { PurchaseRuleTypeEnum } from "@/enums/purchaseRuleCollectionEnums";
import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IPurchaseRuleModificationFormData } from "@/types/purchaseRuleCollection";

class PurchaseRuleResource extends BaseResource {
    createPurchaseRule(
        payload: IPurchaseRuleModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-rule/v1/purchase-rules`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updatePurchaseRule(
        id: number,
        payload: IPurchaseRuleModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-rule/v1/purchase-rules/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deletePurchaseRule(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-rule/v1/purchase-rules/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    getPurchaseRuleCollection(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `/api/purchase/admin/purchase-rule/v1/purchase-rules`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getPurchaseRuleSchema(
        type: PurchaseRuleTypeEnum,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-rule/v1/schema?type=${type}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default PurchaseRuleResource;
