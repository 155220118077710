import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useRfqBulkApprove } from "@/lib/core-react/hooks/private/useRfq";
import { RfqRequestModel } from "@/models/rfqRequestCollectionModel";
import { IRfqRequestsBulkApprove } from "@/types/rfqRequestCreate";
import { Form, DatePicker, Select, Button, Flex } from "antd";
import moment from "moment";
import { useEffect } from "react";
interface Props {
    rfqRequestData: RfqRequestModel[];
    onSuccess: () => void;
}
const BulkApproveRFQForm = ({ rfqRequestData, onSuccess }: Props) => {
    const { bulkRequestsApprove, isLoading: isApproveLoading } =
        useRfqBulkApprove();
    const {
        allOptionsData: { agentCompanyOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const [form] = Form.useForm();
    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.AGENT_COMPANY);
    }, []);

    const requestId = rfqRequestData.map((e) => e.getId());

    const handleApprove = async (inputData) => {
        try {
            const time = moment(inputData.valid_until.$d).format(
                "YYYY-MM-DD HH:mm:ss",
            );
            const payload: IRfqRequestsBulkApprove = {
                request_ids: requestId,
                source_agent_company_ids: inputData?.source_agent_company_ids,
                valid_until: time,
            };
            await bulkRequestsApprove({
                payload,
            });

            showSuccessAlert("Successfully RFQ Request Approved");
            form.resetFields();
            onSuccess();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form form={form} onFinish={handleApprove} layout="vertical">
            <>
                <Form.Item
                    style={{ width: "100%" }}
                    name="valid_until"
                    label="Valid Until"
                    rules={[
                        {
                            required: true,
                            message: "Please provide valid until date ",
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: "100%" }}
                        showTime
                        format={"DD/MM/YYYY"}
                    />
                </Form.Item>

                <Form.Item
                    label="Source Agent Company"
                    name="source_agent_company_ids"
                    style={{ color: "red" }}
                    rules={[
                        {
                            required: true,
                            message: "Please select at least one Company",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear={true}
                        showSearch
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase(),
                                )
                        }
                        style={{ width: "100%" }}
                        placeholder="Please select a company"
                        loading={agentCompanyOptionsData.isLoading}
                        options={agentCompanyOptionsData.options}
                        notFoundContent={
                            agentCompanyOptionsData.isLoading
                                ? LoaderSmall
                                : null
                        }
                    />
                </Form.Item>
            </>

            <Flex justify="end">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isApproveLoading}
                >
                    Approved
                </Button>
            </Flex>
        </Form>
    );
};

export default BulkApproveRFQForm;
