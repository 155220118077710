import { useEffect, useState } from "react";
import {
    Avatar,
    Card,
    Col,
    DatePicker,
    Flex,
    Form,
    FormInstance,
    Input,
    Radio,
    Row,
    Tabs,
    Typography,
} from "antd";
import { PageLoading } from "@ant-design/pro-layout";
import { useAtomValue } from "jotai";

import { useGetPaymentGateway } from "@/lib/core-react/hooks/private/usePayment";
import { paymentGatewayCollectionAtom } from "@/lib/core-react/store/store";
import {
    getIsCurrentUserSuperAdmin,
    getUserPermissionFromLocalStorage,
} from "@/utils/helper";

import { PaymentGatewayCollectionModel } from "@/models/paymentGatewayCollectionModel";

import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "@/consts/permission-enum/payment-enum";
import { CurrencyModel } from "@/models/currencyCollectionModel";

interface IProps {
    form: FormInstance<any>;
    currency: CurrencyModel;
    onFinish: (values: any) => Promise<void>;
}
export const UserWalletRechargeModal = ({
    form,
    currency,
    onFinish,
}: IProps) => {
    const { getPaymentGateway } = useGetPaymentGateway();
    const userPermissions = getUserPermissionFromLocalStorage();

    const [activeTabKey, setActiveTabKey] = useState("1");

    const paymentGatewayCollectionData = useAtomValue(
        paymentGatewayCollectionAtom,
    );

    const PaymentGatewayCollectionData =
        paymentGatewayCollectionData.data &&
        new PaymentGatewayCollectionModel(paymentGatewayCollectionData.data);

    useEffect(() => {
        if (
            getIsCurrentUserSuperAdmin() ||
            userPermissions.includes(
                PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
            )
        ) {
            getPaymentGateway(`currency_id=${currency.getId()}`);
        }
    }, [currency]);

    const gateway_id = Form.useWatch("gateway_id", form);
    const selectedPaymentGateway =
        PaymentGatewayCollectionData?.getPaymentGatewayById(gateway_id);

    if (paymentGatewayCollectionData.isLoading) return <PageLoading />;

    return (
        <Row gutter={16}>
            <Col xs={24} lg={18}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    name="Recharge Customer Wallet"
                >
                    <Tabs
                        centered
                        defaultActiveKey={activeTabKey}
                        type="card"
                        onChange={(activeKey) => {
                            setActiveTabKey(activeKey);
                            form.resetFields();
                        }}
                        items={[
                            {
                                label: `Bank Deposit`,
                                key: "1",
                                children: (
                                    <Form.Item
                                        label={"Select gateway"}
                                        name="gateway_id"
                                        rules={[
                                            {
                                                required:
                                                    activeTabKey === "1"
                                                        ? true
                                                        : false,
                                                message:
                                                    "Please select a gateway",
                                            },
                                        ]}
                                    >
                                        {!PaymentGatewayCollectionData ||
                                        PaymentGatewayCollectionData?.getBankDepositData()
                                            .length === 0 ? (
                                            "No bank deposit gateway available."
                                        ) : (
                                            <Radio.Group
                                                size="large"
                                                style={{
                                                    maxHeight: "50vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {PaymentGatewayCollectionData?.getBankDepositData().map(
                                                    (d) => {
                                                        const logoCollection =
                                                            d.getLogo();
                                                        const logoUrl =
                                                            logoCollection
                                                                ?.getData()
                                                                .map(
                                                                    (logo) =>
                                                                        logo.getSmall()
                                                                            ?.src,
                                                                )
                                                                .find(
                                                                    (url) =>
                                                                        url,
                                                                );

                                                        return (
                                                            <Radio.Button
                                                                value={d.getId()}
                                                                key={d.getId()}
                                                            >
                                                                <Avatar
                                                                    shape="circle"
                                                                    size={20}
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                    }}
                                                                    src={
                                                                        logoUrl
                                                                    }
                                                                />{" "}
                                                                {d.getName()}
                                                            </Radio.Button>
                                                        );
                                                    },
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                ),
                            },
                            {
                                label: `Mobile Merchant`,
                                key: "3",
                                children: (
                                    <Form.Item
                                        label={"Select mobile merchant"}
                                        name="gateway_id"
                                        rules={[
                                            {
                                                required:
                                                    activeTabKey === "3"
                                                        ? true
                                                        : false,
                                                message:
                                                    "Please select a mobile merchant",
                                            },
                                        ]}
                                    >
                                        {!PaymentGatewayCollectionData ||
                                        PaymentGatewayCollectionData?.getMobileMerchantPaymentData()
                                            .length === 0 ? (
                                            "No mobile merchant gateway available."
                                        ) : (
                                            <Radio.Group
                                                size="large"
                                                style={{
                                                    maxHeight: "50vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {PaymentGatewayCollectionData?.getMobileMerchantPaymentData().map(
                                                    (d) => {
                                                        const logoCollection =
                                                            d.getLogo();
                                                        const logoUrl =
                                                            logoCollection
                                                                ?.getData()
                                                                .map(
                                                                    (logo) =>
                                                                        logo.getSmall()
                                                                            ?.src,
                                                                )
                                                                .find(
                                                                    (url) =>
                                                                        url,
                                                                );

                                                        return (
                                                            <Radio.Button
                                                                value={d.getId()}
                                                                key={d.getId()}
                                                            >
                                                                <Avatar
                                                                    shape="circle"
                                                                    size={20}
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                    }}
                                                                    src={
                                                                        logoUrl
                                                                    }
                                                                />{" "}
                                                                {d.getName()}
                                                            </Radio.Button>
                                                        );
                                                    },
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                ),
                            },
                        ]}
                    />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Flex gap="middle" vertical>
                                {selectedPaymentGateway &&
                                selectedPaymentGateway.getMerchantData() &&
                                Object.keys(
                                    selectedPaymentGateway.getMerchantData(),
                                ).length > 0 ? (
                                    <Card title="Merchant Info">
                                        {selectedPaymentGateway?.getMerchantData() &&
                                            Object.entries(
                                                selectedPaymentGateway.getMerchantData() ||
                                                    {},
                                            ).map(([key, value]) => {
                                                return (
                                                    <div
                                                        key={key}
                                                        style={{
                                                            display: "flex",
                                                            gap: "10px",
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        <Typography.Text strong>
                                                            {key}:
                                                        </Typography.Text>
                                                        <Typography.Text>
                                                            {value}
                                                        </Typography.Text>
                                                    </div>
                                                );
                                            })}
                                    </Card>
                                ) : (
                                    ""
                                )}
                                <div
                                    style={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                    }}
                                >
                                    {Object.entries(
                                        selectedPaymentGateway?.getFormSchema() ||
                                            {},
                                    ).map(([key, value]) => {
                                        const {
                                            label,
                                            is_required,
                                            placeholder,
                                            type,
                                        } = value;

                                        return (
                                            <div>
                                                <Form.Item
                                                    key={key}
                                                    label={label}
                                                    name={key}
                                                    rules={[
                                                        {
                                                            required:
                                                                is_required,
                                                            message: `${label} is required`,
                                                        },
                                                    ]}
                                                >
                                                    {type === "string-input" ? (
                                                        <Input
                                                            placeholder={
                                                                placeholder
                                                            }
                                                        />
                                                    ) : type === "date" ? (
                                                        <DatePicker
                                                            format="YYYY-MM-DD"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    ) : type ===
                                                      "image-attachment" ? (
                                                        <MultiChunkUpload
                                                            fieldName={key}
                                                            form={form}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Form.Item>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Flex>
                        </Col>

                        <Form.Item
                            label={`Amount`}
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your amount",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                prefix={currency.getSymbolNative()}
                                placeholder="0"
                            />
                        </Form.Item>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
