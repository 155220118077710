import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import {
    purchaseRuleCollectionAtom,
    purchaseRuleSchemaAtom,
} from "@/lib/core-react/store/store";
import { IPurchaseRuleModificationFormData } from "@/types/purchaseRuleCollection";
import { PurchaseRuleTypeEnum } from "@/enums/purchaseRuleCollectionEnums";

export const usePurchaseRule = () => {
    const [purchaseRuleCollectionAtomData, setPurchaseRuleCollectionAtomData] =
        useAtom(purchaseRuleCollectionAtom);
    const [purchaseRuleSchemaAtomData, setPurchaseRuleSchemaAtomData] = useAtom(
        purchaseRuleSchemaAtom,
    );
    const [isLoading, setIsLoading] = useState(false);
    const { purchaseService } = useService();

    const getPurchaseRuleCollection = async (
        params?: string,
    ): Promise<void> => {
        setPurchaseRuleCollectionAtomData({
            ...purchaseRuleCollectionAtomData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseRuleResource.getPurchaseRuleCollection(
                    params,
                );
            setPurchaseRuleCollectionAtomData({
                ...purchaseRuleCollectionAtomData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseRuleCollectionAtomData({
                ...purchaseRuleCollectionAtomData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };

    const getPurchaseRuleSchema = async (
        type: PurchaseRuleTypeEnum,
    ): Promise<void> => {
        setPurchaseRuleSchemaAtomData({
            ...purchaseRuleSchemaAtomData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseRuleResource.getPurchaseRuleSchema(
                    type,
                );
            setPurchaseRuleSchemaAtomData({
                ...purchaseRuleSchemaAtomData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseRuleSchemaAtomData({
                ...purchaseRuleSchemaAtomData,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };

    const createPurchaseRule = async (
        payload: IPurchaseRuleModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleResource.createPurchaseRule(
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleCollectionAtomData({
                ...purchaseRuleCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const updatePurchaseRule = async (
        id: number,
        payload: IPurchaseRuleModificationFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleResource.updatePurchaseRule(
                    id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseRuleCollectionAtomData({
                ...purchaseRuleCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    const deletePurchaseRule = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseRuleResource.deletePurchaseRule(
                    id,
                );
            setIsLoading(false);
            setPurchaseRuleCollectionAtomData({
                ...purchaseRuleCollectionAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return {
        getPurchaseRuleCollection,
        getPurchaseRuleSchema,
        createPurchaseRule,
        updatePurchaseRule,
        deletePurchaseRule,
        isLoading,
    } as const;
};
