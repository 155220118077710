import { useEffect, useReducer, useState } from "react";
import {
    Alert,
    Card,
    Form,
    Select,
    Skeleton,
    TreeSelect,
    Typography,
    Col,
    Flex,
    Row,
    Button,
} from "antd";
import { agentCategoryPriceReadCollectionAtom } from "@/lib/core-react/store/store";
import { useAtomValue } from "jotai";
import { AgentCategoryPriceReadCollectionModel } from "@/models/agentCategoryPriceReadCollectionModel";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import { useBulkUpdateShipmentBuyProduct } from "@/lib/core-react/hooks/private/usePurchase";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { IModalData } from "@/helpers/getModalTital";

import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import { categoryPriceReducer } from "@/pages/ProductManage/ProductDetail/ProductVariations/components";
import CardAgentCompany from "@/pages/BuyAndShip/components/CardAgentCompany";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { getFilteredQuery } from "@/helpers/getFilteredQuery";
import useRefetch from "@/hooks/useRefetch";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

interface IProps {
    product_ids: number[];
    selectedFirstRow: BuyProductModel;
    handleModal: (payload: IModalData) => void;
}

const PER_PAGE_LIMIT = 500;

const UpdateShippingCompanyBulk = ({
    product_ids,
    selectedFirstRow,
    handleModal,
}: IProps) => {
    const { Text } = Typography;
    const [form] = Form.useForm();
    const { refetchListApi } = useRefetch();

    const {
        allOptionsData: {
            shippingCountryOptionsData,
            destinationWarehouseOptionsData,
            shippingCategoryOptionsData,
            regionOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();

    const [agentCategoryPriceReadId, setAgentCategoryPriceReadId] = useState<
        number | null
    >(null);

    const { getCategoryPrices } = useGetCategoryPrices();

    const { bulkShippingUpdate, isLoading: updateLoading } =
        useBulkUpdateShipmentBuyProduct();

    const getInitialQuery = (): ICategoryPriceRequestParams => {
        return {
            region_id: selectedFirstRow.getRegion()?.getId(),
            region: selectedFirstRow.getRegion()?.getCode() || "",
            shipping_country_id: selectedFirstRow
                ?.getMoveonShipping()
                ?.getShippingCountryId(),
            destination_warehouse_id: selectedFirstRow
                ?.getMoveonShipping()
                ?.getDestinationWarehouseId(),
            shipping_category_id: selectedFirstRow
                ?.getMoveonShipping()
                ?.getShippingCategoryId(),
            shipping_type: selectedFirstRow
                ?.getMoveonShipping()
                ?.getShippingType(),
            shipping_mode: selectedFirstRow
                ?.getMoveonShipping()
                ?.getShippingMode(),
        };
    };

    const [categoryPriceQuery, dispatch] = useReducer(
        categoryPriceReducer,
        getInitialQuery(),
    );

    const handleChangeShippingCompany = async () => {
        try {
            if (!agentCategoryPriceReadId) {
                throw new Error("agent Category Price Read Id Not Found");
            }

            await bulkShippingUpdate({
                product_ids,
                agent_category_price_read_id: agentCategoryPriceReadId,
            });
            showSuccessAlert("Successfully Shipping Company Updated");
            handleModal({
                action: false,
            });
            refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    const {
        data: categoryPriceReadCollection,
        isLoading: categoryPriceReadLoading,
    } = useAtomValue(agentCategoryPriceReadCollectionAtom);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE);
        onFetchFilterApi(filterResourceEnum.REGION);
        onFetchFilterApi(filterResourceEnum.SHIPPING_CATEGORY);
    }, []);

    useEffect(() => {
        if (categoryPriceQuery.region_id) {
            onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
                region_id: categoryPriceQuery.region_id,
            });
        }
    }, [categoryPriceQuery.region_id]);

    useEffect(() => {
        if (categoryPriceQuery) {
            getCategoryPrices({
                ...getFilteredQuery({ ...categoryPriceQuery, region_id: null }),
                region: categoryPriceQuery.region,
                per_page: PER_PAGE_LIMIT,
            });
        }
    }, [categoryPriceQuery]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
            region_id: selectedFirstRow?.getRegion()?.getId(),
        });
    }, [selectedFirstRow]);

    const categoryPriceReadCollectionData =
        categoryPriceReadCollection &&
        new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection);

    useEffect(() => {
        if (categoryPriceReadCollectionData) {
            setAgentCategoryPriceReadId(
                categoryPriceReadCollectionData.getDefaultOption(),
            );
        }
    }, [categoryPriceReadLoading]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    ...getInitialQuery(),
                    region_id: selectedFirstRow.getRegion()?.getId(),
                }}
            >
                <Row gutter={12}>
                    <Col span={8}>
                        <Form.Item name={"region_id"} label="Region" required>
                            <Select
                                allowClear={true}
                                showSearch
                                onClear={() => {
                                    dispatch({
                                        type: "SET_REGION_ID",
                                        payload: undefined,
                                    });
                                    dispatch({
                                        type: "SET_REGION",
                                        payload: "",
                                    });
                                }}
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (
                                                optionB?.label ?? ""
                                            ).toLowerCase(),
                                        )
                                }
                                loading={regionOptionsData.isLoading}
                                placeholder="Select Region"
                                onChange={(value, option: any) => {
                                    dispatch({
                                        type: "SET_REGION_ID",
                                        payload: Number(value),
                                    });
                                    dispatch({
                                        type: "SET_REGION",
                                        payload: String(`${option.code}`),
                                    });
                                }}
                                options={regionOptionsData.options}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name={"shipping_country_id"}
                            label="Shipping Country"
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                onClear={() => {
                                    dispatch({
                                        type: "SET_SHIPPING_COUNTRY_ID",
                                        payload: null,
                                    });
                                }}
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (
                                                optionB?.label ?? ""
                                            ).toLowerCase(),
                                        )
                                }
                                loading={shippingCountryOptionsData.isLoading}
                                placeholder="Select Shipping Country"
                                onChange={(value) =>
                                    dispatch({
                                        type: "SET_SHIPPING_COUNTRY_ID",
                                        payload: Number(value),
                                    })
                                }
                                options={shippingCountryOptionsData.options}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name={"destination_warehouse_id"}
                            label="D Warehouse"
                        >
                            <Select
                                allowClear={true}
                                showSearch
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                            (
                                                optionB?.label ?? ""
                                            ).toLowerCase(),
                                        )
                                }
                                onClear={() => {
                                    dispatch({
                                        type: "SET_DESTINATION_WAREHOUSE_ID",
                                        payload: null,
                                    });
                                }}
                                loading={
                                    destinationWarehouseOptionsData.isLoading
                                }
                                placeholder="select destination Warehouse"
                                options={
                                    destinationWarehouseOptionsData.options
                                }
                                onChange={(value) =>
                                    dispatch({
                                        type: "SET_DESTINATION_WAREHOUSE_ID",
                                        payload: Number(value),
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name={"shipping_category_id"}
                    label="Product Category"
                >
                    <TreeSelect
                        onChange={(value) => {
                            dispatch({
                                type: "SET_SHIPPING_CATEGORY_ID",
                                payload: value,
                            });
                        }}
                        showSearch
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Please select a category"
                        allowClear
                        loading={shippingCategoryOptionsData.isLoading}
                        treeLine={{ showLeafIcon: true }}
                        treeData={shippingCategoryOptionsData.options}
                        filterTreeNode={(searchValue, treeNode) => {
                            const title = treeNode.title;
                            return (
                                typeof title === "string" &&
                                title
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        }}
                    />
                </Form.Item>

                <Row gutter={12}>
                    <Col span={24}>
                        <Row gutter={8}>
                            <Col span={6}>
                                <Form.Item
                                    name={"shipping_type"}
                                    label="Shipping Type       "
                                >
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_SHIPPING_TYPE",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        placeholder="Shipping Type"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_SHIPPING_TYPE",
                                                payload: value,
                                            })
                                        }
                                        options={categoryPriceReadCollectionData?.getShippingTypes()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={"shipping_mode"}
                                    label="Shipping Mode       "
                                >
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_SHIPPING_MODE",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        placeholder="Shipping Mode"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_SHIPPING_MODE",
                                                payload: value,
                                            })
                                        }
                                        options={categoryPriceReadCollectionData?.getShippingModes()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={"contains"} label="Contains">
                                    <Select
                                        mode="multiple"
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        placeholder="select contains"
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_CONTAINS",
                                                payload: null,
                                            });
                                        }}
                                        allowClear
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_CONTAINS",
                                                payload: value,
                                            })
                                        }
                                        options={categoryPriceReadCollectionData?.getShippingContains()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={"unit_type"} label="Unit Type">
                                    <Select
                                        loading={
                                            shippingCountryOptionsData.isLoading
                                        }
                                        allowClear
                                        onClear={() => {
                                            dispatch({
                                                type: "SET_UNIT_TYPE",
                                                payload: null,
                                            });
                                        }}
                                        placeholder="select unit type"
                                        onChange={(value) =>
                                            dispatch({
                                                type: "SET_UNIT_TYPE",
                                                payload: value,
                                            })
                                        }
                                        options={categoryPriceReadCollectionData?.getShippingUnits()}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <Flex vertical gap={24}>
                <Card
                    title={<Text strong>Select Company</Text>}
                    style={{
                        backgroundColor: "#F7F8FA",
                    }}
                >
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {categoryPriceReadLoading ? (
                            <Skeleton active />
                        ) : !categoryPriceReadCollectionData ? (
                            <>
                                <Alert
                                    message="please Select Region"
                                    type="info"
                                />
                            </>
                        ) : categoryPriceReadCollectionData &&
                          categoryPriceReadCollectionData?.getData().length ===
                              0 ? (
                            <Alert
                                message="Shipping Partner Not Available. please change  shipping country or product category"
                                type="info"
                            />
                        ) : (
                            categoryPriceReadCollectionData
                                ?.getData()
                                .map((data) => (
                                    <CardAgentCompany
                                        selectedAgentCategoryPriceReadId={
                                            agentCategoryPriceReadId
                                        }
                                        hadnleSelectCompany={(id) => {
                                            setAgentCategoryPriceReadId(id);
                                        }}
                                        key={data.getId()}
                                        data={data}
                                    />
                                ))
                        )}
                    </div>
                </Card>
            </Flex>

            <Flex
                justify="end"
                style={{
                    marginTop: 12,
                }}
            >
                <Button
                    type="primary"
                    loading={updateLoading}
                    onClick={handleChangeShippingCompany}
                >
                    Submit
                </Button>
            </Flex>
        </>
    );
};

export default UpdateShippingCompanyBulk;
