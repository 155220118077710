import { AgentCategoryPriceReadModel } from "@/models/agentCategoryPriceReadCollectionModel";
import { Avatar, Tag, Typography, Flex, Table } from "antd";
import StatusTag from "../../../components/StatusTag";

const { Text } = Typography;

interface Props {
    data: AgentCategoryPriceReadModel;
    selectedAgentCategoryPriceReadId: number | null;
    hadnleSelectCompany: (id: number) => void;
}
const CardAgentCompany = ({
    data,
    hadnleSelectCompany,
    selectedAgentCategoryPriceReadId,
}: Props) => {
    const slotsColumns = [
        {
            title: "Min",
            dataIndex: "min_amount",
            key: "min_amount",
        },
        {
            title: "Max",
            dataIndex: "max_amount",
            key: "max_amount",
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
        },
    ];

    return (
        <div
            style={{
                backgroundColor: `${
                    selectedAgentCategoryPriceReadId === data.getId()
                        ? "#00800005"
                        : "white"
                }`,
                borderRadius: "5px",
                padding: "16px",
                marginBottom: "16px",
                cursor: "pointer",
                border: `${
                    selectedAgentCategoryPriceReadId === data.getId()
                        ? "1px solid green"
                        : "1px solid white"
                }`,
            }}
            onClick={() => hadnleSelectCompany(data.getId())}
        >
            <Flex vertical gap={12}>
                <Flex gap={12} flex={"1"} justify="space-between">
                    <Flex gap={8}>
                        <Avatar
                            size={50}
                            shape="square"
                            style={{
                                backgroundColor: "#00897B",
                                color: "white",
                                fontWeight: 700,
                                fontSize: "20px",
                            }}
                        >
                            {data.getAgentWarehouse().getCompanyName()}
                        </Avatar>
                        <Flex vertical gap={4}>
                            <Text
                                style={{
                                    fontSize: "16px",
                                }}
                            >
                                {data.getAgentWarehouse().getCompanyName()}
                            </Text>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                }}
                            >
                                Shipping Time :{" "}
                                <Text strong>
                                    {data.getMinShippingTime()}-
                                    {data.getMaxShippingTime()}{" "}
                                    {data.getShippingTimeUnit()}
                                </Text>
                            </Text>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                }}
                            >
                                {data.getShippingMode()} by{" "}
                                {data.getShippingType()} | Total Slots :{"  "}
                                <Tag>
                                    {data.getSlots() &&
                                        data.getSlots()?.getData()?.length}
                                </Tag>
                            </Text>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                }}
                            >
                                {data.getAgentWarehouse().getName()}{" "}
                                <Text strong type="danger">
                                    To{" "}
                                </Text>
                                {data.getDestinationWarehouse().getName()}
                            </Text>
                        </Flex>
                    </Flex>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "7px",
                        }}
                    >
                        <Tag>
                            {data.getRate()}/{data.getUnitType()}
                        </Tag>
                        <StatusTag slug={data.contain} text={data.contain} />
                    </div>
                </Flex>

                {data.getSlots() &&
                data.getSlots()?.getData() &&
                selectedAgentCategoryPriceReadId === data.getId() ? (
                    <Table
                        title={() => "Slots"}
                        columns={slotsColumns}
                        size="small"
                        dataSource={data.getSlots()?.getData()}
                        rowKey={(r) => r.getId()}
                        style={{
                            width: "100%",
                        }}
                        pagination={false}
                        bordered={true}
                        rowHoverable={false}
                    />
                ) : (
                    <></>
                )}
            </Flex>
        </div>
    );
};

export default CardAgentCompany;
