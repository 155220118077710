import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Col,
    Dropdown,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Space,
    Table,
    Tabs,
    Tag,
    Typography,
    message,
    Form,
    Input,
    Image,
    Tooltip,
    notification,
} from "antd";

import {
    CheckOutlined,
    DownOutlined,
    FileExcelFilled,
    HddFilled,
    RestOutlined,
    SendOutlined,
} from "@ant-design/icons";

import { PayoutRequestStatusEnum } from "../../../enums/payoutRequestCollectionEnum";
import {
    useApprovePayoutRequest,
    useGetPayoutRequests,
    useUpdatePayoutRequest,
} from "@/lib/core-react/hooks/private/useWallet";
import {
    PayoutRequestCollectionModel,
    PayoutRequestModel,
} from "@/models/payoutRequestCollectionModel";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import StatusTag from "@/components/StatusTag";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import FiltersComponent from "@/components/FiltersComponent";
import TabPane from "antd/es/tabs/TabPane";
import { formatDateTime } from "@/utils/helpers";
import CommonError from "@/components/Error/CommonError";
import { getExcelRows } from "../helper";
import Paragraph from "antd/lib/typography/Paragraph";
import CustomerDetailsViews from "@/pages/CustomerManage/User/components/CustomerDetailsViews";
import { customerPayoutRequestCollectionAtom } from "@/lib/core-react/store/store";
import { useCustomerCashOutBulkActions } from "./useActions";

const { TextArea } = Input;

export interface ICashOutExcelRow {
    request_number: string;
    customer_name: string;
    requested_amount: number;
    refund_method: string;
    account_holderName?: string;
    bank_name?: string;
    refund_number: string;
    routing_number?: string;
}

const { Text } = Typography;

const CustomerWithdrawalHistory = () => {
    const { getCustomerPayoutRequests } = useGetPayoutRequests();
    const { approvePayoutRequest, isLoading: approveLoading } =
        useApprovePayoutRequest();
    const [
        {
            data: payoutRequestCollectionData,
            isLoading,
            refetch,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(customerPayoutRequestCollectionAtom);
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<PayoutRequestModel[]>([]);
    const [copiedRow, setCopiedRow] = useState<string | null>(null);

    const { isMobile } = useWindowWidth();

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
        isFirstCall,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(
                { ...filters },
                getCustomerPayoutRequests,
            );
        }
    }, [refetch, refetchFromFilter, filters, isFetched, isFirstCall]);

    const PayoutRequestCollectionData =
        payoutRequestCollectionData &&
        new PayoutRequestCollectionModel(payoutRequestCollectionData);

    // Filter
    const filterData = PayoutRequestCollectionData?.getFilters();

    const [selectedPayoutRequest, setSelectedPayoutRequest] = useState<
        PayoutRequestModel | undefined
    >(undefined);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && PayoutRequestCollectionData?.filters) {
            initializeAvailableFilter({ ...filterData });
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        PayoutRequestCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            { ...filters, ...pageInfo },
            getCustomerPayoutRequests,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PayoutRequestCollectionData,
        handlePaginationChange,
    );

    // Filter Handlerfiv
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(
            { ...filters },
            getCustomerPayoutRequests,
        );
    };

    // Modal
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const { updatePayoutRequest } = useUpdatePayoutRequest();

    useEffect(() => {
        if (isErrorVisible) {
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 5000);
        }
    }, [isErrorVisible]);

    const handleMenuClick = async (key: string) => {
        const actionMap = {
            processing: "processing",
            reject: "reject",
        };

        if (!selectedPayoutRequest) {
            return;
        }

        const currentStatus = selectedPayoutRequest.getStatus();

        if (key === "approve") {
            if (currentStatus === "approved") {
                message.error("This request is already approved.");
                return;
            }
            setIsModalVisible(true);
            return;
        }

        if (key === "reject" && currentStatus === "approved") {
            message.error("Cannot reject an already approved request.");
            return;
        }

        try {
            await updatePayoutRequest(
                selectedPayoutRequest.getId(),
                actionMap[key],
            );

            setIsErrorVisible(false);
        } catch (_error) {
            setIsErrorVisible(true);
        }
    };

    useEffect(() => {
        if (isModalVisible && selectedPayoutRequest) {
            form.setFieldsValue({
                approved_amount: selectedPayoutRequest?.getRequestedAmount(),
            });
        } else {
            form.resetFields();
        }
    }, [isModalVisible, selectedPayoutRequest, form]);

    const handleApproveConfirm = async () => {
        try {
            const values = await form.validateFields();
            if (values.approved_amount && selectedPayoutRequest) {
                try {
                    await approvePayoutRequest(selectedPayoutRequest.getId(), {
                        approved_amount: values.approved_amount,
                        admin_note: values.admin_note || null,
                    });
                    form.resetFields();
                    setIsModalVisible(false);
                    setIsErrorVisible(false);
                } catch (_error) {
                    setIsModalVisible(false);
                    setIsErrorVisible(true);
                }
            }
        } catch (_) {
            /* empty */
        }
    };

    const closeModal = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const onSelectChange = (
        newSelectedRowKeys: any,
        newSelectedRows: PayoutRequestModel[],
    ) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const groupedByCurrency = selectedRows.reduce(
        (groups, row) => {
            const currency = row.wallet?.getCurrency().getCode();
            // Ensure that currency is defined
            if (!currency) return groups;

            if (!groups[currency]) {
                groups[currency] = {
                    totalAmount: 0,
                    totalRequestAmount: 0,
                };
            }

            groups[currency].totalAmount += row.approved_amount || 0;
            groups[currency].totalRequestAmount += row.requested_amount || 0;
            return groups;
        },
        {} as Record<
            string,
            { totalAmount: number; totalRequestAmount: number }
        >,
    );

    const copyToClipboard = (formattedData: string, requestNumber: string) => {
        navigator.clipboard
            .writeText(formattedData)
            .then(() => {
                setCopiedRow(requestNumber); // Set the copied row
                notification.success({
                    message: "Success",
                    description:
                        "Data copied to clipboard. You can paste it into Excel.",
                    duration: 2,
                });
                setTimeout(() => {
                    setCopiedRow(null);
                }, 2000);
            })
            .catch(() => {
                notification.error({
                    message: "Error",
                    description:
                        "Failed to copy data to clipboard. Please try again.",
                    duration: 3,
                });
            });
    };

    const handleCopy = (records: PayoutRequestModel | PayoutRequestModel[]) => {
        const recordsArray = Array.isArray(records) ? records : [records];

        const formattedData = recordsArray
            .map((record) => {
                const excelRows = getExcelRows(record);
                return `${excelRows.request_number}\t${excelRows.customer_name}\t${excelRows.refund_method}\t${excelRows.requested_amount}\t${excelRows.account_holderName}\t${excelRows.bank_name}\t${excelRows.refund_number}\t${excelRows.routing_number}`;
            })
            .join("\n");

        const requestNumber = Array.isArray(records)
            ? "Bulk Copy"
            : records.request_number;

        copyToClipboard(formattedData, requestNumber);
    };

    const { getActions: getBulkActions, BulkApproveModal } =
        useCustomerCashOutBulkActions();

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const columns = [
        // {
        //     title: "ID",
        //     dataIndex: "id",
        //     key: "sl",
        //     width: 70,
        //     render: (_: string, record: PayoutRequestModel) => {
        //         return <div>{record.getId()}</div>;
        //     },
        // },
        {
            title: "Request No",
            dataIndex: "request_no",
            key: "request_no",
            hidden: !isMobile,
            width: 200,
            render: (_: string, record: PayoutRequestModel) => {
                const adminNote = record.getAdminNote();
                const customerNote = record.getCustomerNote();
                return (
                    <Space direction="vertical" wrap>
                        <Space>
                            {/* <Text strong>RN:</Text> */}
                            <Text copyable>{record.getRequestNumber()}</Text>
                        </Space>
                        <Space>
                            <span style={{ fontWeight: "bold" }}>Status: </span>
                            <StatusTag
                                slug={record.getStatus()}
                                text={record.getStatus()}
                            />
                        </Space>
                        <Space>
                            <Button
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                type="link"
                                onClick={() => handleCopy(record)}
                            >
                                <span
                                    style={{
                                        color:
                                            copiedRow === record.request_number
                                                ? "#00893c"
                                                : undefined,
                                    }}
                                >
                                    {copiedRow === record.request_number
                                        ? "Copied"
                                        : "Copy to Excel"}
                                </span>
                                <FileExcelFilled
                                    style={{
                                        color:
                                            copiedRow === record.request_number
                                                ? "#00893c"
                                                : "#000",
                                    }}
                                />
                            </Button>
                        </Space>

                        {customerNote && (
                            <div style={{ marginTop: -4 }}>
                                <Text strong>Customer Note : {}</Text>
                                <Tooltip title={record.getCustomerNote()}>
                                    <Text>
                                        {customerNote.length > 10
                                            ? `${customerNote.substring(0, 10)}...`
                                            : record.getCustomerNote()}
                                    </Text>
                                </Tooltip>
                            </div>
                        )}
                        {adminNote && (
                            <div style={{ marginTop: -4 }}>
                                <Text strong>Admin Note : {}</Text>
                                <Tooltip title={record.getAdminNote()}>
                                    <Text>
                                        {adminNote.length > 10
                                            ? `${adminNote.substring(0, 10)}...`
                                            : record.getAdminNote()}
                                    </Text>
                                </Tooltip>
                            </div>
                        )}
                    </Space>
                );
            },
        },
        {
            title: "Withdrawal info",
            dataIndex: "name",
            key: "name",
            hidden: isMobile,
            width: 200,
            render: (_: string, record: PayoutRequestModel) => {
                const adminNote = record.getAdminNote();
                const customerNote = record.getCustomerNote();
                return (
                    <Space direction="vertical" wrap>
                        <Paragraph>
                            <Text strong>R/N : </Text>
                            <Text copyable>{record.getRequestNumber()}</Text>
                        </Paragraph>
                        <Paragraph style={{ marginTop: -5, marginBottom: -5 }}>
                            <Button
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                type="link"
                                onClick={() => handleCopy(record)}
                            >
                                <span
                                    style={{
                                        color:
                                            copiedRow === record.request_number
                                                ? "#00893c"
                                                : undefined,
                                    }}
                                >
                                    {copiedRow === record.request_number
                                        ? "Copied"
                                        : "Copy to Excel"}
                                </span>
                                <FileExcelFilled
                                    style={{
                                        color:
                                            copiedRow === record.request_number
                                                ? "#00893c"
                                                : "#000", // Change icon color dynamically
                                    }}
                                />
                            </Button>
                        </Paragraph>

                        {customerNote && (
                            <div style={{ marginTop: -4 }}>
                                <Text strong>Customer Note : {}</Text>
                                <Tooltip title={record.getCustomerNote()}>
                                    <Text>
                                        {customerNote.length > 10
                                            ? `${customerNote.substring(0, 10)}...`
                                            : record.getCustomerNote()}
                                    </Text>
                                </Tooltip>
                            </div>
                        )}
                        {adminNote && (
                            <div style={{ marginTop: -4 }}>
                                <Text strong>Admin Note : {}</Text>
                                <Tooltip title={record.getAdminNote()}>
                                    <Text>
                                        {adminNote.length > 10
                                            ? `${adminNote.substring(0, 10)}...`
                                            : record.getAdminNote()}
                                    </Text>
                                </Tooltip>
                            </div>
                        )}
                        <StatusTag
                            slug={record.getStatus()}
                            text={record.getStatus()}
                        />
                    </Space>
                );
            },
        },
        {
            title: "Customer Info",
            dataIndex: "customer_info",
            key: "customer_info",
            render: (_: string, record: PayoutRequestModel) => {
                const customerInfo = record.getCustomer();
                return customerInfo ? (
                    <Space direction="vertical" wrap>
                        <CustomerDetailsViews
                            name="Name"
                            selectedUser={customerInfo}
                            currency={record.getWallet()?.getCurrency()}
                        />
                        {customerInfo?.getEmail() && (
                            <Paragraph>
                                <Text strong>Email: </Text>
                                <Text>{customerInfo?.getEmail()}</Text>
                            </Paragraph>
                        )}
                        {customerInfo?.getPhone() && (
                            <Paragraph>
                                <Text strong>Phone: </Text>
                                <Text>{customerInfo?.getPhone()}</Text>
                            </Paragraph>
                        )}
                        {customerInfo?.getShippingMark() && (
                            <Paragraph>
                                <Text strong>Shipping Mark: </Text>
                                <Text>{customerInfo?.getShippingMark()}</Text>
                            </Paragraph>
                        )}
                    </Space>
                ) : (
                    <Space></Space>
                );
            },
        },
        {
            title: "Wallet",
            dataIndex: "wallet",
            key: "wallet",
            render: (_: string, record: PayoutRequestModel) => {
                const currency = record.getWallet()?.getCurrency().getCode();
                return (
                    <Space direction="vertical" wrap>
                        <div>
                            <Text strong>Available: </Text>
                            <Text>
                                {currency}{" "}
                                {record.getWallet()?.getAvailableBalance()}
                            </Text>
                        </div>
                        <div>
                            <Text strong>Reserved: </Text>
                            <Text>
                                {currency}{" "}
                                {record.getWallet()?.getReservedBalance()}
                            </Text>
                        </div>
                        <div>
                            <Text strong>Pending : </Text>
                            <Text>
                                {currency}{" "}
                                {record.getWallet()?.getPendingBalance()}
                            </Text>
                        </div>
                    </Space>
                );
            },
        },
        {
            title: "Withdrawl account",
            dataIndex: "payout_account",
            key: "payout_account",
            render: (_: string, record: PayoutRequestModel) => {
                const payoutAccount = record.getPayoutAccount();
                const payoutGateway = payoutAccount.getPayoutGateway();
                const accountData = payoutAccount?.getAccountData();
                const logos = payoutGateway?.getLogo()?.data;
                return (
                    <Space direction="vertical">
                        {/* Gateway Section with Name and Logo */}
                        {payoutGateway && (
                            <Space
                                align="center"
                                style={{ marginTop: -4, marginBottom: -4 }}
                            >
                                <Text strong>Gateway:</Text>
                                <Text>{payoutGateway.getName()}</Text>
                                {/* Display the first logo (small) */}
                                {logos &&
                                    payoutGateway.getLogo()?.data[0]?.small
                                        ?.url && (
                                        <Image
                                            src={logos[0]?.small?.url}
                                            width={40}
                                            height={40}
                                            alt={`${payoutGateway.getName()} logo`}
                                        />
                                    )}
                            </Space>
                        )}

                        {/* Account Data Section */}
                        {accountData && Object.keys(accountData).length ? (
                            <Space direction="vertical">
                                {Object.keys(accountData).map((key) => {
                                    // Exclude the flag from being displayed in account data
                                    if (key === "flag") return null;

                                    return (
                                        <Space key={key}>
                                            <Text
                                                strong
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {key.replaceAll("_", " ")}:
                                            </Text>{" "}
                                            <Text>{accountData[key]}</Text>
                                        </Space>
                                    );
                                })}
                            </Space>
                        ) : (
                            <Text>No account data available</Text>
                        )}

                        {/* Country Flag Display */}
                        {accountData?.flag && accountData.label && (
                            <Space align="center">
                                <Text strong>Country:</Text>
                                <Image
                                    src={accountData.flag}
                                    width={20}
                                    height={20}
                                    alt={accountData.label}
                                    style={{ marginLeft: 8 }} // Adds some space between text and flag
                                />
                            </Space>
                        )}
                    </Space>
                );
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (_: string, record: PayoutRequestModel) => {
                const currency = record.getWallet()?.getCurrency().getCode();
                return (
                    <Space direction="vertical" wrap>
                        <div>
                            <Text strong>Requested: </Text>
                            <Text>
                                {currency} {record.getRequestedAmount()}
                            </Text>
                        </div>
                        <div>
                            <Text strong>Approved: </Text>
                            <Text>
                                {currency} {record.getApprovedAmount()}
                            </Text>
                        </div>
                    </Space>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            hidden: isMobile,
            render: (_: string, record: PayoutRequestModel) => {
                return (
                    <Tag color="purple">
                        {formatDateTime(record.getCreatedAt())}
                    </Tag>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            render: (_: string, record: PayoutRequestModel) => {
                const items: ExtendedMenuItemType[] = [];

                // Conditionally push actions based on the current status
                if (record.getStatus() !== PayoutRequestStatusEnum.PROCESSING) {
                    items.push({
                        permission:
                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_MANAGE,
                        icon: <SendOutlined />,
                        label: (
                            <Popconfirm
                                title="Are you sure you want to process?"
                                onConfirm={() => handleMenuClick("processing")}
                                okText="Yes"
                                cancelText="No"
                            >
                                Processing
                            </Popconfirm>
                        ),
                        key: "processing",
                    });
                }

                if (record.getStatus() === PayoutRequestStatusEnum.PROCESSING) {
                    items.push({
                        permission:
                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_MANAGE,
                        icon: <CheckOutlined />,
                        label: "Approve",
                        key: "approve",
                        onClick: () => handleMenuClick("approve"),
                    });
                }

                // Add reject action for specific statuses
                if (
                    [
                        PayoutRequestStatusEnum.PROCESSING,
                        PayoutRequestStatusEnum.PENDING,
                        PayoutRequestStatusEnum.CANCELLED,
                    ].includes(record.getStatus())
                ) {
                    items.push({
                        permission:
                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_MANAGE,
                        icon: <RestOutlined />,
                        label: (
                            <Popconfirm
                                title="Are you sure you want to reject?"
                                onConfirm={() => handleMenuClick("reject")}
                                okText="Yes"
                                cancelText="No"
                            >
                                Reject
                            </Popconfirm>
                        ),
                        key: "reject",
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                        onOpenChange={() => setSelectedPayoutRequest(record)}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="Cashouts"
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col span={24}>
                                    <Card title="Filter">
                                        <FiltersComponent
                                            handleProductFilter={
                                                handleProductFilter
                                            }
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            handelFilterClear={
                                                handelFilterClear
                                            }
                                            isFetched={isFetched}
                                            filters={filters}
                                            filtersData={filterData}
                                            isFromProductReceived={true}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {/* Grouping by Wallet Currency */}
                            {hasSelected && (
                                <Tabs
                                    defaultActiveKey={
                                        Object.keys(groupedByCurrency)[0]
                                    }
                                    tabBarExtraContent={{
                                        right: (
                                            <div style={{ display: "flex" }}>
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        handleCopy(selectedRows)
                                                    }
                                                    style={{
                                                        padding: 0,
                                                        margin: 0,
                                                        border: "none",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {!copiedRow
                                                        ? "Copy All to Excel"
                                                        : "Copied"}
                                                    <FileExcelFilled
                                                        style={{
                                                            color: copiedRow
                                                                ? "#00893c"
                                                                : "#000",
                                                        }}
                                                    />
                                                </Button>
                                                {checkActionPermission(
                                                    [
                                                        ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_MANAGE,
                                                    ],
                                                    <div
                                                        style={{
                                                            textAlign: "right",
                                                            marginLeft: 20,
                                                        }}
                                                    >
                                                        <Dropdown
                                                            menu={{
                                                                items: getBulkActions(
                                                                    selectedRowKeys,
                                                                ),
                                                            }}
                                                            disabled={
                                                                selectedRowKeys.length ===
                                                                0
                                                            }
                                                        >
                                                            <Button
                                                                icon={
                                                                    <HddFilled />
                                                                }
                                                            >
                                                                Bulk Actions
                                                                <DownOutlined />
                                                            </Button>
                                                        </Dropdown>
                                                        {BulkApproveModal}
                                                    </div>,
                                                    null,
                                                )}
                                            </div>
                                        ),
                                    }}
                                >
                                    {Object.entries(groupedByCurrency).map(
                                        ([
                                            currency,
                                            { totalAmount, totalRequestAmount },
                                        ]) => (
                                            <TabPane
                                                tab={
                                                    <span>
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                                padding: 3,
                                                                fontSize: 14,
                                                                marginTop: 5,
                                                            }}
                                                        >
                                                            {currency}
                                                        </Text>
                                                    </span>
                                                }
                                                key={currency}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Paragraph
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        <Text>
                                                            {" "}
                                                            Total Approved:{" "}
                                                        </Text>
                                                        <Tag
                                                            color="green"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currency}{" "}
                                                            {totalAmount.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>

                                                    <Paragraph
                                                        style={{
                                                            padding: 3,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        <Text>
                                                            {" "}
                                                            Total Request:{" "}
                                                        </Text>
                                                        <Tag
                                                            color="red"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {currency}{" "}
                                                            {totalRequestAmount.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>
                                                </div>
                                            </TabPane>
                                        ),
                                    )}
                                </Tabs>
                            )}
                        </div>

                        <div>
                            <Table
                                rowSelection={{
                                    type: "checkbox",
                                    ...rowSelection,
                                }}
                                //@ts-ignore
                                columns={columns}
                                dataSource={PayoutRequestCollectionData?.getData()}
                                bordered
                                loading={isLoading}
                                rowKey="id"
                                pagination={{
                                    ...paginationConfig,
                                    showTotal: (total) => (
                                        <div>
                                            Total{" "}
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    color: "green",
                                                }}
                                            >
                                                {total}
                                            </span>{" "}
                                            Items
                                        </div>
                                    ),
                                }}
                                scroll={{ x: 1237 }}
                            />
                        </div>
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                title="Approve Payout Request"
                open={isModalVisible}
                onOk={handleApproveConfirm}
                onCancel={closeModal}
                confirmLoading={approveLoading}
                destroyOnClose={true}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Enter the approved amount"
                        name="approved_amount"
                        rules={[
                            {
                                required: true,
                                message: "Approved amount is required",
                            },
                            {
                                validator: (_, value) => {
                                    const requestedAmount: number | undefined =
                                        selectedPayoutRequest?.getRequestedAmount();

                                    if (requestedAmount === undefined) {
                                        return Promise.reject(
                                            "Requested amount is not available.",
                                        );
                                    }

                                    if (value > requestedAmount) {
                                        return Promise.reject(
                                            `Approved amount cannot exceed the requested amount of ${requestedAmount}.`,
                                        );
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>

                    <Form.Item label="Admin Note" name="admin_note">
                        <TextArea
                            rows={4}
                            placeholder="Add any notes for this approval (optional)"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CustomerWithdrawalHistory;
