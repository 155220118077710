import { PageHeader } from "@ant-design/pro-layout";
import * as Sentry from "@sentry/browser";
import {
    Button,
    Dropdown,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
    Image,
    Form,
    notification,
    Modal,
    message,
    Tabs,
} from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    CalculatorFilled,
    DownloadOutlined,
    DownOutlined,
    EditOutlined,
    EyeFilled,
    HddFilled,
    LoadingOutlined,
    PayCircleOutlined,
    PrinterOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./invoice.css";
import Paragraph from "antd/es/typography/Paragraph";

import { invoiceCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { formatString } from "@/utils/helper";
import { showError } from "@/helpers/showError";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";

import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import {
    AgentInvoiceModel,
    InvoiceCollectionModel,
    InvoiceModel,
} from "@/models/invoiceCollectionModel";
import { RegionModel } from "@/models/regionCollectionModel";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { IFilterType } from "@/types/filters";
import { ExtendedMenuItemType } from "@/types";

import { useCustomerInvoiceBulkActions } from "./useActions";
import CommonError from "@/components/Error/CommonError";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import FiltersComponent from "@/components/FiltersComponent";
import { StatusTag } from "@/components";
import checkActionPermission, {
    IAuthorityType,
} from "@/components/Authorized/CheckPermissions";
import CustomerDetailsViews from "@/pages/CustomerManage/User/components/CustomerDetailsViews";
import { showSuccessAlert } from "@/helpers/showSuccess";
import TabPane from "antd/es/tabs/TabPane";

const CustomerInvoiceList = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {
        getCustomerInvoice,
        recalculateInvoiceData,
        settleInvoice,
        handledownloadData,
        createSession,
        paySession,
        isLoading: isLoadingSession,
    } = useInvoice();

    const [
        {
            data: invoiceCollectionData,
            isLoading,
            refetch,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(invoiceCollectionAtom);
    const { isMobile } = useWindowWidth();
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<InvoiceModel[]>([]);
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [loadingInvoices, setLoadingInvoices] = useState<
        Record<number, boolean>
    >({});
    const { Text } = Typography;
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();
    const { getActions: getBulkActions } = useCustomerInvoiceBulkActions({
        setSelectedPayInvoiceData,
    });

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters || {}, getCustomerInvoice);
        }
    }, [isFirstCall, isFetched, refetch]);
    const InvoiceCollectionData =
        invoiceCollectionData &&
        new InvoiceCollectionModel(invoiceCollectionData);
    // const paginationData = InvoiceCollectionData?.getPagination();
    const filterData = InvoiceCollectionData?.getFilter();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && InvoiceCollectionData?.getFilter()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        InvoiceCollectionData?.getFilter(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getCustomerInvoice,
        );
    };

    const handleShowInvoice = (invoiceId: number) => {
        if (!invoiceId) return;
        navigate(`/invoice/customer-invoice/print/${invoiceId}`);
    };

    const handleShowDetail = async (invoiceId: number) => {
        const url = `/invoice/customer-invoice/details/${invoiceId}`;
        window.open(url, "_blank");
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                ApiHelperModel.makeGetRequest(
                    filters || {},
                    getCustomerInvoice,
                );
                setSelectedPayInvoiceData(undefined);
                setSelectedRowKeys([]);
                setSelectedRows([]);
                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        InvoiceCollectionData,
        handlePaginationChange,
    );

    const handleDownloadInvoice = async (invoiceId: number) => {
        setLoadingInvoices((prev) => ({ ...prev, [invoiceId]: true })); // Set loading for this invoice
        try {
            const response = await handledownloadData(
                Number(invoiceId),
                "customer",
            );

            if (response?.data?.download_url) {
                const downloadUrl = response.data.download_url;

                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "";
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Download URL not found in response");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
        } finally {
            setLoadingInvoices((prev) => ({ ...prev, [invoiceId]: false })); // Reset loading for this invoice
        }
    };

    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getCustomerInvoice);
    };

    const handlePayCustomerInvoice = async (
        region: RegionModel,
        invoice_ids: number[],
        user_id?: number,
    ) => {
        if (typeof user_id === "undefined") {
            return Sentry.captureMessage(
                `No user id provided with invoice ids: ${invoice_ids.join(", ")}`,
            );
        }
        const res = await createSession(user_id, invoice_ids);

        if (res.data.session_token) {
            setSelectedPayInvoiceData({
                region,
                user_id,
                token: res.data.session_token,
            });
        } else {
            return Sentry.captureMessage(
                `No session token provided with response to create session using user id: ${user_id} & invoice ids: ${invoice_ids.join(", ")}`,
            );
        }
    };

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const hasSelected = selectedRowKeys.length > 0;
    // Group transactions by region
    const groupedByRegion = selectedRows.reduce(
        (acc, curr) => {
            const regionName = curr.region.getCode();
            const currencySymbol = curr.region.currency?.getCode() || "";

            if (!acc[regionName]) {
                acc[regionName] = {
                    totalAmount: 0,
                    paid: 0,
                    due: 0,
                    overpaid: 0,
                    currencySymbol,
                    count: 0,
                };
            }
            acc[regionName].totalAmount += curr.getTotalAmount() ?? 0;
            acc[regionName].paid += curr.transaction_summary.paid ?? 0;
            acc[regionName].due += curr.transaction_summary.due ?? 0;
            acc[regionName].overpaid += curr.transaction_summary.overpaid ?? 0;
            acc[regionName].count += 1;

            return acc;
        },
        {} as Record<
            string,
            {
                count: number;
                totalAmount: number;
                paid: number;
                due: number;
                overpaid: number;
                currencySymbol: string;
            }
        >,
    );

    // Grouped data by region

    const columns = [
        // {
        //     title: "ID",
        //     dataIndex: "id",
        //     key: "sl",
        //     width: 70,
        //     align: "center" as const,
        // },
        {
            title: "Invoice info",
            key: "invoice_and_order",
            width: 230,
            render: (_: string, record: InvoiceModel) => {
                return (
                    <>
                        <Paragraph>
                            <Text strong>Invoice : </Text>
                            <Text copyable>
                                {record.getInvoiceNumber().slice(0, 12)}
                            </Text>
                        </Paragraph>

                        <Paragraph>
                            <Text strong>Order : </Text>
                            <Text copyable>
                                {record.getOrder().order_number}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Region : </Text>
                            <StatusTag
                                slug={record.getRegion().code}
                                text={record.getRegion().code}
                            />{" "}
                        </Paragraph>
                        {checkActionPermission(
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                            <Button
                                type="dashed"
                                onClick={() =>
                                    handleDownloadInvoice(record.getId())
                                }
                                style={{ marginTop: 5 }}
                                icon={
                                    loadingInvoices[record.getId()] ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <DownloadOutlined />
                                    )
                                }
                            >
                                Invoice
                            </Button>,
                            null,
                        )}
                    </>
                );
            },
        },

        {
            title: "Product Info",
            dataIndex: "order",
            width: 320,
            key: "product_info",
            render: (_: string, record: InvoiceModel) => {
                const order = record.getOrder();
                const orderType = order.order_type;
                const buyProduct = record.getBuyProduct();
                const shipmentProduct = record.getShipmentProduct();

                const containerStyle = {
                    fontSize: "12px",
                    textAlign: "left" as const,
                };
                const labelStyle = {
                    fontWeight: "bold",
                };
                const valueStyle = {
                    marginLeft: "4px",
                };
                const linkStyle = {
                    color: "#1890ff", // Default link color
                    textDecoration: "underline",
                    cursor: "pointer",
                };

                if (
                    (orderType === "buy-and-ship" && buyProduct) ||
                    (orderType === "buy-and-ship" && shipmentProduct)
                ) {
                    const productNumber = buyProduct?.product_number;
                    const productStatus = buyProduct?.status_label;

                    return (
                        <>
                            <div style={{ display: "flex" }}>
                                {orderType === "buy-and-ship" && buyProduct && (
                                    <div style={{ marginRight: 10 }}>
                                        <Image
                                            width={60}
                                            src={buyProduct?.product_image}
                                            alt="Product Image"
                                            style={{ borderRadius: 5 }}
                                        />
                                    </div>
                                )}
                                <div style={containerStyle}>
                                    <div>
                                        <span
                                            title={"Product Number"}
                                            style={labelStyle}
                                        >
                                            P.N :{" "}
                                        </span>
                                        <Text
                                            style={{
                                                ...linkStyle,
                                                ...valueStyle,
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `${buyProduct?.id ? `/purchase/buy-products/detail/${buyProduct?.id}` : `/shipping/shipment-products/edit/${shipmentProduct?.id}`}`,
                                                )
                                            }
                                            copyable
                                        >
                                            {productNumber}
                                        </Text>
                                    </div>
                                    <div>
                                        <span style={labelStyle}>
                                            Order Type :
                                        </span>
                                        <span style={valueStyle}>
                                            <StatusTag
                                                slug={orderType}
                                                text={formatString(orderType)}
                                            />
                                        </span>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <StatusTag
                                            slug={productStatus || ""}
                                            text={formatString(productStatus)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                } else if (orderType === "only-ship" && shipmentProduct) {
                    const productNumber = shipmentProduct.product_number;
                    const productStatus = shipmentProduct.status_label;
                    const shippingMode = shipmentProduct.shipping_mode;
                    const shippingType = shipmentProduct.shipping_type;

                    return (
                        <div style={containerStyle}>
                            <div>
                                <span style={labelStyle}>P. Number:</span>
                                <span
                                    style={{ ...linkStyle, ...valueStyle }}
                                    onClick={() =>
                                        navigate(
                                            `/shipping/shipment-products/edit/${shipmentProduct?.id}`,
                                        )
                                    }
                                >
                                    {productNumber}
                                </span>
                                <div>
                                    <span style={labelStyle}>Order Type:</span>
                                    <span style={valueStyle}>
                                        <StatusTag
                                            slug={orderType}
                                            text={orderType}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span style={labelStyle}>Shipping Mode:</span>
                                <span style={valueStyle}>{shippingMode}</span>
                            </div>
                            <div>
                                <span style={labelStyle}>Shipping Type:</span>
                                <span style={valueStyle}>{shippingType}</span>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <StatusTag
                                    slug={productStatus}
                                    text={formatString(productStatus)}
                                />
                            </div>
                        </div>
                    );
                }
                return null;
            },
        },
        {
            title: "Customer Information",
            key: "customer_information",
            width: 320,
            render: (_, record: InvoiceModel) => {
                return (
                    <>
                        {record.getCustomer()?.getName() && (
                            <CustomerDetailsViews
                                name="Name"
                                selectedUser={record.getCustomer()}
                                currency={record.getRegion().getCurrency()}
                            />
                        )}
                        {record.getCustomer()?.getEmail() && (
                            <Paragraph>
                                <Text strong>Email : </Text>
                                <Text copyable>
                                    {record.getCustomer()?.getEmail()}
                                </Text>
                            </Paragraph>
                        )}

                        {record.getCustomer()?.getPhone() && (
                            <Paragraph>
                                <Text strong>Phone : </Text>
                                <Text copyable>
                                    {record.getCustomer()?.getPhone()}
                                </Text>
                            </Paragraph>
                        )}

                        {record.getCustomer()?.getShippingMark() && (
                            <Paragraph>
                                <Text strong>Shipping mark : </Text>
                                <Text copyable>
                                    {record.getCustomer()?.getShippingMark()}
                                </Text>
                            </Paragraph>
                        )}
                    </>
                );
            },
        },
        {
            title: "Amount",
            key: "transaction_summary",
            width: 180,
            render: (_, record: InvoiceModel) => {
                const currency = record.getRegion().currency?.code;
                return (
                    <>
                        <Paragraph>
                            <Text strong>Total : </Text>
                            <Text>{`${currency}${" "}${record.getTotalAmount().toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Paid : </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummery().paid?.toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Due : </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummery().due?.toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>OverPaid : </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummery().overpaid?.toFixed(2)}`}</Text>
                        </Paragraph>
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            key: "status",
            render: (_: string, record: InvoiceModel) => {
                const overpaid = record.getTransactionSummery().overpaid;
                return (
                    <>
                        <div style={{ marginBottom: 4 }}>
                            <StatusTag
                                slug={record.getStatus()}
                                text={formatString(record.getStatus())}
                            />
                        </div>
                        {(overpaid ?? 0) > 0 && (
                            <Tag color="green">Overpaid</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: "Created At",
            width: 200,
            dataIndex: "created_at",
            key: "created_at",
            render: (_created_at, record: InvoiceModel) => {
                return <Tag color="purple">{record.getCreatedAt()}</Tag>;
            },
        },
        {
            title: "Details",
            dataIndex: "ud",
            hidden: isMobile,
            key: "details",
            render: (_: string, record: AgentInvoiceModel) => {
                return (
                    <>
                        <Space size={4}>
                            <Tooltip title="Invoice Details">
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        handleShowDetail(record.getId());
                                    }}
                                    shape="round"
                                    icon={<EyeFilled />}
                                />
                            </Tooltip>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: 100,
            render: (_: string, record: InvoiceModel) => {
                const customerId = record.getCustomer()?.getId();

                const handleRecalculate = async () => {
                    setIsRecalculating(true);
                    try {
                        await recalculateInvoiceData(
                            record.getId(),
                            "customer",
                        );
                        message.success("successfully Recalculated");
                    } finally {
                        setIsRecalculating(false);
                    }
                };

                const handleDownloadInvoice = async () => {
                    try {
                        const response = await handledownloadData(
                            Number(record.getId()),
                            "customer",
                        );

                        if (!response?.data?.download_url) {
                            const printUrl = response.data.download_url;
                            window.open(printUrl, "_blank");
                        } else {
                            Sentry.captureMessage(
                                `download_url not found in response with customer invoice id: ${record.getId()}`,
                            );
                        }
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                };

                const shouldDisplaySettlementMenuItem = (
                    record: InvoiceModel,
                ) => {
                    const isOverPaid =
                        record.getTransactionSummery()?.overpaid ?? 0 > 0;
                    return isOverPaid;
                };
                const shouldDisplayPayMenuItem = (record: InvoiceModel) => {
                    const isDue = record.getTransactionSummery()?.getDue() > 0;
                    return isDue;
                };

                const menuItems: ExtendedMenuItemType[] = [
                    ...(shouldDisplaySettlementMenuItem(record)
                        ? [
                              {
                                  label: "Settlement",
                                  key: "update-settlement",
                                  permission:
                                      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_SETTLE,
                                  icon: <EditOutlined />,
                                  onClick: async () => {
                                      Modal.confirm({
                                          title: "Do you want to settle this invoice?",
                                          content:
                                              "Select Ok, otherwise the process cannot success.",
                                          async onOk() {
                                              try {
                                                  await settleInvoice(
                                                      record.getId(),
                                                      "customer",
                                                  );
                                                  showSuccessAlert(
                                                      `Successfully Settled Customer invoice`,
                                                  );
                                              } catch (error) {
                                                  showError(error);
                                              }
                                          },
                                          onCancel() {},
                                      });
                                  },
                                  style: { margin: "5px", padding: "8px 16px" },
                              },
                          ]
                        : []), // Only include Settlement if overpaid > 0
                    ...(shouldDisplayPayMenuItem(record)
                        ? [
                              {
                                  label: isLoadingSession
                                      ? "Wait..."
                                      : "Pay Customer Invoice",
                                  key: "pay-customer-invoice",
                                  permission:
                                      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                                  icon: <PayCircleOutlined />,
                                  onClick: async () => {
                                      handlePayCustomerInvoice(
                                          record.getRegion(),
                                          [record.getId()],
                                          customerId,
                                      );
                                  },
                                  disabled: isLoadingSession,
                                  style: { margin: "5px", padding: "8px 16px" },
                              },
                          ]
                        : []), // Only include Pay if due > 0
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
                        label: isRecalculating ? "Wait...." : "Re-calculate",
                        key: "re-calculate",
                        icon: <CalculatorFilled />,
                        onClick: handleRecalculate,
                        disabled: isRecalculating,
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                        label: isLoadingSession
                            ? "Wait..."
                            : "Download Invoice",
                        key: "download-invoice",
                        icon: <DownloadOutlined />,
                        onClick: handleDownloadInvoice,
                        disabled: isLoadingSession,
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];

                if (isMobile) {
                    menuItems.push(
                        {
                            permission:
                                ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                            label: "Details",
                            key: "details",
                            icon: <EyeFilled />,
                            onClick: () => handleShowDetail(record.getId()),
                            style: { margin: "5px", padding: "8px 16px" },
                        },
                        {
                            permission:
                                ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                            label: "Invoice",
                            key: "details-invoice",
                            icon: <PrinterOutlined />,
                            onClick: () => handleShowInvoice(record.getId()),
                            style: { margin: "5px", padding: "8px 16px" },
                        },
                    );
                }

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(
                                    x.permission as IAuthorityType,
                                    x,
                                    null,
                                ),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}></Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Customer Invoices"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                >
                    <FiltersComponent
                        handleProductFilter={handleProductFilter}
                        handleFilterChange={handleFilterChange}
                        handelFilterClear={handelFilterClear}
                        isFetched={isFetched}
                        filters={filters}
                        filtersData={filterData}
                        isFromProductReceived={true}
                    />
                    {hasSelected && (
                        <div
                            style={{
                                margin: "20px 0",
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ marginBottom: 12 }}>
                                <Tabs
                                    defaultActiveKey={
                                        Object.keys(groupedByRegion)[0]
                                    }
                                >
                                    {Object.entries(groupedByRegion).map(
                                        ([
                                            regionName,
                                            {
                                                totalAmount,
                                                paid,
                                                due,
                                                overpaid,
                                                currencySymbol,
                                                count,
                                            },
                                        ]) => (
                                            <TabPane
                                                tab={
                                                    <span>
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {regionName}({count}
                                                            )
                                                        </Text>
                                                    </span>
                                                }
                                                key={regionName}
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <Paragraph>
                                                        <Text>Total : </Text>
                                                        <Tag
                                                            color="#008000"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {totalAmount.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>
                                                    <Paragraph>
                                                        <Text>
                                                            Total Paid :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="green"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {paid.toFixed(2)}
                                                        </Tag>
                                                    </Paragraph>

                                                    <Paragraph>
                                                        <Text>
                                                            Total Due :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="orange"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {due.toFixed(2)}
                                                        </Tag>
                                                    </Paragraph>

                                                    <Paragraph>
                                                        <Text>
                                                            Total Overpaid :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="red"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {overpaid.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>
                                                </div>
                                            </TabPane>
                                        ),
                                    )}
                                </Tabs>
                            </div>
                            <Dropdown
                                menu={{
                                    items: getBulkActions(selectedRows),
                                }}
                                disabled={selectedRowKeys.length === 0}
                            >
                                <Button icon={<HddFilled />}>
                                    Bulk Actions
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    )}

                    <div className="responsive-table-container">
                        <Table
                            loading={isLoading}
                            bordered
                            setSelectedRowKeys={setSelectedRowKeys}
                            selectedRowKeys={selectedRowKeys}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedRows(selectedRows);
                                    setSelectedRowKeys(selectedRowKeys);
                                },
                            }}
                            rowKey="id"
                            dataSource={InvoiceCollectionData?.data}
                            //@ts-ignore
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 1600 }}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                width={"60%"}
                title="Pay Customer Invoice"
                open={Boolean(selectedPayInvoiceData)}
                onCancel={() => setSelectedPayInvoiceData(undefined)}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingSession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>
        </>
    );
};

export default CustomerInvoiceList;
