import {
    IWallet,
    IWalletCollection,
    IWalletOverview,
} from "@/types/walletOverviewCollection";
import { CurrencyModel } from "./currencyCollectionModel";

export class WalletModel {
    public readonly object: string;
    public readonly id: number;
    public readonly balance: number;
    public readonly currency: CurrencyModel;

    constructor(data: IWallet) {
        this.object = data.object;
        this.id = data.id;
        this.balance = data.balance;
        this.currency = new CurrencyModel(data.currency);
    }

    getId() {
        return this.id;
    }
    getBalance() {
        return this.balance;
    }
    getCurrency() {
        return this.currency;
    }
}

export class WalletCollectionModel {
    public readonly object: string;
    public readonly data: WalletModel[];
    constructor(data: IWalletCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new WalletModel(item));
    }

    getData() {
        return this.data;
    }

    getWalletById(id: string): WalletModel | undefined {
        return this.data.find((item) => item.getId() === Number(id));
    }
}

export class WalletOverviewModel {
    public readonly object: string;
    public readonly available: WalletCollectionModel;
    public readonly reserved: WalletCollectionModel;
    public readonly pending: WalletCollectionModel;

    constructor(data: IWalletOverview) {
        this.object = data.object;
        this.available = new WalletCollectionModel(data.available);
        this.reserved = new WalletCollectionModel(data.reserved);
        this.pending = new WalletCollectionModel(data.pending);
    }

    getAvailable() {
        return this.available;
    }
    getReserved() {
        return this.reserved;
    }
    getPending() {
        return this.pending;
    }

    getAvailableById(id: number): WalletModel | undefined {
        return this.getAvailable()
            .getData()
            .find((item) => item.getId() === id);
    }
    getReservedById(id: number): WalletModel | undefined {
        return this.getReserved()
            .getData()
            .find((item) => item.getId() === id);
    }
    getPendingById(id: number): WalletModel | undefined {
        return this.getPending()
            .getData()
            .find((item) => item.getId() === id);
    }

    getAvailableByCurrencyCode(code: string): WalletModel | undefined {
        return this.getAvailable()
            .getData()
            .find((item) => item.getCurrency().getCode() === code);
    }

    getByCurrency(): Record<
        string,
        {
            totalAvailableBalance: number;
            totalReservedBalance: number;
            totalPendingBalance: number;
            currency: CurrencyModel;
            walletIds: number[];
        }
    > {
        const allWallets = [
            ...this.available.getData(),
            ...this.reserved.getData(),
            ...this.pending.getData(),
        ];

        const walletsByCurrency: Record<
            string,
            {
                totalAvailableBalance: number;
                totalReservedBalance: number;
                totalPendingBalance: number;
                currency: CurrencyModel;
                walletIds: number[];
            }
        > = {};

        allWallets.forEach((wallet) => {
            const currencyCode = wallet.getCurrency().code;
            const balance = wallet.getBalance();
            const currency = wallet.getCurrency();
            if (!walletsByCurrency[currencyCode]) {
                walletsByCurrency[currencyCode] = {
                    totalAvailableBalance: 0,
                    totalReservedBalance: 0,
                    totalPendingBalance: 0,
                    currency: currency,
                    walletIds: [],
                };
            }
            if (this.available.getData().includes(wallet)) {
                walletsByCurrency[currencyCode].totalAvailableBalance +=
                    balance;
            } else if (this.reserved.getData().includes(wallet)) {
                walletsByCurrency[currencyCode].totalReservedBalance += balance;
            } else if (this.pending.getData().includes(wallet)) {
                walletsByCurrency[currencyCode].totalPendingBalance += balance;
            }
            walletsByCurrency[currencyCode].walletIds.push(wallet.getId());
        });

        return walletsByCurrency;
    }
}

// export class WalletOverviewCollectionModel {
//   public readonly object: string
//   public readonly data: WalletOverviewModel[]
//   public readonly pagination: IPagination
//   public readonly filters: IFilterType

//   constructor(data: IWalletOverviewCollection) {
//     this.object = data.object
//     this.data = data.data.map((item) => new WalletOverviewModel(item))
//     this.pagination = data.pagination
//     this.filters = data.filters
//   }

//   getData() {
//     return this.data
//   }

//   getPainations() {
//     return this.pagination
//   }
//   getFilters() {
//     return this.filters
//   }
// }
