import { IPagination } from "@/types/pagination";
import { IFilterType } from "@/types/filters";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { TakeActionsEnums } from "@/enums/takeActionsEnums";

import {
    Address,
    BuyProductPackage,
    IBuyProductVariation,
    ICustomerInvoice,
    IBuyProduct,
    IBuyProductCollection,
    IBuyProductMoveOnShipping,
    IBuyOrder,
    ProductSeller,
    IBuyProductVariationPropertiesCollection,
    IBuyProductVariationProperty,
    IBuyProductAgentAssociation,
    IBuyActionCollection,
    IAgentCategoryPriceRead,
    ShippingSlotCollection,
    ShippingSlot,
    IOrderHandler,
    IActionType,
    IRegisteredCountryDetail,
    IBuyAction,
    IAgentInvoice,
    IShippingSlot,
    IBuyProductCustomerAssociation,
    IBuyProductPackage,
    IShippingSlotCollection,
} from "@/types/buyProductCollection";
import { PaginationModel } from "./paginationModel";
import { StoreModel } from "./storeCollectionModel";
import { IRoleCollection, IUser } from "@/types/userCollection";
import { BuyProductChargeModel } from "./buyActionCollectionModel";
import { IVariant, SequenceSlot } from "@/types/buyOrderCollection";
import { IAddress } from "@/types/shipmentProductCollection";
import { ShipmentProductModel } from "./shipmentProductCollectionModel";
import { IShippingCategory } from "@/types/shippingCategory";
import { RegionModel } from "./regionCollectionModel";
import { IAgentWarehouse } from "@/types/agentWarehouseCollection";
import { IShippingCountry } from "@/types/shippingCountryCollection";
import { IDestinationWarehouse } from "@/types/destinationWarehouseCollection";
import { ShippingCountryModel } from "./shippingCountryCollectionModel";

import { IAgentCompany } from "@/types/agentCompanyCollection";
import { AgentCompanyModel } from "./shippingAddressModel";
import {
    buyProductStatusEnum,
    buyProductVariationFulfilmentStatusEnum,
} from "@/enums/buyProductEnum";
import { SessionInvoiceTransactionSummaryModel } from "./paymentSessionModel";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
import { UnitTypeEnum } from "@/enums/shipForMeContextEnums";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { CustomerUserModel } from "./customerUserCollectionModel";
import { BuyProductChargeStatusEnum } from "@/types/buyActionCollection";
import { TransactionsModel } from "./invoiceCollectionModel";
import { ShipmentTrackingModel } from "./shipmentProductPackageCollection";
import { CurrencyCode } from "@/enums/currencyCollectionEnums";
import { ShippingModeEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
export class CustomerInvoiceModel {
    object: "Invoice";
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: SessionInvoiceTransactionSummaryModel;
    customer?: CustomerModel;
    created_at: string;
    transactions?: TransactionsModel[];

    constructor(payload: ICustomerInvoice) {
        this.object = payload.object;
        this.id = payload.id;
        this.invoice_number = payload.invoice_number;
        this.status = payload.status;
        this.total_amount = payload.total_amount;
        this.initial_amount = payload.initial_amount;
        this.refundable_amount = payload.refundable_amount;
        this.transaction_summary = new SessionInvoiceTransactionSummaryModel(
            payload.transaction_summary,
        );
        this.created_at = payload.created_at;
        this.customer = payload.customer && new CustomerModel(payload.customer);
        this.transactions =
            payload.transactions &&
            payload.transactions.data.map((t) => new TransactionsModel(t));
    }

    getId() {
        return this.id;
    }

    getCustomer() {
        return this.customer;
    }
    getInvoiceNumber() {
        return this.invoice_number;
    }

    getStatus() {
        return this.status;
    }

    getInitialAmount() {
        return this.initial_amount;
    }

    getRefundableAmount() {
        return this.refundable_amount;
    }

    getTransactionSummary() {
        return this.transaction_summary;
    }

    getTotalAmount(): number {
        return this.total_amount ? this.total_amount : 0;
    }
    getDue(): number {
        return this.transaction_summary ? this.transaction_summary.due : 0;
    }
    getSettled(): number {
        return this.transaction_summary ? this.transaction_summary.due : 0;
    }

    getPaid(): number {
        return this.transaction_summary ? this.transaction_summary.paid : 0;
    }
    getOverPaid(): number {
        return this.transaction_summary
            ? this.transaction_summary.overpaid ?? 0
            : 0;
    }
    isOverPaid(): boolean {
        return this.getOverPaid() > 0;
    }
    isDue(): boolean {
        return this.getDue() > 0;
    }

    getCreatedAt() {
        return this.created_at;
    }
    getTransactions(): TransactionsModel[] {
        return this.transactions ? this.transactions : [];
    }
    hasTransactions(): boolean {
        return this.transactions ? this.transactions.length > 0 : false;
    }

    calculatePaidPercentage(): number {
        return this.total_amount
            ? (this.transaction_summary.paid / this.total_amount) * 100
            : 0;
    }
}

export class AgentAddressModel {
    readonly city: string;
    readonly state: string;
    readonly postal: string;
    readonly country: string;
    readonly address_1: string;
    readonly address_2: string;
    readonly area: string;
    readonly email: string;
    readonly phone: string;
    readonly district: string;
    readonly last_name: string;
    readonly first_name: string;
    readonly postal_code: string;

    constructor(payload: IAddress) {
        this.city = payload.city;
        this.state = payload.state;
        this.postal = payload.postal;
        this.country = payload.country;
        this.address_1 = payload.address_1;
        this.address_2 = payload.address_2;
        this.area = payload.area;
        this.email = payload.email;
        this.phone = payload.phone;
        this.district = payload.district;
        this.last_name = payload.last_name;
        this.first_name = payload.first_name;
        this.postal_code = payload.postal_code;
    }
}

export class RegisteredCountryDetailModel {
    id: number;
    code: string;
    name: string;
    status: string;
    created_at: string;

    constructor(payload: IRegisteredCountryDetail) {
        this.id = payload.id;
        this.code = payload.code;
        this.name = payload.name;
        this.status = payload.status;
        this.created_at = payload.created_at;
    }
}

export class BuyProductAgentAssociationModel {
    readonly object: "BuyProductAgentAssociation";
    readonly id: number;
    readonly buy_product_id: number;
    readonly agent_company_id: number;
    readonly agent_company: Omit<IAgentCompany, "purchase_commissions">;
    readonly include_tax: number;
    readonly sub_total: number;
    readonly tax_total: number;
    readonly total: number;
    readonly original_sub_total: number;
    readonly original_total: number;
    readonly discount_total: number;
    readonly fx_rate: number;
    readonly commission_rate: number;
    readonly commission_rate_type: "percentage" | "fixed";
    readonly purchase_note: string | null;
    readonly vendor_order_id: string | null;
    readonly created_at: string;

    constructor(payload: IBuyProductAgentAssociation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.agent_company_id = payload.agent_company_id;
        this.agent_company = payload.agent_company;
        this.include_tax = payload.include_tax;
        this.sub_total = payload.sub_total;
        this.tax_total = payload.tax_total;
        this.total = payload.tax_total;
        this.original_sub_total = payload.original_sub_total;
        this.original_total = payload.original_total;
        this.discount_total = payload.discount_total;
        this.fx_rate = payload.fx_rate;
        this.commission_rate = payload.commission_rate;
        this.commission_rate_type = payload.commission_rate_type;
        this.purchase_note = payload.purchase_note;
        this.vendor_order_id = payload.vendor_order_id;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getAgentCompany(): AgentCompanyModel {
        return new AgentCompanyModel(this.agent_company);
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getOriginalSubTotal() {
        return this.original_sub_total;
    }

    getOriginalTotal() {
        return this.original_total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class BuyProductCollectionModel {
    object: "BuyProductCollection";
    buyProducts: BuyProductModel[];
    data: IBuyProduct[];
    pagination: IPagination;
    filters: IFilterType;

    constructor(payload: IBuyProductCollection) {
        this.object = payload.object;
        this.buyProducts = payload.data.map(
            (buyProduct) => new BuyProductModel(buyProduct),
        );
        this.data = payload.data;
        this.pagination = payload.pagination;
        this.filters = payload.filters;
    }

    getData(): BuyProductModel[] {
        return this.buyProducts;
    }
    getPagination() {
        return new PaginationModel(this.pagination);
    }

    getFilters() {
        return this.filters;
    }
}

export class ActionTypeModel {
    label: string;
    value: string;

    constructor(payload: IActionType) {
        this.label = payload.label;
        this.value = payload.value;
    }
}

export class BuyActionModel {
    object: string;
    id: number;
    action_number: string;
    buy_product_id: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    action_type: ActionTypeModel[];
    created_at: string;

    constructor(payload: IBuyAction) {
        this.object = payload.object;
        this.id = payload.id;
        this.action_number = payload.action_number;
        this.buy_product_id = payload.buy_product_id;
        this.status = payload.status;
        this.admin_note = payload.admin_note;
        this.customer_note = payload.customer_note;
        this.agent_note = payload.agent_note;
        this.action_type = payload.action_type.map(
            (item) => new ActionTypeModel(item),
        );
        this.created_at = payload.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getActionNumber() {
        return this.action_number;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getStatus() {
        return this.status;
    }

    getAdminNote() {
        return this.admin_note;
    }

    getCustomerNote() {
        return this.customer_note;
    }

    getAgentNote() {
        return this.agent_note;
    }

    getActionType() {
        return this.action_type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class BuyProductVariationsModel {
    readonly object: "BuyProductVariation";
    readonly id: number;
    readonly buy_product_id: number;
    readonly sku_id: string;
    readonly image: string;
    readonly quantity: number;
    readonly unit_price: number;
    readonly total_price: number;
    readonly original_unit_price: number;
    readonly original_total_price: number;
    readonly properties: IBuyProductVariationPropertiesCollection;
    private sku_original_id: string;
    private fulfilment_status: buyProductVariationFulfilmentStatusEnum;

    constructor(payload: IBuyProductVariation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.sku_id = payload.sku_id;
        this.image = payload.image;
        this.quantity = payload.quantity;
        this.unit_price = payload.unit_price;
        this.total_price = payload.total_price;
        this.original_unit_price = payload.original_unit_price;
        this.original_total_price = payload.original_total_price;
        this.properties = payload.properties;
        this.sku_original_id = payload.sku_original_id;
        this.fulfilment_status = payload.fulfilment_status;
    }

    getSkuOriginalId() {
        return this.sku_original_id;
    }
    getFulfillmentStatus() {
        return this.fulfilment_status;
    }
    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getSkuId() {
        return this.sku_id;
    }

    getImage() {
        return this.image;
    }

    getQuantity() {
        return this.quantity;
    }

    getUnitPrice() {
        return this.unit_price;
    }

    getTotalPrice() {
        return this.total_price;
    }

    getOriginalUnitPrice() {
        return this.original_unit_price;
    }

    getOriginalTotalPrice() {
        return this.original_total_price;
    }

    getProperties(): BuyProductVariationPropertyModel[] {
        return this.properties.data.map(
            (p) => new BuyProductVariationPropertyModel(p),
        );
    }

    getPropertyImageUrl() {
        const propertiesData = this.getProperties();

        if (propertiesData?.length > 0) {
            const propertyWithImage = propertiesData.find(
                (item) =>
                    item.getPropertyValueImage() ||
                    item.getPropertyValueThumb(),
            );

            if (propertyWithImage) {
                return (
                    propertyWithImage.getPropertyValueImage() ||
                    propertyWithImage.getPropertyValueThumb()
                );
            }
        }

        return this.image;
    }
    isValidStatusForCalculate(): boolean {
        return (
            this.getFulfillmentStatus() ===
                buyProductVariationFulfilmentStatusEnum.AWAITING_FULFILMENT ||
            this.getFulfillmentStatus() ===
                buyProductVariationFulfilmentStatusEnum.FULFILLED
        );
    }
}

export class BuyProductVariationPropertyModel {
    object: "BuyProductVariationProperty";
    id: number;
    property_id: string;
    property_name: string | null;
    property_original_name: string | null;
    property_value_id: string;
    property_value_name: string | null;
    property_value_original_name: string | null;
    property_value_color: string | null;
    property_value_title: string | null;
    property_value_image: string;
    property_value_thumb: string;
    constructor(payload: IBuyProductVariationProperty) {
        this.object = payload.object;
        this.id = payload.id;
        this.property_id = payload.property_id;
        this.property_name = payload.property_name;
        this.property_original_name = payload.property_original_name;
        this.property_value_id = payload.property_value_id;
        this.property_value_name = payload.property_value_name;
        this.property_value_original_name =
            payload.property_value_original_name;
        this.property_value_color = payload.property_value_color;
        this.property_value_title = payload.property_value_title;
        this.property_value_image = payload.property_value_image;
        this.property_value_thumb = payload.property_value_thumb;
    }

    getId() {
        return this.id;
    }

    getPropertyId() {
        return this.property_id;
    }

    getPropertyName() {
        return this.property_name;
    }

    getPropertyOriginalName() {
        return this.property_original_name;
    }

    getPropertyValueId() {
        return this.property_value_id;
    }

    getPropertyValueName() {
        return this.property_value_name;
    }
    getPropertyValueOriginalName() {
        return this.property_value_original_name;
    }

    getPropertyValueColor() {
        return this.property_value_color;
    }

    getPropertyValueTitle() {
        return this.property_value_title;
    }

    getPropertyValueImage() {
        return this.property_value_image;
    }

    getImageUrl() {
        return this.property_value_image || this.property_value_thumb;
    }

    getPropertyValueThumb() {
        return this.property_value_thumb;
    }
}

export class BuyProductModel {
    readonly object: "BuyProduct";
    readonly id: number;
    readonly product_number: string;
    readonly product_id: string;
    readonly product_vid: string;
    readonly cart_item_id: number;
    readonly product_title: string;
    readonly product_image: string;
    readonly product_link: string;
    readonly shipping_provider: string;
    readonly total_quantity: number;
    readonly source_type: string;
    readonly source_id: number;
    readonly status: buyProductStatusEnum;
    readonly shipping_address: Address;
    readonly product_seller: ProductSeller;
    readonly created_at: string;
    readonly buy_product_package: BuyProductPackage;
    readonly reject_reason?: string;
    readonly cancel_reason?: string;
    readonly user?: CustomerUserModel;
    readonly order?: BuyOrderModel;
    readonly store?: StoreModel;
    readonly region?: RegionModel;
    readonly product_customer_association?: IBuyProductCustomerAssociation;
    readonly product_agent_association?: BuyProductAgentAssociationModel;
    readonly shipment_product?: ShipmentProductModel;
    readonly customer_invoice?: ICustomerInvoice;
    readonly agent_invoice?: IAgentInvoice;
    readonly buy_actions?: IBuyActionCollection;
    readonly charges?: BuyProductChargeModel[];
    readonly moveon_shipping?: MoveonShippingModel;
    private product_variations: BuyProductVariationsModel[];
    readonly customer_note: string | null;
    readonly admin_note?: string;
    request_context?: string;

    constructor(payload: IBuyProduct) {
        this.object = payload.object;
        this.id = payload.id;
        this.product_number = payload.product_number;
        this.product_id = payload.product_id;
        this.product_vid = payload.product_vid;
        this.cart_item_id = payload.cart_item_id;
        this.product_title = payload.product_title;
        this.product_image = payload.product_image;
        this.product_link = payload.product_link;
        this.shipping_provider = payload.shipping_provider;
        this.total_quantity = payload.total_quantity;
        this.agent_invoice = payload.agent_invoice;
        this.source_type = payload.source_type;
        this.source_id = payload.source_id;
        this.status = payload.status;
        this.reject_reason = payload.reject_reason ? payload.reject_reason : "";
        this.cancel_reason = payload.cancel_reason;
        this.region = payload.region && new RegionModel(payload.region);
        this.order = payload.order && new BuyOrderModel(payload.order);
        this.shipping_address = payload.shipping_address;
        this.user = payload.user && new CustomerUserModel(payload.user);
        this.customer_note = payload.customer_note && payload.customer_note;
        this.store = payload.store && new StoreModel(payload.store);
        this.product_seller = payload.product_seller;
        this.product_customer_association =
            payload.product_customer_association;
        this.product_agent_association =
            payload.product_agent_association &&
            new BuyProductAgentAssociationModel(
                payload.product_agent_association,
            );
        this.shipment_product =
            payload.shipment_product &&
            new ShipmentProductModel(payload.shipment_product);
        this.buy_product_package = payload.buy_product_package;
        this.customer_invoice = payload?.customer_invoice;
        this.buy_actions = payload.buy_actions;
        this.moveon_shipping =
            payload.moveon_shipping &&
            new MoveonShippingModel(payload.moveon_shipping);
        this.created_at = payload.created_at;
        this.charges =
            payload.charges &&
            payload.charges.data.map((c) => new BuyProductChargeModel(c));
        this.product_variations = payload.product_variations
            ? payload.product_variations.data.map(
                  (v) => new BuyProductVariationsModel(v),
              )
            : [];
        this.request_context = payload.request_context;
        this.admin_note = payload.admin_note;
    }

    getId() {
        return this.id;
    }

    getRequestContext = () => this.request_context;
    getAdminNote = () => this.admin_note || "";

    getShippingPrice(): number {
        const shippingPrice = this.getCustomerInvoice()
            ? (this.getCustomerInvoice()?.getTotalAmount() ?? 0) -
              this.getBuyingPrice()
            : 0;

        return shippingPrice;
    }

    getBuyingPrice(): number {
        if (this.getRegionCurrencyCode() === CurrencyCode.BDT) {
            return Math.round(this.getTotalRegionalPriceCalculated());
        }
        return this.getTotalRegionalPriceCalculated();
    }
    getProfit(): number {
        if (this.getCustomerInvoice() && this.getAgentInvoice()) {
            return (
                (this.getCustomerInvoice()?.getTotalAmount() ?? 0) -
                (this.getAgentInvoice()?.getTotalAmount() ?? 0)
            );
        } else {
            return 0;
        }
    }

    isCanTakeActions(): boolean {
        return (
            this.status === "buy-processing" ||
            this.status === "buy-purchased" ||
            this.status === "buy-not-purchased" ||
            this.status === "buy-approved"
        );
    }

    isCanCancel(): boolean {
        return (
            this.status === "buy-processing" ||
            this.status === "buy-purchased" ||
            this.status === "buy-not-purchased" ||
            this.status === "buy-approved" ||
            this.status === "buy-handover-to-shipping" ||
            this.status === "buy-pending"
        );
    }

    isCanUpdateCurrentFXShippingChange(): boolean {
        return (
            this.status === "buy-processing" ||
            this.status === "buy-not-purchased" ||
            this.status === "buy-approved" ||
            this.status === "buy-pending" ||
            this.status === "buy-reject"
        );
    }

    getRejectReason() {
        return this.reject_reason;
    }
    getCancelReason() {
        return this.cancel_reason;
    }

    getProductNumber() {
        return this.product_number;
    }
    getCustomerFx(): number {
        return this.product_customer_association
            ? this.product_customer_association.fx_rate
            : 0;
    }
    getCustomerCommissionRateType(): CommissionRateTypeEnums | undefined {
        return (
            this.product_customer_association &&
            this.product_customer_association.commission_rate_type
        );
    }
    getCustomerCommissionRate(): number {
        return this.product_customer_association
            ? this.product_customer_association.commission_rate
            : 0;
    }
    isCustomerCommissionRatePercentage(): boolean {
        return this.product_customer_association
            ? this.product_customer_association.commission_rate_type ===
                  CommissionRateTypeEnums.PERCENTAGE
            : false;
    }
    getCustomerTotalPercentageCommissionRate(): number {
        if (this.isCustomerCommissionRatePercentage()) {
            const total =
                this.getCustomerFx() +
                (this.getCustomerFx() * this.getCustomerCommissionRate()) / 100;
            return total;
        } else {
            return 0;
        }
    }

    getCustomerTotalCommissionAndFx = (): number => {
        let total: number = 0;

        if (this.isCustomerCommissionRatePercentage()) {
            total = this.getCustomerTotalPercentageCommissionRate();
        } else if (
            this.getCustomerCommissionRateType() ===
            CommissionRateTypeEnums.FIXED
        ) {
            total = this.getCustomerFx() + this.getCustomerCommissionRate();
        }
        return total;
    };

    getIsAgentAssociationAvailable(): boolean {
        return this.product_agent_association !== null;
    }

    getAgentFx(): number {
        return this.product_agent_association
            ? this.product_agent_association.fx_rate
            : 0;
    }
    getAgentCommissionRateType() {
        return (
            this.product_agent_association &&
            this.product_agent_association.commission_rate_type
        );
    }

    getProductVariations(): BuyProductVariationsModel[] {
        return this.product_variations;
    }

    isShowProductVariationsSummary() {
        return (
            this.getTotalQuantityCalculated() ||
            this.getTotalRegionalPriceCalculated() ||
            this.getTotalOriginalPriceCalculated()
        );
    }

    getTotalQuantityCalculated(): number {
        return this.getProductVariations().reduce((total, variation) => {
            return variation.isValidStatusForCalculate()
                ? total + variation.quantity
                : total;
        }, 0);
    }

    getTotalRegionalPriceCalculated(): number {
        const variationTotal = this.getProductVariations().reduce(
            (total, variation) => {
                return variation.isValidStatusForCalculate()
                    ? total + variation.getTotalPrice()
                    : total;
            },
            0,
        );

        const chargesTotal =
            this.charges && this.charges.length > 0
                ? this.charges
                      .filter(
                          (charge) =>
                              charge.getFulfilmentStatus() !==
                              BuyProductChargeStatusEnum.CANCELLED,
                      )
                      ?.reduce((acc, curr) => acc + curr.getTotalAmount(), 0)
                : 0;
        return variationTotal + chargesTotal;
    }

    getTotalOriginalPriceCalculated(): number {
        const variationTotal = this.getProductVariations().reduce(
            (total, variation) => {
                return variation.isValidStatusForCalculate()
                    ? total + variation.getOriginalTotalPrice()
                    : total;
            },
            0,
        );

        const chargesTotal =
            this.charges && this.charges.length > 0
                ? this.charges
                      .filter(
                          (charge) =>
                              charge.getFulfilmentStatus() !==
                              BuyProductChargeStatusEnum.CANCELLED,
                      )
                      ?.reduce((acc, curr) => acc + curr.getOriginalAmount(), 0)
                : 0;
        return variationTotal + chargesTotal;
    }

    getProductImage(): string {
        return this.product_image;
    }

    getStoreName(): string {
        return this.store ? this.store.name : "";
    }

    getTotalAmount(): number {
        return this.customer_invoice ? this.customer_invoice.total_amount : 0;
    }
    getDue(): number {
        return this.customer_invoice
            ? this.customer_invoice.transaction_summary.due
            : 0;
    }
    getRefundableAmount(): number {
        return this.customer_invoice
            ? this.customer_invoice.refundable_amount
            : 0;
    }
    getPaid(): number {
        return this.customer_invoice
            ? this.customer_invoice.transaction_summary.paid
            : 0;
    }
    getOverPaid(): number {
        return this.customer_invoice
            ? this.customer_invoice.transaction_summary.overpaid
            : 0;
    }
    getPaidPercentage(): number {
        return this.customer_invoice &&
            this.getTotalAmount() &&
            this.customer_invoice?.transaction_summary
            ? (this.customer_invoice.transaction_summary.paid /
                  this.getTotalAmount()) *
                  100
            : 0;
    }

    getOriginalCurrencyCode(): string | undefined {
        return this.store ? this.store.default_currency?.code : "";
    }

    getPurchaseCompanyName(): string | undefined {
        return this.product_agent_association &&
            this.product_agent_association.agent_company &&
            this.product_agent_association.agent_company
            ? this.product_agent_association.agent_company.primary_name
            : undefined;
    }

    getShippingRateUnitType(): string | undefined {
        return this.moveon_shipping && this.moveon_shipping.unit_type;
    }

    getAmountByUnitType = () => {
        const customerAssociation =
            this.getShipmentProduct()?.getCustomerAssociation();

        if (customerAssociation) {
            const unitType = customerAssociation.getUnitType();
            if (unitType === UnitTypeEnum.KG)
                return customerAssociation.getWeight() || 0;
            if (unitType === UnitTypeEnum.CBM)
                return customerAssociation.getCbm() || 0;
            if (unitType === UnitTypeEnum.PIECE)
                return customerAssociation.getPiece() || 0;
        }

        return 0;
    };

    getShippingRate(): number | undefined {
        const shipment_product = this.getShipmentProduct();
        const customer_association = shipment_product?.getCustomerAssociation();
        const moveon_shipping = this.getMoveonShipping();
        if (shipment_product && customer_association) {
            const amount = this.getAmountByUnitType();

            if (amount) {
                const matchedSlot = customer_association
                    .getAssociationSlots()
                    .find(
                        (slot) =>
                            slot.min_amount <= amount &&
                            slot.max_amount >= amount,
                    );
                return matchedSlot
                    ? roundToTwoDecimal(matchedSlot.getRate())
                    : roundToTwoDecimal(customer_association.getRate());
            } else return roundToTwoDecimal(customer_association.getRate());
        } else if (moveon_shipping) {
            const rate = moveon_shipping.getRate();
            const commission_rate = moveon_shipping.getCommissionRate();
            const commission_rate_type =
                moveon_shipping.getCommissionRateType();
            const final_commission_rate =
                commission_rate_type === RateTypeEnums.FIXED
                    ? commission_rate
                    : (commission_rate / 100) * rate;
            return roundToTwoDecimal(rate + final_commission_rate);
        }
    }
    getShippingMode(): string | undefined {
        return this.moveon_shipping && this.moveon_shipping.shipping_mode;
    }
    getShippingType(): string | undefined {
        return this.moveon_shipping && this.moveon_shipping.shipping_type;
    }

    getShipBy(): string | undefined {
        return (
            (this.moveon_shipping &&
                this.getMoveonShipping()?.getAgentWarehouse().agent_company
                    .primary_name) ||
            "N/A"
        );
    }
    getSourceName(): string | undefined {
        return (
            this.getMoveonShipping()
                ?.getShippingCountry()
                .getCountry()
                ?.getName() || "N/A"
        );
    }
    // getDestinationCountryId() {
    //     return (
    //         this.moveon_shipping &&
    //         this.moveon_shipping?.destination_warehouse?.id
    //     );
    // }
    getDestinationWarehouseId() {
        return (
            this.moveon_shipping &&
            this.moveon_shipping.destination_warehouse.id
        );
    }
    getDestinationWarehouseName(): string | undefined {
        return (
            this.moveon_shipping &&
            this.moveon_shipping.destination_warehouse.name
        );
    }
    getDestinationWarehouseFlag(): string | undefined {
        return (
            this.moveon_shipping &&
            this.moveon_shipping.destination_warehouse &&
            this.moveon_shipping.destination_warehouse.country?.flag
        );
    }
    getBuyActions(): BuyActionModel[] {
        return this.buy_actions
            ? this.buy_actions.data.map((action) => new BuyActionModel(action))
            : [];
    }
    getCharges(): BuyProductChargeModel[] | undefined {
        return this.charges;
    }

    getCustomerNote(): string | null {
        return this.customer_note;
    }

    getProductId() {
        return this.product_id;
    }

    getProductVId() {
        return this.product_vid;
    }

    getCartItemId() {
        return this.cart_item_id;
    }

    getProductTitle() {
        return this.product_title;
    }

    getProductLink() {
        return this.product_link;
    }

    getShippingProvider() {
        return this.shipping_provider;
    }

    getTotalQuantity() {
        return this.total_quantity;
    }

    getStatus() {
        return this.status;
    }

    isEditable(): boolean {
        switch (this.status) {
            case buyProductStatusEnum.BUY_APPROVED:
            case buyProductStatusEnum.BUY_PENDING:
            case buyProductStatusEnum.BUY_REJECT:
            case buyProductStatusEnum.BUY_PROCESSING:
            case buyProductStatusEnum.BUY_NOT_PURCHASED:
                return true;
            default:
                return false;
        }
    }

    isNotEditable(): boolean {
        return !this.isEditable();
    }

    getOrder() {
        return this.order;
    }

    getShippingAddress() {
        return this.shipping_address;
    }

    getStore() {
        return this.store && new StoreModel(this.store);
    }

    getRegion() {
        return this.region;
    }
    getRegionCurrencyCode() {
        return this.region ? this.region.currency?.code : "";
    }

    getProductSeller() {
        return this.product_seller;
    }

    getProductCustomerAssociation() {
        return (
            this.product_customer_association &&
            new BuyProductCustomerAssociationModel(
                this.product_customer_association,
            )
        );
    }

    getProductAgentAssociation(): BuyProductAgentAssociationModel | undefined {
        return this.product_agent_association;
    }

    getShipmentProduct(): ShipmentProductModel | undefined {
        return this.shipment_product;
    }
    getTrackings(): ShipmentTrackingModel[] {
        return this.shipment_product
            ? this.shipment_product
                  .getPackages()
                  .flatMap((item) => item.getTrackings())
            : [];
    }

    getBuyProductPackage(): BuyProductPackageModel {
        return new BuyProductPackageModel(this.buy_product_package);
    }

    getMoveonShipping(): MoveonShippingModel | undefined {
        return this.moveon_shipping;
    }

    getCreatedAt() {
        return this.created_at;
    }

    getCustomerInvoice() {
        return this.customer_invoice
            ? new CustomerInvoiceModel(this.customer_invoice)
            : null;
    }
    getAgentInvoice() {
        return (
            this.agent_invoice && new CustomerInvoiceModel(this.agent_invoice)
        );
    }
    getCustomerInvoiceId(): number | undefined {
        return this.customer_invoice?.id;
    }
    getAgentInvoiceId(): number | undefined {
        return this.agent_invoice?.id;
    }

    getUser() {
        return this.user;
    }
    getUserId(): number | undefined {
        return this.user && this.user.id;
    }
    getSourceType() {
        return this.source_type;
    }

    getLocalDeliveryCharge() {
        const localDeliveryChargeModel = this.charges
            ? this.charges.find(
                  (charge) =>
                      charge.getChargeType() === "local_delivery_charge",
              )
            : null;

        return localDeliveryChargeModel
            ? localDeliveryChargeModel.getOriginalAmount()
            : 0;
    }

    getTotalShipmentWeight() {
        const totalWeight =
            this.getShipmentProduct() &&
            this.getShipmentProduct()?.getTotalWeight()
                ? this.getShipmentProduct()?.getTotalWeight()
                : 0;

        return totalWeight || 0;
    }

    getShipmentWeightUnit() {
        const weightUnit =
            this.getShipmentProduct() &&
            this.getShipmentProduct()?.getCustomerAssociation()
                ? this.getShipmentProduct()
                      ?.getCustomerAssociation()
                      ?.getUnitType()
                : "";

        return weightUnit || "";
    }

    getTotalShipmentQuantity() {
        const quantity =
            this.getShipmentProduct() &&
            this.getShipmentProduct()?.getTotalQuantity()
                ? this.getShipmentProduct()?.getTotalWeight()
                : 0;

        return quantity || 0;
    }

    getTotalShippingCharge() {
        return this.getTotalShipmentWeight() * this.getTotalShipmentQuantity();
    }
}

export class ProductAgentAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    agent_company_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;
    purchase_note?: any;
    vendor_order_id?: any;
    created_at: string;

    constructor(payload: IBuyProductAgentAssociation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.agent_company_id = payload.agent_company_id;
        this.include_tax = payload.include_tax;
        this.sub_total = payload.sub_total;
        this.tax_total = payload.tax_total;
        this.total = payload.total;
        this.original_sub_total = payload.original_sub_total;
        this.original_total = payload.original_total;
        this.discount_total = payload.discount_total;
        this.fx_rate = payload.fx_rate;
        this.commission_rate = payload.commission_rate;
        this.commission_rate_type = payload.commission_rate_type;
        this.created_at = payload.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getOriginalSubTotal() {
        return this.original_sub_total;
    }

    getOriginalTotal() {
        return this.original_total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getPurchaseNote() {
        return this.purchase_note;
    }

    getVendorOrderId() {
        return this.vendor_order_id;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class CustomerModel {
    readonly id: number;
    readonly name: string;
    readonly email: string;
    readonly phone: string | null;
    readonly type: string;
    readonly is_active: number;
    readonly is_drop_user?: boolean;
    readonly shipping_mark?: string;
    readonly created_at: string;
    readonly emailVerifiedAt: string | null;
    readonly roles?: IRoleCollection | undefined;

    constructor(payload: IUser) {
        this.id = payload.id;
        this.name = payload.name;
        this.email = payload.email;
        this.phone = payload.phone;
        this.type = payload.type;
        this.is_active = payload.is_active;
        this.is_drop_user = payload.is_drop_user;
        this.shipping_mark = payload.shipping_mark;
        this.created_at = payload.created_at;
        this.emailVerifiedAt = payload.emailVerifiedAt;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getEmail(): string {
        return this.email;
    }

    getPhone(): string | null {
        return this.phone;
    }

    getType(): string {
        return this.type;
    }

    getIsActive(): number {
        return this.is_active;
    }

    getIsDropUser(): boolean | undefined {
        return this.is_drop_user;
    }

    getShippingMark(): string | undefined {
        return this.shipping_mark;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class BuyOrderModel {
    object: "BuyOrder";
    id: number;
    order_number: string;
    order_tag?: string | null;
    order_type: string;
    order_status: string;
    payment_status: string;
    note?: string | null;
    meta: any[];
    order_handler?: IOrderHandler;
    customer: CustomerUserModel;
    created_at: string;
    type: string;
    status: string;
    oauth_client_id: string;
    addresses: { [key: string]: string }[];

    constructor(payload: IBuyOrder) {
        this.object = payload.object;
        this.id = payload.id;
        this.order_number = payload.order_number;
        this.order_tag = payload.order_tag;
        this.order_type = payload.order_type;
        this.order_status = payload.order_status;
        this.payment_status = payload.payment_status;
        this.note = payload.note;
        this.meta = payload.meta;
        this.order_handler = payload.order_handler;
        this.customer =
            payload.customer && new CustomerUserModel(payload.customer);
        this.created_at = payload.created_at;
        this.type = payload.type;
        this.status = payload.status;
        this.oauth_client_id = payload.oauth_client_id;
        this.addresses = payload.addresses;
    }

    getId() {
        return this.id;
    }
    getCreatedAt() {
        return this.created_at;
    }

    getOrderNumber(): string {
        return this.order_number;
    }
    getPaymentStatus() {
        return this.payment_status;
    }
    getOrderStatus() {
        return this.order_status;
    }
    getOrderType() {
        return this.order_type;
    }

    getCustomer() {
        return this.customer;
    }

    getOrderHandler() {
        return this.order_handler && new OrderHandlerModel(this.order_handler);
    }
}

export class OrderHandlerModel {
    object: "User";
    id: number;
    name: string;
    phone: string | null;
    email: string;
    type: string;
    is_active: number;
    roles?: IRoleCollection;
    created_at: string;
    is_drop_user?: boolean | undefined;
    shipping_mark?: string | undefined;
    email_verified_at?: string;
    order_handler?: string;
    billing_addr_id?: string;
    shipping_addr_id?: string;
    agent_company_id?: string;
    lock_version?: string;
    etag?: string;
    gravatar?: string;

    constructor(payload: IOrderHandler) {
        this.object = payload.object;
        this.id = payload.id;
        this.name = payload.name;
        this.phone = payload.phone;
        this.email = payload.email;
        this.type = payload.type;
        this.is_active = payload.is_active;
        this.created_at = payload.created_at;
        this.is_drop_user = payload.is_drop_user;
        this.shipping_mark = payload.shipping_mark;

        this.shipping_mark = payload.shipping_mark;
        this.email_verified_at = payload.email_verified_at;
        this.order_handler = payload.order_handler;
        this.billing_addr_id = payload.billing_addr_id;
        this.shipping_addr_id = payload.shipping_addr_id;
        this.agent_company_id = payload.agent_company_id;
        this.lock_version = payload.lock_version;
        this.etag = payload.etag;
        this.gravatar = payload.gravatar;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getPhone() {
        return this.phone;
    }

    getEmail() {
        return this.email;
    }

    getEmailVerifiedAt() {
        return this.email_verified_at;
    }

    getType() {
        return this.type;
    }

    getIsDropUser() {
        return this.is_drop_user;
    }

    getOrderHandler() {
        return this.order_handler;
    }

    getBillingAddressId() {
        return this.billing_addr_id;
    }

    getShippingAddressId() {
        return this.shipping_addr_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getShippingMark() {
        return this.shipping_mark;
    }

    getIsActive() {
        return this.is_active;
    }

    getCreatedAt() {
        return this.created_at;
    }

    getLockVersion() {
        return this.lock_version;
    }

    getEtag() {
        return this.etag;
    }
}

export class BuyProductCustomerAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: CommissionRateTypeEnums;
    agent_company_id: number;
    created_at: string;
    purchase_note: string;
    vendor_order_id: string;

    constructor(payload: IBuyProductCustomerAssociation) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.include_tax = payload?.include_tax;
        this.sub_total = payload?.sub_total;
        this.tax_total = payload?.tax_total;
        this.total = payload?.total;
        this.original_sub_total = payload?.original_sub_total;
        this.original_total = payload?.original_total;
        this.discount_total = payload?.discount_total;
        this.fx_rate = payload?.fx_rate;
        this.commission_rate = payload?.commission_rate;
        this.commission_rate_type = payload?.commission_rate_type;
        this.agent_company_id = payload.agent_company_id;
        this.created_at = payload.created_at;
        this.purchase_note = payload.purchase_note;
        this.vendor_order_id = payload.vendor_order_id;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getTotalCommissionAndFx = (): string => {
        let total: number = 0;

        if (this.commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
            total = this.fx_rate + (this.fx_rate * this.commission_rate) / 100;
        } else if (
            this.commission_rate_type === CommissionRateTypeEnums.FIXED
        ) {
            total = this.fx_rate + this.commission_rate;
        }
        return total.toFixed(2);
    };
}

export class BuyProductPackageModel {
    object: string;
    id: number;
    buy_product_id: number;
    weight: number;
    weight_unit: string;
    length: number;
    length_unit: string;
    width: number;
    width_unit: string;
    height: number;
    height_unit: string;

    constructor(payload: IBuyProductPackage) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.weight = payload?.weight;
        this.weight_unit = payload?.weight_unit;
        this.length = payload?.length;
        this.length_unit = payload?.length_unit;
        this.width = payload?.width;
        this.width_unit = payload?.width_unit;
        this.height = payload?.height;
        this.height_unit = payload?.height_unit;
    }

    getId() {
        return this.object;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getWeight() {
        return this.weight;
    }

    getWeightUnit() {
        return this.weight_unit;
    }

    getLength() {
        return this.length;
    }

    getLengthUnit() {
        return this.length_unit;
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    getHeightUnit() {
        return this.height_unit;
    }
}

export class MoveonShippingModel {
    object: "BuyProductMoveOnShipping";
    id: number;
    shipping_type: ShippingTypeEnums;
    shipping_mode: ShippingModeEnums;
    unit_type: string;
    rate: number;
    agent_category_price_read: IAgentCategoryPriceRead;
    shipping_country: IShippingCountry;
    agent_warehouse: IAgentWarehouse;
    shipping_category: IShippingCategory;
    destination_warehouse: IDestinationWarehouse;
    slots: ShippingSlotCollection;
    commission_rate: number;
    commission_rate_type: RateTypeEnums;

    constructor(payload: IBuyProductMoveOnShipping) {
        this.object = payload.object;
        this.id = payload.id;
        this.shipping_type = payload.shipping_type;
        this.shipping_mode = payload.shipping_mode;
        this.unit_type = payload.unit_type;
        this.rate = payload.rate;
        this.agent_category_price_read = payload.agent_category_price_read;
        this.shipping_country = payload.shipping_country;
        this.agent_warehouse = payload.agent_warehouse;
        this.shipping_category = payload.shipping_category;
        this.destination_warehouse = payload.destination_warehouse;
        this.slots = payload.slots;
        this.commission_rate = payload.commission_rate;
        this.commission_rate_type = payload.commission_rate_type;
    }

    getId() {
        return this.id;
    }

    getShippingCountry() {
        return (
            this.shipping_country &&
            new ShippingCountryModel(this.shipping_country)
        );
    }

    getShippingCountryId() {
        return this.shipping_country.id;
    }

    getAgentCategoryPriceReadId() {
        return this.agent_category_price_read.id;
    }
    getContain() {
        return this.agent_category_price_read?.contain;
    }

    getAgentWarehouse() {
        return this.agent_warehouse;
    }

    getAgentWarehouseName() {
        return this.agent_warehouse.name;
    }

    getAgentWarehouseLocation() {
        return (
            (this.agent_warehouse.warehouse_address &&
                this.agent_warehouse.warehouse_address.address.address_1) ||
            ""
        );
    }

    getAgentWarehouseCountryName() {
        return this.agent_warehouse.country.name;
    }
    getAgentWarehouseCountryFlag() {
        return this.agent_warehouse.country.flag;
    }
    getShippingCompanyName() {
        return this.agent_warehouse.agent_company.primary_name;
    }
    getAgentCompany() {
        return this.agent_warehouse;
    }

    getShippingCategoryName() {
        return this.shipping_category.name;
    }
    getShippingCategoryId() {
        return this.shipping_category.id;
    }
    getShippingCountryFlag() {
        return this.getShippingCountry().getCountry()?.getFlag() || "";
    }
    getShippingCountryName() {
        return this.getShippingCountry().getCountry()?.getName() || "";
    }

    getDestinationWarehouseId() {
        return this.destination_warehouse.id;
    }
    getDestinationWarehouse() {
        return new DestinationWarehouseModel(this.destination_warehouse);
    }

    getShippingType() {
        return this.shipping_type;
    }

    getShippingMode() {
        return this.shipping_mode;
    }

    getUnitType() {
        return this.unit_type;
    }

    getRate() {
        return this.rate;
    }

    getSlots(): ShippingSlot[] {
        return this.slots.data;
    }
    getCommissionRate = () => this.commission_rate;
    getCommissionRateType = () => this.commission_rate_type;
}

export class DestinationWarehouseModel {
    object: string;
    id: number;
    slug: string;
    name: string;
    status: string;
    created_at: string;

    constructor(payload: IDestinationWarehouse) {
        this.object = payload?.object;
        this.id = payload.id;
        this.slug = payload.slug;
        this.name = payload.name;
        this.status = payload.status;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getSlug() {
        return this.slug;
    }

    getName() {
        return this.name;
    }
}

export class ShippingSlotCollectionModel {
    object: string;
    data: ShippingSlotModel[];

    constructor(payload: IShippingSlotCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (singleData) => new ShippingSlotModel(singleData),
        );
    }
}

export class ShippingSlotModel {
    object: string;
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;

    constructor(payload: IShippingSlot) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.min_amount = payload?.min_amount;
        this.max_amount = payload?.max_amount;
        this.rate = payload?.rate;
    }

    getId() {
        return this.id;
    }

    getMinAmount() {
        return this.min_amount;
    }

    getMaxAmount() {
        return this.max_amount;
    }

    getRate() {
        return this.rate;
    }
}

export class PaginationCompressedModel {
    public current_page: number;
    public from: number;
    public last_page: number;
    public per_page: number;
    public to: number;
    public total: number;

    constructor(data: IPagination) {
        this.current_page = data.current_page;
        this.from = data.from;
        this.last_page = data.last_page;
        this.per_page = data.per_page;
        this.to = data.to;
        this.total = data.total;
    }

    getCurrentPage() {
        return this.current_page;
    }

    getFrom() {
        return this.from;
    }

    getLastPage() {
        return this.last_page;
    }

    getPerPage() {
        return this.per_page;
    }

    getTo() {
        return this.to;
    }

    getTotalData() {
        return this.total;
    }
}

export class VariantModel {
    variant_id: number;
    new_original_unit_price?: number;
    new_quantity?: number;
    suggested_sku_id?: string;

    constructor(data: IVariant) {
        this.variant_id = data.variant_id;
        this.new_original_unit_price = data.new_original_unit_price;
        this.new_quantity = data.new_quantity;
        this.suggested_sku_id = data.suggested_sku_id;
    }
}

export class SequenceModel {
    action_type: TakeActionsEnums;
    variants?: VariantModel[];
    addon_ids?: any[];
    images?: any[];
    new_shipping_category_id?: string;
    original_amount?: number;
    new_fx?: number;
    minimum_order_quantity?: number;
    new_rate?: number;
    new_agent_category_price_read?: number;
    slots?: SequenceSlot[];
    admin_note: string;

    constructor(data) {
        this.action_type = data.action_type;
        this.variants = data.variants?.map(
            (variant) => new VariantModel(variant),
        );
        this.addon_ids = data.addon_ids;
        this.images = data.images;
        this.new_shipping_category_id = data.new_shipping_category_id;
        this.minimum_order_quantity = data?.minimum_order_quantity;
        this.original_amount = data.original_amount;
        this.new_fx = data.new_fx;
        this.new_rate = data.new_rate;
        this.slots = data.slots;
        this.admin_note = data.admin_note;
    }
}

export class SequencesDataModel {
    sequences: SequenceModel[];
    admin_note: string;

    constructor(data) {
        this.sequences = data.sequences.map(
            (sequence) => new SequenceModel(sequence),
        );
        this.admin_note = data.admin_note;
    }
}
