import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "@/lib/core-react/contexts";
import { shipmentOrderCollectionAtom } from "@/lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import { getError } from "../utils/errors";
import {
    IDefaultAssignAgentPayload,
    IDefaultWithPriceAssignAgentPayload,
    IOpenBidPayload,
    IShipmentProductAddPackagePayload,
    IShipmentProductAttachImagesPayload,
    RejectPayload,
} from "@/types/shipmentProductCollection";
import {
    bidCollectionAtom,
    shipmentProductAtom,
    shipmentProductIdsByTrackingsAtom,
    shipmentProductPackageAtom,
    shipmentProductsAtom,
    shipmentProductsInvoiceAtom,
    shipmentProductsSyncableAtom,
    unlistedPackageIdsByTrackingsAtom,
    unlistedProductsAtom,
} from "@/lib/core-react/store/store";
import { ShippingModesEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
import { TrackingTypesEnums } from "@/enums/shipForMeContextEnums";
import { message, notification } from "antd";
import { IQuery } from "@/lib/core/request/types/request";
import { ShipmentProductPackageCollectionModel } from "@/models/shipmentProductPackageCollection";
import { ShipmentProductBidCollectionModel } from "@/models/shipmentProductBidCollectionModel";
import {
    ShipmentProductCollectionModel,
    ShipmentProductModel,
} from "@/models/shipmentProductCollectionModel";
import { ShipmentOrderCollectionModel } from "@/models/shipmentOrderCollectionModel";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { isUnauthorized } from "@/helpers/isUnauthorized";
import { getErrorCode } from "@/helpers/getErrorCode";
import {
    IBulkShippingRateUpdatePayload,
    IShippingRateUpdatePayload,
} from "@/pages/Shipping/ShipmentProducts/components/ShipmentProductChangeShippingRate.tsx";
import {
    IFindUnlistedPackageIdsByTrackingFormData,
    IUnlistedPackageClaimFormData,
} from "@/types/unlistedPackageCollection";

export const useGetShipmentOrder = () => {
    const [shipmentOrderCollectionData, setShipmentOrderCollectionData] =
        useAtom(shipmentOrderCollectionAtom);
    const { shippingService } = useService();
    const getShipmentOrder = async (params?: string): Promise<void> => {
        setShipmentOrderCollectionData({
            ...shipmentOrderCollectionData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getShipmentOrders(
                    params,
                );

            const processData =
                response && new ShipmentOrderCollectionModel(response);
            setShipmentOrderCollectionData({
                ...shipmentOrderCollectionData,
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error: any) {
            setShipmentOrderCollectionData({
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getError(error),
                unauthorized: isUnauthorized(error),
            });
            throw error;
        }
    };
    return { getShipmentOrder } as const;
};
export const useGetAllBids = () => {
    const [, setBidCollectionAtomData] = useAtom(bidCollectionAtom);
    const { shippingService } = useService();
    const getAllBids = async (productNumber: number): Promise<void> => {
        setBidCollectionAtomData({
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getAllBids(
                    productNumber,
                );

            const processData =
                response && new ShipmentProductBidCollectionModel(response);
            setBidCollectionAtomData({
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error: any) {
            setBidCollectionAtomData({
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            message.error("Something went wrong!");
            throw error;
        }
    };
    return { getAllBids } as const;
};

export const useGetShipmentProducts = () => {
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [
        unlistedPackageIdsByTrackingData,
        setUnlistedPackageIdsByTrackingData,
    ] = useAtom(unlistedPackageIdsByTrackingsAtom);
    const [
        shipmentProductIdsByTrackingData,
        setShipmentProductIdsByTrackingData,
    ] = useAtom(shipmentProductIdsByTrackingsAtom);

    const { shippingService } = useService();
    const getShipmentProducts = async (params?: string): Promise<void> => {
        setShipmentProductsData({
            ...shipmentProductsData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getShipmentProducts(
                    params,
                );
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    const getUnlistedPackageIdsByTracking = async (
        payload: IFindUnlistedPackageIdsByTrackingFormData,
    ): Promise<void> => {
        setUnlistedPackageIdsByTrackingData({
            ...unlistedPackageIdsByTrackingData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.findUnlistedPackageIdsByTracking(
                    payload,
                );
            setUnlistedPackageIdsByTrackingData({
                ...unlistedPackageIdsByTrackingData,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setUnlistedPackageIdsByTrackingData({
                ...unlistedPackageIdsByTrackingData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    const getShipmentProductIdsByTracking = async (
        payload: IFindUnlistedPackageIdsByTrackingFormData,
    ): Promise<void> => {
        setShipmentProductIdsByTrackingData({
            ...shipmentProductIdsByTrackingData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.findShipmentProductIdsByTracking(
                    payload,
                );
            setShipmentProductIdsByTrackingData({
                ...shipmentProductIdsByTrackingData,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setShipmentProductIdsByTrackingData({
                ...shipmentProductIdsByTrackingData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return {
        getShipmentProducts,
        getUnlistedPackageIdsByTracking,
        getShipmentProductIdsByTracking,
    } as const;
};
export const useGetShipmentProductsForInvoice = () => {
    const [shipmentProductsData, setShipmentProductsData] = useAtom(
        shipmentProductsInvoiceAtom,
    );
    const { shippingService } = useService();
    const getShipmentProductsInvoice = async (
        params?: string,
    ): Promise<void> => {
        try {
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: true,
                error: null,
            });

            const response =
                await shippingService.shippingResource.forInvoice(params);

            const processData =
                response && new ShipmentProductCollectionModel(response);

            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error: any) {
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getShipmentProductsInvoice } as const;
};

export const useGetShipmentProduct = () => {
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const { shippingService } = useService();
    const getShipmentProduct = async (
        productId: number,
        params?: string,
    ): Promise<void> => {
        setShipmentProductData({
            ...shipmentProductData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getShipmentProduct(
                    productId,
                    params,
                );
            if (response) {
                const processData = new ShipmentProductModel(response.data);

                setShipmentProductData({
                    ...shipmentProductData,
                    isLoading: false,
                    data: processData,
                    refetch: false,
                });
            }
        } catch (error) {
            setShipmentProductData({
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getErrorCode(error),
            });
            throw error;
        }
    };
    return { getShipmentProduct } as const;
};

export const useUpdateShippingCountry = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const updateShippingCountry = async (payload: {
        shipment_product_ids: number[];
        shipping_country_id: number;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateShippingCountry(
                    payload,
                );
            setIsLoading(false);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateShippingCountry, isLoading, isError };
};
export const useUpdateDestinationWarehouse = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);

    const updateDestinationWarehouse = async (payload: {
        shipment_product_ids: number[];
        destination_warehouse_id: number;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateDestinationWarehouse(
                    payload,
                );
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateDestinationWarehouse, isLoading, isError };
};
export const useUpdateShippingCategory = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);

    const updateShippingCategory = async (payload: {
        shipment_product_ids: number[];
        shipping_category_id: string;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateShippingCategory(
                    payload,
                );
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateShippingCategory, isLoading, isError };
};
export const useUpdateShippingMode = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);

    const updateShippingMode = async (payload: {
        shipment_product_ids: number[];
        shipping_mode: ShippingModesEnums;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateShippingMode(
                    payload,
                );
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateShippingMode, isLoading, isError };
};
export const useUpdateShippingType = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const updateShippingType = async (payload: {
        shipment_product_ids: number[];
        shipping_type: ShippingTypeEnums;
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateShippingType(
                    payload,
                );
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateShippingType, isLoading, isError };
};
export const useAttachTrackingId = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const attachTrackingId = async (
        productId: number,
        payload: {
            tracking: string;
            package_id: number;
        }[],
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.attachTrackingId(
                    productId,
                    payload,
                );
            setIsLoading(false);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { attachTrackingId, isLoading, isError };
};

export const useShipmentProductAttachTrackingId = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const attachTrackingId = async (
        productId: number,
        // payload: {
        //   tracking: string;
        //   package_id:number
        // }[],
        payload: any,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.attachTrackingId(
                    productId,
                    payload,
                );
            setIsLoading(false);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { attachTrackingId, isLoading, isError };
};

export const useShipmentProductUpdateTrackingId = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const updateTrackingId = async (
        shipmentProductId: number,
        trackingId: number,
        payload: {
            tracking: string;
            type: TrackingTypesEnums;
        },
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.updateTrackingId(
                    shipmentProductId,
                    trackingId,
                    payload,
                );
            setIsLoading(false);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error?.response?.data?.message);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateTrackingId, isLoading, isError };
};

export const useAddProductImage = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);

    const addProductImage = async (payload: {
        shipment_product_ids: number[];
        images: string[];
    }) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.addProductImage(payload);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            setShipmentProductData({ ...shipmentProductData, refetch: true });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addProductImage, isLoading, isError };
};

export const useApprovedShippingProduct = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentOrderCollectionData, setShipmentOrderCollectionData] =
        useAtom(shipmentOrderCollectionAtom);
    const [shipmentProductData, setShipmentProductData] =
        useAtom(shipmentProductAtom);
    const approvedShippingProduct = async (shipmentProductId: number) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.approvedShipmentProduct(
                    shipmentProductId,
                );
            setIsLoading(false);
            setShipmentOrderCollectionData({
                ...shipmentOrderCollectionData,
                refetch: true,
            });
            setShipmentProductData({
                ...shipmentProductData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { approvedShippingProduct, isLoading, isError };
};

export const useApprovedShippingProductForProduct = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const approvedShippingProduct = async (productId: number) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.approvedShipmentProduct(
                    productId,
                );
            setIsLoading(false);

            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { approvedShippingProduct, isLoading, isError };
};
export const useRejectedShippingProduct = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const rejectedShippingProduct = async (
        productId: number,
        payload: RejectPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.rejectShipmentProduct(
                    payload,
                    productId,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { rejectedShippingProduct, isLoading, isError };
};

export const usePutChangeAgentRateShipmentProduct = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const changeAgentRate = async (
        productId: number,
        payload: IShippingRateUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.changeAgentRateShipmentProduct(
                    payload,
                    productId,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { changeAgentRate, isLoading, isError };
};

export const useBulkChangeAgentRateShipmentProduct = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const bulkChangeAgentRate = async (
        payload: IBulkShippingRateUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.bulkChangeAgentRateShipmentProduct(
                    payload,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { bulkChangeAgentRate, isLoading, isError };
};

export const useDefaultAgentAssign = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentOrderCollectionData, setShipmentOrderCollectionData] =
        useAtom(shipmentOrderCollectionAtom);
    const [shipmentProductCollectionData, setShipmentProductCollectionData] =
        useAtom(shipmentProductsAtom);
    const defaultAgentAssign = async (payload: IDefaultAssignAgentPayload) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.agentDefaultAssign(
                    payload,
                );
            setIsLoading(false);
            setShipmentOrderCollectionData({
                ...shipmentOrderCollectionData,
                refetch: true,
            });
            setShipmentProductCollectionData({
                ...shipmentProductCollectionData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { defaultAgentAssign, isLoading, isError };
};
export const useDefaultAgentAssignWithPrice = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentOrderCollectionData, setShipmentOrderCollectionData] =
        useAtom(shipmentOrderCollectionAtom);
    const [shipmentProductCollectionData, setShipmentProductCollectionData] =
        useAtom(shipmentProductsAtom);
    const defaultAgentAssignWithPrice = async (
        payload: IDefaultWithPriceAssignAgentPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.agentDefaultWithPriceAssign(
                    payload,
                );
            setIsLoading(false);
            setShipmentOrderCollectionData({
                ...shipmentOrderCollectionData,
                refetch: true,
            });
            setShipmentProductCollectionData({
                ...shipmentProductCollectionData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { defaultAgentAssignWithPrice, isLoading, isError };
};

export const useCreateBid = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [shipmentProductsData, setShipmentProductsData] =
        useAtom(shipmentProductsAtom);
    const createBid = async (payload: IOpenBidPayload, productId: number) => {
        setIsLoading(true);
        try {
            const response = await shippingService.shippingResource.createBid(
                payload,
                productId,
            );
            setIsLoading(false);
            setShipmentProductsData({ ...shipmentProductsData, refetch: true });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createBid, isLoading, isError };
};
export const useUpdateBid = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const updateBid = async (payload: IOpenBidPayload, bidId: number) => {
        setIsLoading(true);
        try {
            const response = await shippingService.shippingResource.updateBid(
                payload,
                bidId,
            );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateBid, isLoading, isError };
};
export const useAbandonBid = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const abandonBid = async (bidId: number) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.abandonBid(bidId);
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { abandonBid, isLoading, isError };
};
export const useWinnerWarehouse = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const winnerWarehouse = async (
        bidId: number,
        payload: { agent_warehouse_id: number },
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.winnerWarehouse(
                    bidId,
                    payload,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { winnerWarehouse, isLoading, isError };
};

export const useGetShipmentProductPackages = (query: IQuery = {}) => {
    const [shipmentProductPackages, setShipmentProductPackages] = useAtom(
        shipmentProductPackageAtom,
    );
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const getPackages = async (productId: number) => {
        setIsLoading(true);
        try {
            const response = await shippingService.shippingResource.getPackages(
                productId,
                query,
            );
            setIsLoading(false);
            const data = new ShipmentProductPackageCollectionModel(response);
            setShipmentProductPackages({
                ...shipmentProductPackages,
                data: data,
                refetch: true,
            });

            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { getPackages, isLoading, isError };
};
// Shipment product add new package
export const useShipmentProductAddPackage = () => {
    const [shipmentOrderCollection, setShipmentOrderCollection] = useAtom(
        shipmentOrderCollectionAtom,
    );
    const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const addPackage = async (
        productId: number,
        payload: IShipmentProductAddPackagePayload,
    ) => {
        setIsLoading(true);
        try {
            const response = await shippingService.shippingResource.addPackage(
                productId,
                payload,
            );
            setIsLoading(false);
            setShipmentOrderCollection({
                ...shipmentOrderCollection,
                refetch: true,
            });
            setShipmentProduct({
                ...shipmentProduct,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addPackage, isLoading, isError };
};
export const useShipmentProductUpdatePackage = () => {
    const [shipmentOrderCollection, setShipmentOrderCollection] = useAtom(
        shipmentOrderCollectionAtom,
    );
    const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updatePackage = async (
        shipmentProductId: number,
        packageId: number,
        payload: IShipmentProductAddPackagePayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.putUpdatePackage(
                    shipmentProductId,
                    packageId,
                    payload,
                );
            setIsLoading(false);
            setShipmentOrderCollection({
                ...shipmentOrderCollection,
                refetch: true,
            });
            setShipmentProduct({
                ...shipmentProduct,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updatePackage, isLoading, isError };
};
export const useShipmentProductDeletePackage = () => {
    const [shipmentOrderCollection, setShipmentOrderCollection] = useAtom(
        shipmentOrderCollectionAtom,
    );
    const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const deletePackage = async (productId: number, packageId: number) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.deletePackage(
                    productId,
                    packageId,
                );
            setIsLoading(false);
            setShipmentOrderCollection({
                ...shipmentOrderCollection,
                refetch: true,
            });
            setShipmentProduct({
                ...shipmentProduct,
                refetch: true,
            });
            notification["success"]({
                message: response.message,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { deletePackage, isLoading, isError };
};

// Shipment product add new package
export const useShipmentProductAttachPackageImage = () => {
    const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const attachImage = async (
        payload: IShipmentProductAttachImagesPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingService.shippingResource.attachImage(payload);
            setIsLoading(false);
            setShipmentProduct({
                ...shipmentProduct,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { attachImage, isLoading, isError };
};

export const useSyncShipmentProducts = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const syncShipmentProducts = async (payload: { ids: number[] }) => {
        try {
            setIsLoading(true);
            const response =
                await shippingService.shippingResource.sync(payload);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { syncShipmentProducts, isLoading } as const;
};

export const useShippingStart = () => {
    const [shipmentProductCollectionData, setShipmentProductCollectionData] =
        useAtom(shipmentProductsAtom);
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const shippingStart = async (shipmentProductId: number) => {
        try {
            setIsLoading(true);
            const response =
                await shippingService.shippingResource.shippingStart(
                    shipmentProductId,
                );
            setIsLoading(false);
            setShipmentProductCollectionData({
                ...shipmentProductCollectionData,
                refetch: true,
            });
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { shippingStart, isLoading } as const;
};

// Unlisted Package
export const useUnlistedProductsGet = () => {
    const [unlistedProductsData, setUnlistedProductsData] =
        useAtom(unlistedProductsAtom);
    const { shippingService } = useService();
    const getUnlistedProducts = async (params?: string): Promise<void> => {
        setUnlistedProductsData({
            ...unlistedProductsData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getUnlistedProducts(
                    params,
                );
            setUnlistedProductsData({
                ...unlistedProductsData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setUnlistedProductsData({
                ...unlistedProductsData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getUnlistedProducts } as const;
};

export const useUnlistedPackagesClaim = () => {
    const { shippingService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const claim = async (
        dw_id: number,
        pkg_id: number,
        payload: IUnlistedPackageClaimFormData,
    ) => {
        try {
            setIsLoading(true);
            const response =
                await shippingService.shippingResource.claimUnlistedPackage(
                    dw_id,
                    pkg_id,
                    payload,
                );

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return { claim, isLoading } as const;
};

export const useSyncableShipmentProductsGet = () => {
    const [shipmentProductsData, setShipmentProductsData] = useAtom(
        shipmentProductsSyncableAtom,
    );
    const { shippingService } = useService();
    const getSyncable = async (
        dw_id: number,
        query: IQuery = {},
    ): Promise<void> => {
        setShipmentProductsData({
            ...shipmentProductsData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingService.shippingResource.getSyncableShipmentProducts(
                    dw_id,
                    query,
                );
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setShipmentProductsData({
                ...shipmentProductsData,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getSyncable } as const;
};
