import React, { CSSProperties, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Table, TablePaginationConfig, Button, Modal, Typography } from "antd";

import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import { IUnlistedPackageModalType } from "@/types/unlistedPackageCollection";
import { tw } from "@/consts/theme/tailwindTheme";

import UnlistedPackageInfo from "./components/UnlistedPackageInfo";
import ShippingInfo from "./components/ShippingInfo";
import ClaimStepper from "./components/Claim/ClaimStepper";
import { LinkOutlined } from "@ant-design/icons";
import { useGetShipmentProducts } from "@/lib/core-react/hooks/private/useShipping";
import { useAtomValue } from "jotai";
import { shipmentProductIdsByTrackingsAtom } from "@/lib/core-react/store/store";
import { checkTrackingMatch, getSyncableTrackings } from "./utils";

interface IProps {
    data: UnlistedPackageModel[] | undefined;
    paginationConfig?: false | TablePaginationConfig;
    isLoading?: boolean;
    isOrderPage: boolean;
    selectedRowKeys: number[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
    selectedRows: UnlistedPackageModel[];
    setSelectedRows: React.Dispatch<
        React.SetStateAction<UnlistedPackageModel[]>
    >;
    onClearBulkSelectedState: () => void;
}

const UnlistedPackagesTable = ({
    data,
    isLoading,
    paginationConfig,
    isOrderPage,
    selectedRowKeys,
    setSelectedRowKeys,
    setSelectedRows,
}: IProps) => {
    const { getShipmentProductIdsByTracking } = useGetShipmentProducts();

    const { data: shipmentProductIdsByTracking } = useAtomValue(
        shipmentProductIdsByTrackingsAtom,
    );
    const [selectedUnlistedPackage, setSelectedUnlistedPackage] =
        useState<UnlistedPackageModel>();
    const [modalOpen, setModalOpen] = useState<IUnlistedPackageModalType>();

    const onClearSelectedState = () => {
        setSelectedUnlistedPackage(undefined);
        setModalOpen(undefined);
    };

    const onHeaderCellStyle: CSSProperties = {
        backgroundColor: isOrderPage ? "white" : "",
    };

    useEffect(() => {
        if (data) {
            const trackings = getSyncableTrackings(data);
            if (trackings.length) {
                getShipmentProductIdsByTracking({
                    trackings,
                });
            }
        }
    }, [isLoading]);

    const columns: ColumnsType<UnlistedPackageModel> = [
        {
            title: "Product Info",
            dataIndex: "product-details",
            key: "product-details",
            width: 400,
            onHeaderCell: () => {
                return {
                    style: onHeaderCellStyle,
                };
            },
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, unlistedProduct) => (
                <>
                    <UnlistedPackageInfo
                        handleModal={() => {}}
                        unlistedPackageModel={unlistedProduct}
                    />
                    {shipmentProductIdsByTracking &&
                    checkTrackingMatch(
                        unlistedProduct,
                        shipmentProductIdsByTracking,
                    ) ? (
                        <Typography.Text
                            style={{
                                color: "#D8000C",
                                backgroundColor: "#FFBABA",
                                borderRadius: "4px",
                                border: "1px solid #D8000C",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            Found matching shipment product!
                        </Typography.Text>
                    ) : null}
                </>
            ),
        },
        {
            title: "Shipping Info",
            key: "shipping-info",
            width: 400,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, unlistedProduct) => (
                <>
                    <ShippingInfo
                        handleModal={() => {}}
                        unlistedPackageModel={unlistedProduct}
                    />
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            width: 150,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({ style: { verticalAlign: "top" } }),
            render: (_: string, record) => {
                return (
                    <>
                        {record.getShipmentProduct() ? (
                            <Button
                                onClick={() => {
                                    const url = `/shipping/shipment-products?keyword=${record.getShipmentProduct()?.product_number}`;
                                    window.open(url, "_blank");
                                }}
                            >
                                Show Claimed Product <LinkOutlined size={16} />
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    setSelectedUnlistedPackage(record);
                                    setModalOpen("Claim");
                                }}
                            >
                                Claim
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <div>
                <Table
                    loading={isLoading}
                    dataSource={data}
                    columns={columns}
                    rowHoverable={false}
                    rowKey={(r) => r.id}
                    scroll={{ x: 1600 }} // Changed to accommodate total column widths
                    style={{
                        backgroundColor: "white",
                        boxShadow: tw.boxShadow.md,
                        marginTop: 24,
                        width: "100%", // Ensure table takes full container width
                    }}
                    bordered
                    pagination={
                        isOrderPage
                            ? false
                            : {
                                  ...paginationConfig,
                                  showTotal: (total) => (
                                      <div>
                                          Total{" "}
                                          <span
                                              style={{
                                                  fontWeight: "bold",
                                                  fontSize: "18px",
                                                  color: "green",
                                              }}
                                          >
                                              {total}
                                          </span>{" "}
                                          Items
                                      </div>
                                  ),
                              }
                    }
                    rowSelection={
                        isOrderPage
                            ? undefined
                            : {
                                  selectedRowKeys: selectedRowKeys,
                                  type: "checkbox",
                                  onChange: (selectedRowKeys, selectedRows) => {
                                      const productIds =
                                          selectedRowKeys.map(Number);
                                      setSelectedRowKeys(productIds);
                                      setSelectedRows(selectedRows);
                                  },
                              }
                    }
                />
            </div>

            <Modal
                width={"60%"}
                title="Claim"
                open={modalOpen === "Claim"}
                onCancel={onClearSelectedState}
                footer={false}
                centered
                destroyOnClose={true}
            >
                {selectedUnlistedPackage && (
                    <ClaimStepper
                        data={selectedUnlistedPackage}
                        onFinish={onClearSelectedState}
                    />
                )}
            </Modal>
        </div>
    );
};

export default UnlistedPackagesTable;
