import NotificationApiService from "../../apiServices/notificationApiService";
import { NotificationResource } from "../../resources/private";

class NotificationService extends NotificationApiService {
    public notificationResource: NotificationResource;

    constructor() {
        super();

        this.notificationResource = new NotificationResource(this.config);
    }
}

export default NotificationService;
