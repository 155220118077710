import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Flex,
    Form,
    Input,
    InputNumber,
    Select,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect } from "react";
import { FormInstance } from "antd/lib";
import { IVariant } from "../CreateOrderSteps/CreateOrderSteps";
import { capitalize } from "@/utils/helper";

interface IProps {
    form: FormInstance<any>;
    initialData: any;
}
const VariantWithPropertiesPart = ({ form, initialData }: IProps) => {
    const defaultCurrencyCode = initialData?.default_currency_code;
    const changedProperties = Form.useWatch("var_with_property_info", form);
    const variationFormRealtimeData = Form.useWatch("variants", form);
    const cartesianProduct = (arrays) => {
        return arrays.reduce(
            (a, b) => a.flatMap((d) => b.map((e) => [...d, e])),
            [[]],
        );
    };
    useEffect(() => {
        if (changedProperties && Array.isArray(changedProperties)) {
            const lastItem = changedProperties[changedProperties.length - 1];
            const validateLastItem =
                !!lastItem?.name &&
                !!lastItem?.values &&
                Array.isArray(lastItem?.values) &&
                lastItem?.values.length > 0;

            if (validateLastItem) {
                const selectedValues = changedProperties.map(
                    (property) => property.values,
                );
                const combinations = cartesianProduct(selectedValues);
                const newVariants: IVariant[] = combinations.map(
                    (combination, index) => {
                        const varImage = variationFormRealtimeData
                            ? variationFormRealtimeData[index]?.image
                            : undefined;
                        const varQuantity = variationFormRealtimeData
                            ? variationFormRealtimeData[index]?.quantity
                            : undefined;
                        const varPrice = variationFormRealtimeData
                            ? variationFormRealtimeData[index]
                                  ?.original_unit_price
                            : undefined;
                        return {
                            image: varImage || "",
                            quantity: varQuantity || 1,
                            original_unit_price: varPrice || 1,
                            properties: combination.map((value, idx) => ({
                                property_name: changedProperties[idx].name,
                                property_value_name: value,
                            })),
                        };
                    },
                );
                form.setFieldsValue({
                    variants: [...newVariants],
                });
            } else {
                if (!changedProperties.length) {
                    form.setFieldsValue({
                        variants: [],
                    });
                }
            }
        }
    }, [Form.useWatch("var_with_property_info", form)]);

    return (
        <>
            <div>
                <Form.List name="var_with_property_info">
                    {(fields, { add, remove }) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    style={{ width: "20%" }}
                                    onClick={() => {
                                        form.validateFields([
                                            [
                                                "var_with_property_info",
                                                fields.length - 1,
                                                "name",
                                            ],
                                            [
                                                "var_with_property_info",
                                                fields.length - 1,
                                                "values",
                                            ],
                                        ]).then(() => {
                                            add();
                                        });
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add Property
                                </Button>
                            </Form.Item>

                            {fields.length > 0 && (
                                <Table
                                    bordered
                                    size="small"
                                    dataSource={fields.map((field) => ({
                                        key: field.key,
                                        name: (
                                            <Form.Item
                                                name={[field.name, "name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Missing property name",
                                                    },
                                                ]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input placeholder="Property Name (e.g. Size, Color)" />
                                            </Form.Item>
                                        ),
                                        values: (
                                            <Form.Item
                                                name={[field.name, "values"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: "array",
                                                        min: 1,
                                                        message:
                                                            "Please add at least one property value",
                                                    },
                                                ]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Select
                                                    mode="tags"
                                                    style={{ width: "100%" }}
                                                    placeholder="Add property values"
                                                    allowClear
                                                />
                                            </Form.Item>
                                        ),
                                        actions: (
                                            <MinusCircleOutlined
                                                style={{ color: "red" }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        ),
                                    }))}
                                    columns={[
                                        {
                                            title: "Name",
                                            dataIndex: "name",
                                            key: "name",
                                            width: 250,
                                        },
                                        {
                                            title: "Values",
                                            dataIndex: "values",
                                            key: "values",
                                        },
                                        {
                                            title: "Actions",
                                            dataIndex: "actions",
                                            key: "actions",
                                            align: "center",
                                            width: 100,
                                        },
                                    ]}
                                    pagination={false}
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                />
                            )}
                        </>
                    )}
                </Form.List>
            </div>

            <Form.List name="variants">
                {(fields) => (
                    <>
                        <Table
                            title={() => (
                                <Typography.Text strong>
                                    Variation
                                </Typography.Text>
                            )}
                            bordered
                            dataSource={fields.map((field) => ({
                                ...field,
                                key: field.key,
                            }))}
                            rowKey="key"
                            pagination={false}
                        >
                            <Table.Column
                                title="Meta & Image URL"
                                dataIndex="image"
                                key="image"
                                render={(_, __, index) => {
                                    const targetedData = Array.isArray(
                                        variationFormRealtimeData,
                                    )
                                        ? variationFormRealtimeData[index]
                                              ?.properties
                                        : undefined;

                                    return (
                                        <Flex vertical gap={12}>
                                            <div>
                                                {targetedData &&
                                                    targetedData.map(
                                                        (property, idx) => (
                                                            <Tag
                                                                key={idx}
                                                            >{`${capitalize(property.property_name)}: ${capitalize(property.property_value_name)}`}</Tag>
                                                        ),
                                                    )}
                                            </div>

                                            <Form.Item name={[index, "image"]}>
                                                <Input placeholder="Image URL" />
                                            </Form.Item>
                                        </Flex>
                                    );
                                }}
                            />

                            <Table.Column
                                title="Quantity"
                                dataIndex="quantity"
                                key="quantity"
                                align="center"
                                render={(_, __, index) => (
                                    <Form.Item
                                        name={[index, "quantity"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Quantity required",
                                            },
                                            {
                                                type: "number",
                                                min: 1,
                                                message:
                                                    "Quantity must be at least 1",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ minWidth: 200 }}
                                            placeholder="Quantity"
                                        />
                                    </Form.Item>
                                )}
                            />
                            <Table.Column
                                title={`Original Unit Price ${defaultCurrencyCode ? `- (${defaultCurrencyCode})` : ""}`}
                                dataIndex="original_unit_price"
                                key="original_unit_price"
                                align="center"
                                render={(_, __, index) => {
                                    return (
                                        <Form.Item
                                            name={[
                                                index,
                                                "original_unit_price",
                                            ]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Unit Price required",
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value > 0 ||
                                                            value === undefined
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                "Unit Price must be greater than 0",
                                                            ),
                                                        );
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                style={{ minWidth: 200 }}
                                                placeholder="Price"
                                            />
                                        </Form.Item>
                                    );
                                }}
                            />

                            <Table.Column
                                title="Action"
                                dataIndex="action"
                                key="action"
                                align="center"
                                render={(_, __, index) => (
                                    <MinusCircleOutlined
                                        style={{
                                            color: "red",
                                        }}
                                        onClick={() =>
                                            form.getFieldValue("variants")
                                                .length > 0 &&
                                            form.setFieldsValue({
                                                variants: form
                                                    .getFieldValue("variants")
                                                    .filter(
                                                        (_, i) => i !== index,
                                                    ),
                                            })
                                        }
                                    />
                                )}
                            />
                        </Table>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default VariantWithPropertiesPart;
