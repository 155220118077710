import { Config } from "../request/types/request";
import { ServiceBaseUrl, ServiceNameEnum } from "./servicBaseUrl";

class NotificationApiService {
    public config: Config;

    constructor() {
        this.config = {
            baseUrl: ServiceBaseUrl.getServiceBaseUrl(
                ServiceNameEnum.NOTIFICAITON_API_SERVICE,
            ),
            apiKey: "",
            maxRetries: 0,
            publishableApiKey: "",
        };
    }
}

export default NotificationApiService;
