import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IInvoiceTransactionDetailsResponse } from "@/types/InvoiceCollection";
import {
    IPaymentSessionPayPayload,
    IPaymentSessionResponse,
} from "@/types/paymentSession";

class InvoiceResource extends BaseResource {
    customerPath = `/api/invoice/admin/invoice/v1/invoices`;
    agentPath = `/api/invoice/admin/agent-invoice/v1/agent-invoices`;
    sessionPath = `/api/invoice/admin/invoice/v1/session`;

    calculateInvoices(payload: { invoice_ids: number[] }): ResponsePromise {
        const path = `${this.customerPath}/calculate`;
        return this.client.request("POST", path, payload, {}, {});
    }

    calculateAgentInvoices(payload: {
        invoice_ids: number[];
    }): ResponsePromise {
        const path = `${this.agentPath}/calculate`;
        return this.client.request("POST", path, payload, {}, {});
    }

    settleBulk(payload: { invoice_ids: number[] }): ResponsePromise {
        const path = `${this.agentPath}/bulk/settle`;
        return this.client.request("PUT", path, payload, {}, {});
    }
    getCustomerInvoices(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.customerPath}?${params ? `${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getAgentInvoices(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.agentPath}?${params ? `${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getCustomerInvoiceDetails(id: number | string): ResponsePromise {
        const path = `${this.customerPath}/${id}`;
        return this.client.request("GET", path, undefined, undefined, {});
    }
    getAgentInvoiceDetails(id: string | number): ResponsePromise {
        const path = `${this.agentPath}/${id}`;
        return this.client.request("GET", path, undefined, undefined, {});
    }
    getTransactionDetails(
        invoiceId: string | number,
        transactionId: string | number,
        requestFor: "customer" | "agent",
    ): Promise<IInvoiceTransactionDetailsResponse> {
        const customerInvoicePath = `${this.customerPath}/${invoiceId}/transactions/${transactionId}`;
        const agentInvoicePath = `${this.agentPath}/${invoiceId}/transactions/${transactionId}`;
        const path =
            requestFor === "customer" ? customerInvoicePath : agentInvoicePath;
        return this.client.request("GET", path, undefined, undefined, {});
    }

    settleInvoiceTransaction(
        invoiceId: number | string,
        requestFor: "customer" | "agent",
    ) {
        const path =
            requestFor === "customer"
                ? `${this.customerPath}/${invoiceId}/settle`
                : `${this.agentPath}/${invoiceId}/settle`;
        return this.client.request("POST", path, undefined, undefined);
    }

    downloadInvoiceTransaction(
        invoiceId: number | string,
        requestFor: "customer" | "agent",
    ) {
        const path =
            requestFor === "customer"
                ? `${this.customerPath}/${invoiceId}/download`
                : `${this.agentPath}/${invoiceId}/download`;
        return this.client.request("GET", path, undefined, undefined);
    }

    recalculateInvoice(
        invoiceId: number | string,
        requestFor: "customer" | "agent",
    ) {
        const path =
            requestFor === "customer"
                ? `${this.customerPath}/${invoiceId}/recalculate`
                : `${this.agentPath}/${invoiceId}/recalculate`;
        return this.client.request("POST", path, undefined, undefined, {});
    }

    createSession(user_id: number, invoice_ids: number[]) {
        const path = this.sessionPath;
        const payload = {
            user_id,
            invoice_ids,
        };
        return this.client.request("POST", path, payload, undefined);
    }

    getSession(
        user_id: number,
        session_token: string,
    ): Promise<IPaymentSessionResponse> {
        const path = `${this.sessionPath}/${user_id}/${session_token}`;
        return this.client.request("GET", path, undefined, undefined);
    }

    paySession(
        user_id: number,
        session_token: string,
        payload: IPaymentSessionPayPayload,
    ): Promise<IPaymentSessionResponse> {
        const path = `${this.sessionPath}/${user_id}/${session_token}/pay`;
        return this.client.request("POST", path, payload, undefined);
    }
}

export default InvoiceResource;
