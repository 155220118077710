import { RfqRequestCollectionEnum } from "@/enums/rfqRequestCollectionEnum";
import { IFilterType } from "@/types/filters";
import {
    IRfqAgentCompanyCustomer,
    IRFQParticipant,
    IRFQQuoteVariant,
    IRFQQuoteVariantCollection,
    IRFQRequestQuote,
    IRFQRequestQuoteCollection,
} from "@/types/rfqRequestQuoteCollection";
import { PaginationModel } from "./paginationModel";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

export class RFQRequestQuoteModel {
    object: RfqRequestCollectionEnum.RfqRequestQuote;
    id: number;
    valid_until: string;
    product_title: string;
    product_link: string;
    product_id: string;
    product_description: string;
    agent_note: string | null;
    lead_time_from: number;
    lead_time_end: number;
    participant?: RFQParticipantModel | null;
    quote_variants?: RFQQuoteVariantCollectionModel | null;

    constructor(payload: IRFQRequestQuote) {
        this.object = payload.object;
        this.id = payload.id;
        this.valid_until = payload.valid_until;
        this.product_title = payload.product_title;
        this.product_link = payload.product_link;
        this.product_id = payload.product_id;
        this.product_description = payload.product_description;
        this.agent_note = payload.agent_note;
        this.lead_time_from = payload.lead_time_from;
        this.lead_time_end = payload.lead_time_end;
        this.participant = payload.participant
            ? new RFQParticipantModel(payload.participant)
            : null;
        this.quote_variants = payload.quote_variants
            ? new RFQQuoteVariantCollectionModel(payload.quote_variants)
            : null;
    }

    public getId(): number {
        return this.id;
    }

    public getValidUntil(): string {
        return this.valid_until;
    }

    public getProductTitle(): string {
        return this.product_title;
    }

    public getProductLink(): string {
        return this.product_link;
    }

    public getProductId() {
        return this.product_id;
    }

    public getProductDescription(): string {
        return this.product_description;
    }

    public getAgentNote() {
        return this.agent_note || "";
    }

    public getLeadTimeFrom(): number {
        return this.lead_time_from;
    }

    public getLeadTimeEnd(): number {
        return this.lead_time_end;
    }

    public getParticipant() {
        return this.participant;
    }

    public getQuoteVariants() {
        return this.quote_variants;
    }
}

export class RFQParticipantModel {
    object: RfqRequestCollectionEnum.RfqParticipant;
    id: number;
    rfq_request_id: number;
    agent_company_id: number;
    agent_company?: RfqAgentCompanyCustomerModel | null;
    agent_ability: string;

    constructor(payload: IRFQParticipant) {
        this.object = payload.object;
        this.id = payload.id;
        this.rfq_request_id = payload.rfq_request_id;
        this.agent_company_id = payload.agent_company_id;
        this.agent_company = payload.agent_company
            ? new RfqAgentCompanyCustomerModel(payload.agent_company)
            : null;
        this.agent_ability = payload.agent_ability;
    }

    public getId(): number {
        return this.id;
    }

    public getRfqRequestId(): number {
        return this.rfq_request_id;
    }

    public getAgentCompanyId(): number {
        return this.agent_company_id;
    }

    public getAgentCompany() {
        return this.agent_company;
    }

    public getAgentAbility(): string {
        return this.agent_ability;
    }
}

export class RfqAgentCompanyCustomerModel {
    object: RfqRequestCollectionEnum.AgentCompany;
    id: number;
    name: string;
    address: string;
    operation_status: string;
    verification_status: string;

    constructor(payload: IRfqAgentCompanyCustomer) {
        this.object = payload.object;
        this.id = payload.id;
        this.name = payload.name;
        this.address = payload.address;
        this.operation_status = payload.operation_status;
        this.verification_status = payload.verification_status;
    }

    public getId(): number {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getAddress(): string {
        return this.address;
    }

    public getOperationStatus(): string {
        return this.operation_status;
    }

    public getVerificationStatus(): string {
        return this.verification_status;
    }
}

export class RFQQuoteVariantCollectionModel {
    object: RfqRequestCollectionEnum.RfqRequestQuoteVariantCollection;
    data: RFQQuoteVariantModel[];

    constructor(payload: IRFQQuoteVariantCollection) {
        this.object = payload.object;
        this.data = payload.data.map(
            (variant) => new RFQQuoteVariantModel(variant),
        );
    }

    public getVariants(): RFQQuoteVariantModel[] {
        return this.data;
    }
}

export class RFQQuoteVariantModel {
    object: RfqRequestCollectionEnum.RfqRequestQuoteVariant;
    id: number;
    rfq_quote_id: number;
    sku_id: string;
    minimum_order_quantity: number;
    unit_price: number;

    constructor(payload: IRFQQuoteVariant) {
        this.object = payload.object;
        this.id = payload.id;
        this.rfq_quote_id = payload.rfq_quote_id;
        this.sku_id = payload.sku_id;
        this.minimum_order_quantity = payload.minimum_order_quantity;
        this.unit_price = payload.unit_price;
    }

    public getId(): number {
        return this.id;
    }

    public getRfqQuoteId(): number {
        return this.rfq_quote_id;
    }

    public getSkuId() {
        return this.sku_id;
    }

    public getMinimumOrderQuantity(): number {
        return this.minimum_order_quantity;
    }

    public getUnitPrice(): number {
        return roundToTwoDecimal(this.unit_price);
    }
}

export class RfqQouteCollectionModel {
    data: RFQRequestQuoteModel[];
    filters: IFilterType;
    pagination: PaginationModel;

    constructor(payload: IRFQRequestQuoteCollection) {
        this.data = payload?.data?.map(
            (item) => new RFQRequestQuoteModel(item),
        );
        this.filters = payload?.filters;
        this.pagination = new PaginationModel(payload?.pagination);
    }

    public getData(): RFQRequestQuoteModel[] {
        return this.data;
    }

    public getFilters(): IFilterType {
        return this.filters;
    }

    public getPagination(): PaginationModel {
        return this.pagination;
    }
}
