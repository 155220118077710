import { Button, Flex, Form } from "antd";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
import { useAddAdminNote } from "@/lib/core-react/hooks/private/usePurchase";
import { IModalData } from "@/helpers/getModalTital";
import { useState } from "react";
import { showSuccessAlert } from "@/helpers/showSuccess";
import MvnRichTextEditor from "@/components/MvnRichTextEditor/MvnRichTextEditor";
import { useParams } from "react-router-dom";

interface IProps {
    buyProductId: number;
    adminNote: string;
    handleModal: (payload: IModalData) => void;
}

const AddAdminNoteForm = ({ buyProductId, adminNote, handleModal }: IProps) => {
    const [form] = Form.useForm();
    const { refetchListApi, refetchDetailApi } = useRefetch();
    const { id, productId } = useParams();

    const { addAdminNote, isLoading } = useAddAdminNote();
    const [content, setContent] = useState("");

    const handleAddNote = async () => {
        try {
            const payload = {
                admin_note: content,
            };
            await addAdminNote(buyProductId, payload);
            showSuccessAlert(
                adminNote
                    ? "Successfully Added Amin Note"
                    : "Successfully updated Amin Note",
            );

            handleModal({
                action: false,
            });
            await refetchListApi();
            if (id) {
                await refetchDetailApi(id);
            } else if (productId) {
                await refetchDetailApi(productId);
            }
        } catch (error) {
            showError(error, form);
            handleModal({
                action: false,
            });
        }
    };

    return (
        <Flex vertical gap={12}>
            <MvnRichTextEditor
                onUpdate={(note) => setContent(note)}
                editable={true}
                content={adminNote}
            />

            <Flex justify="end">
                <Button
                    onClick={() => handleAddNote()}
                    loading={isLoading}
                    type="primary"
                    htmlType="button"
                >
                    Submit
                </Button>
            </Flex>
        </Flex>
    );
};

export default AddAdminNoteForm;
