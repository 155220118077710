import { Button, Flex, Form, InputNumber } from "antd";
import { useBulkUpdateFx } from "@/lib/core-react/hooks/private/usePurchase";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { showError } from "@/helpers/showError";
import { IModalData } from "@/helpers/getModalTital";
import useRefetch from "@/hooks/useRefetch";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { IUpdateBulkFXPayload } from "@/types/buyProductCollection";
import { showSuccessAlert } from "@/helpers/showSuccess";

interface IProps {
    selectedRows: BuyProductModel[];
    handleModal: (payload: IModalData) => void;
}
const default_fx_rate = 1;
const default_commission_rate = 1;
const UpdateBulkFxForm = ({ selectedRows, handleModal }: IProps) => {
    const [form] = Form.useForm();
    const { isLoading: isLoadingBulkUpdateFx, updateBulkFx } =
        useBulkUpdateFx();

    const { refetchListApi } = useRefetch();

    const onFinish = async (values: {
        fx_rate: number;
        commission_rate: number;
    }) => {
        try {
            const payload: IUpdateBulkFXPayload = {
                fx_rate: values.fx_rate,
                product_ids: selectedRows.map((row) => row.getId()),
                commission_rate: values.commission_rate,
            };
            await updateBulkFx(payload);
            handleModal({
                data: null,
                action: false,
            });
            showSuccessAlert("Bulk FX Updated successfully.");
            refetchListApi();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                initialValues={{
                    commission_rate_type: CommissionRateTypeEnums.PERCENTAGE,
                    fx_rate: default_fx_rate,
                    commission_rate: default_commission_rate,
                }}
            >
                <Form.Item label="FX Rate" name="fx_rate">
                    <InputNumber
                        placeholder="FX rate"
                        min={0}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ width: "100%" }}
                    name="commission_rate"
                    label="Commission Rate"
                    rules={[
                        {
                            required: false,
                            message: "Please enter a commission rate",
                        },
                        {
                            validator: (_, value) =>
                                value > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              "Commission rate must be greater than 0",
                                          ),
                                      ),
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="write commission"
                    />
                </Form.Item>
                <Flex justify="end">
                    <Button
                        loading={isLoadingBulkUpdateFx}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Flex>
            </Form>
        </>
    );
};

export default UpdateBulkFxForm;
