import { Alert, Space, Typography, Button, message } from "antd";
import { memo } from "react";
import { CopyOutlined } from "@ant-design/icons";
const { Text, Paragraph } = Typography;

interface Props {
    shippingAddress: Record<string, any>;
}

const formatKey = (key: string) => {
    return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

const CustomerInfo = ({ shippingAddress }: Props) => {
    if (!shippingAddress) {
        return <Alert message="Shipping Address Not Available" />;
    }
    const address = shippingAddress.display_address || {};

    const copyAddressToClipboard = () => {
        const addressString = Object.entries(address)
            .map(([key, value]) => `${formatKey(key)}: ${value}`)
            .join("\n");

        navigator.clipboard
            .writeText(addressString)
            .then(() => {
                message.success("Address copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy address.");
            });
    };

    return (
        <div style={{ position: "relative" }}>
            {/* Copy Button */}
            <Button
                type="link"
                icon={<CopyOutlined />}
                onClick={copyAddressToClipboard}
                style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1,
                }}
            >
                Copy Address
            </Button>

            {/* Displaying Address as Key-Value Pairs */}
            <Space direction="vertical" size={"small"}>
                {Object.entries(address).map(([key, value]) =>
                    value ? (
                        <Paragraph key={key}>
                            <Text strong>{`${formatKey(key)}: `}</Text>
                            {value ? <Text>{value.toString()}</Text> : <></>}
                        </Paragraph>
                    ) : null,
                )}
            </Space>
        </div>
    );
};

export default memo(CustomerInfo);
