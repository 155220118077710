import React, { useEffect } from "react";
import {
    Button,
    Form,
    FormInstance,
    Modal,
    TreeSelect,
    notification,
} from "antd";
import { useAtom } from "jotai";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import { useUpdateShippingCategory } from "@/lib/core-react/hooks/private/useShipping";
import { shippingCategoryAtom } from "@/lib/core-react/store/store";
import { ShippingCategoryCollectionModel } from "@/models/shippingCategory";
import { getError } from "@/lib/core-react/hooks/utils/errors";

interface IProps {
    productIds: number[];
    isOpen: boolean;
    onCancel: (isMutate?: boolean) => void;
    shipping_category_id: string | undefined;
    region_id: number | undefined;
}

export const ChangeShippingCategoryModal = ({
    isOpen,
    onCancel,
    productIds,
    shipping_category_id,
    region_id,
}: IProps) => {
    const { getShippingCategory } = useGetShippingCategory();
    const { updateShippingCategory, isLoading: updateShippingCategoryLoading } =
        useUpdateShippingCategory();
    const [{ data: shippingCategoryAtomData, isLoading }] =
        useAtom(shippingCategoryAtom);
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();
    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [shippingCategoryAtomData, form]);

    useEffect(() => {
        if (region_id) {
            getShippingCategory(`per_page=500&region_id=${region_id}`);
        }
    }, [region_id]);

    const shippingCategoryOptions =
        shippingCategoryAtomData &&
        new ShippingCategoryCollectionModel(
            shippingCategoryAtomData,
        ).getShippingCategoryTreeIds();
    // Ok handler
    const onOkHandler = async (values) => {
        try {
            const payload: {
                shipment_product_ids: number[];
                shipping_category_id: string;
            } = {
                shipment_product_ids: productIds,
                shipping_category_id: values.shipping_category_id,
            };
            const data = await updateShippingCategory(payload);
            notification["success"]({
                message: data.message,
            });
            onCancel();
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return (
        <Modal
            title="Change Shipping Category"
            open={isOpen}
            destroyOnClose={true}
            confirmLoading={updateShippingCategoryLoading}
            onCancel={() => onCancel()}
            footer={false}
        >
            <Form
                onFinish={onOkHandler}
                form={form}
                layout="vertical"
                name="update_warehouse"
                ref={formRef}
                initialValues={{
                    shipping_category_id: shipping_category_id,
                }}
            >
                <Form.Item
                    label="Select Shipping Category"
                    name="shipping_category_id"
                    rules={[
                        {
                            required: true,
                            message: "Shipping Category is required",
                        },
                    ]}
                >
                    <TreeSelect
                        showSearch
                        dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
                        placeholder="Please select a shipping category"
                        allowClear
                        loading={isLoading}
                        treeLine={{ showLeafIcon: true }}
                        treeData={shippingCategoryOptions}
                        filterTreeNode={(searchValue, treeNode) => {
                            const title = treeNode.title;
                            return (
                                typeof title === "string" &&
                                title
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={updateShippingCategoryLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
