import React, { ReactElement, Suspense, useEffect, useState } from "react";
import {
    Layout,
    Button,
    Spin,
    Popover,
    Avatar,
    Space,
    Typography,
    Flex,
} from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LoadingOutlined,
    BulbOutlined,
    UserOutlined,
    LogoutOutlined,
    SyncOutlined,
    ProfileOutlined,
} from "@ant-design/icons";
import {
    NotificationBell,
    NovuProvider,
    PopoverNotificationCenter,
} from "@/components/NovuPackage";
import { NOVU_APPLICATION_IDENTIFIER } from "@/consts/storage";
import HarvestJobPopupOver from "@/pages/HarvestJobManage/components/harvestJobPopupOver";
import { Outlet } from "react-router-dom";
import {
    getEnvironmentMode,
    removeCurrentUserPermissionsFromStorage,
    removeSuperAdminFromLocalStorage,
} from "@/utils/helper";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import { useNavigate } from "react-router-dom";
import { loginUserPermission } from "../../lib/core-react/store/store";
import { useAtom } from "jotai";
import Sidebar from "./Sidebar";
import { useGetImpersonateSessionsDetail } from "@/lib/core-react/hooks/private/useRole";
import useToggleState from "@/hooks/useToggleState";
import { tw } from "@/consts/theme/tailwindTheme";
import ScrollToTopButton from "@/components/scroll-to-top";

const { Text } = Typography;
const { Header, Content } = Layout;
const isModeProduction = getEnvironmentMode() !== "development";

const AntDesignLayout: React.FC<{ children?: ReactElement }> = () => {
    const navigate = useNavigate();
    const { getSessionDetail } = useGetImpersonateSessionsDetail();
    const { state, toggle } = useToggleState();
    const [permission] = useAtom(loginUserPermission);
    // State
    const [showHarvestJobDrawer, setShowHarvestJobDrawer] = useState(false);
    const [isImpersonate, setIsImpersonate] = useState(false);

    // Hooks
    const {
        session,
        getNotificationSubscriberId,
        getIsImpersonateIdentity,
        getUserPhoto,
        getUserName,
        logoutUrl,
        isAuthLoading,
    } = useOryAuth();

    const handleCloseDrawer = () => {
        setShowHarvestJobDrawer(false);
    };

    const handleLogOut = () => {
        if (logoutUrl) {
            removeSuperAdminFromLocalStorage();
            removeCurrentUserPermissionsFromStorage();
            window.location.href = logoutUrl;
        }
    };

    useEffect(() => {
        const checkImpersonateIdentity = async () => {
            if (isImpersonate) {
                try {
                    await getSessionDetail();
                } catch (_error) {
                    /* empty */
                }
            }
        };

        checkImpersonateIdentity();
    }, [isImpersonate]);

    useEffect(() => {
        const currentImpersonateStatus = getIsImpersonateIdentity();
        if (currentImpersonateStatus !== isImpersonate) {
            setIsImpersonate(currentImpersonateStatus);
        }
    }, [isImpersonate]);

    // Loader
    if (isAuthLoading && isModeProduction) {
        return <Spin />;
    }

    return (
        <NovuProvider
            stores={[
                {
                    storeId: "Orders",
                    query: { feedIdentifier: "Orders" },
                },
                {
                    storeId: "Shipment Updated",
                    query: { feedIdentifier: "Shipment Updated" },
                },
                {
                    storeId: "Action Needed",
                    query: { feedIdentifier: "Action Needed" },
                },
                {
                    storeId: "Promotions",
                    query: { feedIdentifier: "Promotions" },
                },
                { storeId: "Notice", query: { feedIdentifier: "Notice" } },
            ]}
            subscriberId={getNotificationSubscriberId()}
            applicationIdentifier={NOVU_APPLICATION_IDENTIFIER}
        >
            {/* className={`${styles.layoutStyle}`} */}
            <Layout
                hasSider
                style={{
                    minHeight: "100vh",
                }}
            >
                <Sidebar isMenuCollapsed={state} />

                <Layout style={{ marginInlineStart: 0 }}>
                    <Content
                        style={{
                            // overflow: "initial",
                            paddingLeft: state ? 0 : 250,
                            height: "100%",
                            transition: `${tw.transitionProperty.all} ${tw.transitionDelay[100]} ${tw.transitionTimingFunction.DEFAULT}`,
                            zIndex: 0,
                            backgroundColor: "white",
                        }}
                        // className={`${styles.layoutStyle__content}`}
                    >
                        <Header
                            style={{
                                padding: 0,
                                backgroundColor: "white",
                                width: "100%",
                                borderBottom: tw.border.DEFAULT,
                            }}
                        >
                            <Flex
                                justify="space-between"
                                align="center"
                                style={{ width: "100%" }}
                            >
                                <Button
                                    type="text"
                                    icon={
                                        state ? (
                                            <MenuUnfoldOutlined />
                                        ) : (
                                            <MenuFoldOutlined />
                                        )
                                    }
                                    onClick={() => toggle()}
                                    style={{
                                        fontSize: "16px",
                                        width: 64,
                                        height: 64,
                                    }}
                                />

                                <Flex
                                    align="center"
                                    style={{
                                        gap: "15px",
                                        paddingRight: "15px",
                                    }}
                                >
                                    <Button
                                        type="default"
                                        shape="circle"
                                        icon={
                                            <SyncOutlined
                                                spin={permission.isLoading}
                                            />
                                        }
                                        onClick={async () => {
                                            window.location.reload();
                                        }}
                                        loading={false}
                                    />
                                    {/* Notifications */}
                                    <PopoverNotificationCenter colorScheme="light">
                                        {({ unseenCount }) => (
                                            <NotificationBell
                                                unseenCount={unseenCount}
                                            />
                                        )}
                                    </PopoverNotificationCenter>

                                    {/* Harvest Job */}
                                    <Popover
                                        placement="bottomRight"
                                        content={
                                            <HarvestJobPopupOver
                                                open={true}
                                                onClose={handleCloseDrawer}
                                            />
                                        }
                                        trigger="click"
                                        overlayStyle={{ width: 450 }}
                                    >
                                        <Button type="dashed" shape="circle">
                                            <BulbOutlined />
                                        </Button>
                                    </Popover>

                                    {showHarvestJobDrawer && (
                                        <HarvestJobPopupOver
                                            open={showHarvestJobDrawer}
                                            onClose={handleCloseDrawer}
                                        />
                                    )}

                                    {/* User Avatar with Popover */}

                                    {session &&
                                        isModeProduction &&
                                        logoutUrl && (
                                            <Popover
                                                placement="bottomRight"
                                                content={
                                                    <div
                                                        style={{
                                                            padding: "10px",
                                                            textAlign: "center",
                                                            minWidth: "160px",
                                                        }}
                                                    >
                                                        <Avatar
                                                            size={64}
                                                            style={{
                                                                backgroundColor:
                                                                    "#87d068",
                                                                border: "2px solid #f0f0f0",
                                                                marginBottom:
                                                                    "10px",
                                                            }}
                                                            src={getUserPhoto()}
                                                            icon={
                                                                <UserOutlined />
                                                            }
                                                        />
                                                        <Text
                                                            strong
                                                            style={{
                                                                display:
                                                                    "block",
                                                                marginBottom:
                                                                    "10px",
                                                            }}
                                                        >
                                                            {getUserName() ||
                                                                "Development"}
                                                        </Text>
                                                        <Space
                                                            direction="vertical"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Button
                                                                type="primary"
                                                                icon={
                                                                    <ProfileOutlined />
                                                                }
                                                                block
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/my-profile",
                                                                    )
                                                                }
                                                            >
                                                                My Profile
                                                            </Button>
                                                            <Button
                                                                type="dashed"
                                                                icon={
                                                                    <LogoutOutlined />
                                                                }
                                                                block
                                                                onClick={
                                                                    handleLogOut
                                                                }
                                                            >
                                                                Logout
                                                            </Button>
                                                        </Space>
                                                    </div>
                                                }
                                                trigger="click"
                                            >
                                                <Avatar
                                                    size="default"
                                                    style={{
                                                        backgroundColor:
                                                            "#87d068",
                                                        cursor: "pointer",
                                                        boxShadow:
                                                            "0 2px 8px rgba(0, 0, 0, 0.15)",
                                                    }}
                                                    icon={<UserOutlined />}
                                                />
                                            </Popover>
                                        )}
                                </Flex>
                            </Flex>
                        </Header>
                        {/* className={`site-layout-background ${styles.layoutStyle__contentAuth}`} */}
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                zIndex: 0,
                                backgroundColor: "white",
                            }}
                        >
                            <Suspense
                                fallback={
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{ fontSize: 24 }}
                                                spin
                                            />
                                        }
                                    />
                                }
                            >
                                <Outlet />
                            </Suspense>
                        </div>

                        {/* <Footer style={{ textAlign: "center" }}>
                            Powered by Moveon Global ©
                            {new Date().getFullYear()}
                        </Footer> */}
                        <ScrollToTopButton />
                    </Content>
                </Layout>
            </Layout>
        </NovuProvider>
    );
};

export default AntDesignLayout;
