import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useUpdateRfqApprove } from "@/lib/core-react/hooks/private/useRfq";
import { RfqRequestModel } from "@/models/rfqRequestCollectionModel";
import { IRfqRequestsApprovePayload } from "@/types/rfqRequestCreate";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import {
    Form,
    DatePicker,
    Select,
    Button,
    Flex,
    Typography,
    InputNumber,
    Switch,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
interface Props {
    rfqRequestData: RfqRequestModel;
    onSuccess: () => void;
}
const ApproveRFQForm = ({ rfqRequestData, onSuccess }: Props) => {
    const { updateRequestsApprove, isLoading: isApproveLoading } =
        useUpdateRfqApprove();
    const {
        allOptionsData: { agentCompanyOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const [form] = Form.useForm();

    const [isCommissionAdd, setIsCommissionAdd] = useState<boolean>(false);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.AGENT_COMPANY);
    }, []);

    const handleApprove = async (inputData) => {
        try {
            const time = moment(inputData.valid_until.$d).format(
                "YYYY-MM-DD HH:mm:ss",
            );

            let payload = {} as IRfqRequestsApprovePayload;

            if (inputData?.commission_rate && isCommissionAdd) {
                payload = {
                    source_agent_company_ids:
                        inputData?.source_agent_company_ids,
                    valid_until: time,
                    commission_rate: inputData?.commission_rate,
                    commission_rate_type: inputData?.commission_rate_type,
                };
            } else {
                payload = {
                    source_agent_company_ids:
                        inputData?.source_agent_company_ids,
                    valid_until: time,
                };
            }

            await updateRequestsApprove({
                requestId: `${rfqRequestData.getId()}`,
                payload,
            });

            form.resetFields();
            onSuccess();
            showSuccessAlert("Successfully RFQ Request Approved");
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form form={form} onFinish={handleApprove} layout="vertical">
            <>
                <Form.Item
                    style={{ width: "100%" }}
                    name="valid_until"
                    label="Valid Until"
                    rules={[
                        {
                            required: true,
                            message: "Please provide valid until date ",
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: "100%" }}
                        showTime
                        format={"DD/MM/YYYY"}
                    />
                </Form.Item>

                <Form.Item
                    label="Source Agent Company"
                    name="source_agent_company_ids"
                    style={{ color: "red" }}
                    rules={[
                        {
                            required: true,
                            message: "Please select at least one Company",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear={true}
                        showSearch
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase(),
                                )
                        }
                        style={{ width: "100%" }}
                        placeholder="Please select a company"
                        loading={agentCompanyOptionsData.isLoading}
                        options={agentCompanyOptionsData.options}
                        notFoundContent={
                            agentCompanyOptionsData.isLoading
                                ? LoaderSmall
                                : null
                        }
                    />
                </Form.Item>
            </>

            <Flex vertical gap={4}>
                <Flex align="center" gap={12}>
                    <Typography.Text strong>Commission</Typography.Text>
                    <Switch
                        size="small"
                        onChange={() => setIsCommissionAdd(!isCommissionAdd)}
                    />
                </Flex>
                <Flex justify="space-between" gap={8}>
                    <Form.Item
                        style={{
                            width: "100%",
                        }}
                        initialValue={CommissionRateTypeEnums.FIXED}
                        name={"commission_rate_type"}
                        rules={[{ required: true, message: "Required" }]}
                    >
                        <Select
                            disabled={!isCommissionAdd}
                            placeholder="Rate type"
                            options={transformEnumToLabeledValue(
                                CommissionRateTypeEnums,
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            width: "100%",
                        }}
                        name={"commission_rate"}
                    >
                        <InputNumber
                            disabled={!isCommissionAdd}
                            min={0.0000001}
                            style={{
                                width: "100%",
                            }}
                            placeholder="Rate"
                        />
                    </Form.Item>
                </Flex>
            </Flex>

            <Flex justify="end">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isApproveLoading}
                >
                    Approved
                </Button>
            </Flex>
        </Form>
    );
};

export default ApproveRFQForm;
