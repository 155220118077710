//Operations

export enum ShipmentProductOperationEnum {
    AddShipmentProductPackage = "add-shipment-product-package",
}

export enum ShipmentProductCollectionEnums {
    ShipmentProductCollection = "ShipmentProductCollection",
    ShipmentProduct = "ShipmentProduct",
}

export enum ShipmentProductMeasurementPackagesWeightUnitEnum {
    KG = "kg",
    LB = "lb",
}

export enum ShipmentProductMeasurementPackagesLengthUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesWidthUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesHeightUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesCbmUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductCommonUnitEnum {
    CM = "cm",
    IN = "in",
}

export enum ShipmentProductStatusEnum {
    PENDING = "shipment-pending",
    APPROVED = "shipment-approved",
    REJECTED = "shipment-rejected",
    ASSIGNED_AGENT = "shipment-assigned-agent",
    SHIPPING_STARTED = "shipment-shipping-started",
    NOT_ARRIVED = "shipment-not-arrived",
    SHIPPING_CANCELLED = "shipment-shipping-cancelled",
    ARRIVED_AT_WAREHOUSE = "shipment-arrived-at-warehouse",
    HANDOVER_TO_TRAVEL_AGENT = "shipment-handover-to-travel-agent",
    HANDOVER_TO_MOVEON = "shipment-handover-to-moveon",
    PREPARING_FOR_TRANSPORT = "shipment-preparing-for-transport",
    HANDOVER_TO_SHIP = "shipment-handover-to-ship",
    HANDOVER_TO_AIRLINE = "shipment-handover-to-airline",
    ARRIVED_AT_DESTINATION_PORT = "shipment-arrived-at-destination-port",
    ARRIVED_AT_DESTINATION_AIRPORT = "shipment-arrived-at-destination-airport",
    CUSTOMS_RELEASED = "shipment-customs-released",
    RECEIVED_BY_MOVEON = "shipment-received-by-moveon",
}
