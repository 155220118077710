import { IPublishToAllPayload } from "@/types/notification.";
import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class NotificationResource extends BaseResource {
    publishToAll(
        payload: IPublishToAllPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/manager/admin/notification/v1/notifications/publish-to-all`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
}

export default NotificationResource;
