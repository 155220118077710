import { BuyProductModel } from "@/models/buyProductCollectionModel";

export type IBuyProductActionsType =
    | "reject_bulk_buy_product"
    | "reject_buy_product"
    | "cancel_buy_product"
    | "approved_buy_product"
    | "approved_buy_product_bulk"
    | "take_actions_buy_product"
    | "update_shipping_price_buy_product"
    | "sync_buy_product"
    | "quick_view_buy_product"
    | "update_shipping_category"
    | "update_agent_category_price_read"
    | "add_admin_note"
    | "admin-buy-product-fx-update"
    | "admin-buy-product-update"
    | "admin-buy-product-add-customer-note";

export type IFxActionsType = "update_fx" | "set_current_fx";

export type ICampaignActionsType =
    | "create_campaign"
    | "update_campaign"
    | "delete_campaign"
    | "add_to_campaign";

export type IHarvestActionsType =
    | "harvest_create"
    | "harvest_update"
    | "harvest_delete";

export type IShipmentProductActionsType =
    | "approve_and_assign_agent"
    | "approve_rfq_product"
    | "bid_list"
    | "change_shipping_type"
    | "change_shipping_mode"
    | "change_shipping_category"
    | "change_destination_warehouse"
    | "change_source_country"
    | "tracking"
    | "update_package_shipment_product"
    | "add_package_shipment_product"
    | "won_source_add_package_shipment_product"
    | "delete_package_shipment_product"
    | "quick_view_package_shipment_product"
    | "change_shipping_country"
    | "cancel_shipment_product"
    | "approved_shipment_product"
    | "reject_shipment_product"
    | "approved_bulk_shipment_product"
    | "reject_bulk_shipment_product"
    | "add_tracking_shipment_product"
    | "update_tracking_shipment_product"
    | "attach_images_shipment_product"
    | "shipment_product_change_rate"
    | "shipment_product_change_customer_rate"
    | "bulk_shipment_product_change_rate";

export type IInventoryProductActionsType =
    | "add_product_to_campaign"
    | "delete_product_from_campaign";

export type IInvoiceActionsType = "settlement" | "pay-invoice" | "bulk-settle";
export type RFQActionsType =
    | "rfq-approve"
    | "rfq-reject"
    | "rfq-create"
    | "rfq-update"
    | "rfq-update-single-field"
    | "rfq-bulk-approve";

export type IModalActionsType =
    | IBuyProductActionsType
    | IFxActionsType
    | ICampaignActionsType
    | IHarvestActionsType
    | IShipmentProductActionsType
    | IInventoryProductActionsType
    | RFQActionsType
    | "pay-invoice"
    | "make_refund"
    | "refetch"
    | false;

export interface IModalData {
    action: IModalActionsType;
    data?: any;
}

export const defaultModalData: IModalData = {
    action: false,
    data: null,
};

export interface IBuyProductBulkActionsProps {
    selectedRows: BuyProductModel[];
    handleModal: (payload: IModalData) => void;
}
export interface IActionsProps {
    handleModal: (payload: IModalData) => void;
}
export const getModalTitle = (type: IModalActionsType): string => {
    switch (type) {
        case "update_fx":
            return "Update Customer FX";
        case "quick_view_buy_product":
            return "Quick View";
        case "create_campaign":
            return "Create Campaign";
        case "update_shipping_price_buy_product":
            return "Update shipping price ";
        case "take_actions_buy_product":
            return "Take action for the product ";
        case "update_agent_category_price_read":
            return "Edit Shipping Info ";
        case "make_refund":
            return "Create Refund";

        default:
            return "";
    }
};

export const getModalWidth = (
    type: IModalActionsType,
): string | number | undefined => {
    switch (type) {
        case "approve_and_assign_agent":
            return "100%";
        case "take_actions_buy_product":
            return "50%";

        default:
            return undefined;
    }
};
