import { useService } from "@/lib/core-react/contexts";
import {
    agentInvoiceCollectionAtom,
    invoiceCollectionAtom,
    customerInvoiceDetailsAtom,
    agentInvoiceDetailsAtom,
    paymentSessionResponseAtom,
    invoiceCalculationAtom,
} from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useAtom, useSetAtom } from "jotai";
import { message } from "antd";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import {
    IPaymentSessionPayPayload,
    IPaymentSessionResponse,
} from "@/types/paymentSession";
import { useState } from "react";
interface UseInvoice {
    settleBulk: (payload: { invoice_ids: number[] }) => Promise<void>;
    calculateInvoices: (payload: { invoice_ids: number[] }) => Promise<void>;
    calculateAgentInvoices: (payload: {
        invoice_ids: number[];
    }) => Promise<void>;
    getCustomerInvoice: (params?: string) => Promise<void>;
    getAgentInvoice: (params?: string) => Promise<void>;
    getInvoiceDetailsById: (id: number | string) => Promise<void>;
    getAgentInvoiceDetailsById: (id: number | string) => Promise<void>;
    recalculateInvoiceData: (
        id: number | string,
        requestFor: "customer" | "agent",
    ) => Promise<void>;
    settleInvoice: (
        id: number | string,
        requestFor: "customer" | "agent",
    ) => Promise<void>;
    handledownloadData: (
        invoiceId: string | number,
        requestFor?: "customer" | "agent",
    ) => Promise<any>;
    createSession: (user_id: number, invoice_ids: number[]) => Promise<any>;
    getSession: (
        user_id: number,
        session_token: string,
    ) => Promise<IPaymentSessionResponse | undefined>;
    paySession: (
        user_id: number,
        session_token: string,
        payload: IPaymentSessionPayPayload,
    ) => Promise<unknown>;
    isLoading: boolean;
}

export const useInvoice = (): UseInvoice => {
    const [invoice, setInvoice] = useAtom(invoiceCollectionAtom);
    const [agentInvoice, setAgentInvoice] = useAtom(agentInvoiceCollectionAtom);
    const setCustomerInvoiceDetails = useSetAtom(customerInvoiceDetailsAtom);
    const setAgentInvoiceDetails = useSetAtom(agentInvoiceDetailsAtom);
    const setPaymentSessionResponse = useSetAtom(paymentSessionResponseAtom);
    const [invoiceCalculation, setInvoiceCalculation] = useAtom(
        invoiceCalculationAtom,
    );
    const [isLoading, setIsLoading] = useState(false);

    const { invoiceService } = useService();

    const calculateInvoices = async (payload: {
        invoice_ids: number[];
    }): Promise<void> => {
        setInvoiceCalculation({
            ...invoiceCalculation,
            isLoading: true,
            error: null,
        });
        try {
            setIsLoading(true);
            const response =
                await invoiceService.invoiceResource.calculateInvoices(payload);
            setInvoiceCalculation({
                ...invoiceCalculation,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setInvoice({
                ...invoice,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            setIsLoading(false);
            throw error;
        }
    };

    const calculateAgentInvoices = async (payload: {
        invoice_ids: number[];
    }): Promise<void> => {
        setInvoiceCalculation({
            ...invoiceCalculation,
            isLoading: true,
            error: null,
        });
        try {
            setIsLoading(true);
            const response =
                await invoiceService.invoiceResource.calculateAgentInvoices(
                    payload,
                );
            setInvoiceCalculation({
                ...invoiceCalculation,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setInvoice({
                ...invoice,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            setIsLoading(false);
            throw error;
        }
    };

    const settleBulk = async (payload: {
        invoice_ids: number[];
    }): Promise<void> => {
        setInvoice({ ...invoice, isLoading: true, error: null });
        try {
            setIsLoading(true);
            const response =
                await invoiceService.invoiceResource.settleBulk(payload);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const getCustomerInvoice = async (params?: string): Promise<void> => {
        setInvoice({ ...invoice, isLoading: true, error: null });
        try {
            const response =
                await invoiceService.invoiceResource.getCustomerInvoices(
                    params,
                );
            setInvoice({
                ...invoice,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setInvoice({
                ...invoice,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };

    const getAgentInvoice = async (params?: string): Promise<void> => {
        setAgentInvoice({ ...agentInvoice, isLoading: true, error: null });
        try {
            const response =
                await invoiceService.invoiceResource.getAgentInvoices(params);
            setAgentInvoice({
                ...agentInvoice,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAgentInvoice({
                ...agentInvoice,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            // throw error;
        }
    };

    const getInvoiceDetailsById = async (
        id: number | string,
    ): Promise<void> => {
        setCustomerInvoiceDetails((pre) => ({
            ...pre,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await invoiceService.invoiceResource.getCustomerInvoiceDetails(
                    id,
                );
            setCustomerInvoiceDetails((pre) => ({
                ...pre,
                data: response.data,
                isLoading: false,
                error: null,
            }));
        } catch (error: any) {
            setCustomerInvoiceDetails((pre) => ({
                ...pre,
                refetch: false,
                isLoading: false,
                error: getError(error),
            }));
            throw error;
        }
    };

    const getAgentInvoiceDetailsById = async (
        id: number | string,
    ): Promise<void> => {
        setAgentInvoiceDetails((pre) => ({
            ...pre,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await invoiceService.invoiceResource.getAgentInvoiceDetails(id);
            setAgentInvoiceDetails((pre) => ({
                ...pre,
                data: response.data,
                isLoading: false,
                error: null,
            }));
        } catch (error: any) {
            setAgentInvoiceDetails((pre) => ({
                ...pre,
                refetch: false,
                isLoading: false,
                error: getError(error),
            }));
            // throw error;
        }
    };

    const recalculateInvoiceData = async (
        invoiceId: string | number,
        requestFor: "customer" | "agent" = "customer",
    ) => {
        try {
            const response =
                await invoiceService.invoiceResource.recalculateInvoice(
                    invoiceId,
                    requestFor,
                );
            if (response) {
                if (requestFor === "customer") {
                    setInvoice({
                        ...invoice,
                        isLoading: false,
                        refetch: true,
                    });
                } else {
                    setAgentInvoice({
                        ...agentInvoice,
                        isLoading: false,
                        refetch: true,
                    });
                }
            }
        } catch (e: any) {
            if (e?.response?.status === 422) {
                const errorMessage = e.response.data.message;
                message.error(errorMessage);
            } else {
                message.error("Server error, try again later");
            }
            throw e;
        }
    };

    const handleSettleInvoiceData = async (
        invoiceId: string | number,
        requestFor: "customer" | "agent" = "customer",
    ) => {
        try {
            const response =
                await invoiceService.invoiceResource.settleInvoiceTransaction(
                    invoiceId,
                    requestFor,
                );
            if (response) {
                if (requestFor === "customer") {
                    setInvoice({
                        ...invoice,
                        isLoading: false,
                        refetch: true,
                    });
                } else {
                    setAgentInvoice({
                        ...agentInvoice,
                        isLoading: false,
                        refetch: true,
                    });
                }
            }
        } catch (e: any) {
            if (e?.response?.status === 422) {
                const errorMessage = e.response.data.message;
                message.error(errorMessage);
            } else {
                message.error("Server error, try again later");
            }

            throw e;
        }
    };

    const handledownloadData = async (
        invoiceId: string | number,
        requestFor: "customer" | "agent" = "customer",
    ) => {
        setIsLoading(true);

        try {
            const response =
                await invoiceService.invoiceResource.downloadInvoiceTransaction(
                    invoiceId,
                    requestFor,
                );
            setIsLoading(false);
            return response;
        } catch (e: any) {
            if (e?.response?.status === 422) {
                const errorMessage = e.response.data.message;
                message.error(errorMessage);
                setIsLoading(false);
            } else {
                message.error("Server error, try again later");
                setIsLoading(false);
            }
            throw e;
        }
    };

    const settleInvoice = async (
        invoiceId: string | number,
        requestFor: "customer" | "agent" = "customer",
    ) => {
        try {
            return await handleSettleInvoiceData(invoiceId, requestFor);
        } catch (error) {
            throw error;
        }
    };

    const createSession = async (user_id: number, invoice_ids: number[]) => {
        try {
            const res = await invoiceService.invoiceResource.createSession(
                user_id,
                invoice_ids,
            );
            return res;
        } catch (err) {
            message.error(getErrorMessage(err));
            throw err;
        }
    };

    const getSession = async (user_id: number, session_token: string) => {
        try {
            setPaymentSessionResponse((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const res = await invoiceService.invoiceResource.getSession(
                user_id,
                session_token,
            );

            setPaymentSessionResponse({
                isLoading: false,
                data: res,
            });

            return res;
        } catch (err) {
            setPaymentSessionResponse((prev) => ({
                ...prev,
                isLoading: false,
            }));
            message.error(getErrorMessage(err));
        }
    };

    const paySession = async (
        user_id: number,
        session_token: string,
        payload: IPaymentSessionPayPayload,
    ) => {
        try {
            setIsLoading(true);
            const res = await invoiceService.invoiceResource.paySession(
                user_id,
                session_token,
                payload,
            );

            setIsLoading(false);
            return res;
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    return {
        getCustomerInvoice,
        getInvoiceDetailsById,
        getAgentInvoiceDetailsById,
        getAgentInvoice,
        recalculateInvoiceData,
        settleInvoice,
        handledownloadData,
        createSession,
        getSession,
        paySession,
        settleBulk,
        calculateInvoices,
        calculateAgentInvoices,
        isLoading,
    } as const;
};
