import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IPurchaseRuleGroupModificationFormData,
    IPurchaseRuleGroupUserModificationFormData,
} from "@/types/purchaseRuleGroupCollection";
import QueryString from "qs";

class PurchaseRuleGroupResource extends BaseResource {
    path = "/api/purchase/admin/purchase-rule-group/v1/purchase-rule-groups";
    usersPath = "/api/purchase/admin/purchase-rule-group/v1/users";

    createPurchaseRuleGroup(
        payload: IPurchaseRuleGroupModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            this.path,
            payload,
            {},
            customHeaders,
        );
    }

    updatePurchaseRuleGroup(
        id: number,
        payload: IPurchaseRuleGroupModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.path}/${id}`,
            payload,
            {},
            customHeaders,
        );
    }

    deletePurchaseRuleGroup(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "DELETE",
            `${this.path}/${id}`,
            undefined,
            {},
            customHeaders,
        );
    }

    getPurchaseRuleGroupCollection(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = this.path;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getPurchaseRuleGroupUserCollection(
        params: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const { id, ...restParams } = params;
        let path = `${this.usersPath}/${id}`;
        if (Object.keys(restParams).length > 0) {
            path = `${path}?${QueryString.stringify(restParams)}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createPurchaseRuleGroupUser(
        id: number,
        payload: IPurchaseRuleGroupUserModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            `${this.usersPath}/${id}`,
            payload,
            {},
            customHeaders,
        );
    }

    deletePurchaseRuleGroupUser(
        id: number,
        payload: IPurchaseRuleGroupUserModificationFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "DELETE",
            `${this.usersPath}/${id}`,
            payload,
            {},
            customHeaders,
        );
    }
}

export default PurchaseRuleGroupResource;
