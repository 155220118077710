import React, { useState } from "react";
import { Button, notification, Steps } from "antd";

import { IUnlistedPackageClaimFormDataRaw } from "@/types/unlistedPackageCollection";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";
import {
    useUnlistedPackagesClaim,
    useUnlistedProductsGet,
} from "@/lib/core-react/hooks/private/useShipping";
import { showError } from "@/helpers/showError";

import ProductSelection from "./ProductSelection";
import CategoryPriceSelection from "./CategoryPriceSelection";
import { ClaimWithPriceModal } from "./ClaimWithPriceModal";

interface IProps {
    data: UnlistedPackageModel;
    onFinish: () => void;
}

const ClaimStepper = ({ data, onFinish }: IProps) => {
    const { getUnlistedProducts } = useUnlistedProductsGet();
    const { claim, isLoading: isLoadingClaim } = useUnlistedPackagesClaim();

    const [claimValues, setClaimValues] =
        useState<IUnlistedPackageClaimFormDataRaw>();
    const [claimWithPriceModalOpen, setClaimWithPriceModalOpen] =
        useState(false);

    const onFinishClaim = async () => {
        const dw_id = data.getDestinationWarehouse().getId();
        const pkg_id = data.getId();
        const agent_category_price_read_id =
            claimValues?.agent_category_price_read?.getId();
        const shipment_product_id = claimValues?.shipment_product?.getId();

        if (agent_category_price_read_id && shipment_product_id) {
            try {
                await claim(dw_id, pkg_id, {
                    agent_category_price_read_id: agent_category_price_read_id,
                    shipment_product_id: shipment_product_id,
                });
                notification.success({
                    message: "Claimed Succesfully",
                });
                await getUnlistedProducts();
                onFinish();
            } catch (error) {
                showError(error);
            }
        }
    };

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: "Select product",
            content: (
                <ProductSelection data={data} setClaimValues={setClaimValues} />
            ),
        },
        {
            title: "Select category price",
            content: (
                <CategoryPriceSelection
                    data={data}
                    claimValues={claimValues}
                    setClaimValues={setClaimValues}
                />
            ),
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const contentStyle: React.CSSProperties = {
        maxHeight: "460px",
        overflow: "auto",
    };

    const isClaimDisabled =
        !claimValues?.shipment_product?.getId() ||
        !claimValues?.agent_category_price_read?.getId();

    return (
        <>
            <Steps current={current} items={items} />
            <div style={contentStyle}>{steps[current].content}</div>
            <div style={{ marginTop: 24 }}>
                {current < steps.length - 1 && (
                    <Button
                        disabled={!claimValues?.shipment_product}
                        onClick={() => next()}
                    >
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button
                        loading={isLoadingClaim}
                        disabled={isClaimDisabled}
                        onClick={onFinishClaim}
                    >
                        Claim
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button
                        style={{ margin: "0 8px" }}
                        disabled={isClaimDisabled || isLoadingClaim}
                        onClick={() => setClaimWithPriceModalOpen(true)}
                    >
                        Claim With Price
                    </Button>
                )}
                {current > 0 && (
                    <Button
                        style={{ margin: "0 8px" }}
                        disabled={isLoadingClaim}
                        onClick={() => prev()}
                    >
                        Previous
                    </Button>
                )}
            </div>

            {claimWithPriceModalOpen && claimValues && (
                <ClaimWithPriceModal
                    claimValues={claimValues}
                    data={data}
                    onCancel={() => setClaimWithPriceModalOpen(false)}
                    onFinish={onFinish}
                    open={claimWithPriceModalOpen}
                />
            )}
        </>
    );
};

export default ClaimStepper;
