import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { defaultDataMinimal } from "@/filters/utils";
import { useService } from "@/lib/core-react/contexts";
import { useState } from "react";

export const useGetPurchaseRuleGroupMinimal = () => {
    const { purchaseService } = useService();
    const [purchaseRuleGroupOptionsData, setPurchaseRuleGroupOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getPurchaseRuleGroup = async (params: string): Promise<void> => {
        try {
            setPurchaseRuleGroupOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await purchaseService.purchaseRuleGroupResource.getPurchaseRuleGroupCollection(
                    params,
                );

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setPurchaseRuleGroupOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setPurchaseRuleGroupOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getPurchaseRuleGroup, purchaseRuleGroupOptionsData } as const;
};
