import { Button, Flex, Form } from "antd";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
import { useAddCustomerNote } from "@/lib/core-react/hooks/private/usePurchase";
import { IModalData } from "@/helpers/getModalTital";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

interface IProps {
    buyProductId: number;
    customerNote: string | null;
    handleModal: (payload: IModalData) => void;
}

const AddCustomerNoteForm = ({
    buyProductId,
    customerNote,
    handleModal,
}: IProps) => {
    const [form] = Form.useForm();
    const { refetchListApi, refetchDetailApi } = useRefetch();
    const { id, productId } = useParams();

    const { addCustomerNote, isLoading } = useAddCustomerNote();

    const handleAddNote = async (values) => {
        try {
            await addCustomerNote(buyProductId, {
                customer_note: values.customer_note,
            });
            showSuccessAlert(
                customerNote
                    ? "Successfully Added Customer Note"
                    : "Successfully updated Customer Note",
            );
            handleModal({
                action: false,
            });

            await refetchListApi();
            if (id) {
                await refetchDetailApi(id);
            } else if (productId) {
                await refetchDetailApi(productId);
            }
        } catch (error) {
            showError(error, form);
            handleModal({
                action: false,
            });
        }
    };

    return (
        <>
            <Form form={form} onFinish={handleAddNote}>
                <Form.Item name={"customer_note"}>
                    <TextArea
                        defaultValue={`${customerNote || ""}`}
                        rows={6}
                        size="large"
                        placeholder="write customer note here..."
                    />
                </Form.Item>

                <Flex justify="end">
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Flex>
            </Form>
        </>
    );
};

export default AddCustomerNoteForm;
