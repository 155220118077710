import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface IframePortalProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    title?: string;
}

export function IframePortal({ children, style, title }: IframePortalProps) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [mountNode, setMountNode] = useState<HTMLBodyElement | null>(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const doc = iframe.contentDocument;
            if (doc && doc.body) {
                setMountNode(doc.body as HTMLBodyElement);
            }
        }
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const doc = iframe.contentDocument;
            if (doc) {
                const editorElement = doc.querySelector(".tiptap.ProseMirror");

                if (editorElement) {
                    (editorElement as HTMLElement).style.padding = "8px";
                }

                const trailingBreak = doc.querySelector(
                    ".ProseMirror-trailingBreak",
                );
                trailingBreak?.remove();
            }
        }
    }, [mountNode]);

    return (
        <iframe ref={iframeRef} style={style} title={title || "iframe"}>
            {mountNode && ReactDOM.createPortal(children, mountNode)}
        </iframe>
    );
}
