import { Flex, Tag, Typography } from "antd";
import { memo } from "react";
import { IModalData } from "@/helpers/getModalTital";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";

interface Props {
    unlistedPackageModel: UnlistedPackageModel;
    handleModal: (payload: IModalData) => void;
}

const { Text } = Typography;

const ShippingInfo = ({ unlistedPackageModel }: Props) => {
    const CBM = unlistedPackageModel.getCbm();
    const cartonNumber = unlistedPackageModel.getCartonNumber();
    const rate = unlistedPackageModel.getRate();
    const agentWarehouse =
        unlistedPackageModel?.getAgentWarehouse()?.getName() || "N/A";
    const destinationWarehouse =
        unlistedPackageModel?.getDestinationWarehouse()?.getName() || "N/A";
    const estimatedBill = unlistedPackageModel.getEstimatedBill();
    const estimatedProfit = unlistedPackageModel.getEstimatedProfit();

    return (
        <Flex gap={8} vertical>
            <Flex gap={12}>
                <Flex vertical gap={4}>
                    <Typography.Text>
                        <Text>CBM: </Text>
                        <Text strong>
                            {CBM ? (
                                <Tag color="lime-inverse">
                                    {" "}
                                    {CBM} m<sup>3</sup>{" "}
                                </Tag>
                            ) : (
                                "N/A"
                            )}
                        </Text>
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Carton Number: </Text>
                        <Text strong copyable>
                            {cartonNumber}
                        </Text>
                    </Typography.Text>

                    <Typography.Text>
                        <Text> Rate: </Text>
                        <Tag color="geekblue-inverse">
                            {rate ? rate : "N/A"}
                        </Tag>
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Agent Warehouse: </Text>
                        <Text strong>{agentWarehouse}</Text>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Destination Warehouse: </Text>
                        <Text strong>{destinationWarehouse}</Text>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Estimated Bill: </Text>
                        <Text strong>{estimatedBill}</Text>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Estimated Profit: </Text>
                        <Text strong>{estimatedProfit}</Text>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Tracking: </Text>
                        <Text strong copyable>
                            {unlistedPackageModel?.getTrackings().getTracking()}
                        </Text>
                    </Typography.Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default memo(ShippingInfo);
