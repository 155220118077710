import {
    IRegionCollection,
    IRegionPostRequest,
} from "@/types/regionCollection";
import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { ICurrentCurrencyResponse } from "@/types/currencyCollection";

class RegionResource extends BaseResource {
    path = `/api/core/admin/region/v1/regions`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IRegionCollection> {
        const path = `${this.path}?expand=currency,countries,languages${params ? `&${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getCurrency(
        params: { region: string; [key: string]: any },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<ICurrentCurrencyResponse> {
        // /api/core/admin/region/v1/regions/current?region=BD
        const path = `${this.path}/current${this.addQuery(params)}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: IRegionPostRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    update(
        id: number,
        payload: IRegionPostRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
}

export default RegionResource;
