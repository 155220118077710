export const BUY_ORDER_DETAIL_PAGE = "/purchase/buy-orders/edit";
export const BUY_ORDER_LIST_PAGE = "/purchase/buy-orders";
export const BUY_PRODUCT_LIST_PAGE = "/purchase/buy-products";
export const BUY_CREATE_CUSTOM_ORDER_PAGE =
    "/purchase/buy-orders/create-custom-order";
export const BUY_PRODUCT_DETAIL_PAGE = "/purchase/buy-products/detail";
export const SHIPMENT_PRODUCTS_PAGE = "/shipping/shipment-products";
export const SHIPMENT_PRODUCTS_DETAIL_PAGE = "/shipping/shipment-products/edit";

export const SHIPMENT_ORDERS_PAGE = "/shipping/shipment-orders";

export const BASE_SHIPPING_CATEGORIES = "/shipping/global-categories";

export const SHIPPING_CATEGORIES = "/shipping/regional-categories";

export const SHIPPING_COUNTRY = "/region/shipping-countries";
export const BUY_PRODUCT_UPDATE_PAGE = "/purchase/buy-products/update";
